<template>
    <card class="card">
        <cardHeader class="text-center">
            <h2>Verify Identity</h2>
        </cardHeader>
        <cardBody>
            <div class="col-md-12 text-center">
                <p>We will now Verify and Validate your Identity.</p>
            </div>
            <div class="col-md-12 text-center">
                <p>Choose an Identity Document Type.</p>
            </div>
            <div class="col-md-12 text-center">
                <label style="font-size: 14px" for="rbtn1"><input id="rbtn1" v-model="docType" type="radio" title="License/State ID" value="1"> <b>License/State ID</b></label> &nbsp;&nbsp;&nbsp;
                <label style="font-size: 14px" for="rbtn2"><input id="rbtn2" v-model="docType" type="radio" title="Passport" value="2"> <b>Passport</b></label>
            </div>
            <div class="col-md-12 text-center" style="margin-top:2rem" v-show="!takePhoto">
                <img src="/images/driving-license-new.png" style="width:50rem; height:35rem" alt="" class="img-responsive m-auto" v-show="docType == 1">
                <img src="/images/passport.png" style="width:50rem; height:35rem" alt="" class="img-responsive m-auto" v-show="docType == 2">
                <i>Sample</i>
            </div>

            <div class="col-md-12 text-center" style="margin-top:2rem" v-show="takePhoto">
                <div class="col-md-12">
                    <video id="webcam" autoplay playsinline style="width:640px; height:480px; background:#CCC;"></video>
                    <canvas id="canvas" style="display: none;"></canvas>
                    <h1 v-if="loadingCamera">Loading Camera</h1>
                </div>
                <div class="col-md-12" style="margin-top:2rem;">
                    <k-button @click="snap" title="Click to snap" class="k-primary" style="width: 10rem;">SNAP</k-button> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                    <k-button @click="cancelVideoCapture" title="Click to cancel" class="k-primary" style="width: 10rem;">CANCEL</k-button>
                </div>
            </div>

            <div class="col-md-12 text-center" style="margin-top:3rem" v-if="docType == 1">
                <div class="col-md-6">
                    <div class="col-md-12">
                        <h3>Front</h3>
                    </div>
                    <div class="col-md-12">
                        <div id="dragContainer" v-cloak @drop.prevent="handleFrontFileAddition" @dragover.prevent>
                            <div v-if="frontFile == ''">
                                <img src="/images/ic_cloud_upload.png" style="width:8rem;height:8rem;margin-top:.1rem;">
                                <p>
                                    Drag & Drop Photo
                                </p>
                                <p>
                                    OR
                                </p>
                                <input type="file" style="display: none" id="frontFileUploadIdentityPicture" accept="image/png, image/jpeg" @change="frontFileUpdated($event)">
                                <k-button onclick="$('#frontFileUploadIdentityPicture').trigger('click');" class="btn-link" style="width: 15rem" title="Click to browse">Browse Photo</k-button>
                                <p style="margin-top:1rem">
                                    OR
                                </p>
                                <k-button class="btn-link" style="width: 15rem; margin-bottom:3rem;" @click="takePhotoFromCamera(true)" title="Click to take">Take Photo</k-button>
                            </div>
                            <div v-if="frontFile != ''">
                                <img :src="frontFile" id="img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="col-md-12">
                        <h3>Back</h3>
                    </div>
                    <div class="col-md-12">
                        <div id="dragContainer" v-cloak @drop.prevent="handleBackFileAddition" @dragover.prevent>
                            <div v-if="backFile == ''">
                                <img src="/images/ic_cloud_upload.png" style="width:8rem;height:8rem;margin-top:.1rem;">
                                <p>
                                    Drag & Drop Photo
                                </p>
                                <p>
                                    OR
                                </p>
                                <input type="file" style="display: none" id="backFileUploadIdentityPicture" accept="image/png, image/jpeg" @change="backFileUpdated($event)">
                                <k-button onclick="$('#backFileUploadIdentityPicture').trigger('click');" class="btn-link" style="width: 15rem" title="Click to browse">Browse Photo</k-button>
                                <p style="margin-top:1rem">
                                    OR
                                </p>
                                <k-button class="btn-link" style="width: 15rem; margin-bottom:3rem;" @click="takePhotoFromCamera(false)" title="Click to take">Take Photo</k-button>
                            </div>
                            <div v-if="backFile != ''">
                                <img :src="backFile" id="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" style="margin-top:2rem" v-if="docType == 2">
                <div id="dragContainer" v-cloak @drop.prevent="handleFrontFileAddition" @dragover.prevent>
                    <div v-if="frontFile == ''">
                        <img src="/images/ic_cloud_upload.png" style="width:8rem;height:8rem;margin-top:.1rem;">
                        <p>
                            Drag & Drop Photo
                        </p>
                        <p>
                            OR
                        </p>
                        <input type="file" style="display: none" id="frontFileUploadIdentityPicture" accept="image/png, image/jpeg" @change="frontFileUpdated($event)">
                        <k-button onclick="$('#frontFileUploadIdentityPicture').trigger('click');" class="btn-link" style="width: 15rem" title="Click to browse">Browse Photo</k-button>
                        <p style="margin-top:1rem">
                            OR
                        </p>
                        <k-button class="btn-link" style="width: 15rem; margin-bottom: 3rem;" @click="takePhotoFromCamera(true)" title="Click to take">Take Photo</k-button>
                    </div>
                    <div v-if="frontFile != ''">
                        <img :src="frontFile" id="img" />
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center">
                <k-button style="background-color: red; color: white; width:15rem;" :primary="true" @click="handleClearingPhotos" title="Click to clear">CLEAR PHOTOS</k-button>&nbsp;&nbsp;&nbsp;
                <k-button :primary="true" :disabled="disabled" @click="upload" style="width: 15rem;" title="Click to confirm">CONFIRM</k-button>
            </div>
            <div class="col-md-12 text-center" style="margin-bottom:1em">
                <p class="text-dangar" style="margin-top:1em; text-align:left">{{errorMsg}}</p>
            </div>
        </cardBody>
    </card>
</template>

<style>

    #screen-page input {
        margin-bottom: initial;
    }

    .col-centered {
        float: none;
        margin: 0 auto;
    }

    .k-input-inner {
        border: none;
    }

        .k-input-inner:focus {
            outline: none;
        }

    .k-grid-offset {
        margin-top: 80px;
    }

    .k-textbox-container, .k-floating-label-container, .k-input-inner, .k-input {
        width: 100%;
    }

    .card {
        border-radius: 20px;
        margin-bottom: 20px;
        margin: 10px 20px;
    }

    .p-m {
        padding: 16px;
    }

    .pb-m {
        padding-bottom: 16px;
    }

    .bdr-rad-15 {
        border-radius: 15px;
        overflow: hidden;
    }

    .bdr {
        background: #fff;
        border: 1px solid #bbbbbb;
        border-radius: 6px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    #dragContainer {
        border-style: dashed;
        border-color: lightgrey;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 2rem;
        text-align: center;
        width: 100%;
        height: 100%;
    }

    #img {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        width: 40rem;
        height: 26rem;
    }
</style>

<script>

    import ApiService from "@/core/services/api-service";
    import { Button } from "@progress/kendo-vue-buttons";
    import DriversLicense from "@/components/forms/invitation-steps/DriversLicense.vue";
    import FileDropArea from "@/components/FileDropArea";
    import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
    import Webcam from 'webcam-easy';

    import {
        Card,
        CardHeader,
        CardBody,
        CardTitle,
        CardSubtitle,
        CardActions,
    } from "@progress/kendo-vue-layout";
    export default {
        name: 'PhotoUpload',
        props: ['reupload'],
        components: {
            DriversLicense,
            "k-button": Button,
            card: Card,
            cardHeader: CardHeader,
            cardBody: CardBody,
            cardTitle: CardTitle,
            cardSubtitle: CardSubtitle,
            cardActions: CardActions,
            'FileDropArea': FileDropArea,
        },
        emits: ["componentValid"],
        data() {
            return {
                errorMsg: "",
                signerData: this.$store.getters["Auth/signerSessionInformation"],

                takePhoto: false,
                loadingCamera: false,
                webcam: null,

                docType: '1',
                frontFile: "",
                backFile: "",
                disabled: true,
                isFront: true,
            }
        },
        created() {
            $(this.$refs.modalInstuctionsRef).modal("show");
        },
        watch: {
            docType(value) {
                console.log("docTypeChange", value);
                this.handleClearingPhotos();
            }
        },
        methods: {
            async isValid() {
                // check inputs ref for data
                return true; //await this.upload();
            },
            handleClearingPhotos() {
                this.frontFile = "";
                this.backFile = "";
                this.disabled = true;
            },
            frontFileUpdated(event) {
                this.frontFile = "";
                const [file] = event.target.files;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.frontFile = reader.result;
                        this.handleDisablingConfirmation();
                    };
                }
            },
            handleFrontFileAddition(e) {
                let droppedFiles = e.dataTransfer.files;
                if (!droppedFiles) return;
                if (droppedFiles.length > 1) {
                    Swal.fire("Error", "You can only upload one image.", "error");
                    return;
                };
                this.frontFile = "";
                // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
                ([...droppedFiles]).forEach(f => {
                    console.log("f", f);
                    if (f.type.match("image.*")) {
                        if (f) {
                            var reader = new FileReader();
                            reader.readAsDataURL(f);
                            reader.onload = () => {
                                this.frontFile = reader.result;
                                this.handleDisablingConfirmation();
                            };
                        }
                    } else {
                        Swal.fire("Error", "You can only upload an image.", "error");
                    }
                });
            },
            backFileUpdated(event) {
                this.backFile = "";
                const [file] = event.target.files;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.backFile = reader.result;
                        this.handleDisablingConfirmation();
                    };
                }
            },
            handleBackFileAddition(e) {
                let droppedFiles = e.dataTransfer.files;
                if (!droppedFiles) return;
                if (droppedFiles.length > 1) {
                    Swal.fire("Error", "You can only upload one image.", "error");
                    return;
                };
                this.backFile = "";
                // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
                ([...droppedFiles]).forEach(f => {
                    console.log("f", f);
                    if (f.type.match("image.*")) {
                        if (f) {
                            var reader = new FileReader();
                            reader.readAsDataURL(f);
                            reader.onload = () => {
                                this.backFile = reader.result;
                                this.handleDisablingConfirmation();
                            };
                        }
                    } else {
                        Swal.fire("Error", "You can only upload an image.", "error");
                    }
                });
            },
            takePhotoFromCamera(isFront) {
                this.isFront = isFront;
                this.takePhoto = true;
                this.loadingCamera = true;
                if (!this.webcam) {
                    const webcamElement = document.getElementById('webcam');
                    const canvasElement = document.getElementById('canvas');
                    this.webcam = new Webcam(webcamElement, 'user', canvasElement);
                }
                this.webcam.start()
                    .then(result => {
                        console.log("webcam started");
                        this.loadingCamera = false;
                    })
                    .catch(err => {
                        this.loadingCamera = false;
                    });
            },
            cancelVideoCapture() {
                this.takePhoto = false;
                this.webcam.stop();
            },
            snap() {
                var picture = this.webcam.snap();
                if (this.isFront) {
                    this.frontFile = picture;
                } else {
                    this.backFile = picture;
                }
                this.takePhoto = false;
                this.handleDisablingConfirmation();
            },
            async upload() {
                this.errorMsg = "";

                if (this.docType == 2 && this.frontFile === null) {
                    this.errorMsg = "Add an image";
                    return false;
                }

                if (this.docType == 1 && this.frontFile === null) {
                    this.errorMsg = "Add a Front image";
                    return false;
                }

                if (this.docType == 1 && this.backFile === null) {
                    this.errorMsg = "Add a Back image";
                    return false;
                }

                let response = null;

                if (this.docType == 1) {
                    response = await ApiService.GeneratePostRequest("verify/id_documents", {
                        "identityType": parseInt(this.docType),
                        "documents": [
                            {
                                "ImageData": this.frontFile.replace(/data:image\/[a-zA-Z]+;base64,/, ""),
                                "isFront": true
                            },
                            {
                                "ImageData": this.backFile.replace(/data:image\/[a-zA-Z]+;base64,/, ""),
                                "isFront": false
                            }
                        ],
                        "userId": this.signerData["signerId"],
                        "notarizeId": this.signerData["notarizeId"],
                    });
                } else if (this.docType == 2) {
                    response = await ApiService.GeneratePostRequest("verify/id_documents", {
                        "identityType": parseInt(this.docType),
                        "documents": [
                            {
                                "ImageData": this.frontFile.replace(/data:image\/[a-zA-Z]+;base64,/, ""),
                                "isFront": true
                            },
                            {
                                "ImageData": "",
                                "isFront": false
                            }
                        ],
                        "userId": this.signerData["signerId"],
                        "notarizeId": this.signerData["notarizeId"],
                    });
                }


                console.log("Upload Response");
                console.log(response);
                if (response["isSuccess"]) {
                    console.log("reupload", this.reupload);
                    if (!this.reupload) {
                        this.$router.push({ path: `/signer/room` });
                    } else {
                        //send refresh to notary
                        await ApiService.GeneratePostRequest("transaction/signer/uploadedid");
                        //close signer modal
                        this.$emit('close-modal');
                    }
                    return true;
                }


                this.errorMsg = response["message"];
                return false;
            },
            handleDisablingConfirmation() {
                if (this.docType == 2 && this.frontFile != "") {
                    this.disabled = false;
                } else if (this.docType == 1 && this.frontFile != "" && this.backFile != "") {
                    this.disabled = false;
                } else {
                    this.disabled = true;
                }
            },
        },
    }
</script>
