<template>
    <form id="signingRoomForm" @submit="handleSubmit">
        <div class="col-md-12" style="margin-top:20px">
            <div class="title-block">
                <h3>
                    <strong>SigningRoom Wizard</strong>
                </h3>
            </div>

            <!--SigningRoom Information-->
            <div class="k-card">
                <div class="k-card-header">
                    <h3>SigningRoom Information</h3>
                </div>
                <div class="k-card-body container">
                    <div class="data-input row">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-4">
                                    <label for="inputName" class="input-label">
                                        Name:
                                    </label>
                                </div>
                                <div class="col-lg-8">
                                    <k-input :name="'inputName'"
                                             :required="true"
                                             :placeholder="'Name'"
                                             @change="handleChange"></k-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-input row">
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="inputStartDate" class="input-label">
                                        Start Date:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <date-picker :name="'inputStartDate'"
                                                 :required="true"
                                                 :value="inputStartDate"
                                                 :default-value="new Date()"
                                                 :placeholder="'Start Date'"
                                                 @change="handleChange"
                                                 :format="'yyyy-MM-dd'">
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="inputExpirationDate" class="input-label">
                                        Access Expiration Date:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <date-picker :name="'inputExpirationDate'"
                                                 :required="true"
                                                 :value="inputExpirationDate"
                                                 :placeholder="'Expiration Date'"
                                                 :valid="isExpirationValid()"
                                                 :validation-message="getExpirationValidationMessage()"
                                                 @change="handleChange"
                                                 :format="'yyyy-MM-dd'">
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-input row">
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="inputSigningDate" class="input-label">
                                        Signing Date:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <date-time-picker :name="'inputSigningDate'"
                                                      :valid="isSigningValid()"
                                                      :validation-message="getSigningValidationMessage()"
                                                      :required="true"
                                                      :value="inputSigningDate"
                                                      :placeholder="'Signing Date'"
                                                      @change="handleChange"
                                                      :format="'yyyy-MM-dd hh:mm a'">
                                    </date-time-picker>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="inputSigningDate" class="input-label">
                                        Signing Expiration Date:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <date-time-picker :name="'inputSigningExpirationDate'"
                                                      :valid="isSigningExpirationValid()"
                                                      :validation-message="getSigningExpirationValidationMessage()"
                                                      :required="true"
                                                      :value="inputSigningExpirationDate"
                                                      :placeholder="'Signing Expiration Date'"
                                                      @change="handleChange"
                                                      :format="'yyyy-MM-dd hh:mm a'">
                                    </date-time-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-input row">
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="signingType" class="input-label">
                                        Signing Type:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <dropdownlist :data-items="signingTypes"
                                                  :data-item-key="'id'"
                                                  :text-field="'name'"
                                                  :name="'selectedSigningType'"
                                                  :value="selectedSigningType"
                                                  @change="handleSigningTypeChange"
                                                  :required="true">
                                    </dropdownlist>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="signingType" class="input-label">
                                        Time Zone:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <dropdownlist id="dropdownlist"
                                                  :data-items="timeZones"
                                                  :data-item-key="'id'"
                                                  :text-field="'timeZoneName'"
                                                  :value="selectedTZ"
                                                  @change="changeTimeZone"
                                                  :steps="steps"
                                                  required>
                                    </dropdownlist>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <!--Loan Information-->
            <div class="k-card">
                <div class="k-card-header">
                    <h3>Loan Information</h3>
                </div>
                <div class="k-card-body container">
                    <div class="data-input row">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-4">
                                    <label for="inputAddress1" class="input-label">
                                        Address 1:
                                    </label>
                                </div>
                                <div class="col-lg-8">
                                    <k-input :name="'inputAddress1'"
                                             :required="true"
                                             :placeholder="'Address'"
                                             @change="handleChange"></k-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-input row">
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="inputAddress2" class="input-label">
                                        Address 2:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <k-input :name="'inputAddress2'"
                                             :placeholder="'Apt/Unit #'"
                                             @change="handleChange"></k-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-input row">
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="inputCity" class="input-label">
                                        City:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <k-input :name="'inputCity'"
                                             :placeholder="'City'"
                                             @change="handleChange"></k-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-input row">
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="inputCountry" class="input-label">
                                        Country:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <dropdownlist :data-items="countries"
                                                  style="min-width: 100%"
                                                  :data-item-key="'id'"
                                                  :text-field="'countryName'"
                                                  :name="'inputCountry'"
                                                  :value="inputCountry"
                                                  @change="handleCountryChange"></dropdownlist>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-input row">
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="inputZip" class="input-label">
                                        ZIP Code:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <k-input :name="'inputZip'"
                                             :placeholder="'ZIP Code'"
                                             :valid="isZipCodeValid()"
                                             :validation-message="'Please enter a valid ZIP Code'"
                                             @change="handleChange"></k-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="showStates" class="data-input row">
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="inputState" class="input-label">
                                        State:
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <dropdownlist :data-items="states"
                                                  :data-item-key="'id'"
                                                  :text-field="'stateName'"
                                                  style="min-width:100%"
                                                  :name="'inputState'"
                                                  :value="inputState"
                                                  @change="handleStateChange"></dropdownlist>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-input row" v-if="showCounties">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-4">
                                    <label for="inputCounty" class="input-label">
                                        County:
                                    </label>
                                </div>
                                <div class="col-lg-4">
                                    <dropdownlist style="min-width: 100%"
                                                  :data-items="filteredCounties"
                                                  :data-item-key="'id'"
                                                  :text-field="'countyName'"
                                                  :name="'inputCounty'"
                                                  :value="inputCounty"
                                                  :filterable="true"
                                                  @filterchange="handleCountyFilter"
                                                  @change="handleCountyChange"></dropdownlist>
                                </div>
                                <div class="col-lg-4">
                                    <button class="k-button k-primary bottom-button"
                                            type="button"
                                            @click="checkEEligibility()">
                                        Check E-Eligibility
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <div style="text-align:right">
                <button class="k-button k-secondary bottom-button"
                        type="button"
                        @click="cancel()">
                    Cancel
                </button>
                &nbsp;
                <button class="k-button k-primary bottom-button" type="submit">
                    Submit
                </button>
            </div>
        </div>
    </form>

    <!-- E-Eligibility Popup -->
    <k-dialog v-if="eligibilityVisible"
              :title="'E-Eligibility Response'"
              @close="closeEEligibility"
              :width="750">
        <div style="font-size:28px;">
            <strong>State - </strong>{{inputState.stateName}}
        </div>
        <div>
            <div v-if="eEligibility['ronAccepted']">
                <div class="col-lg-12">
                    <span class="k-icon k-i-check" style="color:green;"></span>
                    &nbsp;
                    <label>Remote Online Notarization (RON) Accepted</label>
                </div>
            </div>
            <div v-if="!eEligibility['ronAccepted']">
                <div class="col-lg-12">
                    <span class="k-icon k-i-close-circle" style="color:darkred;"></span>
                    &nbsp;
                    <label style="vertical-align:top">Remote Online Notarization (RON) Not Accepted</label>
                </div>
            </div>
            <div>
                <div class="col-lg-offset-1" style="cursor:pointer !important" @click="showRonDropdown = !showRonDropdown">
                    <span v-if="!showRonDropdown" class="k-icon k-i-arrow-60-right"></span>
                    <span v-if="showRonDropdown" class="k-icon k-i-arrow-60-down"></span>
                    <b style="color:blue">Exceptions, criteria, and requirements</b>
                </div>
                <div v-if="showRonDropdown" style="padding-top:5px">
                    <div v-if="eEligibility['ronProviderApproval']">
                        <div class="col-lg-offset-1">
                            <span class="k-icon k-i-check" style="color:green;"></span>
                            &nbsp;
                            <label style="vertical-align:top">RON Provider Approval Required</label>
                        </div>
                    </div>
                    <div v-if="!eEligibility['ronProviderApproval']">
                        <div class="col-lg-offset-1">
                            <span class="k-icon k-i-close-circle" style="color:darkred;"></span>
                            &nbsp;
                            <label style="vertical-align:top">RON Provider Approval Not Required</label>
                        </div>
                    </div>

                    <div v-if="eEligibility['outOfStateAccepted']">
                        <div class="col-lg-offset-1">
                            <span class="k-icon k-i-check" style="color:green;"></span>
                            &nbsp;
                            <label style="vertical-align:top">Out-of-State RON Approved</label>
                        </div>
                    </div>
                    <div v-if="!eEligibility['outOfStateAccepted']">
                        <div class="col-lg-offset-1">
                            <span class="k-icon k-i-close-circle" style="color:darkred;"></span>
                            &nbsp;
                            <label style="vertical-align: baseline">Out-of-State RON Not Approved</label>
                        </div>
                    </div>

                    <div v-if="eEligibility['outOfStateMustBeInHomeState']">
                        <div class="col-lg-offset-1">
                            <span class="k-icon k-i-check" style="color:green;"></span>
                            &nbsp;
                            <b>Out of state notaries do not have to be physically located in the state where they received their commission</b>
                        </div>
                    </div>
                    <div v-if="!eEligibility['outOfStateMustBeInHomeState']">
                        <div class="col-lg-offset-1">
                            <span class="k-icon k-i-close-circle" style="color:darkred;"></span>
                            &nbsp;
                            <label style="vertical-align: baseline;">Out of state notaries must be physically located in the state where they received their commission</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div v-if="eEligibility['ipenAccepted']">
            <div class="col-lg-12">
                <span class="k-icon k-i-check" style="color:green;"></span>
                &nbsp;
                <label>In-Person Electronic Notarization (IPEN) Accepted</label>
            </div>
        </div>
        <div v-if="!eEligibility['ipenAccepted']">
            <div class="col-lg-12">
                <span class="k-icon k-i-close-circle" style="color:darkred;"></span>
                &nbsp;
                <label>In-Person Electronic Notarization (IPEN) Not Accepted</label>
            </div>
        </div>
        <div>
            <div class="col-lg-offset-1" style="cursor:pointer !important" @click="showIpenDropdown = !showIpenDropdown">
                <span v-if="!showIpenDropdown" class="k-icon k-i-arrow-60-right"></span>
                <span v-if="showIpenDropdown" class="k-icon k-i-arrow-60-down"></span>
                <b style="color:blue">Exceptions, criteria, and requirements</b>
            </div>
            <div v-if="showIpenDropdown" style="padding-top:5px">
                <div v-if="eEligibility['ipenProviderApproval']">
                    <div class="col-lg-offset-1">
                        <span class="k-icon k-i-check" style="color:green;"></span>
                        &nbsp;
                        <label style="vertical-align:top">IPEN Provider Approval Required</label>
                    </div>
                </div>
                <div v-if="!eEligibility['ipenProviderApproval']">
                    <div class="col-lg-offset-1">
                        <span class="k-icon k-i-close-circle" style="color:darkred;"></span>
                        &nbsp;
                        <label style="vertical-align:top">IPEN Provider Approval Not Required</label>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div v-if="eEligibility['paperOutAccepted']">
            <div class="col-lg-12">
                <span class="k-icon k-i-check" style="color:green;"></span>
                &nbsp;
                <label>Paper-out Recording Accepted</label>
            </div>
        </div>
        <div v-if="!eEligibility['paperOutAccepted']">
            <div class="col-lg-12">
                <span class="k-icon k-i-close-circle" style="color:darkred;"></span>
                &nbsp;
                <label>Paper-out Recording Not Accepted</label>
            </div>
        </div>
        <br />
        <br />
        <div style="font-size:28px;">
            <strong>County - </strong>{{inputCounty.countyName}}
        </div>
        <div v-if="eEligibility['eRecording']">
            <div class="col-lg-12">
                <span class="k-icon k-i-check" style="color:green;"></span>
                &nbsp;
                <label>eRecording Accepted</label>
            </div>
        </div>
        <div v-if="!eEligibility['eRecording']">
            <div class="col-lg-12">
                <span class="k-icon k-i-close-circle" style="color:darkred;"></span>
                &nbsp;
                <label>eRecording Not Accepted</label>
            </div>
        </div>
    </k-dialog>
</template>
<style type="css">
    .data-input {
        padding: 10px 0 10px 0 !important;
        display: flex;
        flex-wrap: wrap;
    }

    .input-label {
        font-size: medium;
        padding-top: 3%;
        align-content: flex-start;
    }

    @media(min-width: 1200px) {
        .input-label {
            float: right;
            padding-left: 10px;
        }
    }

    .k-input, .k-widget {
        margin-right: 10px;
    }

    .button-container {
        float: right;
    }

    hr {
        border: 10px thick;
    }
</style>
<script>
    import { Popup } from "@progress/kendo-vue-popup";
    import { Button } from "@progress/kendo-vue-buttons";
    import { Grid, GridColumn } from "@progress/kendo-grid-vue-wrapper";
    import { GridToolbar, Grid as NativeGrid } from "@progress/kendo-vue-grid";
    import { DropDownList, ComboBox } from "@progress/kendo-vue-dropdowns";
    import { KendoDataSource } from "@progress/kendo-datasource-vue-wrapper";
    import { Input } from "@progress/kendo-vue-inputs";
    import { filterBy } from '@progress/kendo-data-query';
    import Swal from "sweetalert2";
    import { DatePicker, DateTimePicker } from "@progress/kendo-vue-dateinputs";
    import ApiService from "../../core/services/api-service";
    import moment from "moment";
    import { Dialog } from '@progress/kendo-vue-dialogs';

    export default {
        name: "AddSigningRoom",
        components: {
            Popup: Popup,
            "kendo-grid": Grid,
            "kendo-grid-column": GridColumn,
            "kendo-datasource": KendoDataSource,
            dropdownlist: DropDownList,
            combobox: ComboBox,
            "k-button": Button,
            "k-input": Input,
            toolbar: GridToolbar,
            "date-picker": DatePicker,
            "date-time-picker": DateTimePicker,
            "k-dialog": Dialog
        },
        data: function () {
            return {
                inputLoanNumber: null,
                inputLoanDescription: null,
                inputOriginator: null,
                inputAddress1: null,
                inputAddress2: null,
                inputCity: null,
                inputZip: null,
                inputCountry: {},
                inputState: {},
                inputCounty: {},
                inputName: null,
                inputDescription: null,
                inputStartDate: null,
                inputExpirationDate: null,
                inputSigningDate: null,
                inputSigningExpirationDate: null,
                countries: [],
                states: [],
                showStates: false,
                settings: null,
                signingTypes: [],
                selectedSigningType: "",
                timeZones: [],
                selectedTZ: "",
                steps: {
                    year: 1,
                    month: 1,
                    day: 1,
                    hour: 1,
                    minute: 15,
                    second: 1,
                },
                showEligibilityButton: false,
                eligibilityVisible: false,
                eEligibility: {},
                ronAccepted: false,
                ipenAccepted: false,
                paperOutAccepted: false,
                showRonDropdown: false,
                showIpenDropdown: false,
                showCounties: false,
                counties: [],
                filteredCounties: []
            };
        },
        computed: {
            moment: () => moment,
        },
        mounted() {
            ApiService.GenerateGetRequest(
                "SigningRoomManager/SigningRooms/GetSigningTypes"
            ).then((response) => {
                this.signingTypes = response.content;
            });
            ApiService.GenerateGetRequest("Country/all").then((response) => {
                this.countries = response;
                this.inputCountry = response[237];
            });
            ApiService.GenerateGetRequest("Country/239/states").then((response) => {
                this.states = response;
                if (response.length > 0) {
                    this.showStates = true;
                    this.showCounties = true;
                    this.inputState = response[0];

                    var state = this.inputState.stateName;
                    ApiService.GeneratePostRequest(
                        "country/eligibility/state?stateName=" + state)
                        .then((stateResponse) => {
                            if (stateResponse === "") {
                                this.counties = [];
                                this.filteredCounties = [];
                                this.showCounties = false;
                                return;
                            }

                            this.showCounties = true;

                            ApiService.GeneratePostRequest(
                                "country/eligibility/counties?sort_by=name&stateID=" + stateResponse
                            ).then((countyResponse) => {
                                this.counties = countyResponse;
                                this.filteredCounties = countyResponse;
                                this.showCounties = this.counties.length !== 0;
                                this.inputCounty = countyResponse[0];
                            });
                        });
                } else {
                    this.showStates = false;
                    this.showCounties = false;
                    this.inputState = {};
                }
            });

            ApiService.GenerateGetRequest("Admin/LenderSettings").then((response) => {
                this.settings = response.content;
            });

            ApiService.GeneratePostRequest("Package/timezones").then((response) => {
                this.timeZones = response.content;
            });
        },
        methods: {
            changeTimeZone(event) {
                this.selectedTZ = event.value;
            },
            handleChange(event) {
                this[event.target.name] = event.target.value;
            },
            handleSigningTypeChange(event) {
                this.selectedSigningType = event.target.value;
            },
            populateCounties() {
                if (!this.showCounties) return;

                var state = this.inputState.stateName;

                ApiService.GeneratePostRequest("country/eligibility/state?stateName=" + state).then((stateResponse) => {
                    if (stateResponse === "") {
                        this.counties = [];
                        this.filteredCounties = [];
                        this.showCounties = false;
                        return;
                    }

                    ApiService.GeneratePostRequest("country/eligibility/counties?stateID=" + stateResponse).then((countyResponse) => {
                        this.counties = countyResponse;
                        this.filteredCounties = countyResponse;
                        this.inputCounty = countyResponse[0];
                        this.showCounties = this.counties.length !== 0;
                    });
                });
            },
            handleCountryChange(event) {
                var self = this;
                this.inputCountry = event.target.value;
                ApiService.GenerateGetRequest(
                    "Country/" + this.inputCountry.id + "/states"
                ).then((response) => {
                    this.states = response;
                    if (response.length > 0) {
                        this.showStates = true;
                        this.inputState = response[0];
                    } else {
                        this.showStates = false;
                        this.inputState = {};
                    }
                }).then(function () {
                    console.log('Country ID: ', self.inputCountry.id);

                    self.showCounties = self.inputCountry.id === 239;

                    self.populateCounties();
                });
            },
            handleCountyChange(event) {
                this[event.target.name] = event.target.value;

                this.showEligibilityButton = this.inputCounty != null;
            },
            handleCountyFilter(event) {
                const data = this.counties.slice();
                if (event.filter.value === undefined || event.filter.value == '') {
                    this.filteredCounties = this.counties;
                    return;
                }

                const filtered = filterBy(data, event.filter);

                if (filtered.length == 0) return;
                this.filteredCounties = filtered;
            },
            handleStateChange(event) {
                this[event.target.name] = event.target.value;

                var state = this.inputState.stateName;
                var self = this;
                ApiService.GeneratePostRequest("country/eligibility/state?stateName=" + state).then((stateResponse) => {
                    if (stateResponse === "") {
                        self.counties = [];
                        self.showCounties = false;
                        return;
                    }

                    self.showCounties = true;

                    self.populateCounties();
                });
            },
            isExpirationValid() {
                if (this.inputStartDate !== null && this.inputExpirationDate !== null) {
                    return this.inputStartDate < this.inputExpirationDate;
                }
                return this.inputExpirationDate !== null;
            },
            getExpirationValidationMessage() {
                if (this.inputExpirationDate === null) {
                    return "Please enter a valid date.";
                } else {
                    return "Expiration Date must be after the Start Date.";
                }
            },
            isSigningValid() {
                if (this.inputSigningDate !== null && this.inputExpirationDate !== null) {
                    return this.inputSigningDate <= this.inputExpirationDate;
                }
                return this.inputSigningDate !== null;
            },
            isSigningExpirationValid() {
                if (
                    this.inputSigningDate !== null &&
                    this.inputSigningExpirationDate !== null
                ) {
                    return this.inputSigningDate <= this.inputSigningExpirationDate;
                }
                return this.inputSigningExpirationDate !== null;
            },
            isZipCodeValid() {
                return /^[0-9]{5}(?:-[0-9]{4})?$|^$/.test(this.inputZip) || this.inputZip === null;
            },
            getSigningValidationMessage() {
                if (this.inputSigningDate === null) {
                    return "Please enter a valid date.";
                } else {
                    return "Signing Date must be before the Expiration Date";
                }
            },
            getSigningExpirationValidationMessage() {
                if (this.inputSigningExpirationDate === null) {
                    return "Please enter a valid date.";
                } else {
                    return "Signing Date must be before the Signing Expiration Date";
                }
            },
            checkEEligibility() {
                if (this.inputCounty === null) {
                    Swal.fire(
                        "Operation failed",
                        "Please enter a valid county",
                        "error"
                    );
                    return;
                }

                var self = this;

                var zipCode = this.inputZip;
                var countyCode = this.inputCounty.id;
                var state = this.inputState.stateName;
                ApiService.GeneratePostRequest(
                    "country/eligibility/state?stateName=" + state)
                    .then((stateResponse) => {
                        ApiService.GeneratePostRequest(
                            "country/eligibility/requirements?countyCode=" + countyCode + "&stateCode=" + stateResponse
                        ).then((eResponse) => {
                            if (eResponse.EEligibilityCountyRequirements == null) {
                                Swal.fire(
                                    "Operation failed",
                                    "Invalid State and County combination",
                                    "error"
                                );
                                return;
                            }

                            var eEligibility = {};

                            this.ronAccepted = eResponse.EEligibilityCountyRequirements.AllowsRonEClosings;
                            this.ipenAccepted = eResponse.EEligibilityStateRequirements.AllowsIPENEClosings;
                            this.paperOutAccepted = eResponse.EEligibilityStateRequirements.AllowsPaperOutRecordings;

                            eEligibility["ronAccepted"] = eResponse.EEligibilityStateRequirements.AllowsRonEClosings;
                            if (eResponse.EEligibilityStateRequirements.RONRequirements != null) {
                                eEligibility["ronProividerApproval"] = eResponse.EEligibilityStateRequirements.RONRequirements.ApprovedByAgency;
                                eEligibility["outOfStateAccepted"] = eResponse.EEligibilityStateRequirements.RONRequirements.OutOfStateNotaries;
                                eEligibility["outOfStateMustBeInHomeState"] = eResponse.EEligibilityStateRequirements.RONRequirements.NotaryMustBeInState;
                            }

                            eEligibility["ipenAccepted"] = eResponse.EEligibilityStateRequirements.AllowsIPENEClosings;
                            if (eResponse.EEligibilityStateRequirements.IPENRequirements != null) {
                                eEligibility["ipenProviderApproval"] = eResponse.EEligibilityStateRequirements.IPENRequirements.ApprovedByAgency;
                            }

                            eEligibility["paperOutAccepted"] = eResponse.EEligibilityStateRequirements.AllowsPaperOutRecordings;

                            eEligibility["eRecording"] = eResponse.EEligibilityCountyRequirements.AllowsRonEClosings;

                            this.eEligibility = eEligibility;

                            this.eligibilityVisible = true;
                        });
                    });

            },
            closeEEligibility() {
                this.eligibilityVisible = false;
                this.showIpenDropdown = false;
                this.showRonDropdown = false;
            },
            cancel() {
                this.$router.push("/admin");
            },
            async handleSubmit(event) {
                event.preventDefault();

                var expirationDate =
                    this.inputExpirationDate !== null
                        ? "/Date(" + new Date(this.inputExpirationDate).getTime() + ")/"
                        : "";
                var signingDate = moment(this.signingDate).format();
                var signingExpirationDate = moment(
                    this.inputSigningExpirationDate
                ).format();
                var startDate =
                    this.inputStartDate !== null
                        ? "/Date(" + new Date(this.inputStartDate).getTime() + ")/"
                        : "";
                this.inputName = this.inputName.trim();
                var data = {
                    LoanNumber: this.inputName,
                    ClientId: this.$store.state.Admin.loginInformation.clientId,
                    EvaultIntegration: true,
                    LoanDescription:
                        this.inputLoanDescription != null
                            ? this.inputLoanDescription
                            : "NULL",
                    Originator:
                        this.inputOriginator !== null ? this.inputOriginator : "NULL",
                    Address1: this.inputAddress1 !== null ? this.inputAddress1 : null,
                    Address2: this.inputAddress2 !== null ? this.inputAddress2 : null,
                    City: this.inputCity !== null ? this.inputCity : null,
                    Zip_Code: this.inputZip !== null ? this.inputZip : null,
                    CountryID: this.inputCountry !== null ? this.inputCountry.id : null,
                    StateID: this.inputState !== null ? this.inputState.id : null,
                    CountryCode: this.inputCountry !== null ? this.inputCountry.iso2 : null,
                    StateCode: this.inputState !== null ? this.inputState.stateCode : null,
                    LoanStatus: 1,
                    Creator: {
                        UserId: this.$store.state.Admin.loginInformation.userId,
                        Name: (
                            this.$store.state.Admin.loginInformation.firstName +
                            " " +
                            this.$store.state.Admin.loginInformation.lastName
                        ).trim(),
                        Email: this.$store.state.Admin.loginInformation.emailAddress,
                    },
                    LoanPackages: [
                        {
                            PackageType: "Manual",
                            PackageName: this.inputName,
                            PackageDescription: this.inputName,
                            ExpirationDate: expirationDate,
                            PresignDate: signingDate,
                            SigningDate: signingDate,
                            EsignExpirationDate: signingExpirationDate,
                            PresignDate: signingDate,
                            StartDate: startDate,
                            LoanNumber: this.inputName,
                            ClientId: this.$store.state.Admin.loginInformation.clientId,
                            Participants: [],
                            Documents: [],
                            TimeZone: this.selectedTZ.id,
                            SigningDescription: this.selectedSigningType.name,
                        },
                    ],
                };

                return ApiService.GeneratePostRequest("package/new", data).then(
                    (response) => {
                        if (response["isSuccess"]) {
                            Swal.fire("Success", "Signing Room Created", "success");
                            this.$router.push("/admin/detail/" + response["content"][0]);
                        } else {
                            Swal.fire(
                                "Operation failed",
                                "Signing Room could not be created.",
                                "error"
                            );
                        }
                    }
                );
            },
        },
    };
</script>
