<template>
  <router-view />
</template>
<style>
    /*==== Sweet  Alert Size Increase ====*/
    .swal2-container.swal2-center > .swal2-popup {
        font-size: 1.6rem !important;
    }

        .swal2-container.swal2-center > .swal2-popup button {
            font-size: 1em;
        }
    /*====Front End Styles====*/
    /* Signers Page*/
    .fe-signed {
        border: 3px solid green !important;
    }

    .fe-to-be-signed {
        border: 3px solid yellow !important;
    }
    .fe-optional {
        border: 3px solid #0072E1 !important;
    }


        .fe-signed canvas, .fe-to-be-signed canvas, .active-thumbnail canvas {
            width: 100% !important;
            height: 100% !important;
        }


    .active-thumbnail {
        border-bottom: 3px solid #3BC6F7 !important;
    }

    /*Style for kendo dropdown to work with modal*/
    .k-animation-container.k-animation-container-relative {
        z-index: 1055 !important
    }

</style>
<script>
export default {
  name: "App",
  created() {
    // this.$store.commit("App/updateLoaderOverride", {
    //   showLoaderOverride: false,
    // });
    /* this.$store.dispatch("Auth/isUserLoggedIn");*/

    // watch to see if user sets terms and conditions to false and behave accordingly.
    //this.unwatch = this.$store.watch((state, getters) => {
    //    return getters["App/termsAndConditions"];
    //}, (newValue) => {
    //    if (newValue === false) {
    //        this.$router.push({ name: "Home" });
    //    }
    //});
  },
  beforeUnmount() {
    this.unwatch();
  },
};
</script>
