<template>
    <template v-if="systemPermissions.length > 0"><!--Kendo errors out if no roles are available on load-->

        <kendo-datasource ref="source"
                          :transport-read="readData"
                          :transport-create="createData"
                          :transport-update="updateData"
                          :transport-destroy="deleteData"
                          :schema-model-id="'roleId'"
                          :schema-model-fields="schema"
                          :transport-parameter-map="parameterMap"
                          :page-size="8">
        </kendo-datasource>

        <kendo-grid ref="kendoJqueryGrid"
                    :data-source-ref="'source'"
                    :height="500"
                    :class="'users'"
                    :sortable="true"
                    :resizable="true"
                    :navigatable="true"
                    :pageable="true"
                    :pdf-all-pages="true"
                    :pdf-avoid-links="true"
                    :editable="packagePopUpTemplate"
                    :pdf-paper-size="'A4'"
                    :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                    :pdf-landscape="true"
                    :pdf-repeat-headers="true"
                    :pdf-scale="0.8"
                    :columns="columns"
                    :edit="onEdit"
                    @databound="documentDataBound"
                    @save="onPopUpModalSaved">


            <toolbar>
                <button title="Export to Excel" class="k-button k-primary" @click="exportExcel">
                    Export to Excel
                </button>&nbsp;
                <button class="k-button k-primary" @click="exportPDF">
                    Export to PDF
                </button>
                <div style="margin: 0 0px 0 auto;" v-if="canAdd">
                    <button title="Add New" class="k-button k-primary k-add-button particiant-add" @click="addButtonClicked" >
                        Add
                    </button>
                </div>
            </toolbar>
        </kendo-grid>
        <div v-show="false">
            <pdfexport ref="gridPdfExport">
                <native-grid :columns="exportColumns" />
            </pdfexport>
        </div>
    </template>
</template>
<style scoped>
    * {
        font-weight: 400;
    }

    /deep/ .participant-cmd, /deep/ .participant-cmd:hover, /deep/ .participant-cmd::before {
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: unset;
        background: initial;
        border: none;
        border-radius: unset;
        transition: none;
        -webkit-transition: none;
        padding: 8px 5px;
        font: 900 .94em 'Lato', sans-serif;
        color: #4d97ac;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.completed /deep/ span, .status.remote /deep/ span {
            background: #19a71c;
        }

        .status.pending /deep/ span, .status.pendingsigning /deep/ span {
            background: #f8ab10;
        }

        .status.canceled /deep/ span {
            background: #ff2b2b;
        }

        .status.reviewed /deep/ span {
            background: #e960ea;
        }

        .status.pendingreview /deep/ span {
            background: #c14e4e;
        }

        .status.paper /deep/ span {
            background: #c6c6c6;
        }

        .status.signing /deep/ span, .status.signed /deep/ span {
            background: #296298;
        }



    
</style>
<script>
    import { useRoute } from 'vue-router';
    import ApiService from "@/core/services/api-service";
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { GridPdfExport } from '@progress/kendo-vue-pdf';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { process } from '@progress/kendo-data-query';
    import KendoPopupGenerator from '@/core/services/kendo-grid-popup-form-generator.js';
    import Swal from "sweetalert2";
    import PermissionHelper from "../../core/permission-helper";

    const ROLE_SETTINGS = {
        VIEW: "CanViewRoles",
        ADD: "CanAddRole",
        EDIT: "CanEditRole",
        DELETE: "CanDeleteRole",
    };

    const ROLE_PREFIX = {
        DASHBOARD: "Dashboard_",
        LOAN_DETAIL: "Loan_Detail",
        LOAN_PARTICIPANT: "Loan_Participant_",
        LOAN_DOCUMENT: "Loan_Document_",        
        SETTINGS: "Settings_",
        GENERAL_SETTINGS: "GeneralSettings_",
        ROLE_SETTINGS: "RoleSettings_",
        USER_SETTINGS: "UserSettings_",
        EMAIL_SETTINGS: "EmailSettings_"
    };

    export default {
        name: 'Roles',
        components: {
            'kendo-grid': Grid,
            'kendo-grid-column': GridColumn,
            'kendo-datasource': KendoDataSource,
            'pdfexport': GridPdfExport,
            'toolbar': GridToolbar,
            'dropdownlist': DropDownList,
            'native-grid': NativeGrid,
        },        
        data() {
            ///****************************************************************************** NOTE ******************************************************************************///
            ///This is the ONLY WAY right now to include a function to specify if a column can be visible. All other ways fail due to a bug in kendo that attempts to add a _ctx ///
            ///to variables in the function thereby breaking the generated template. The workaround is to have an inline function so that kendo cannot access the function scope ///
            ///******************************************************************************************************************************************************************///

            return {
                schema: {
                    roleId: { editable: false },
                    name: { type: 'string', editable: true },
                    participantType: { type: "string", editable: true },
                    clientId: { type: 'string', editable: true },
                },
                columns: [
                    { field: "name", title: 'Role' },
                    { field: "participantType", title: 'Participant Type' }, 
                    {
                        title: '&nbsp;', width: "280px", command: [
                            { className: 'participant-cmd', text: 'Edit', click: (e) => { this.gridEditClicked(e) }, visible: function (e) { return true; } },
                            { className: 'participant-cmd', text: 'Delete', click: (e) => { this.gridDeleteClicked(e) }, visible: function (e) { return true; } },
                        ]
                    },
                ],
                exportColumns: [
                    { field: "name", title: 'Role' },
                    { field: "participantType", title: 'ParticipantType' },
                    { field: "group", title: 'Permission Group' },
                    { field: "permissionTexts", title: 'Permissions' },
                  
                ],

                roles: [],
                selectedRoles: [],

                systemPermissions: [],
                selectedPermissions: [],
                permissions: {},
            }
        },
        async created() {
            let response = await ApiService.GeneratePostRequest("Role/GetPermissions");
            console.log("system permissions", response);
            if (response["isSuccess"])
                this.systemPermissions = response["content"];

            console.log(this.systemPermissions);
            this.permissions = PermissionHelper.GetPermissions(ROLE_PREFIX.ROLE_SETTINGS);
        },
        computed: {
            packagePopUpTemplate(e) {               
                var grouped = [];
                grouped.push({ 'Name': 'Dashboard Settings', 'Values': this.systemPermissions.filter(perm => perm.name.indexOf(ROLE_PREFIX.DASHBOARD) >= 0) });
                grouped.push({ 'Name': 'Loan Detail Settings', 'Values': this.systemPermissions.filter(perm => perm.name.indexOf(ROLE_PREFIX.LOAN_DETAIL) == 0) });
                grouped.push({ 'Name': 'Loan Participant Settings', 'Values': this.systemPermissions.filter(perm => perm.name.indexOf(ROLE_PREFIX.LOAN_PARTICIPANT) >= 0) });
                grouped.push({ 'Name': 'Loan Document Settings', 'Values': this.systemPermissions.filter(perm => perm.name.indexOf(ROLE_PREFIX.LOAN_DOCUMENT) >= 0) });
                grouped.push({ 'Name': 'Settings Menu', 'Values': this.systemPermissions.filter(perm => perm.name.indexOf(ROLE_PREFIX.SETTINGS) == 0) });
                grouped.push({ 'Name': 'General Settings', 'Values': this.systemPermissions.filter(perm => perm.name.indexOf(ROLE_PREFIX.GENERAL_SETTINGS) >= 0) });
                grouped.push({ 'Name': 'Role Settings', 'Values': this.systemPermissions.filter(perm => perm.name.indexOf(ROLE_PREFIX.ROLE_SETTINGS) >= 0) });                
                grouped.push({ 'Name': 'User Settings', 'Values': this.systemPermissions.filter(perm => perm.name.indexOf(ROLE_PREFIX.USER_SETTINGS) >= 0) });

                let html = "";
                html += "<div id='RolesGrid'>";
                html += "   <div class='col-md-6'>";
                html += KendoPopupGenerator.generatePopUpFormFields('name', 'Role Name', 'name');
                html += KendoPopupGenerator.generatePopUpFormDropdown(
                    "ParticipantType",
                    "Role",
                    "participantType",
                    [ 
                        { Id: "Lender", Text: "Lender" },
                        { Id: "TitleAgent", Text: "TitleAgent" },
                        { Id: "Notary", Text: "Notary" }, 
                        { Id: "LoanOfficer", Text: "LoanOfficer" },
                        { Id: "Seller", Text: "Seller" },
                        { Id: "Selling_Agent", Text: "Selling_Agent" },
                        { Id: "Listing_Agent", Text: "Listing_Agent" },
                        { Id: "Settlement_Agent", Text: "Settlement_Agent" },
                        { Id: "Closing_Attorney", Text: "Closing_Attorney" },
                        { Id: "Closer", Text: "Closer" },
                        { Id: "Broker", Text: "Broker" },
                        { Id: "Contractor", Text: "Contractor" }
                    ],
                    "Id",
                    "Text"
                );
                html += "   </div>";               
                html += `   <div id="right-grid" class='col-md-6'>
                                <h3>Role Permissions</h3>`

                for (let i in grouped) {
                    html += `     <div class="accordion">`
                    html += "       <h3>" + grouped[i]['Name'] + "</h3>"
                    html += `         <div>
                                         <ul class="list-group">`
                    for (let j in grouped[i]['Values']) {
                        var item = grouped[i]['Values'][j]
                        html += `           <li class="list-group-item">`;
                        html += KendoPopupGenerator.generatePopUpFormCheckboxFields(item.permissionId, item.name, item.name, item.permissionId);
                        html += `           </li>`;
                    }
                    html += `              </ul>
                                      </div>
                                    </div>`
                }
                html += "     </div>";
                html += "</div>";
                return { 'template': html, 'mode': 'popup', width: "600px", height: "300px", }
            },
            canAdd: function () { return this.permissions[ROLE_SETTINGS.ADD] },
            canEdit: function () { return this.permissions[ROLE_SETTINGS.EDIT] },
            canDelete: function () { return this.permissions[ROLE_SETTINGS.DELETE] },
            canView: function () { return this.permissions[ROLE_SETTINGS.VIEW] },        

        },
        methods: {
            onPopUpModalSaved(e) {
                console.log("onPopUpModalSaved", e);
            },
            addButtonClicked() {
                this.$refs.kendoJqueryGrid.kendoWidget().addRow();
            },
            gridEditClicked(event) {
                console.log("clicked", event);
                this.$refs.kendoJqueryGrid.kendoWidget().editRow($(event.target).closest("tr"));
            },
            gridDeleteClicked() {
                console.log("clicked");
                this.$refs.kendoJqueryGrid.kendoWidget().removeRow($(event.target).closest("tr"));
            },
            documentDataBound: function (e) {
                var self = this;
                var grid = e.sender;
                var items = e.sender.items();
                items.each(function (index) {
                    var dataItem = grid.dataItem(this);
                    var row = $("tr[data-uid='" + dataItem.uid + "']");
                    if (!self.canEdit || dataItem.isSystem) {
                        
                        row.find(".k-grid-Edit").remove();
                    }
                    if (!self.canDelete || dataItem.isSystem) {
                        row.find(".k-grid-Delete").remove();
                    }
                });
            },
            parameterMap: function (options, operation) {
                console.log("options", options);
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            readData: function (e) {
                //console.log(e)
                var userId = this.$store.state.Admin.loginInformation.userId;
                return ApiService.GenerateGetRequest("Role/GetRoles/" + userId).then(
                    response => {
                        console.log("Role Read data", response);
                        // this map is just to make the create work fine for now - otherwise it will call create multiple times
                        e.success(response["content"]);
                    }
                );
            },
            createData: function (e) {
                if (!this.canAdd) return;
                console.log("Creating", e);
                let permissions = JSON.parse(JSON.stringify(this.selectedPermissions));// selectedRoles is a proxy object not array
                console.log("Permissions " + permissions)
                this.selectedPermissions = [];
                return ApiService.GeneratePostRequest("Role/AddRole", {
                    Name: e.data.name, 
                    ParticipantType: e.data.participantType,
                    Permissions: permissions,
                    UserId: this.$store.state.Admin.loginInformation.userId
                }).then(
                    response => {
                        e.success(response["content"]);
                        if (response["isSuccess"]) {
                            Swal.fire({
                                title: 'Saved!',
                                text: 'Role created successfully',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: 'Could not create role',
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                        this.reloadGrid();
                    }
                );
            },

            updateData: function (e) {
                if (!this.canEdit) return;
                console.log("updating", e);
                let permissions = JSON.parse(JSON.stringify(this.selectedPermissions));// selectedRoles is a proxy object not array
                console.log("Permissions selected " + permissions);
                this.selectedPermissions = [];

                return ApiService.GeneratePostRequest("Role/UpdateRole", {
                    RoleId: e.data.roleId,
                    Name: e.data.name, 
                    ParticipantType: e.data.participantType,
                    Permissions: permissions,
                    UserId: this.$store.state.Admin.loginInformation.userId
                }).then(
                    response => {
                        e.success(response);
                        if (response["isSuccess"]) {
                            Swal.fire({
                                title: 'Saved!',
                                text: '',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: response.content.message,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                        this.reloadGrid();
                    }
                );
            },
            deleteData: function (e) {
                if (!this.canDelete) return;
                console.log("deleting", e);
                return ApiService.GeneratePostRequest(`Role/DeleteRole/${e.data.roleId}`).then(
                    response => {
                        e.success(response);
                        if (response["isSuccess"]) {
                            Swal.fire(
                                'Role deleted',
                                '',
                                'success'
                            );
                        } else {
                            Swal.fire(
                                'Operation failed',
                                '',
                                'error'
                            );
                        }
                        this.reloadGrid();
                    }
                );
            },
            exportExcel() {
                var self = this;
                this.readData({
                    success: async function (arr) {

                        var options = {
                            skip: 0,
                        };

                        let processedItemsToExport = await self.handleExportData(process(arr, options).data);

                        saveExcel({
                            data: processedItemsToExport,
                            fileName: "userlist",
                            columns: self.exportColumns
                        });
                    }
                });
            },
            searchGrid: function () {
                console.log(this.search);
            },
            async handleExportData(itemsToExport) {
                let self = this;
                let processedItemsToExport = [];
                var userId = this.$store.state.Admin.loginInformation.userId;
                let response = await ApiService.GenerateGetRequest(`Role/GetApplicationRolePermissions/` + userId);
                if (response["isSuccess"]) {
                    itemsToExport.forEach((item, index) => {
                        item.group = "";
                        processedItemsToExport.push(item);
                        response["content"].forEach((permission) => {
                            if (item["roleId"] == permission["roleId"]) {
                                let name = self.systemPermissions.find((sp) => sp["permissionId"] === permission["permissionId"]).name;
                                processedItemsToExport.push({
                                    name: "", 
                                    participantType: "",
                                    group: "Manage Loans",
                                    permissionTexts: name
                                });
                            }
                        });
                        processedItemsToExport.push({
                            name: "",
                            group: "",
                            permissionTexts: "", 
                            participantType: "",
                        });
                    });
                }
                return processedItemsToExport;
            },
             exportPDF() {
                var self = this;
                this.readData({
                    success: async function (arr) {
                        var options = {
                            skip: 0,
                        };
                        const itemsToExport = process(arr, options).data;
                        let processedItemsToExport = await self.handleExportData(itemsToExport);

                        (self.$refs.gridPdfExport).save(processedItemsToExport);
                        return arr;
                    }
                });
            },
            onEdit(e) {
                if (e.model.isNew()) {
                    $(".k-window-title").text("Add");
                }
                // handles resizing the size of the edit form container
                $(".k-edit-form-container").parent().width(1000).data("kendoWindow").center();
                $(".k-edit-form-container").css('width', 'auto');

                // set up checkbox listeners to add to list of enabled checkboxes
                //i.e  $(checkbox)onchange -> if checkbox === enabled: this.activeCheckboxes.push (checboxid) else remove checkbox id if exists
                this.selectedPermissions = [];
                let self = this;
                $('#RolesGrid').find("#right-grid .Toggle-Switch input").on("change", function () {
                    console.log("found");
                    let permissionId = parseInt($(this).attr("data-id"));
                    console.log(permissionId);
                    if ($(this).prop("checked") && !self.selectedPermissions.includes(permissionId)) {
                        self.selectedPermissions.push(permissionId);
                    } else if (!$(this).prop("checked") && self.selectedPermissions.includes(permissionId)) {
                        self.selectedPermissions = self.selectedPermissions.filter(item => item !== permissionId)

                        console.log("removing", self.selectedPermissions);
                    }
                    console.log(self.selectedPermissions);
                });


                // fetch role data and populate it
                if (!e.model.isNew()) {
                    let roleId = e.model.roleId;
                    ApiService.GenerateGetRequest(`Role/GetRolePermissions/${roleId}`).then((response) => {
                        let permissionIds = response["content"];
                        permissionIds.forEach((permissionId) => {
                            self.selectedPermissions.push(parseInt(permissionId));
                            $('#RolesGrid').find(`#right-grid .Toggle-Switch input[data-id="${permissionId}"]`).prop("checked", true);
                        });
                    });
                }


                $(".accordion").accordion();
            },
            reloadGrid() {
                this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
            },       
        },
    }
</script>