class CookieService {
    static Get(name) {
        let cookie = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`); //cookie name qst
        return cookie ? cookie.pop() : ""
    }

    static Create(name, value) {
        document.cookie = name + "=" + value;
        return this;
    }
    // refactor update and create
    static Update(name, value) {
        document.cookie = name + "=" + value;
        return this;
    }

    static Delete(name) {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
        return this;
    }

}

export default CookieService ;