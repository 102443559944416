<template>
    <template v-if="systemRoles.length > 0"><!--Kendo errors out if no roles are available on load-->

        <kendo-datasource ref="participantsource"
                          :transport-read="readData"
                          :transport-create="createData"
                          :transport-update="updateData"
                          :transport-destroy="deleteData"
                          :schema-model-id="'userID'"
                          :schema-model-fields="schema"
                          :transport-parameter-map="parameterMap"
                          :page-size="8">
            
        </kendo-datasource>

        <kendo-grid ref="kendoJqueryGrid"
                    :data-source-ref="'participantsource'"
                    :height="500"
                    :class="'users'"
                    :sortable="true"
                    :resizable="true"
                    :navigatable="true"
                    :pageable="true"
                    :pdf-all-pages="true"
                    :pdf-avoid-links="true"
                    :editable="packagePopUpTemplate"
                    :pdf-paper-size="'A4'"
                    :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                    :pdf-landscape="true"
                    :pdf-repeat-headers="true"
                    :pdf-scale="0.8"
                    :columns="columns"
                    :edit="onEdit"
                    @databound="documentDataBound"
                    @save="onPopUpModalSaved">


            <toolbar>
                <button title="Export to Excel" class="k-button k-primary" @click="exportExcel">
                    Export to Excel
                </button>&nbsp;
                <button class="k-button k-primary" @click="exportPDF">
                    Export to PDF
                </button>
                <div style="margin: 0 0px 0 auto;" v-if="canAdd">
                    <button title="Add New" class="k-button k-primary k-add-button particiant-add" @click="userAdd">
                        Add
                    </button>
                </div>
            </toolbar>
        </kendo-grid>
        <div v-show="false">
            <pdfexport ref="gridPdfExport">
                <native-grid :columns="exportColumns" />
            </pdfexport>
        </div>
    </template>
</template>
<style scoped>
    * {
        font-weight: 400;
    }

    /deep/ .participant-cmd, /deep/ .participant-cmd:hover, /deep/ .participant-cmd::before {
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: unset;
        background: initial;
        border: none;
        border-radius: unset;
        transition: none;
        -webkit-transition: none;
        padding: 8px 5px;
        font: 900 .94em 'Lato', sans-serif;
        color: #4d97ac;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.completed /deep/ span, .status.remote /deep/ span {
            background: #19a71c;
        }

        .status.pending /deep/ span, .status.pendingsigning /deep/ span {
            background: #f8ab10;
        }

        .status.canceled /deep/ span {
            background: #ff2b2b;
        }

        .status.reviewed /deep/ span {
            background: #e960ea;
        }

        .status.pendingreview /deep/ span {
            background: #c14e4e;
        }

        .status.paper /deep/ span {
            background: #c6c6c6;
        }

        .status.signing /deep/ span, .status.signed /deep/ span {
            background: #296298;
        }
</style>
<script>
    import { useRoute } from 'vue-router';
    import ApiService from "@/core/services/api-service";
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { GridPdfExport } from '@progress/kendo-vue-pdf';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { process } from '@progress/kendo-data-query';
    import KendoPopupGenerator from '@/core/services/kendo-grid-popup-form-generator.js';
    import Swal from "sweetalert2";
    import PermissionHelper from "../../core/permission-helper";

    const PREFIX = "UserSettings_";

    const USER_SETTINGS = {
        VIEW: "CanViewUsers",
        ADD: "CanAddUser",
        EDIT: "CanEditUser",
        DELETE: "CanDeleteUser",
        RESET_PASSWORD: "CanResetPassword",
    };

    export default {
        name: 'Users',
        components: {
            'kendo-grid': Grid,
            'kendo-grid-column': GridColumn,
            'kendo-datasource': KendoDataSource,
            'pdfexport': GridPdfExport,
            'toolbar': GridToolbar,
            'dropdownlist': DropDownList,
            'native-grid': NativeGrid,
        },
        data() {
            ///****************************************************************************** NOTE ******************************************************************************///
            ///This is the ONLY WAY right now to include a function to specify if a column can be visible. All other ways fail due to a bug in kendo that attempts to add a _ctx ///
            ///to variables in the function thereby breaking the generated template. The workaround is to have an inline function so that kendo cannot access the function scope ///
            ///******************************************************************************************************************************************************************///

            return {
                schema: {
                    userID: { editable: false },
                    firstName: { type: 'string', editable: true },
                    lastName: { type: 'string', editable: true },
                    phoneNumber: { type: 'number', editable: true },
                    email: { type: 'string', editable: true },
                    active: { type: 'boolean', editable: true },
                    changePasswordNextLogin: { type: 'boolean', editable: true }
                },
                columns: [
                    { field: "firstName", title: 'First Name', width:'14%' },
                    { field: "lastName", title: 'Last Name', width:'14%' },
                    { field: "email", title: 'Email', width:'24%' },
                    { field: "phoneNumber", title: 'Phone', width:'14%' },
                    { field: "active", title: 'Active', width:'10%' },
                    {
                        title: '&nbsp;', width: "20%", command: [
                            { className: 'participant-cmd', text: 'Edit', click: (e) => { this.userEdit(e) }, visible: function (e) { return true; } },
                            { className: 'participant-cmd', text: 'Delete', click: (e) => { this.userDelete(e) }, visible: function (e) { return true; } },
                            { className: 'participant-cmd', text: 'Reset Password', click: (e) => { this.userPassword(e) }, visible: function (e) { return true; } },
                        ]
                    },
                ],
                exportColumns: [
                    { field: "firstName", title: 'First Name' },
                    { field: "lastName", title: 'Last Name' },
                    { field: "email", title: 'Email' },
                    { field: "phoneNumber", title: 'Phone' },
                    { field: "active", title: 'Active' },
                   
                ],

                systemRoles: [],

                selectedRoles: [],

                user: 0,
                permissions: {},
            }
        },
        async created() {
            var userId = this.$store.state.Admin.loginInformation.userId;
            let response = await ApiService.GenerateGetRequest("Role/GetRoles/" + userId);
            console.log("system roles", response);
            if (response["isSuccess"])
                this.systemRoles = response["content"];
            this.permissions = PermissionHelper.GetPermissions(PREFIX); 
        },
        computed: {
            packagePopUpTemplate(e) {
                let html = "";
                html += "<div id='UsersGrid'>";
                html += "   <div class='col-md-6'>";
                html += KendoPopupGenerator.generatePopUpFormFields('FirstName', 'First Name', 'firstName');
                html += KendoPopupGenerator.generatePopUpFormFields('LastName', 'Last Name', 'lastName');
                html += KendoPopupGenerator.generatePopUpFormFields('EmailAddress', 'Email', 'email');
                html += KendoPopupGenerator.generateNumberPopUpFormFields('phoneNumber', 'Phone Number', 'phoneNumber');
                html += KendoPopupGenerator.generatePopUpFormCheckboxFields("active", "Is Active", "active");
                html += "   </div>";
                html += `   <div id="right-grid" class='col-md-6'>
                                <h3>Roles</h3>
                                <ul class="list-group">`
                for (let i in this.systemRoles) {
                    html += `       <li class="list-group-item">`;
                    html += KendoPopupGenerator.generatePopUpFormCheckboxFields(this.systemRoles[i].roleId, this.systemRoles[i].name, this.systemRoles[i].name, this.systemRoles[i].roleId);
                    html += `       </li>`;
                }
                html +=`</ul>
                            </div>`
                html += "</div>";
                return { 'template': html, 'mode': 'popup', width: "600px", height: "300px", }
            },
            canAdd: function () { return this.permissions[USER_SETTINGS.ADD] },
            canEdit: function () { return this.permissions[USER_SETTINGS.EDIT] },
            canDelete: function () { return this.permissions[USER_SETTINGS.DELETE] },
            canView: function () { return this.permissions[USER_SETTINGS.VIEW] },
            canResetPassword: function () { return this.permissions[USER_SETTINGS.RESET_PASSWORD] },
        },
        methods: {
            participantTemplate(e) {
                //console.log(e);
                var html = `<div class="status ` + e.participantStatus.toLowerCase() + `" ` + this.$options.__scopeId + `><span>` + e.participantStatus + `</span></div>`;
                return html;
            },
            onPopUpModalSaved(e) {
                console.log("onPopUpModalSaved", e);
            },
            sendInvite: function (e) {
                console.log('sendInvite', e)
            },
            userAdd() {
                if (!this.canAdd) return;
                this.$refs.kendoJqueryGrid.kendoWidget().addRow();
            },
            userEdit(event) {
                if (!this.canEdit) return;
                console.log("clicked", event);
                this.$refs.kendoJqueryGrid.kendoWidget().editRow($(event.target).closest("tr"));
            }, userDelete() {
                if (!this.canDelete) return;
                console.log("clicked");
                this.$refs.kendoJqueryGrid.kendoWidget().removeRow($(event.target).closest("tr"));
            },
            userPassword(e) {
                if (!this.canResetPassword) return;
                var data = this.$refs.kendoJqueryGrid.kendoWidget().dataItem($(e.target).closest("tr"));
                this.user = data["email"];
                return ApiService.GeneratePostRequest("Admin/ResetPassword", { username : this.user }).then(
                    response => {
                        if (response["isSuccess"]) {
                            Swal.fire({
                                title: 'Password Reset',
                                text: 'Reset Password Link sent successfully',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: 'Unable to reset user password',
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                    }
                );

            },
            
            documentDataBound: function (e) {
                var self = this;
                var grid = e.sender;
                var items = e.sender.items();
                items.each(function (index) {
                    var dataItem = grid.dataItem(this);
                    var row = $("tr[data-uid='" + dataItem.uid + "']");
                    if (!self.canEdit) {
                        row.find(".k-grid-Edit").remove();
                    }
                    if (!self.canDelete) {
                        row.find(".k-grid-Delete").remove();
                    }
                    if (!self.canResetPassword) {
                        row.find(".k-grid-ResetPassword").remove();
                    }
                });
            },
            parameterMap: function (options, operation) {
                console.log("options", options);
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            readData: function (e) {
                //console.log(e)
                return ApiService.GeneratePostRequest("Admin/client_users").then(
                    response => {
                        // this map is just to make the create work fine for now - otherwise it will call create multiple times
                        e.success(response["content"]);
                    }
                );
            },
            createData: function (e) {
                if (!this.canAdd) return;
                console.log("Creating", e);
                let roles = JSON.parse(JSON.stringify(this.selectedRoles));// selectedRoles is a proxy object not array
                this.selectedRoles = [];
                console.log("roles", roles);
                return ApiService.GeneratePostRequest("Admin/AddUser", {
                    FirstName: e.data.firstName, LastName: e.data.lastName,
                    PhoneNumber: e.data.phoneNumber, Email: e.data.email, Roles: roles
                }).then(
                    response => {
                        e.success(response["content"]);
                        if (response["isSuccess"]) {
                            Swal.fire({
                                title: 'User created',
                                text: 'User created successfully',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: 'Unable to create user',
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                        this.reloadGrid();
                    }
                );
            },

            updateData: function (e) {
                if (!this.canEdit) return;
                console.log("updating", e);
                let roles = JSON.parse(JSON.stringify(this.selectedRoles));// selectedRoles is a proxy object not array
                this.selectedRoles = [];
                console.log(roles, "roles");
                return ApiService.GeneratePostRequest("Admin/UpdateUser", {
                    UserID: e.data.userID, FirstName: e.data.firstName,
                    LastName: e.data.lastName, PhoneNumber: e.data.phoneNumber,
                    Email: e.data.email, Roles: roles, Active : e.data.active
                }).then(
                    response => {
                        e.success(response);
                        if (response["isSuccess"]) {
                            Swal.fire({
                                title: 'Saved!',
                                text: '',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: 'Unable to update user',
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                        this.reloadGrid();
                    }
                );
            },
            deleteData: function (e) {
                if (!this.canDelete) return;
                console.log("deleting", e);
                return ApiService.GeneratePostRequest(`Admin/SoftDeleteUser/${e.data.userID}`).then(
                    response => {
                        e.success(response);
                        if (response["isSuccess"]) {
                            Swal.fire({
                                title: 'Deleted Successfully',
                                text: '',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: 'Could not delete user',
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                        this.reloadGrid();
                    }
                );
            },
            exportExcel() {
                var self = this;
                this.readData({
                    success: function (arr) {

                        var options = {
                            skip: 0,
                        };
                        saveExcel({
                            data: process(arr, options).data,
                            fileName: "userlist",
                            columns: self.columns
                        });
                    }
                });
            },
            searchGrid: function () {
                console.log(this.search);
            },
            exportPDF() {
                var self = this;
                this.readData({
                    success: function (arr) {
                        var options = {
                            skip: 0,
                        };
                        const itemsToExport = process(arr, options).data;
                        console.log("itemsToExport users", itemsToExport);
                        (self.$refs.gridPdfExport).save(itemsToExport);
                        return arr;
                    }
                });
            },
            onEdit(e) {
                if (!this.canEdit) return;
                if (e.model.isNew()) {
                    $(".k-window-title").text("Add");
                }
                // handles resizing the size of the edit form container
                $(".k-edit-form-container").parent().width(1000).data("kendoWindow").center();
                $(".k-edit-form-container").css('width', 'auto');

                // set up checkbox listeners to add to list of enabled checkboxes
                //i.e  $(checkbox)onchange -> if checkbox === enabled: this.activeCheckboxes.push (checboxid) else remove checkbox id if exists
                this.selectedRoles = [];
                let self = this;
                $('#UsersGrid').find("#right-grid .Toggle-Switch input").on("change", function () {
                    console.log("found");
                    let roleId = parseInt($(this).attr("data-id"));
                    if ($(this).prop("checked") && !self.selectedRoles.includes(roleId)) {
                        self.selectedRoles.push(roleId);
                    } else if (!$(this).prop("checked") && self.selectedRoles.includes(roleId)) {
                        self.selectedRoles = self.selectedRoles.filter(item => item !== roleId)
                    }
                });

              
                // fetch role data and populate it
                if (!e.model.isNew()) {
                    let userId = e.model.userID;
                    ApiService.GenerateGetRequest(`Admin/GetUserRoles/${userId}`).then((response) => {
                        console.log("user role data", response);
                        let roleIds = response["content"];
                        roleIds.forEach((roleId) => {
                            self.selectedRoles.push(parseInt(roleId));
                            $('#UsersGrid').find(`#right-grid .Toggle-Switch input[data-id="${roleId}"]`).prop("checked", true);
                        });
                    });
                }
            },
            reloadGrid() {
                this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
            },
        },
    }
</script>