<template>
    <agree-to-terms-and-conditions @userSelectedOption="userChoseOption" />
</template>
<script>
    import ApiService from "@/core/services/api-service";
    import AgreeToTermsAndConditions from "@/components/page/AgreeTermsAndConditions";
    import Swal from "sweetalert2";
    export default {
        name: 'TermsAndConditions',
        components: {
            AgreeToTermsAndConditions
        },
        data() {
            return {
                isRon: this.$store.getters["Invitation/isRon"] || false
            }
        },
        async created() {
            console.log("ISRON: ", this.isRon);
            let settings = await this.$store.getters["App/appSettings"];
            if (!settings.showEConsentonSign) {
                this.$router.push({ name: "SignerDetails" });
                return;
            }
        },
        methods: {
            async userChoseOption({ ToS, econsent }) {
                this.$store.commit("App/updateTermsAndConditions", { AgreedTo: ToS });
                await ApiService.GeneratePostRequest(`verify/update`, { "agreement": ToS, "eConsentText": econsent }).then((response) => {
                    if (response.isSuccess) {
                        if (ToS) {
                            this.$store.commit("App/updateTermsAndConditions", { AgreedTo: true });
                            let settings = this.$store.getters["App/appSettings"];
                            let role = this.$store.getters["App/getRole"];
							//CHECK THIS OUT SEE WHERE IT HAS TO MOVE TO
                        	//this.$router.push('/signer/setup');
                            if (role == "Observer") {
                                this.$router.push({ path: `/signer/room` });
                            } else if (this.isRon) {
                                this.$router.push({ name: "SignerDetails" });
                            } else {
                                if (settings["skipSignerDetails"] && settings["skipSSN"]) {
                                    this.$router.push({ path: `/signer/room` });
                                } else if (settings["skipSignerDetails"] && !settings["skipSSN"]) {
                                    this.$router.push('/signer/ssn');
                                } else if (!settings["skipSignerDetails"] && settings["skipSSN"]) {
                                    this.$router.push({ name: "SignerDetails" });
                                } else {
                                    this.$router.push({ name: "SignerDetails" });
                                }
                            }
                            
                        } else {
                            this.$store.commit("App/updateTermsAndConditions", { AgreedTo: false });
                            this.$router.push({ name: 'Declined' });
                        }
                    } else {
                        Swal.fire({
                            title: 'Failed to update eConsent',
                            text: response.message,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                    }
                });;
                // need to get back package id and admin id from auth url: admin comes from the session ticket, may need a new ticket for package id

            }
        }
    }
</script>