
import ApiService from "@/core/services/api-service.js";
import store from "../../store/index";
class PDFForm {
    constructor() {
        this.pdfObject = null;
        this.data = null;
        this.numpages = null;
        this.currentPage = null;
        this.docTarget = null;
        this.elementsOnPage = null;
        this.allFormFields = [];
        this.pdfName = "";
        this.pdfUrl = "";
        this.documentId = 0;
        this.currentPageInLoadingEntirePDF = 1;
        this.formIsReviewed = false;
        this.viewport = { width: 0, height: 0 };
        this.isEnote = false;
        this.isMultiple = false;
        this.pdfForms = [];
        this.path = "";
        this.currentPDFFormsObject = -1;
        this.signerId = ""
    }

    savePageData() {
        try {
            let data = {};
            this.allFormFields.forEach((field) => {
                data[field["noSpaceId"]] = field["value"];
            });
            return this.data;
        }
        catch (e) {
            alert(e.message);
        }
    }

    setField(id, value) {
        for (var i = 0; i < this.allFormFields.length; i++) {
            if (this.allFormFields[i]["noSpaceId"] === id) {
                this.allFormFields[i]["value"] = value;
                break;
            }
        }
    }

    allFieldsCompleted(isAdmin) {
        try {
            let completed = true;
            for (var i = 0; i < this.allFormFields.length; i++) {
                if (this.allFormFields[i]["value"] === "" && this.allFormFields[i].isAdmin == isAdmin) {
                    completed = false;
                    break;
                }
            }
            return completed;
        }
        catch (e) {
            alert(e.message);
        }
    }

    static dumpData = function (data) {
        var string = '<table style="border:1px solid red">';
        Object.keys(data).forEach(function (key, index) {
            string = string + '<tr><td>' + key + '</td><td>' + data[key] + '</td></tr>';
        });
        string = string + '</table>';
        return string;
    }


    getPdfData(encode) {
        if (typeof encode === 'undefined') {
            encode = false;
        }
        this.savePageData();
        var postdata = {};
        var newName = '';
        for (var datarow in this.data) {
            if (encode) {
                newName = encodeURIComponent(datarow);
            }
            else {
                newName = datarow;
            }
            postdata[newName] = this.data[datarow];
        }
        return postdata;
    }

    loadMulitplePDFs(userId, signerId, pdfList, startAtIndex, target, canvasWidth = false, canvasHeight = false, doForm = true, isAdminPage = false, callBackAfterRender) {
        this.isMultiple = true;
        let self = this;
        if (pdfList[startAtIndex]["isEnote"]) {
            let newobj = new this.constructor();
            newobj.isEnote = true;
            newobj.pdfName = pdfList[startAtIndex]["noSpaceName"];
            newobj.numpages = 1;
            this.pdfForms.push(newobj);
            this.currentPDFFormsObject++;
            
            let container = $($(".signer-room-form-containter > div > div:last-child")[0]).clone();
            let blob = new Blob(["<script>window.addEventListener('message', function(){ window.parent.postMessage(this.document.documentElement.scrollHeight, window.location.origin)}, false);</script><div id='wrapperContainer' style=''><style>html {margin: 0 auto; height:100px !important;} #wrapperContainer > div {font-size: 1.2em;width:98%;overflow-wrap:break-word;} @media (min-width:1100px)  { #wrapperContainer {width: 70%; margin-left: auto; margin-right: auto; -webkit-transform: scale(1.1); -ms-transform: scale(1.1); transform: scale(1.1); -webkit-transform-origin: 0 0; -ms-transform-origin: 0 0; transform-origin: 0 0;}}</style>" + pdfList[startAtIndex].html +"</div>"], { type: "text/html; charset=utf-8" });
            var iframe = document.createElement("iframe");
            iframe.style.width = "700px";
            iframe.style.background = "#FFF";
            iframe.style.overflowY = "hidden";
            iframe.onload = async function (event) {
                setTimeout(async () => {
                    this.style.height = (this.contentWindow.document.documentElement.scrollHeight + 100) + 'px';
                    this.contentWindow.document.documentElement.style.overflowY = "hidden";
                }, 500);
                newobj.viewport = {
                    width: this.contentWindow.document.documentElement.scrollWidth,
                    height: this.contentWindow.document.documentElement.scrollHeight
                }               

                let name = this.contentWindow.document.querySelectorAll("[id^='Borrower'][id$='FirstName'], [id^='Borrower'][id$='MiddleName'], [id^='Borrower'][id$='LastName']");
                if (name.length > 0)
                    name.forEach((item) => {
                        let txt = $(item).text();
                        if (txt.trim() === "")
                            txt = " "
                        else 
                            txt = ' ' + txt.trim() + ' '
                        $(item).text(txt);
                    });

                if(pdfList[startAtIndex].documentType == "Review_And_Sign"){
                    // set up form fields when content loads
                    let fields = this.contentWindow.document.getElementsByTagName("signature_line");
                    for (let field of fields) {
                        let client = field.getBoundingClientRect();
                        for (let i = 0; i < pdfList[startAtIndex].smartTags.length; i++) {
                            let tag = pdfList[startAtIndex].smartTags[i];
                            let img = tag.imgUrl || "";
                            console.log("Enote FIeld", field);

                            const url = "document/load?path=" + img;
                            let fileBlob = "";
                            if (img.length != 0) {
                                const file = await ApiService.GenerateGetBlobRequest(url);
                                fileBlob = URL.createObjectURL(file);
                            }

                            if (tag.controlId === field.getAttribute("_id") && !tag.imgUrl && (userId == tag.signerId || (isAdminPage && signerId == tag.signerId))) {
                                console.log("Enote FIeld inside", field);
                                newobj.allFormFields.push({
                                    noSpaceId: tag.controlId ,
                                    noSpaceIdAlias: tag.controlId,
                                    id: tag.controlId ,
                                    type: "Signature",
                                    originalName: tag.displayText,
                                    value: img,
                                    guidedTagEnabled: false,
                                    belongsToPage: 1,
                                    x: client.left,
                                    y: client.top,
                                    width: client.width,
                                    smartTag: pdfList[startAtIndex].smartTags[i],
                                    isAdmin: tag.isAdmin
                                });
                            } else if (tag.controlId === field.getAttribute("_id") && img && tag.isTag) {
                                // load image in eNote.
                                let new_element = $("<img/>");
                                $(new_element).attr('src', fileBlob);
                                $(new_element).css('bottom', '0px');
                                $(new_element).css('width', 'auto');
                                $(new_element).css('height', '30px');


                                let marginLeft = $(field).closest("tr").css('margin-left');
                                if (marginLeft == "0" || marginLeft == "0px")
                                    marginLeft = $(field).closest("p").css('text-indent');
                                $(new_element).css('margin-left', marginLeft);

                                $(field).html(new_element);
                            }
                        }
                    }
                }

                //added this section to displayed signatures in signing section for fully signed enotes
                if(pdfList[startAtIndex].documentStatus == "Signed"){
                    let signerFields = this.contentWindow.document.getElementsByTagName("signature_image");
                    for (let field of signerFields) {
                        pdfList[startAtIndex].smartTags.forEach(async tag => {
                            if (tag.controlId === field.getAttribute("_id")) {

                                const url = "document/load?path=" + encodeURIComponent(tag.imgUrl);
                                let fileBlob = "";
                                if (img.length != 0) {
                                    const file = await ApiService.GenerateGetBlobRequest(url);
                                    fileBlob = URL.createObjectURL(file);
                                }

                                let new_element = $("<img/>");
                                $(new_element).attr('src', fileBlob);
                                $(new_element).css('bottom', '0px');
                                $(new_element).css('width', 'auto');
                                $(new_element).css('height', '30px');


                                let marginLeft = $(field).closest("tr").css('margin-left');
                                if (marginLeft == "0" || marginLeft == "0px")
                                    marginLeft = $(field).closest("p").css('text-indent');
                                $(new_element).css('margin-left', marginLeft);
                                $(field).html(new_element);
                            }
                        });
                    }
                }
               

                if (callBackAfterRender)
                    callBackAfterRender(1, pdfList[startAtIndex], startAtIndex);




                let newIndex = startAtIndex + 1;
                if (newIndex < pdfList.length) {
                    self.loadMulitplePDFs(userId, signerId, pdfList, newIndex, target, canvasWidth, canvasHeight, doForm, isAdminPage, callBackAfterRender);
                }
            }
            iframe.setAttribute("id", newobj.pdfName + "-EnotePage_1")
            iframe.src = URL.createObjectURL(blob);
            container.html(iframe);
            container.css('height', '100%');
            container.css('background', '#FFF');
            container.attr("data-pagename", pdfList[startAtIndex]["noSpaceName"]);
            container.attr("data-page", 1);
            container.attr("id", newobj.pdfName + "-Page_1");
            container.attr("data-page", 1);
            $(".signer-room-form-containter > div").append(container);



        } else {
            this.loadPdf(target, pdfList[startAtIndex]["noSpaceName"], pdfList[startAtIndex]["path"], canvasWidth, canvasHeight, pdfList[startAtIndex]["documentType"]=="Review_And_Sign", (pageNum, viewport) => {
                if (callBackAfterRender)
                    callBackAfterRender(pageNum, pdfList[startAtIndex], startAtIndex);

                this.pdfForms[this.currentPDFFormsObject]["viewport"] = viewport;
                let newIndex = startAtIndex + 1;
                if (newIndex < pdfList.length && pageNum === pdfList[startAtIndex]["pages"]) {
                    this.loadMulitplePDFs(userId, signerId, pdfList, newIndex, target, canvasWidth, canvasHeight, doForm, isAdminPage, callBackAfterRender);
                }
            }, pdfList[startAtIndex]["smartTags"], pdfList[startAtIndex], isAdminPage);
        }
    }


    loadEnoteThumbnail(pdfName, html, callBackAfterRender, thumbWidth) {
        let newobj = new this.constructor();
        newobj.isEnote = true;
        newobj.pdfName = pdfName;
        newobj.numpages = 1;
        let container = $($(".thumbnail-container > div > div")[0]).clone();
        let blob = new Blob([html], { type: "text/html; charset=utf-8" });
        var iframe = document.createElement("iframe");
        iframe.style.width = "100%";
        iframe.style.height = "100px";

        iframe.style.background = "#FFF";
        iframe.style.overflowY = "hidden";
        iframe.onload = function () {
            this.contentWindow.document.documentElement.style.overflowY = "hidden";
            this.contentWindow.document.documentElement.style.overflowX = "hidden";
            newobj.viewport = {
                width: this.contentWindow.document.documentElement.scrollWidth,
                height: this.contentWindow.document.documentElement.scrollHeight
            }

            if (callBackAfterRender)
                callBackAfterRender(1);
        }
        iframe.src = URL.createObjectURL(blob);
        container.html(iframe);
        container.css('height', '100%');
        container.css('width', thumbWidth+"px");
        container.css('margin', "10px auto") ;
        container.css('background', '#FFF');
        container.attr("data-pagename", pdfName);
        container.attr("data-page", 1);

        $(".thumbnail-container > div").append(container);
    }

    loadPdf(target, pdfName, pdfUrl, canvasWidth = false, canvasHeight = false, doForm = true, callBackAfterRender, smartTags = [], object = {}, isAdminPage = false) {
        try {
            var self = this;
            var $this = self;
            if ($this.isMultiple) {
                self = new $this.constructor();
                this.pdfForms.push(self);
                this.currentPDFFormsObject++;
            }
            self.pdfName = pdfName;
            self.path = pdfUrl;
            self.documentId = object ? object.documentID : 0;
            self.docTarget = document.querySelector(target);
            var style = getComputedStyle(self.docTarget, null);
            var width = canvasWidth || parseInt(style.getPropertyValue('width'), 10);
            self.data = {};
            self.elementsOnPage = {};
            self.availableForPresign = object.availableForPresign
            PDFJS.getDocument({
                url: pdfUrl,
                httpHeaders: { Authorization: `Bearer ${store.getters["App/token"]}` },
                withCredentials: true,
            }).then(async function (pdf) {
                self.pdfObject = pdf;
                self.numpages = self.pdfObject.numPages;
                let page = null;
                for (var i = 1; i <= self.numpages; i++) {
                    page = await self.pdfObject.getPage(i)
                    var elements = PDFJS.FormFunctionality.returnFormElementsOnPage(page);
                    self.elementsOnPage['' + (page.pageIndex + 1)] = elements;
                }

                self.currentPage = 1;
                // Using promise to fetch the page
                page = await self.pdfObject.getPage(1);
                $this.loadAllPages(width, canvasHeight, page, self.docTarget, doForm, self.data, self.pdfName, self.allFormFields, 1, callBackAfterRender, smartTags, isAdminPage);
            });
            return true;
        }
        catch (e) {
            console.log("Error Loaidng PDF", e);
        }
        return false;
    }

    loadAllPages(width, canvasHeight, page, docTarget, doForm, data, pdfName, allFormFields, pageNum, callBackAfterRender, smartTags = [], isAdminPage = false) {

        var self = this;
        var $this = self;
        if ($this.isMultiple) {
            self = $this.pdfForms[$this.currentPDFFormsObject];
        }
        PDFJS.FormFunctionality.render(width, canvasHeight, page, self.docTarget, doForm, self.data, self.pdfName, self.allFormFields, pageNum, callBackAfterRender, smartTags, isAdminPage);
        self.currentPageInLoadingEntirePDF++;
        if (self.pdfObject !== null && self.currentPageInLoadingEntirePDF <= self.numpages) {
            self.pdfObject.getPage(self.currentPageInLoadingEntirePDF).then(function (page) {
                $this.loadAllPages(width, canvasHeight, page, docTarget, doForm, data, pdfName, allFormFields, self.currentPageInLoadingEntirePDF, callBackAfterRender, smartTags, isAdminPage);
            });
        }
    }


    loadPage(width, canvasHeight, page, docTarget, doForm, pageNum, callBackAfterRender, smartTags = [], isAdmin = false, additionalData = {}) {
        var self = this;
        var $this = self;
        if ($this.isMultiple) {
            self = $this.pdfForms[$this.currentPDFFormsObject];
        }
        PDFJS.FormFunctionality.render(width, canvasHeight, page, docTarget, doForm, self.data, self.pdfName, self.allFormFields, pageNum, callBackAfterRender, smartTags, isAdmin, additionalData );
    }




    getPageCount() {
        return this.numpages;
    }

    getPageElement(pageNumber) {
        if (typeof this.elementsOnPage['' + pageNumber] != 'undefined') {
            return this.elementsOnPage['' + pageNumber];
        }
        return [];
    }

    getAllPagesElementnames() {
        return this.elementsOnPage;
    }

    getPageForElement(elementId) {
        for (var i = 1; i <= this.numpages; i++) {
            if (typeof this.elementsOnPage['' + i] != 'undefined') {
                var pageElements = this.elementsOnPage['' + i];
                for (var j = 0; j < this.pageElements.length; j++) {
                    if (pageElements[j] == elementId) {
                        return i;
                    }
                }
            }
        }
        return false;
    }

    getCurrentPage() {
        return this.currentPage;
    }

    getPreviousPage() {
        try {
            if (this.currentPage == 1)
                return 1;
            return this.currentPage - 1;
        }
        catch (e) {
            alert(e.message);
        }
        return false;
    }

    getNextPage() {
        try {
            if (this.currentPage == this.numpages)
                return this.currentPage;
            return this.currentPage + 1;
        }
        catch (e) {
            alert(e.message);
        }
        return false;
    }


    loadPdfPage(pageNumber, target, pdfName, pdfUrl, canvasWidth = false, canvasHeight = false, doForm = true, callBackAfterRender, smartTags = [], isAdmin = false, additionalData = {}) {
        try {
            var self = this;
            var $this = self;
            if ($this.isMultiple) {
                self = new $this.constructor();
                this.pdfForms.push(self);
                this.currentPDFFormsObject++;
            }
            self.pdfName = pdfName;
            self.docTarget = document.querySelector(target);
            var style = getComputedStyle(self.docTarget, null);
            var width = canvasWidth || parseInt(style.getPropertyValue('width'), 10);
            self.data = {};
            self.elementsOnPage = {};
            PDFJS.getDocument({
                url: pdfUrl,
                httpHeaders: { Authorization: `Bearer ${store.getters["App/token"]}` },
                withCredentials: true,
            }).then(async function (pdf) {
                self.pdfObject = pdf;
                self.numpages = self.pdfObject.numPages;
                let page = await self.pdfObject.getPage(pageNumber)
                var elements = PDFJS.FormFunctionality.returnFormElementsOnPage(page);
                self.elementsOnPage['' + (page.pageIndex + 1)] = elements;
                $this.loadPage(width, canvasHeight, page, self.docTarget, doForm, pageNumber, callBackAfterRender, smartTags, isAdmin, additionalData  );
            });
            return true;
        }
        catch (e) {
            console.log("Error Loaidng PDF", e);
        }
        return false;
    }

    getData() {
        if (typeof this.pdfObject != 'undefined') {
            try {
                return PDFJS.FormFunctionality.getFormValues();
            }
            catch (e) {
                alert(e.message);
            }
        }
        return false;
    }
}

export default PDFForm;