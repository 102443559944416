<template>
    <section id="screen-page" style="padding-top:0;">
        <div class="container" id="pricingpage">
            @*<div class="row">
                <div class="col-md-12">
                    <img src="/images/pricing.png" alt="">
                    <h3>Pricing</h3>
                    <img src="/images/line.png" alt="">
                </div>
            </div>*@
            <div class="row">
                <div class="col-md-6">
                    <div class="pricing-box greenbdr">
                        <img src="/images/green-pricing.png" alt="">
                        <h3>E-SIGN</h3>
                        <ul>
                            <li>Upto 2 Signers</li>
                            <li>Upto 2 Witness</li>
                            <li><span>$6</span> For each Signer�s 1st Signature</li>
                            <li><span>$1</span> Additional Sign each signer</li>
                        </ul>
                        <a href="" class="btn btn-green">GET STARTED</a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="pricing-box bluebdr">
                        <img src="/images/blue-pricing.png" alt="">
                        <h3>NOTARIZE</h3>
                        <ul>
                            <li>Upto 2 Signers</li>
                            <li>Upto 2 Witness</li>
                            <li><span>$6</span> For each Signer�s 1st Signature</li>
                            <li><span>$1</span> Additional Sign each signer</li>
                        </ul>
                        <ul>
                            <li>Connect on call with Notary</li>
                            <li><span>$25</span> each seal</li>
                        </ul>
                        <a href="" class="btn btn-blue">GET STARTED</a>
                    </div>
                </div>
            </div>

            <div id="pricing-stripe" style="padding-top: 50px">
                <div class="col-md-12">
                    <a class="stripe stripe2" href="">Click Here To See Business Client Pricing</a>
                </div>
            </div>
            <div id="pricing-stripe">
                <div class="col-md-12">
                    <a class="stripe stripe2" href="">Please Contact Sales for Bulk/Volume Discount Pricing</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Pricing',
    }
</script>