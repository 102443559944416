<template>
  <h2 style="width:100%; text-align:center; margin-top:20px">
    Please Print, Sign, Scan and Upload the Following Documents
  </h2>
  <document-grid
    :docs="wetDocs"
    @viewDocument="viewDocument"
    :visibleFields="wetSignDocFields"
    :title="'Documents_To_Upload'"
    :hiddenActions="hiddenWetSignDocActions"
  />
  <h2 style="width:100%; text-align:center; margin-top:20px">
    Uploaded Documents
  </h2>
  <document-grid
    :docs="uploadedDocs"
    @viewDocument="viewDocument"
    :visibleFields="uploadedDocFields"
    :title="'Uploaded_Documents'"
    @refetchDocuments="fetchWetSignDocuments"
    :hiddenActions="hiddenUploadSignDocActions"
  />
  <upload-documents
    @uploadComplete="fetchWetSignDocuments"
    :wetSignDocs="wetDocs"
    :uploadedDocs="uploadedDocs"
        />
</template>
<script>
import DocumentsGrid from "./DocumentsGrid.vue";
import ApiService from "../../core/services/api-service";
import UploadDocuments from "./UploadDocuments.vue";

export default {
  name: "PrintAndUpload",
  props: [],
  components: {
    "document-grid": DocumentsGrid,
    "upload-documents": UploadDocuments,
  },

  data: function() {
    return {
      uploadedDocs: [],
      wetDocs: [],
      wetSignDocFields: ["documentId", "description", "participantStatus", "checkbox"],
      uploadedDocFields: ["documentId", "description", "dateAdded"],
      hiddenWetSignDocActions:["delete"],
      hiddenUploadSignDocActions:["download", "print", "downloadAll"],
    };
  },
  async created() {
    this.fetchWetSignDocuments();
  },
  methods: {
    async fetchWetSignDocuments() {
      let response = await ApiService.GenerateGetRequest(
        "document/signer/printandupload"
      );
      console.log(response.content);
      if (response && response["isSuccess"]) {
        this.uploadedDocs = [];
        this.wetDocs = [];
        response["content"].forEach((item) => {
          if (item.documentType == "Uploaded") {
            this.uploadedDocs.push({
              ...item,
              documentId: item.documentID,
              selected: false,
            });
          } else {
            var mappedDocIdx = response["content"].findIndex(x => x.documentID == item.uploadedDocumentId)
            this.wetDocs.push({
              ...item,
              documentId: item.documentID,
              selected: false,
              docFile:
                item.participantStatus == "Uploaded" && item.uploadedDocumentId > 0 && mappedDocIdx >= 0 
                  ? { uid: item.uploadedDocumentId, name: response["content"][mappedDocIdx].description }
                  : "",
        });
      }
        });
      }
      console.log("wet docs", this.wetDocs);
      console.log("uploaded docs", this.uploadedDocs);
    },
    viewDocument(documentId) {
      this.$emit("setDocumentId", documentId);
    },
  },
};
</script>
<style scoped>
.select-counter {
  color: white;
  font-weight: 900;
}
</style>
