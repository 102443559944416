<template>
    <section id="opt-out-final-page">
        <div class="opt-out-final-content">
            <p v-html="optoutfinal"></p>
            <div class="lender">
                <p>{{ lender }}</p>
            </div>
        </div>
    </section>
</template>
<style>

    .lender {
        font-weight: bold;
    }

    .opt-out-final-content h1 {
        padding-top: 5%;
        font-size: 45px;
        text-align: center;
    }

    .opt-out-final-content p {
        font-size: 30px;
        text-align: center;
    }
</style>
<script>
    import ApiService from "../../core/services/api-service";
    export default {
        name: 'Optout',
        data() {
            this.readData();
            return {
                lender: "",
                optoutfinal: [],
                lenderid: null,
            }
        },
        methods: {
            async readData() {
                let signerData = this.$store.getters["Auth/signerSessionInformation"];
                let lenderid = this.$store.state.App.lenderId;
                return ApiService.GenerateGetRequest(`econsent/optoutfinal?lenderid=${lenderid}`).
                    then((response) => {
                        this.lender = signerData["lender"];
                        this.optoutfinal = response["content"];
                    });
            }
        },
    }
</script>