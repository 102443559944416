<template>
  <div class="row">
    <div
      class="innerbox col-md-12"
      style=" text-align: center; padding-top: 10%; }"
      v-if="!signingInitiated"
    >
      <button class="btn-secondary" @click="signingInitiated = true">
        Start Signing
      </button>
    </div>
  </div>

  <signer-room v-if="signingInitiated" @completed="completedSigning" />
</template>
<style scoped>
.btn-secondary a {
  color: #fff;
}
</style>
<script>
import ApiService from "@/core/services/api-service";
import SignerRoom from "@/views/signer/SignerRoom";
export default {
  name: "SignDocuments",
  emits: ["componentValid"],
  components: {
    SignerRoom,
  },
  data() {
    return {
      signingInitiated: true,
    };
  },
  methods: {
    async isValid() {
      return true;
    },
    completedSigning() {
      console.log("completed");
      this.$emit("componentValid", true, true);
    },
  },
};
</script>

<style scoped></style>
