<template>
  <div class="frame">
    <header>
      <h1>Signing Room Checklist</h1>
    </header>
    <form>
      <ul class="list">
        <li v-for="item in items">
          <input
            disabled
            id="item-0"
            :checked="item.IsCompleted"
            :value="item.Id"
            type="checkbox"
          />
          <label for="item-0" @click.prevent="itemClicked(item)">{{
            item.Text
          }}</label>
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api-service";
export default {
  name: "Checklist",
  props: ["items"],
  emits: ["itemClicked"],
  data() {
    return {};
  },
  created() {
    console.log("Checklist items", this.items);
  },
  methods: {
    itemClicked(item) {
      this.$emit("itemClicked", item);
    },
  },
};
</script>

<style scoped>
.frame {
  width: 100%;
  border-radius: 4px;
  box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: #fff;
  color: #333;
  font-family: "Open Sans", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px 36px;
}

header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

h1 {
  font-size: 1.7rem;
  margin-bottom: 4px;
}

h2 {
  font-size: 1rem;
  font-weight: 400;
}

    .list {
        list-style: none;
        margin-top: 24px;
        padding-left: 0;
    }

.list li {
  margin-top: 0 !important;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.list label {
  cursor: pointer;
  flex-grow: 1;
  transition: color 0.5s;
}

.list input {
  position: relative;
}

.list input::after {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  border: 1px solid #888888;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  top: 50%;
  left: 50%;
  background-color: white;
  transition: transform 0.6s, border 0.6s;
}

.list input::before {
  position: absolute;
  content: "\2714";
  height: 18px;
  width: 18px;
  transform: translate(-50%, -50%) scale(0.8);
  top: 60%;
  left: 80%;
  color: #888;
  z-index: 1;
  opacity: 0;
  transition: transform 0.6s, opacity 0.6s;
}

.list input:checked + label {
  color: rgba(51, 51, 51, 0.4);
}

.list input:checked::after {
  transform: translate(-50%, -50%) scale(1.2);
  border: 1px solid rgba(136, 136, 136, 0);
}

.list input:checked::before {
  opacity: 0.4;
  transform: translate(-50%, -50%) scale(1);
}
</style>
