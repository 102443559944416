<template>

    <kendo-datasource ref="source"
                      :transport-read="readData"
                      :transport-create="createData"
                      :transport-update="updateData"
                      :transport-destroy="deleteData"
                      :schema-model-id="'templateID'"
                      :schema-model-fields="schema"
                      :transport-parameter-map="parameterMap">
    </kendo-datasource>

    <kendo-grid ref="kendoJqueryGrid"
                :data-source-ref="'source'"
                :height="400"
                :class="'users'"
                :sortable="true"
                :resizable="true"
                :navigatable="true"
                :pageable="true"
                :pdf-all-pages="true"
                :pdf-avoid-links="true"
                :editable="packagePopUpTemplate"
                :pdf-paper-size="'A4'"
                :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                :pdf-landscape="true"
                :pdf-repeat-headers="true"
                :pdf-scale="0.8"
                :columns="columns"
                :edit="onEdit"
                @databound="documentDataBound"
                @save="onPopUpModalSaved">


        <toolbar>
            <button title="Export to Excel" class="k-button k-primary" @click="exportExcel">
                Export to Excel
            </button>&nbsp;
            <button class="k-button k-primary" @click="exportPDF">
                Export to PDF
            </button>
            <div style="margin: 0 0px 0 auto;">
                <!--<button title="Add New" class="k-button k-primary k-add-button particiant-add" @click="addButtonClicked">
                    Add
                </button>-->
            </div>
        </toolbar>
    </kendo-grid>
    <div v-show="false">
        <pdfexport ref="gridPdfExport">
            <native-grid :columns="exportColumns" />
        </pdfexport>

        <div ref="placeholderTemplate">
            <label for='templateToolPlaceHolder' style='vertical-align:middle;'>Placeholder:</label>
            <select class='templateToolPlaceHolder' style='width:150px'>
                <option value='' selected>Choose</option>
                <template v-for="item in emailPlaceHolders">
                    <option :value='item.name'>{{item.key}}</option>
                </template>
            </select>
        </div>
    </div>

    <!--Email Recipients-->
    <EmailRecipients :offset="offset" :show="showRecipientsModal" :systemRoles="systemRoles" :emailRecipients="emailRecipients" @handleSaveEmailRecipients="handleSaveEmailRecipients" @closeEmailRecipientsModal="closeEmailRecipientsModal" @addRecipient="addRecipient" @deleteRecipient="deleteRecipient" />

    <!--<div class="row k-pb-md"></div>
    <div class="k-card k-card-vertical">
        <div class="k-card-header">
            <div class="col-lg-12">
                <h4>Reminder Settings</h4>
            </div>
        </div>
        <div class="k-card-body">
            <div class="row k-pb-md">
                <div class="col-lg-3 col-md-6">
                    <div>
                        Set 24 Hour Reminders
                    </div>
                    <div style="vertical-align:bottom;">
                        <br />
                        <k-switch :checked=flag24 @change="flag24Change" v-on:click ="updateReminder()" />
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div>
                        Set 48 Hour Reminders
                    </div>
                    <div style="vertical-align:bottom;">
                        <br />
                        <k-switch :checked="flag48" @change="flag48Change" />
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div>
                        Set 72 Hour Reminders
                    </div>
                    <div style="vertical-align:bottom;">
                        <br />
                        <k-switch :checked="flag72" @change="flag72Change" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row k-pb-md"></div>-->
</template>
<style scoped>
    * {
        font-weight: 400;
    }

    /deep/ .participant-cmd, /deep/ .participant-cmd:hover, /deep/ .participant-cmd::before {
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: unset;
        background: initial;
        border: none;
        border-radius: unset;
        transition: none;
        -webkit-transition: none;
        padding: 8px 5px;
        font: 900 .94em 'Lato', sans-serif;
        color: #4d97ac;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.completed /deep/ span, .status.remote /deep/ span {
            background: #19a71c;
        }

        .status.pending /deep/ span, .status.pendingsigning /deep/ span {
            background: #f8ab10;
        }

        .status.canceled /deep/ span {
            background: #ff2b2b;
        }

        .status.reviewed /deep/ span {
            background: #e960ea;
        }

        .status.pendingreview /deep/ span {
            background: #c14e4e;
        }

        .status.paper /deep/ span {
            background: #c6c6c6;
        }

        .status.signing /deep/ span, .status.signed /deep/ span {
            background: #296298;
        }
</style>
<script>
    import { useRoute } from 'vue-router';
    import ApiService from "@/core/services/api-service";
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { GridPdfExport } from '@progress/kendo-vue-pdf';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { process } from '@progress/kendo-data-query';
    import { Switch } from "@progress/kendo-inputs-vue-wrapper";
    import KendoPopupGenerator from '@/core/services/kendo-grid-popup-form-generator.js';
    import Swal from "sweetalert2";
    import EmailRecipients from "@/components/EmailRecipients";

    export default {
        name: 'Emails',
        components: {
            'kendo-grid': Grid,
            'kendo-grid-column': GridColumn,
            'kendo-datasource': KendoDataSource,
            'pdfexport': GridPdfExport,
            'toolbar': GridToolbar,
            'dropdownlist': DropDownList,
            'native-grid': NativeGrid,
            "k-switch": Switch,
            'EmailRecipients': EmailRecipients,
        },
        data() {
            ///****************************************************************************** NOTE ******************************************************************************///
            ///This is the ONLY WAY right now to include a function to specify if a column can be visible. All other ways fail due to a bug in kendo that attempts to add a _ctx ///
            ///to variables in the function thereby breaking the generated template. The workaround is to have an inline function so that kendo cannot access the function scope ///
            ///******************************************************************************************************************************************************************///

            return {
                schema: {
                    templateID: { editable: false },
                    emailTypeName: { type: 'string', editable: false },
                    emailSubject: { type: 'string', editable: true },
                    emailBody: { type: 'string', editable: true },
                    emailFrom: { type: 'string', editable: true },
                    emailReplyTo: { type: 'string', editable: true },
                    displayName: { type: 'string', editable: true },
                    isPushNotification: { type: 'boolean', editable: true },
                    pushNotificationURL: { type: 'string', editable: true },
                    disableSendingToRecipients: { type: 'boolean', editable: true },
                    disableSendingToGlobalRecipients: { type: 'boolean', editable: true },
                    isInactive: { type: 'boolean', editable: true },
                },
                columns: [
                    { field: "emailTypeName", title: 'Type' },
                    { field: "emailSubject", title: 'Subject' },
                    //{ field: "emailBody", title: 'Body' },

                    {
                        title: '&nbsp;', width: "280px", command: [
                            { className: 'participant-cmd', text: 'Edit', click: (e) => { this.gridEditClicked(e) }, visible: function (e) { return true; } },
                            { className: 'participant-cmd', text: 'Edit Recipients', click: (e) => { this.handleEditRecipients(e) }, visible: function (e) { return true; } },
                        ]
                    },
                ],
                exportColumns: [
                    { field: "emailTypeName", title: 'Type' },
                    { field: "emailSubject", title: 'Subject' },
                    { field: "emailBody", title: 'Body' },
                    { field: "emailFrom", title: 'From' },
                    { field: "emailReplyTo", title: 'Reply To' },
                    { field: "displayName", title: 'Display Name' },
                    { field: "isPushNotification", title: 'Push Notification' },
                    { field: "pushNotificationURL", title: 'Push Notification URL'},
                    { field: "disableSendingToRecipients", title: 'Disable Sending to Recipients' },
                    { field: "disableSendingToGlobalRecipients", title: 'Disable Sending to Global Recipients' },
                    { field: "isInactive", title: 'Inactive' },
                ],
                emailTemplates: [],
                emailPlaceHolders: [
                    {
                        key: "Current DateTime",
                        name: "[CurrentDateTime]"
                    },
                    {
                        key: "End Period",
                        name: "[EndPeriod]"
                    },
                    {
                        key: "Invite Link",
                        name: "[InviteLink]"
                    },
                    {
                        key: "Lender Name",
                        name: "[LenderName]"
                    },
                    {
                        key: "Package Name",
                        name: "[PackageName]"
                    },
                    {
                        key: "Query Result",
                        name: "[QueryResult]"
                    },
                    {
                        key: "Recipient",
                        name: "[Recipient]"
                    },
                    {
                        key: "Scheduled DateTime",
                        name: "[ScheduledDateTime]"
                    },
                    {
                        key: "Signer",
                        name: "[Signer]"
                    },
                    {
                        key: "Signing Room Code",
                        name: "[OTPCode]"
                    },
                    {
                        key: "Start Period",
                        name: "[StartPeriod]"
                    },
                    {
                        key: "Timezone",
                        name: "[ScheduledTimeZone]"
                    },
                    {
                        key: "Username",
                        name: "[Username]"
                    }],
                templateBody: "",
                showRecipientsModal: false,
                recipients: '',
                systemRoles: [],
                emailRecipients: [],
                offset: { left: 335, top: 108 },
                templateID: 0,
                displayName: '',
                emailSubject: '',
                emailBody: '',
                emailFrom: '',
                emailReplyTo: '',
                reminder72: '',
                isPushNotification: false,
                pushNotificationURL: '',
                disableSendingToGlobalRecipients: false,
                disableSendingToRecipients: false,
                isInactive: false,
                //pushNotificationValue: false
                
            }
        },
        async mounted() {
            var userId = this.$store.state.Admin.loginInformation.userId;
            let response = await ApiService.GenerateGetRequest("Role/GetRoles/" + userId);
            console.log("system roles", response);
            if (response["isSuccess"])
                this.systemRoles = response["content"];
        },
        async created() {
            // fetch action types
            await ApiService.GenerateGetRequest(
                "Admin/EmailTemplates"
            ).then((response) => {
                if (response && response.isSuccess) {
                    this.emailTemplates = response.content;
                } 

            });

            //await ApiService.GenerateGetRequest("Admin/Get24HrFlag").then(
            //    (response) => {
            //        this.flag24 = response.content;
            //    });
            //await ApiService.GenerateGetRequest("Admin/Get48HrFlag").then(
            //    (response) => {
            //        this.flag48 = response.content;
            //    });
            //await ApiService.GenerateGetRequest("Admin/Get72HrFlag").then(
            //    (response) => {
            //        this.flag72 = response.content;
            //    });
            console.log(this.emailTemplates);
        },
        computed: {
            packagePopUpTemplate(e) {
                console.log("popup", e);
                let html = "";
                html += "<div id='EmailsGrid'>";
                html += "   <div class='col-md-6'>";
                html += KendoPopupGenerator.generatePopUpFormFields("displayName", "Display Name", "displayName");
                html += "   </div>";
                html += "   <div class='col-md-6'>";
                html += KendoPopupGenerator.generatePopUpFormFields("emailSubject", "Subject", "emailSubject");
                html += "   </div>";
                html += "   <div class='col-md-6'>";
                html += KendoPopupGenerator.generateEmailPopUpFormFields("emailFrom", "From", "emailFrom");
                html += "   </div>";
                html += "   <div class='col-md-6'>";
                html += KendoPopupGenerator.generateEmailPopUpFormFields("emailReplyTo", "Reply To", "emailReplyTo");
                html += "   </div>";
                html += "   <div class='col-md-12'>";
                html += "       <div class='k-edit-label'><label >Email Template</label></div>";
                html += "       <div class='emails-editor'>";
                html += KendoPopupGenerator.generatePopUpFormTextarea("emailBody", "Body", "emailBody");
                html+="</div > ";
                html += "   </div>";
                html += "   </div>";
                html += "   <div class='col-md-4'>";                
                //html += " < li class='list-group-item' > ";
                html += KendoPopupGenerator.generatePopUpFormDefaultCheckboxFields("isPushNotification", "Push Notification", "isPushNotification");                   
                //html += "</li>";
                html += "   </div>";
                html += "   <div class='col-md-4'>";
                html += KendoPopupGenerator.generatePopUpFormDefaultCheckboxFields("disableSendingToRecipients", "Disable Sending to Recipients", "disableSendingToRecipients", false);
                html += "   </div>";
                html += "   <div class='col-md-4'>";
                html += KendoPopupGenerator.generatePopUpFormDefaultCheckboxFields("disableSendingToGlobalRecipients", "Disable Sending to Global Recipients", "disableSendingToGlobalRecipients", false);
                html += "   </div>";
                html += "   <div class='col-md-4'>";
                html += KendoPopupGenerator.generatePopUpFormDefaultCheckboxFields("isInactive", "Inactive", "isInactive", false);
                html += "   </div>";
                html += "   <div class='col-md-12'>";
                html += "   </div>";
                html += "   <div class='col-md-6'>";
                html += KendoPopupGenerator.generatePopUpFormFields("pushNotificationURL", "Push Notification URL", "pushNotificationURL", false);
                html += "   </div>";
                html += "   <div class='col-md-12' style='margin-top:10px'>";
                //html += KendoPopupGenerator.generatePopUpFormFields("fromEmail", "From Email", "fromEmail");
                //html += KendoPopupGenerator.generatePopUpFormFields("fromAddress", "From Address", "fromAddress");
                //html += KendoPopupGenerator.generatePopUpFormFields("pushUrl", "Push Notification URL", "pushUrl");
                html += "   </div>";             
                html += "</div>";
                return { 'template': html, 'mode': 'popup', width: "600px", height: "300px", }
            },
        },
        methods: {
            onPopUpModalSaved(e) {
                console.log("onPopUpModalSaved", e);
            },
            addButtonClicked() {
            },
            gridEditClicked(event) {
                console.log("edit clicked", event);
                console.log(this.$refs.kendoJqueryGrid.kendoWidget().dataSource.data());
                this.$refs.kendoJqueryGrid.kendoWidget().editRow($(event.target).closest("tr"));
            },
            gridDeleteClicked() {
                console.log("delete clicked");
                this.$refs.kendoJqueryGrid.kendoWidget().removeRow($(event.target).closest("tr"));
            },
            //pushNotificationSwitch(e) {
            //    $(".isPushNotification").kendoSwitch({
            //        change: function (e) {
            //            console.log("event :: change (" + (e.checked ? true : false) + ")");
            //        }
            //    });
            //},
            documentDataBound: function (e) {
                var self = this;
                var grid = e.sender;
                var items = e.sender.items();
                //items.each(function (index) {
                //    var dataItem = grid.dataItem(this);
                //    $("tr[data-uid='" + dataItem.uid + "']").find(".k-grid-Edit").each(function (index) {
                //        //permissions aren't available yet for this grid
                //        //var perm = dataItem.permission.access.find(perm => perm.permission == 'ViewDocument');
                //        //if (perm && perm.hasAccess) {
                //        $(this).addClass('k-state-disabled')
                //        ///}
                //    });
                /*});*/
            },
            parameterMap: function (options, operation) {
                console.log("options", options);
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            readData: function (e) {
                console.log("readData function");
                
                return ApiService.GenerateGetRequest(`Admin/EmailTemplates`).then(
                    response => {
                        e.success(response.content);
                    }
                );
               
            },
            createData: function (e) {
                console.log("createData function");
            },
            updateData: function (e) {
                console.log("update Data function", e.data);

                if (e.data.isPushNotification && $.trim(e.data.pushNotificationURL) == "") {
                    Swal.fire({
                        title: 'Error',
                        text: "Push notification URL is required.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                    $(".k-widget.k-window .k-loading-mask").remove();
                    return;
                }

                this.$refs.kendoJqueryGrid.kendoWidget().refresh();

                return ApiService.GeneratePostRequest("Admin/UpdateEmailTemplate", {
                    templateID: e.data.templateID,
                    displayName: e.data.displayName,
                    emailSubject: e.data.emailSubject,
                    emailBody: e.data.emailBody,
                    emailFrom: e.data.emailFrom,
                    emailReplyTo: e.data.emailReplyTo,
                    reminder72: e.data.reminder72,
                    isPushNotification: e.data.isPushNotification,
                    pushNotificationURL: e.data.pushNotificationURL,
                    disableSendingToRecipients: e.data.disableSendingToRecipients,
                    disableSendingToGlobalRecipients: e.data.disableSendingToGlobalRecipients,
                    isInactive: e.data.isInactive
                }).then(
                    response =>
                    {
                        this.$store.commit("App/updateLoader", { showLoader: false });
                        e.success(response);
                        console.log("updated e", response);
                        if (response["isSuccess"]) {
                            Swal.fire({
                                title: 'Saved!',
                                text: '',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                            //this.$refs.kendoJqueryGrid.kendoWidget().refresh();
                            this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: response.content.message,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                            this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
                        }
                    }
                );
            },
            deleteData: function (e) {
                
            },
            exportExcel() {
                var self = this;
                this.readData({
                    success: async function (arr) {

                        var options = {
                            skip: 0,
                        };

                        let processedItemsToExport = await self.handleExportData(process(arr, options).data);

                        saveExcel({
                            data: processedItemsToExport,
                            fileName: "email_template",
                            columns: self.exportColumns
                        });
                    }
                });
            },
            searchGrid: function () {
                console.log(this.search);
            },
            async handleExportData(itemsToExport) {
                let self = this;
                let processedItemsToExport = [];

                return processedItemsToExport;
            },
            exportPDF() {
                var self = this;
                this.readData({
                    success: async function (arr) {
                        var options = {
                            skip: 0,
                        };
                        const itemsToExport = process(arr, options).data;
                        let processedItemsToExport = await self.handleExportData(itemsToExport);

                        (self.$refs.gridPdfExport).save(processedItemsToExport);
                        return arr;
                    }
                });
            },
            onEdit(e) {
                if (e.model.isNew()) {
                    console.log("e modal is new ", e.model);
                    $(".k-window-title").text("Add");
                } 
                $(".k-edit-form-container").parent().width(1000);
                $(".k-edit-form-container").css('width', 'auto');
                
                // set up editor
                $("#EmailsGrid .emails-editor textarea").kendoEditor({
                    tools: [{
                        name: "customTemplate",
                        template: $(this.$refs.placeholderTemplate).html()
                    },
                        "bold", "italic", "underline", "strikethrough", "foreColor", "backColor", "justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "insertUnorderedList", "insertOrderedList", "indent",
                        "outdent", "createLink", "unlink", "insertImage", "subscript", "superscript", "deleteRow", "deleteColumn", "fontName", "fontSize", "viewHtml", "tableWizard", "createTable", "addRowAbove", "addRowBelow", "addColumnLeft",
                        "addColumnRight",],
                });

                $("#EmailsGrid .templateToolPlaceHolder").kendoDropDownList({
                    valuePrimitive: true,
                    dataTextField: "key",
                    dataValueField: "name",
                    dataSource: this.emailPlaceHolders,
                    optionLabel: "Click to Insert",
                    change: function (e) {
                        var editor = $("#EmailsGrid .emails-editor textarea").data("kendoEditor");
                        console.log(editor, "this editor");
                        editor.exec("inserthtml", { value: "<span>" + e.sender.value() + "</span> " });
                        e.sender.value('');// reset dropdown value
                    }
                });                
                $("#EmailsGrid .emails-editor iframe").css("width", "100%"); // style not applying in component. don't want to add it global since it's not needed everywhere

                // handles resizing the size of the edit form container
                $(".k-edit-form-container").parent().data("kendoWindow").center();

            },
            handleEditRecipients(e) {
                this.emailRecipients = [];
                var data = this.$refs.kendoJqueryGrid.kendoWidget().dataItem($(e.target).closest("tr"));
                console.log("data", data);
                this.templateID = data["templateID"];
                this.displayName = data["displayName"];
                this.emailSubject = data["emailSubject"];
                this.emailBody = data["emailBody"];
                this.emailFrom = data["emailFrom"];
                this.emailReplyTo = data["emailReplyTo"];
                this.reminder72 = data["reminder72"];
                this.isPushNotification = data["isPushNotification"];
                this.pushNotificationURL = data["pushNotificationURL"];
                this.disableSendingToRecipients = data["disableSendingToRecipients"];
                this.disableSendingToGlobalRecipients = data["disableSendingToGlobalRecipients"];
                this.isInactive = data["isInactive"];
                this.recipients = data["recipients"] == null ? "" : data["recipients"];
                if (this.recipients != "") {
                    var arr = this.recipients.split(",");
                    for (var i = 0; i < arr.length; i++) {
                        this.emailRecipients.push({ "roleId": arr[i].trim() });
            }
                }
                this.showRecipientsModal = true;
            },
            closeEmailRecipientsModal() {
                this.showRecipientsModal = !this.showRecipientsModal;
            },
            addRecipient() {
                this.emailRecipients.push({ "roleId": 0 });
            },
            deleteRecipient(index) {
                this.emailRecipients.splice(index, 1);
            },
            handleSaveEmailRecipients(e) {
                console.log("this.emailRecipients", this.emailRecipients);

                for (var i = 0; i < this.emailRecipients.length; i++) {
                    var roleId = this.emailRecipients[i].roleId;
                    if (i != 0) {
                        this.recipients = this.recipients + "," + roleId;
                    } else {
                        this.recipients = roleId;
                    }
                }
                console.log("this.recipients", this.recipients);
                console.log("this.templateID", this.templateID);
                console.log("this.displayName", this.displayName);
                console.log("this.emailSubject", this.emailSubject);
                console.log("this.emailBody", this.emailBody);
                console.log("this.emailFrom", this.emailFrom);
                console.log("this.emailReplyTo", this.emailReplyTo);
                console.log("this.reminder72", this.reminder72);
                console.log("this.isPushNotification", this.isPushNotification);
                console.log("this.pushNotificationURL", this.pushNotificationURL);
                console.log("this.disableSendingToRecipients", this.disableSendingToRecipients);
                console.log("this.disableSendingToGlobalRecipients", this.disableSendingToGlobalRecipients);
                console.log("this.isInactive", this.isInactive);

                this.showRecipientsModal = !this.showRecipientsModal;

                return ApiService.GeneratePostRequest("Admin/UpdateEmailTemplate", {
                    templateID: this.templateID,
                    displayName: this.displayName,
                    emailSubject: this.emailSubject,
                    emailBody: this.emailBody,
                    emailFrom: this.emailFrom,
                    emailReplyTo: this.emailReplyTo,
                    reminder72: this.reminder72,
                    isPushNotification: this.isPushNotification,
                    pushNotificationURL: this.pushNotificationURL,
                    recipients: this.recipients,
                    disableSendingToRecipients: this.disableSendingToRecipients,
                    disableSendingToGlobalRecipients: this.disableSendingToGlobalRecipients,
                    isInactive: this.isInactive
                }).then(
                    response => {
                        this.$store.commit("App/updateLoader", { showLoader: false });
                        console.log("updated e", response);
                        if (response["isSuccess"]) {
                            Swal.fire({
                                title: 'Saved!',
                                text: '',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                            this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
                        } else {
                            Swal.fire({
                                title: 'Error',
                                text: response.content.message,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                            this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
                        }
                    }
                );
            },
        },
    }
</script>