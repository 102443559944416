import { createRouter, createWebHistory } from 'vue-router';
import store from "../store/index";


// ADMIN LAYOUT VIEWS
import AdminLayout from "../views/shared/AdminLayout";
import AdminDashboard from '../views/admin/Dashboard.vue';
import AdminSettings from '../views/admin/Settings.vue';
import AdminLogin from '../views/admin/Login.vue';
import AdminLoanDetail from '../views/admin/LoanDetail.vue';
import AdminPasswordReset from '../views/admin/ResetPassword.vue';
import DocumentDetails from '../views/admin/DocumentDetails.vue';
import AdminDocumentTagging from '../views/admin/DocumentTaggingRework.vue';
import AdminReports from '../views/admin/Reports/index.vue';
import AdminReportTable from '../views/admin/Reports/table.vue';
import AdminOrders from '../views/admin/Orders.vue';
import NotaryRequests from '../views/admin/Requests.vue';
import AdminCalendar from '../views/admin/Calendar.vue';
import NotaryJournal from '../components/NotaryJournal.vue';
import AdminNSigningRoom from '../views/admin/NSigningRoom.vue';
import ThankYou from '../views/admin/ThankYou.vue';
import FileDropArea from '../components/FileDropArea.vue';
// MAIN LAYOUT VIEWS
import MainLayout from "../views/shared/MainLayout";
import Home from '../views/Home.vue';
import Landing from '../views/Landing.vue';
import AuthenticateIdentityToken from '../views/auth/AuthenticateIdentityToken.vue';
import AuthenticateSigner from '../views/auth/AuthenticateSigner.vue';
import DirectSigner from '../views/auth/DirectSigner.vue';
import TermsAndConditions from '../views/auth/TermsAndConditions.vue';
import Declined from '../views/auth/Declined.vue';
import DeclinedFinal from '../views/auth/DeclinedFinal.vue';
import Invitation from '../views/signer/Invitation.vue';
import SignerDetails from '../views/signer/SignerDetails.vue';
import Optout from '../views/signer/Optout.vue';
import OptoutFinal from '../views/signer/OptoutFinal.vue';
import Dashboard from '../views/Dashboard.vue';
import SignerRoomSA from '../views/signer/SignerRoomSA.vue';
import SignerRoomSALazyLoad from '../views/signer/SignerRoomSALazyLoad.vue';
import SignerRoomMobile from '../views/signer/SignerRoomMobile.vue';

import SignIn from '../views/auth/SignIn.vue';
import SignOut from '../views/auth/SignOut.vue';
import SignUp from '../views/auth/SignUp.vue';
import Pricing from '../views/Pricing.vue';
import Contact from '../views/Contact.vue';
import FAQ from '../views/FAQ.vue';
import VideoTest from '../views/VideoTest.vue';
import Waiting from '../views/Waiting.vue';
import Setup from '../components/forms/invitation-steps/test/Setup.vue';
import TestSetup from '../components/forms/invitation-steps/test/TestHardwareInstructions.vue';
import MeetingFailure from '../views/MeetingFailure.vue';
import SigningDocs from '../views/admin/Docs.vue';
import AddSigningRoom from '../views/admin/AddSigningRoom.vue';
import LoanDepot from '../views/NoteSign/LoanDepot.vue';
import SnapDocs from '../views/NoteSign/SnapDocs.vue';
import CustomIndex from '../views/NoteSign/Index.vue';

import SignerRoomCompletedDocuments from '../components/CompletedDocuments/DocumentGrid.vue';
import SignerRoomCompletedDocument from '../components/CompletedDocuments/DocumentView.vue';
import AdmineNoteDocumentView from '../components/eNoteView.vue';
import CustomSigningRoom from '../components/direct/customsigningroom/NoteSign/CustomSigningRoom.vue';
import SSNVue from '../components/SSN.vue';
import KBA from '../components/forms/invitation-steps/verify/VerifyQuestions.vue';
import PhotoUpload from '../components/forms/invitation-steps/verify/PhotoUpload.vue';
import Certificate from '../components/forms/invitation-steps/verify/VerifyCertificate.vue';
import WaitingParticipants from '../components/WaitingParticipants.vue';


const routes = [
    {
        path: "/",
        name: "Home",
        component: Landing,
    },
    {
        path: "/direct/customsigningroom/NoteSign",
        name: "CustomSigningRoom",
        component: CustomSigningRoom,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/NoteSign",
        name: "LoanDepot",
        component: LoanDepot,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/NoteSign",
        name: "CustomIndex",
        component: CustomIndex,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/NoteSign",
        name: "SnapDocs",
        component: SnapDocs,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/",
        component: MainLayout,
        children: [{
            path: "/pricing",
            name: "Pricing",
            component: Pricing,
        }, {
            path: "/contact",
            name: "Contact",
            component: Contact,
        }, {
            path: "/faq",
            name: "FAQ",
            component: FAQ,
        }, {
            path: "/sign-in",
            name: "SignIn",
            component: SignIn,
        }, {
            path: "/sign-up",
            name: "SignUp",
            component: SignUp,
        }, {
            path: "/sign-out",
            name: "SignOut",
            component: SignOut,
        }, {
            path: "/video",
            name: "VideoTest",
            component: VideoTest,
        },
        // "Logged in routes"
        {
            path: "/dashboard",
            name: "Dashboard",
            component: Dashboard,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/invitation",
            name: "Invitation",
            component: Invitation,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/FileDropArea",
            name: "FileDropArea",
            component: FileDropArea,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/room",
            name: "SignerRoom",
            component: SignerRoomSA,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/room/new",
            name: "SignerRoomSALazyLoad",
            component: SignerRoomSALazyLoad,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/room-mobile",
            name: "SignerRoomMobile",
            component: SignerRoomMobile,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/waiting",
            name: "WaitingParticipants",
            component: WaitingParticipants,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/signer/details",
            name: "SignerDetails",
            component: SignerDetails,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/ssn",
            name: "SSN",
            component: SSNVue,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/setup",
            name: "Setup",
            component: Setup
            , meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/testsetup",
            name: "TestHardwareInstructions",
            component: TestSetup
            , meta: {
                requiresAuth: true
            }
        },
        {
            path: "/admin/signingDocs",
            name: "SigningRoomDocs",
            component: SigningDocs
            , meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/kba",
            name: "VerifyQuestions",
            component: KBA,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/terms",
            name: "TermsAndConditions",
            component: TermsAndConditions,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/signer/photoupload",
            name: "PhotoUpload",
            component: PhotoUpload,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/declined",
            name: "Declined",
            component: Declined,
        },
        {
            path: "/declinedfinal",
            name: "DeclinedFinal",
            component: DeclinedFinal,
        },
        {
            path: "/optout",
            name: "Optout",
            component: Optout,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/optoutfinal",
            name: "OptoutFinal",
            component: OptoutFinal,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/authenticate/:t",
            name: "AuthenticateSigner",
            component: AuthenticateSigner,
        },
        {
            path: "/direct/:t",
            name: "DirectSigner",
            component: DirectSigner,
        },
        {
            path: "/signer/room/completed",
            name: "SignerRoomCompletedDocuments",
            component: SignerRoomCompletedDocuments,
        },
        {
            path: "/signer/room/completed/:id",
            name: "SignerRoomCompletedDocument",
            component: SignerRoomCompletedDocument,
        },
        ]
    },
    {
        path: "/admin/*",
        component: AdminLayout,
        children: [{
            path: "/admin",
            name: "AdminDashboard",
            component: AdminDashboard,
            meta: {
                adminAuth: true
            },

        },
        {
            path: "/admin/document/tag/:documentId/:signerId",
            name: "AdminDocumentTagging",
            component: AdminDocumentTagging,
            meta: {
                adminAuth: true
            },
        },
        {
            path: "/admin/orders",
            name: "AdminOrders",
            component: AdminOrders,
            meta: {
                adminAuth: true
            },
        },
        {
            path: "/admin/requests",
            name: "NotaryRequests",
            component: NotaryRequests,
            meta: {
                adminAuth: true
            },
        },
        {
            path: "/admin/calendar",
            name: "AdminCalendar",
            component: AdminCalendar,
            meta: {
                adminAuth: true
            },
        },
        {
            path: "/admin/signing",
            name: "AdminNSigningRoom",
            component: AdminNSigningRoom,
            props: true,
            meta: {
                adminAuth: true
            },
        },
        {
            path: "/admin/waiting",
            name: "Waiting",
            component: Waiting,
            meta: {
                adminAuth: true
            },
        },
        {
            path: "/admin/meetingfailure",
            name: "MeetingFailure",
            component: MeetingFailure,
            props: true,
            meta: {
                adminAuth: true
            },
        },
        {
            path: "/admin/add-signing-room",
            name: "AddSigningRoom",
            component: AddSigningRoom,
            props: true,
            meta: {
                adminAuth: true
            }
        },
        {
            path: "/admin/settings",
            name: "AdminSettings",
            component: AdminSettings,
            meta: {
                adminAuth: true
            },
        },
        {
            path: "/admin/certificate",
            name: "Certificate",
            component: Certificate,
            meta: {
                adminAuth: true
            },
        }, {
            path: "/admin/reports-listing",
            name: "AdminReportsListing",
            component: AdminReports,
            meta: {
                adminAuth: true
            },
        }, {
            path: "/admin/reports/:reportId?",
            name: "AdminReportTable",
            component: AdminReportTable,
            meta: {
                adminAuth: true
            },
        }, {
            path: "/admin/detail/:id",
            name: "AdminLoanDetail",
            component: AdminLoanDetail,
            meta: {
                adminAuth: true
            },
        }, {
            path: "/admin/document/:id",
            name: "AdminDocumentDetails",
            component: DocumentDetails,
            meta: {
                adminAuth: true
            },
        }, {
            path: "/admin/notary/:id",
            name: "NotaryJournal",
            component: NotaryJournal,
            meta: {
                adminAuth: true
            }
        },
        ]
    }, {
        path: "/admin/login",
        name: "AdminLogin",
        component: AdminLogin,
        meta: {
            requiresAuth: false
        }
    }, {
        path: "/admin/forgotpassword/:t",
        name: "AdminPassword",
        component: AdminPasswordReset,
        meta: {
            requiresAuth: false
        }
    }, {
        path: "/admin/ThankYou",
        name: "ThankYou",
        component: ThankYou,
        meta: {
            requiresAuth: false
        }
    }, {
        path: "/admin/enote/:id",
        name: "AdmineNoteDocumentView",
        component: AdmineNoteDocumentView,
        meta: {
            adminAuth: true
        }
    }, {
        path: '/:catchAll(.*)*',
        redirect: "/",
        meta: { is404: true }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// GLOBAL Client Side Middleware
export const equalsIgnoreCase = (str1, str2) => {
    return (!str1 && !str2) || (str1 && str2 && str1.toUpperCase() == str2.toUpperCase())
}
router.beforeEach((to, from) => {
    console.log("TO Route", to);
    console.log("FROM Route", from);

    store.commit("App/updateLoaderOverride", {
        showLoaderOverride: false,
    });
    console.log(to);
    var patt = /(\/Admin$)|(\/Admin\/.*)/i;
    var adminInfo = store.getters["Admin/loginInformation"];

    //console.log("index.js login: ", adminInfo);

    if (to.meta && to.meta.requiresAuth && !store.getters["Auth/token"]) {
        console.log("to.meta", to.meta);
        console.log("auth/token", store.getters["Auth/token"]);
        return {
            path: "/Admin/Login", // TODO: dynamically inject route param
        }
        //return {
        //    //path: "/authenticate/" + store.getters["Auth/invite"], // TODO: dynamically inject route param
        //}
    } else if (patt.test(to.path) == true && to.meta && to.meta.adminAuth && !adminInfo) {
        return {
            path: "/Admin/Login", // TODO: dynamically inject route param
        }
    } else if (equalsIgnoreCase(to.path, "/admin/login") == true && adminInfo) {
        return {
            path: "/Admin", // TODO: dynamically inject route param
        }
    } else if (to.path.toLowerCase().includes("/admin/forgotpassword")) {
        //do nothing
    } else if (to.path.toLowerCase() !== "/admin/settings" && to.path.toLowerCase() !== "/admin/login" && adminInfo != null && !adminInfo.hasAccessToMenu) {
        return { path: "/admin/settings" }
    }
});

export default router
