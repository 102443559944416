<template>
    <section >
        <div class="container personal-signup-main" style="margin-bottom: 50px;">
            <div class="sub-container">
                <div class="sub-container2">
                    <p v-html="econsent"></p>
                    <div class="agreement-block">
                        <a href="#" class="btn btn-success" @click="acceptBtn">Accept eSign Consent</a>
                        <!--<a href="#" class="btn btn-danger" @click="declineBtn">Decline</a>-->
                        <router-link to="/declined" class="btn btn-danger" style="color: white; text-decoration: none">Decline eSign Consent</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<style scoped>

   #e-sign-page {
       margin: 0px;
       padding: 0px;
   }

    .agreement-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 15px;
    }

    .btn-success,.btn-danger {
        font-size: 20px;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 5px;
    }

    .details {
        margin-bottom: 10px;
        justify-content: space-evenly;
    }

    .container-sub2 h2 {
        font-size: 50px !important;
    }

    .details p {
        margin-bottom: 0;
        text-align: left;
        display: flex;
        justify-content: normal;
        font-size: 20px;
    }

    .e-consent-body {
        font-size: 20px;
    }

    .required-block {
        font-size: 20px;
    }

</style>

<script>
    import moment from "moment";
    import ApiService from "@/core/services/api-service"; 
    export default {
        name: 'AgreeToTermsAndConditions',
        props: ['invitedBy', 'invitedByID', 'date', 'details'],
        data() {
            this.readData();
            return {
                date: moment(this.date).format("LLL"),
                econsent: [],
                lenderid: null,
            }
        },
        methods: {
            async readData() {
               let signerData = this.$store.state.App.lenderId;
               return ApiService.GenerateGetRequest(`econsent/consent?lenderid=${signerData}`).
                    then((response) => {
                        this.econsent = response["content"];
                        console.log(this.econsent);
                    });
            },
            acceptBtn() {
                this.$emit('user-selected-option', { ToS: true, econsent: this.econsent });
                this.lenderid = this.$store.state.App.lenderId;
                this.signerData = this.$store.getters["Auth/signerSessionInformation"];

                var payload = {
                    "SignerId": this.signerData["signerId"],
                    "SignerName": this.signerData["firstName"] + " " + this.signerData["lastName"],
                    "SignerEmail": this.signerData["emailAddress"],
                    "PackageName": this.signerData["description"],
                    "NotarizeId": this.signerData["notarizeId"],
                    "RequesterId": this.signerData["requesterId"],
                    "Lender": this.signerData["lender"],
                    "ClientId": this.signerData["clientID"],
                    "LenderId": this.$store.state.App.lenderId

                };
                console.log("signer data", this.signerData, this.$store.state.App.lenderId);
                 
                ApiService.GeneratePostRequest(`econsent/accepted/sendLenderNotification`, payload);
                

            }, 
            declineBtn() {
                this.$emit('user-selected-option', { ToS: false, econsent: this.econsent });
            }
        },
    }
</script>