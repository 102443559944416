<template>

    <div class="audit_list">
        <div>
            <div style="text-align:left;" v-if="pckgId">
                <k-button class="k-button k-primary" @click="$emit('backToSigning')">
                    Back To Signing
                </k-button>
            </div>
            <div style="text-align:right; margin-top: 5px">
                <k-button class="k-button k-primary" @click="handlePackageDetails" style="margin-right: 10px">
                    Package Details
                </k-button>
                <k-button class="k-button k-primary" @click="printPDF">
                    Print PDF
                </k-button>
            </div>
            <div style="overflow:hidden; height:0;">
                <NotaryJournalPDF ref="pdfLog" :pckgId="package" />
            </div>
        </div>

        <card class="card">
            <cardHeader>
                <h3>Loan # {{ loanDetail.loanNumber }}</h3>
            </cardHeader>
            <cardBody>
                <h4>Details</h4>
                <div class="row p-m">
                    <div class="row pb-m">
                        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 pb-m">
                            <div>Borrower</div>
                            <div>
                                <b>{{ loanDetail.borrowerName }} </b>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-9 col-sm-6 col-xs-12 pb-m">
                            <div>Property Address</div>
                            <div>
                                <b>{{ loanDetail.propertyAddress }}</b>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-3 col-sm-6 col-xs-12 pb-m">
                            <div>Package Type</div>
                            <div>
                                <b>{{ loanDetail.packageType }}</b>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-3 col-sm-6 col-xs-12 pb-m">
                            <div>Signing Type</div>
                            <div>
                                <b>{{ loanDetail.signingDescription }}</b>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 pb-m">
                            <div>Status</div>
                            <div class="status" :class="[statusTemplate]">
                                <span>{{ normalizeStatus(loanDetail.statusDescription)}}</span>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 pb-m">
                            <div>Notary Assigned</div>
                            <div>
                                <b>{{ notaryAssigned ? notaryAssigned : "No" }}</b>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-m">

                        <div class="col-lg-3 col-xl-2 col-md-4 col-xs-12 pb-m">
                            <div>Available for Review Date</div>
                            <div>
                                <b>
                                    {{moment(loanDetail.startDate).format("MM/DD/YYYY h:mm A")}}
                                </b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-xl-2 col-md-4 col-xs-12 pb-m">
                            <div>Pre-Sign Date</div>
                            <div>
                                <b>
                                    {{moment(loanDetail.preSignDate).format("MM/DD/YYYY h:mm A")}}
                                </b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-xl-2 col-md-4 col-xs-12 pb-m">
                            <div>Signing Date</div>
                            <div>
                                <b>
                                    {{moment(loanDetail.closingDate).format("MM/DD/YYYY h:mm A")}}
                                </b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-xl-2 col-md-4 col-xs-12 pb-m">
                            <div>Expiration Date</div>
                            <div>
                                <b>
                                    {{moment(loanDetail.expirationDate).format("MM/DD/YYYY h:mm A")}}
                                </b>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xl-3 col-md-4 col-xs-12 pb-m">
                            <div>Timezone</div>
                            <div>
                                <b>
                                    {{loanDetail.clientTimeZone}}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
            </cardBody>
        </card>

        <card class="card">
            <cardHeader>
                <h3>Notarization Details</h3>
            </cardHeader>
            <cardBody>
                <div class="row p-m">
                    <div class="row pb-m">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 pb-m">
                            <div>Notarial Acts</div>
                            <div v-for="n in notarialActs">
                                <b>{{ n.notarialActDescription }}</b><br />
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 pb-m">
                            <div>Platform</div>
                            <div>
                                <b>Web</b>
                            </div><br />
                            <div>Customer ID</div>
                            <div>
                                <b>{{ loanDetail.loanNumber }}</b>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 pb-m">
                            <div>Signing Status</div>
                            <div class="status" :class="[statusTemplate]">
                                <span>{{normalizeStatus(loanDetail.statusDescription)}}</span>
                            </div><br />
                            <div>Signing Date</div>
                            <div>
                                <b>{{ moment(loanDetail.signingDate).format("MM/DD/YYYY h:mm A") }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </cardBody>
        </card>


        <card class="card">
            <cardHeader>
                <h3>Authentication and Identification</h3>
            </cardHeader>
            <cardbody ref="identification">
                <div class="row p-m" style="padding-left:2em; float:left; width:100%;">
                    <div class="row justify-content-between" v-for="i in authDetails">
                        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12 pb-m">
                            <div>Signer Name</div>
                            <div>
                                <b>{{i.signerName}}</b>
                            </div><br />
                            <div>
                                <div>KBA Status</div>
                                <div>
                                    <b>{{i.kbaStatus}}</b>
                                </div>
                            </div><br />
                            <div>
                                <div>Signature</div>
                                <div>
                                    <img style="width:80%; margin-top:1em;" v-if="i.signatureImg != ''" v-bind:src="'data:image/png;base64,' + i.signatureImg" />
                                    <span v-if="i.signatureImg == ''"><b>Not Uploaded</b></span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12 pb-m">
                            <div>Credential Analysis</div>
                            <div>
                                <b><span class = "status" v-bind:class="i.caStatus">{{normalizeStatus(i.caStatus)}}</span></b>
                            </div><br />
                            <div>Notary Verified</div>
                            <div>
                                <b>{{i.caNotaryVerified?"Yes":"No"}}</b>
                            </div><br />
                            <div>Third Party Verified</div>
                            <div>
                                <b>{{i.caThirdPartyVerified?"Yes":"No"}}</b>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 pb-m">
                            <div v-if="i.documentTypeID == 1">Driver's License Front</div>
                            <div v-if="i.documentTypeID == 2">Passport</div>
                            <div v-if="i.documentTypeID != 0">
                                <img style="width:100%;" v-if="i.frontImagePath != null" v-bind:src="'data:image/png;base64,' + i.frontImagePath" />
                                <span v-if="i.frontImagePath == null"><b>Not Uploaded</b></span>
                            </div>
                            <div v-if="i.documentTypeID == 1">
                                <div>Driver's License Back</div>
                                <div>
                                    <img style="width:100%;" v-if="i.backImagePath != null" v-bind:src="'data:image/png;base64,' + i.backImagePath" />
                                    <span v-if="i.backImagePath == null"><b>Not Uploaded</b></span>
                                </div>
                            </div>
                        </div>
                        
                        <div v-if="pckgId" class="col-lg-3 col-md-3 col-sm-6 col-xs-12 pb-m">
                            <div :id="'journalSubscriber-' + (i.signerName).replace(/\s/g,'')" style="width:100%; height:250px; margin-top:1em; z-index:2;">

                            </div>
                        </div>
                        <div v-if="i.caStatus != 'Passed' && pckgId" class="col-lg-2 col-md-2 col-sm-6 col-xs-12 pb-m text-right">
                            <div style="float:right;">
                                <k-button class="k-button k-primary" style="margin:1em 0 1em 1em;" @click="showVerify({signerId: i.signerId, docTypeId: i.documentTypeID})">
                                    VERIFY ID
                                </k-button>
                                <k-button class="k-button k-primary" @click="signerReuploadID(i.signerId)">
                                    REUPLOAD ID
                                </k-button>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </cardbody>
        </card>


        <card class="card">
            <cardHeader>
                <h3>Notes</h3>
            </cardHeader>
            <cardBody id="notesBody">
                <notes v-bind:packageid="package"
                       :key="package"
                       ref="notesGrid" />
            </cardBody>
        </card>

        <card class="card">
            <cardHeader>
                <h3>Signer Details</h3>
            </cardHeader>
            <cardBody>
                <div>
                    <package-participants v-bind:packageide="package"
                                          :key="package"
                                          :pnotarizeId="notarizeId"
                                          :type="'notary'"
                                          :setfilter="'signer'" />
                </div>
            </cardBody>
        </card>

        <card class="card">
            <cardHeader>
                <h3>Other Participant Details</h3>
            </cardHeader>
            <cardBody>
                <package-participants v-bind:packageide="package"
                                      :key="package"
                                      :pnotarizeId="notarizeId"
                                      :type="'notary'"
                                      :setfilter="'other'" />
            </cardBody>
        </card>

        <card class="card">
            <cardHeader>
                <h3>Executed Documents</h3>
            </cardHeader>
            <cardBody>
                <package-documents 
                   v-bind:packageide="package"
                                   :key="package"
                                   :pnotarizeId="package"
                                   ref="packageDocGrid"
                   fetchUrl='Package/documents'
                   :fetchFilter="filterNotUploaded"
                   :hiddenCols="docHiddenCols"
                   addText="Add"
                   :hiddenActions="hiddenActions"
                   :ntype="'notary'" 
                />
            </cardBody>
        </card>

        <card class="card">
            <cardHeader>
                <h3>Recordings</h3>
            </cardHeader>
            <cardBody>
                <recordings :notarizeID="notarizeId" v-if="notarizeId!=0" />
                <div v-for="r in recordingsList" :key="r.id">
                    {{r.videoUrl}}
                    <k-button class="k-button k-primary" @click="downloadRecording(r.filePath)">
                        Download
                    </k-button>
                </div>
            </cardBody>
        </card>

        <card class="card">
            <cardHeader>
                <h3>Package Audit Logs</h3>
            </cardHeader>
            <cardBody>
                <audit-log :auditLog="auditLog" :pckgId="package" :ref="'jauditlog_'+package" />
            </cardBody>
        </card>
    </div>

    <popup :show="show" :anchor="'identification'" :popup-class="'popup-content'" :anchor-align="{horizontal:'center', vertical:'middle'}"
           :popup-align="{horizontal:'center', vertical:'middle'}" style="width:40vw;">
        <div id='IDVerification' style="padding:1em;">
            <div class="row pb-m">
                <div class='col-lg-6'>
                    <h4>Verify Signer ID</h4>
                </div>
                <div class='col-lg-6' style="text-align:right;">
                    <k-button @click="verifyID(verifyIdDetails)" class="k-button k-primary" style="margin-right:1em;">
                        Save
                    </k-button>
                    <k-button @click="close" class="k-button k-primary" style="margin-right:1em;">
                        Cancel
                    </k-button>
                </div>
            </div>
            Comments
            <br />
            <div class='col-md-12'>
                <textarea ref="editor" rows="4" cols="50" style="width:100%;"></textarea>
            </div>
        </div>
    </popup>
</template>

<script>
    import moment from "moment";
    import NotaryJournalPDF from "@/components/AuditLogs/NotaryJournalPDF";
    import { Button } from "@progress/kendo-vue-buttons";
    import { useRoute } from "vue-router";
    import ApiService from "@/core/services/api-service";
    import GridDataHelper from "@/core/services/grid-data-helper";
    import PackageParticipantsTemplate from "@/components/grids/PackageParticipants";
    import PackageDocumentsTemplate from "@/components/grids/PackageDocuments";
    import NotesTemplate from "@/components/grids/Notes";
    import Recordings from "@/components/grids/Recordings";
    import { downloadRecordingUrl } from "@/core/services/api-service";
    import { Popup } from '@progress/kendo-vue-popup';
    import {
        Card,
        CardHeader,
        CardBody,
        CardTitle
    } from "@progress/kendo-vue-layout";
    import Swal from 'sweetalert2';
    import subscriber from "@/components/Video/Subscriber.vue";
    import { videoConnection, subscribeToVideo, publishVideo } from "../OTConnection";
    import AuditLogVue from "@/components/AuditLog.vue";

    export default {
        name: "NotaryJournal",
        props: ["pckgId", "NotarizeID", "session", "stream"],
        emits: ["backToSigning"],
        components: {
            NotaryJournalPDF: NotaryJournalPDF,
            "k-button": Button,
            card: Card,
            cardHeader: CardHeader,
            cardBody: CardBody,
            cardTitle: CardTitle,
            PackageParticipants: PackageParticipantsTemplate,
            PackageDocuments: PackageDocumentsTemplate,
            'Notes': NotesTemplate,
            Recordings: Recordings,
            subscriber: subscriber,
            'audit-log': AuditLogVue,
            'popup': Popup
        },
        data() {
            return {
                package: 0,
                notarizeId: 0,
                loanDetail: {},
                signerDetails: [],
                auditLog: [],
                audit: 0,
                authDetails: [],
                comment: "",
                show: false,
                verifyIdDetails: {},
                notaryAssigned: null,
                notarialActs: [],
                filterNotUploaded: {
                    logic: "and",
                    filters: [
                        {
                            "field": "StatusDescription",
                            "operator": "neq",
                            "value": "Uploaded"
                        },
                    ]
                },
                docHiddenCols: ['uploaderName'],
                hiddenActions: ['downloadAllUploaded', 'acknowledgeUpload']
            }
        },
        computed: { moment: () => moment },
        created() {
            var self = this;
            const route = useRoute();
            self.package = parseInt(route.params.id);
            if (route.params.id == undefined) {
                self.package = this.pckgId;
            }
        },
        async mounted() {
            var self = this;
            var unauthorized = false;

            await ApiService.GeneratePostRequest(
                "Package/GetLoanPackageById/" + this.package
            ).then((response) => {
                if (!response.isSuccess) {
                    if (response.message == "Unauthorized") {
                        unauthorized = true;
                        this.$router.back();
                        unauthorized = true;
                        console.log("Back!");
                        return;
                    }
                    this.$router.push("/admin")
                }

                self.loanDetail = response["content"];
                console.log("loanDetails", response, self.loanDetail.borrowerName);
            });

            if (unauthorized) return;

            this.notarizeId = self.loanDetail.notarizeID;
            this.readDocumentLog(this.package);

            await ApiService.GenerateGetRequest(
                `verify/signerverification?notarizeid=${this.notarizeId}`
            ).then((response) => {
                console.log("signer verification", response);
                this.authDetails = response.content;

            });

            await ApiService.GenerateGetRequest(
                `notarize/getPackageNotarialActs?notarizeid=${this.notarizeId}`
            ).then((response) => {
                console.log("package acts", response);
                this.notarialActs = response.content;

            });

            await this.getIdDetails();

            this.getPackageNotary();


        },
        methods: {
            printPDF: function () {

                console.log(this.pckgId);
                this.$refs.pdfLog.exportPDFWithComponent();
            },
            reloadPDF: function () {
                this.$refs.pdfLog.populateLog();
            },
            normalizeStatus(string) {
                return GridDataHelper.normalizeStatus(string);
            },
            showVerify(data) {
                this.show = true;
                this.verifyIdDetails = { signerID: data.signerId, docTypeId: data.docTypeId };
            },
            close() {
                this.show = false;
                this.comment = "";
                this.verifyIdDetails = {};
            },
            async getIdDetails() {
                return await ApiService.GenerateGetRequest(
                    `verify/signerverification?notarizeid=${this.notarizeId}`
                ).then((response) => {
                    console.log("signer verification", response);
                    this.authDetails = response.content;

                });
            },
            async verifyID() {
                this.comment = this.$refs.editor.value;
                var data = this.verifyIdDetails;
                console.log("verify id data ", data);
                var loginInformaiton = this.$store.getters["Admin/loginInformation"];
                var notaryName = loginInformaiton.firstName + " " + loginInformaiton.lastName;
                await ApiService.GeneratePostRequest(
                    `verify/verifySignerCA`, { SignerId: data.signerID, CANarration: this.comment, IdentityDocumentTypeId: data.docTypeId, NotarizeId: this.notarizeId, NotaryAppUserId: loginInformaiton.userId, NotaryName: notaryName }
                ).then((response) => {
                    if (response["isSuccess"]) {
                        Swal.fire("Success", "Signer ID verified sucessfully", "success");
                        this.getIdDetails();
                    } else {
                        Swal.fire(
                            "Operation failed",
                            "Signer ID could not be verified",
                            "error"
                        );
                    }
                });
                this.show = false;
            },
            signerReuploadID(data) {
                console.log("signer reupload ", data);
                ApiService.GeneratePostRequest(`transaction/signer/sendSignerUploadID/?receiverSignerID=` + data);
            },
            async readDocumentLog(packageId) {
                var self = this;
                await ApiService.GenerateGetRequest(
                    "Package/GetPackageAuditLog/" + packageId
                ).then((response) => {
                    self.auditLog = response;
                });
                console.log(this.$refs);
                var alRef = this.$refs["jauditlog_" + packageId];
                if (Array.isArray(alRef)) {
                    console.log("REF is array");
                    alRef[0].reloadPDF();
                }
                else {
                    console.log("REF is not array");
                    alRef.reloadPDF();
                }
                console.log("reloaded pdf");
            },
            async getPackageNotary() {
                let obj = {
                    LoanID: parseInt(this.$route.params.id),
                    PackageId: parseInt(this.loanDetail.loanPackageID)
                };
                let response = await ApiService.GeneratePostRequest(`Package/notary`, obj);
                obj = null;
                if (response.content.data.length > 0) {
                    obj = response.content.data[0];
                }

                this.notaryAssigned = obj.notary;
                console.log("notary ", this.notaryAssigned);
            },
            startCall() {
                console.log(this.notarizeId);
                var journals = [];
                
                for (var i = 0; i < this.authDetails.length; i++) {
                    journals.push({ name: 'journalSubscriber-' + (this.authDetails[i].signerName).replace(/\s/g, ""), height: '99%', width: '99%' });
                    //videoConnection(this.notarizeId, { name: 'journalSubscriber-'+ (this.authDetails[i].signerName).replace(" ",""), height: '280px', width: '300px' });                   
                }
                videoConnection(this.notarizeId, journals, null, null, 'journal');
            },
            async handlePackageDetails() {
                this.$router.push("/admin/detail/" + this.loanDetail.loanPackageID);
            }
        },
    };
</script>

<style scoped>
    .status.Pending{
        color:orange;
    }
    .status.Valid, .status.Third_Party_Validated, .status.Notary_Validated{
        color:green;
    }
    .status.Not_Valid, .status.Third_Party_Failed {
        color:red;
    }

    .audit_list {
        margin: 0;
    }

        .audit_list ul {
            margin: 0;
            padding: 0;
            height: 400px;
            width: 100%;
            float: left;
            overflow-y: scroll;
        }

            .audit_list ul li {
                margin: 5px 0 15px 0;
                list-style: none;
                padding: 2px 0 8px 0;
            }

                .audit_list ul li:hover {
                    background: rgba(78, 78, 78, 0.1);
                }

                .audit_list ul li .icon {
                    float: left;
                    margin: 6px 10px 0 0;
                }

                    .audit_list ul li .icon .material-icons {
                        font-size: 35px;
                    }

                .audit_list ul li .title {
                    font: 600 1.14em "Lato", sans-serif;
                }

                .audit_list ul li .time {
                }

    .card {
        margin-bottom: 3em;
    }

    #notesBody {
        height: 565px;
    }
</style>
