<div>
    <h1>Custom Signing Room</h1>
    <p v-if="document">Document: {{ document }}</p>
    <p v-else>Loading document...</p>
</div>

<script>
    import ApiService2 from "@/core/services/api-customsigningroom";
    import Swal from "sweetalert2";

    export default {
        name: "CustomSigningRoom",
        data() {

            //console.log("CSR.vue invitationCode: " + this.$route.query.invitationCode);
            //console.log("CSR.vue postBackUrl: " + this.$route.query.postBackUrl);
            //console.log("CSR.vue appKey: " + this.$route.query.appKey);
            console.log(this.$route.query);

            return {
                document: null,
                invitationCode: this.$route.query.invitationCode || "",
                postBackUrl: this.$route.query.postBackUrl || this.$route.query.postbackURL || this.$route.query.postbackUrl || "NA",
                appKey: this.$route.query.appKey || ""
            };
        },
        created() {
            this.fetchDocument();
        },
        methods: {
            async fetchDocument() {
                //console.log("Starting fetchDocument method");
                try {
                    //console.log("Sending POST request to NoteSign endpoint");
                    const response = await ApiService2.GeneratePostRequest(
                        "NoteSign",
                        {
                            invitationCode: this.invitationCode,
                            postBackUrl: this.postBackUrl,
                            appKey: this.appKey
                        },
                        {
                            'Content-Type': 'application/json'
                        }
                    );

                    console.log("Received response:", response);
                    if (response && response.view === "LoanDepot") {
                        this.document = "LoanDepot successful response";
                        console.log("LoanDepot successful response");
                        this.$router.push({
                            name: 'LoanDepot',
                            query: {
                                invitationLink: response.result.invitationLink,
                                participantId: response.result.participantId,
                                invitationCode: this.invitationCode,
                                postBackURL: this.postBackURL,
                                appKey: this.appKey
                            }
                        });
                    } else if (response && response.view === "SnapDocs") {
                        this.document = "SnapDocs successful response";
                        console.log("SnapDocs successful response");
                        this.$router.push({
                            name: 'SnapDocs',
                            query: {
                                invitationLink: response.result.invitationLink,
                                participantId: response.result.participantId,
                                invitationCode: this.invitationCode,
                                postBackUrl: this.postBackUrl,
                                appKey: this.appKey
                            }
                        });
                    } else if (response && response.view === "MORTL") {
                        this.document = "MORTL successful response";
                        console.log("MORTL successful response");
                        this.$router.push({
                            name: 'CustomIndex',
                            query: {
                                invitationLink: response.result.invitationLink,
                                participantId: response.result.participantId,
                                invitationCode: this.invitationCode,
                                postBackUrl: this.postBackUrl,
                                appKey: this.appKey
                            }
                        });
                    } else if (response && response.view === "DEMO") {
                        this.document = "DEMO successful response";
                        console.log("DEMO successful response");
                        this.$router.push({
                            name: 'CustomIndex',
                            query: {
                                invitationLink: response.result.invitationLink,
                                participantId: response.result.participantId,
                                invitationCode: this.invitationCode,
                                postBackURL: this.postBackURL,
                                appKey: this.appKey
                            }
                        });
                    } else {
                        console.error("Error in response:", response.message);
                        Swal.fire({
                            title: "Error in response: " + response.message,
                            text: response.message,
                            icon: "error",
                            confirmButtonText: "Ok"
                        });
                    }
                } catch (error) {
                    console.error("Error fetching document:", error);
                    Swal.fire({
                        title: "Error - Failed to fetch document",
                        text: "Failed to fetch document",
                        icon: "error",
                        confirmButtonText: "Ok"
                    });
                }
            }
        }
    };
</script>
