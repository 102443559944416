<template>
    <router-link-bread-crumbs :navItems="navItems" />
    <div>
        <card class="card">
            <cardHeader>
                <h4 v-if="!editDocNameBool">
                    <b>{{ documentDetails.description }}</b>                    
                    <k-button :icon="'pencil'" :look="'flat'" primary="true" @click.prevent="editDocName" v-if="canEdit" ></k-button>                  
                </h4>
                <h4 v-else>
                    <input type="text" name="name" v-model="docName" />                    
                    <k-button :icon="'checkmark'" :look="'flat'" primary="true" @click.prevent="saveDocName"></k-button>
                    <k-button :icon="'cancel'" :look="'flat'" primary="true" @click.prevent="editDocName"></k-button>                                  
                </h4>
            </cardHeader>
            <cardBody>
                <h4>Document Summary</h4>
                <package-document-summary :summary="documentDetails" @save="saveEdit" />
                <hr class="solid" />
            </cardBody>
            <cardBody>
                <div class="row ">
                    <div class="col-lg-7 document-details-actions">

                        <div v-if="canManageTags">
                            <k-button :look="inEditSmartTags ? '' : 'outline'"
                                      primary="true"
                                      @click="toggleManageSmartTags">
                                {{inEditSmartTags ?  'Close':'Manage Smart Tags' }}
                            </k-button>
                        </div>                        
                        <k-button :look="'outline'" primary="true" @click="toggleDocViewer">
                            View Document
                        </k-button>


                        <k-button :look="'outline'" primary="true" @click="download">
                            Download
                        </k-button>

                    </div>
                </div>

                <document-tagging v-if="inEditSmartTags" :docid="documentId"></document-tagging>
            </cardBody>
        </card>
        <card class="card">
            <cardHeader>
                <h4>
                    <b>Participants ({{ documentParticipants.length }})</b>
                </h4>
            </cardHeader>
            <cardBody>
                <package-document-participants :documentParticipants="documentParticipants"
                                               :packageParticipants="packageParticipants"
                                               :documentId="documentId"
                                               @updateDocumentParticipants="updateDocumentParticipants"
                                               :isEnote="
            documentDetails.isEnote
          " />
            </cardBody>

        </card>
        <card class="card">
            <cardHeader>
                <h4><b>Activity Log</b></h4>
            </cardHeader>
            <cardBody>
                <audit-log v-if="packageId != 0" ref="auditlog" :documentParticipants="documentParticipants"
                           :docId="documentId" :pckgId="packageId" :auditLog="auditLog" />
            </cardBody>
        </card>
    </div>
</template>

<style scoped>
    .document-details-actions {
        display: flex;
        flex-wrap: wrap;
    }

        .document-details-actions .k-primary.k-button {
            margin-right: 10px;
            margin-top: 5px;
        }

    .card {
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        margin-top: 15px;
        border-radius: 20;
    }

    /* Solid border */
    hr.solid {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        margin-left: -16px;
        margin-left: -16px;
    }
</style>
<script>   
    import AuditLogVue from "../../components/AuditLog.vue";
    import PackageDocumentParticipantsVue from "../../components/PackageDocumentDetails/PackageDocumentParticipants.vue";
    import { Button } from "@progress/kendo-vue-buttons";
    import PackageDocumentSummaryVue from "../../components/PackageDocumentDetails/PackageDocumentSummary.vue";
    import { useRoute } from "vue-router";
    import ApiService from "@/core/services/api-service";
    import { loadFileUrl } from "@/core/services/api-service";
    import { downloadFileUrl } from "@/core/services/api-service";
    import PackageDocumentEditDialogVue from "../../components/PackageDocumentDetails/PackageDocumentEditDialog.vue";
    import RouterLinkBreadCrumbsVue from "../../components/RouterLinkBreadCrumbs.vue";
    import DocumentTagging from "@/views/admin/DocumentTaggingRework.vue";
    import Swal from "sweetalert2";
    import PermissionHelper from "../../core/permission-helper";
    import {
        Card,
        CardHeader,
        CardBody,
        CardTitle,
        CardSubtitle,
        CardActions,
    } from "@progress/kendo-vue-layout";

    const PERMISSION_PREFIX = "Loan_";
    const DOC_PERMISSIONS = {
        EDIT_DETAIL: "Document_CanEditDocumentDetail",
        MANAGE_TAGS: "Document_CanManageSmartTags"
    }

    export default {
        name: "DocumentDetails",
        components: {
            "k-button": Button,
            PackageDocumentParticipants: PackageDocumentParticipantsVue,
            AuditLog: AuditLogVue,
            PackageDocumentSummary: PackageDocumentSummaryVue,
            PackageDocumentEditDialog: PackageDocumentEditDialogVue,
            DocumentTagging: DocumentTagging,
            RouterLinkBreadCrumbs: RouterLinkBreadCrumbsVue,
            card: Card,
            cardHeader: CardHeader,
            cardBody: CardBody,
            cardTitle: CardTitle,
            cardSubtitle: CardSubtitle,
            cardActions: CardActions,
        },        
        async created() {
            console.log("creating document details");
            const route = useRoute();
            this.documentId = route.params.id;
            await this.fetch();
            var settings = await ApiService.GenerateGetRequest("Admin/LenderSettings");
            if (settings != null && settings.isSuccess) {
                this.lenderSettings = settings.content;
                console.log("Admin/LenderSettings", settings);
            }
        },
        data() {
            return {
                documentDetails: "",
                documentParticipants: [],
                packageParticipants: [],
                host: loadFileUrl,
                downloadUrl: downloadFileUrl,
                documentId: 0,
                inEditSmartTags: false,
                auditLog: [],
                navItems: [],
                packageId: 0,
                docName: "",
                editDocNameBool: false,
                permissions: {},
                lenderSettings: [],
                canModifyTags:true,
            };
        },
        computed: {
            canEdit: function () { return this.permissions[DOC_PERMISSIONS.EDIT_DETAIL] },
            canManageTags: function () { return this.permissions[DOC_PERMISSIONS.MANAGE_TAGS] && this.canModifyTags && !this.documentDetails.isEnote},
        },
        methods:
        {
            async fetch() {
                
                await ApiService.GenerateGetRequest(
                    `package/document/${this.documentId}`
                ).then((response) => {
                    this.documentDetails = response.content;

                    this.packageId = this.documentDetails.loanPackageId;
                    console.log("from document details page package id", this.packageId, "document id", this.documentId);

                    this.docName = this.documentDetails.description;
                    console.log("Document Details:", response.content);
                    this.navItems = [
                        {
                            id: 1,
                            label: "Home",
                            url: "/admin",
                            icon: "home",
                        },
                        {
                            id: 2,
                            label: this.documentDetails.loanNumber,
                            icon: "folder",
                            url: "/admin/detail/" + this.documentDetails.loanPackageId,
                        },
                        {
                            id: 3,
                            label: this.documentDetails.description,
                            icon: "file",
                        },
                    ];
                });



                await ApiService.GenerateGetRequest(
                    `package/document/${this.documentId}/participants`
                ).then((response) => {
                    this.documentParticipants = response.content;
                    this.canModifyTags = this.documentParticipants.filter(x => (x.participationStatus == "Signed" || x.participationStatus == "Notarized" || x.participationStatus == "Reviewed") && x.autoSignFlag === false).length == 0
                    console.log("document participants:", response.content);
                });

                await ApiService.GenerateGetRequest(
                    `package/document/${this.documentId}/package_participants`
                ).then((response) => {
                    this.packageParticipants = response.content;
                    console.log("this.packageParticipants", this.packageParticipants);
                });

                await this.readDocumentLog();

                this.permissions = PermissionHelper.GetPermissions(PERMISSION_PREFIX);           
            },

            editDocName() {
                if (!this.canEdit) return;
                console.log("Document Details:", this.docName);
                this.editDocNameBool = !this.editDocNameBool;
            },
            saveDocName() {
                if (!this.canEdit) return;
                console.log(this.docName);
                let formData = new FormData();
                formData.append("AdminUserId", this.$store.state.Admin.loginInformation.userId);
                formData.append("LoanPackageId", this.documentDetails.loanPackageId);
                formData.append("DocumentId", this.documentId);
                formData.append("Description", this.docName);
                ApiService.GeneratePostRequest("package/document/update", formData, { "Content-Type": "multipart/form-data" }).then((success) => {
                    if (!success["isSuccess"]) {
                        Swal.fire({
                            title: "Error",
                            text: response.content.message,
                            icon: "error",
                            confirmButtonText: "Ok"
                        });
                        return;
                    }
                    this.navItems[2]["label"] = this.docName;
                    this.documentDetails.description = this.docName;
                    this.editDocName();
                });
            },
            
            async toggleDocViewer() {
                console.log("this.documentDetails: ", this.documentDetails);

                //var watermark = "";
                //if (this.documentDetails.wetSign) {
                //    watermark = this.lenderSettings.wetSignWatermark;
                //}
                //if (this.documentDetails.status == "Notarized") {
                //    watermark = this.lenderSettings.notarizeWatermark;
                //}
                //if (this.documentDetails.isEnote) {
                //    watermark = this.lenderSettings.eSignDocWatermark;
                //}
                //const url = this.host + this.documentDetails.documentUrl + "&watermark=" + watermark;
                if (this.documentDetails.isEnote) {
                    let route = this.$router.resolve({
                        path: "/admin/enote/" + this.documentDetails.documentID
                    });
                    window.open(route.href, "_blank");
                    return;
                }
                const url = "document/load-doc?document=" + this.documentDetails.documentID; 

                let file = await ApiService.GenerateGetBlobRequest(url);
                const fileBlob = URL.createObjectURL(file);

                window.open(
                    fileBlob,
                    "_blank"
                );
            },

            updateDocumentParticipants(participants) {
                this.documentParticipants = [...participants];
            },

            toggleManageSmartTags() {
                if (!this.canManageTags) return;
                if (this.documentDetails.documentType == "PDF") {
                    this.inEditSmartTags = !this.inEditSmartTags;
                }
            },

            async saveEdit(edits) {
                if (!this.canEdit) return;
                var user = this.$store.state.Admin.loginInformation;
                await ApiService.GeneratePostRequest(`package/document/details/update`, {
                    wetSign: edits.wetSign,
                    reviewRequired: edits.reviewRequired,
                    availableForPreSign: edits.availableForPreSign,
                    documentId: parseInt(this.documentId),
                    adminUserId: user.userId,
                }).then((response) => {
                    this.inEditSmartTags = false;

                    if (response.isSuccess) {
                        this.fetch().then(() => {
                            this.documentDetails.wetSign = edits.wetSign;
                            this.documentDetails.reviewRequired = edits.reviewRequired;
                            this.documentDetails.availableForPreSign = edits.availableForPreSign;
                            Swal.fire({
                                title: "Saved!",
                                text: "",
                                icon: "success",
                                confirmButtonText: "Ok",
                            });
                        });
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: response.content.message,
                            icon: "error",
                            confirmButtonText: "Ok",
                        });
                    }
                });
            },

            async download() {
                console.log("this.documentDetails: ", this.documentDetails);

                //var watermark = "";
                //if (this.documentDetails.wetSign) {
                //    watermark = this.lenderSettings.wetSignWatermark;
                //}
                //if (this.documentDetails.status == "Notarized") {
                //    watermark = this.lenderSettings.notarizeWatermark;
                //}
                //if (this.documentDetails.isEnote) {
                //    watermark = this.lenderSettings.eSignDocWatermark;
                //}

                //const url = this.downloadUrl + this.documentDetails.documentUrl + "&watermark=" + watermark + "&name=" + this.documentDetails.description;


                var queryParam = "";
                if (this.documentDetails.loanPackageStatus === "Completed" || this.documentDetails.loanPackageStatus === "Closed")
                    queryParam = "&i=" + this.documentDetails.documentID;
                const url = "document/download-doc?document=" + this.documentDetails.documentID + "&name=" + this.documentDetails.description + queryParam;

                console.log("downloadUrl: ", url);

                let file = await ApiService.GenerateGetBlobRequest(url);
                const fileBlob = URL.createObjectURL(file);

                var a = document.createElement("a");
                a.href = fileBlob;

                //if the .xml extension exists in the file description, remove as the file is converted to pdf
                var docName = this.documentDetails.description;
                if (docName.lastIndexOf('.xml') > -1)
                    docName = docName.substr(0, docName.lastIndexOf('.xml'));
                a.download = docName;
                a.click();
            },

            async readDocumentLog() {
                var self = this;
                console.log("read doc audit logs", self.documentId);

                await ApiService.GenerateGetRequest(
                    "Package/GetDocumentAuditLog/" + this.documentId
                ).then((response) => {
                    this.auditLog = response;
                    console.log(this.auditLog);
                });

                this.$refs.auditlog.reloadPDF();
                console.log("reloaded pdf");
            },
        }
    }
</script>

<style scoped>
    .card {
        border-radius: 20px;
    }
</style>
