<template>
  <ul>
    <li
      style="display:inline;"
      v-for="(item, index) in navItems"
      :key="item.id"
    >
      <kbutton v-on:click="reroute(item.url)" :look="'flat'" :icon="item.icon">
        {{ item.label }}
      </kbutton>
      <span v-if="!isLast(index)">/</span>
    </li>
  </ul>
</template>

<script>
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";

export default {
  props: ["navItems"],
  data: function() {
    return {};
  },
  components: {
    kbutton: Button,
    buttongroup: ButtonGroup,
  },
  async created() {
    console.log(this.navItems);
  },

  methods: {
    reroute(path) {
      this.$router.push(path);
    },
    isLast(index) {
      return index === this.navItems.length - 1;
    },
  },
};
</script>
<style scoped>
li:last-child {
  color: #54b7d3;
}
</style>
