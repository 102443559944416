<template>
  <div class="example-wrapper">
    <Grid
      ref="grid"
      :style="{ height: '440px' }"
      :data-items="participants"
      :columns="columns"
      :total="total"
      :take="take"
      :skip="skip"
      :pageable="true"
      @pagechange="pageChangeHandler"
    >
      >
      <template v-slot:myTemplate="{ props }">
        <td>
          <custom :data-item="props.dataItem" @remove="remove" v-if="canEdit" />
          <!--<manage-smart-tag-command-cell
            v-if="props.dataItem.participantType == 'Signer' && !isEnote"
            :data-item="props.dataItem"
            @manage-smart-tags="manageSmartTags"
          />-->
        </td>
      </template>
      <grid-toolbar style="justify-content:flex-end">
        <button title="Add new" class="k-button k-primary" @click="insert" v-if="canEdit">
          Assign
        </button>
      </grid-toolbar>
      <grid-norecords>
        There is no data available custom
      </grid-norecords>
    </Grid>
    <dialog-container
      v-if="participantInEdit"
      :data-item="participantInEdit"
      @save="save"
      @cancel="cancel"
      :packageParticipants="packageParticipants"
    >
    </dialog-container>
  </div>
</template>
<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import AddParticipantDialog from "./AddDocumentParticipantDialog";
import CommandCell from "./CommandCell";
import ManageSmartTagCommandCell from "./ManageSmartTagCommandCell";
import ApiService from "../../core/services/api-service";
import Swal from "sweetalert2";
import GridDataHelper from "../../core/services/grid-data-helper";
import moment from "moment";
import PermissionHelper from "../../core/permission-helper";

const CAN_EDIT_PERM = "Loan_Document_CanEditDocumentParticipant";

export default {
  name: "PackageDocumentParticipants",
  props: [
    "documentParticipants",
    "packageParticipants",
    "documentId",
    "isEnote",
  ],
  async created() {
    var perm = PermissionHelper.GetPermission(CAN_EDIT_PERM);
    if (perm[CAN_EDIT_PERM] !== undefined) this._canEdit = perm[CAN_EDIT_PERM];
  },
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    "dialog-container": AddParticipantDialog,
    custom: CommandCell,
    ManageSmartTagCommandCell: ManageSmartTagCommandCell,
  },
  computed: {
    participants: {
      get: function() {
        return this.documentParticipants.slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    total() {
      return this.documentParticipants ? this.documentParticipants.length : 0;
    },
    moment: () => moment,     
    canEdit: function () { return this._canEdit },
  },
  data: function() {
    return {
      skip: 0,
      take: 10,
      participantInEdit: undefined,
      columns: [
        {
          field: "participantId",
          editable: false,
          title: "participantId",
          hidden: true,
        },
        {
          field: "documentId",
          editable: false,
          title: "documentId",
          hidden: true,
        },

        { field: "firstName", title: "Name",width: "250px", cell: this.joinName },
        { field: "participantType", title: "Type", width: "150px" },
        { field: "email", title: "Email Address", width: "350px" },
        { field: "dateAdded", title: "Date Added", cell: this.dateFormat, width: "150px" },
        { field: "addedBy", title: "Added By", width: "120px" },
        {
          field: "participationStatus",
          title: "Status",
          cell: this.statusFunction,
          width: "200px"
        },
        { field: "participantMappingId", hidden: true },
        { cell: "myTemplate", width: "150px" },
      ],
      participants: [...this.documentParticipants],
      _canEdit: false
    };
  },
  methods: {
    async remove(dataItem) {
      var user = this.$store.state.Admin.loginInformation;
      await ApiService.GeneratePostRequest(
        `package/document/participants/remove`,
        {
          ParticipantId: parseInt(dataItem.participantId),
          DocumentId: parseInt(dataItem.documentId),
          AdminUserId: user.userId,
        }
      ).then((response) => {
        if (response.isSuccess) {
          let items = this.documentParticipants.slice();
          const filtered = items.filter(
            (x) => x.participantId !== dataItem.participantId
          );

          this.$emit("updateDocumentParticipants", filtered);
          Swal.fire({
            title: "Participant Unassigned Successfully",
            text: "",
            icon: "success",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: response.message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
    },
    async save(participant) {
      var user = this.$store.state.Admin.loginInformation;
      console.log(this.documentId);
      if (
        this.documentParticipants.findIndex(
          (x) => x.participantId == participant.participantId
        ) != -1
      ) {
        alert("Participant already added");
      } else {
        await ApiService.GeneratePostRequest(
          `package/document/participants/add`,
          {
            ParticipantId: parseInt(participant.participantId),
            DocumentId: parseInt(this.documentId),
            AdminUserId: user.userId,
          }
        ).then((response) => {
          console.log(response.content);
          if (response.isSuccess) {
            let items = this.documentParticipants.slice();
            items.unshift(response.content);
            this.$emit("updateDocumentParticipants", items);
            Swal.fire({
              title: "Participant Assigned Successfully",
              text: "",
              icon: "success",
              confirmButtonText: "Ok",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: response.message,
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        });
      }

      this.participantInEdit = undefined;
    },
    manageSmartTags(item) {
      this.$router.push({
        name: `AdminDocumentTagging`,
        params: { documentId: item.documentId, signerId: item.participantId },
      });
    },
    cancel() {
      this.participantInEdit = undefined;
    },
    insert() {
      this.participantInEdit = {};
    },
    pageChangeHandler: function(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    statusFunction: function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
        [GridDataHelper.normalizeStatus(props.dataItem.participationStatus)]
      );
    },
    dateFormat: function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
          [moment(props.dataItem.dateAdded).format("MM/DD/YYYY")]
      );
    },
    joinName:function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
        [`${props.dataItem.firstName} ${props.dataItem.middleName ?? ""} ${props.dataItem.lastName}`]
      );
    },
  },
};
</script>
