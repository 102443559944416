<template>
    <div class="col-md-12">
        <div class="participants-grid">
            <div class="title-block">
                <h3>Video Recording Requests</h3>
            </div>

            <kendo-datasource ref="datasource1"
                              :transport-read="readData"
                              :transport-update="updateData"
                              :schema-data="'data'"
                              :schema-total="'total'"
                              :schema-groups="'groups'"
                              :schema-aggregates="'aggregates'"
                              :schema-errors="'errors'"
                              :schema-model-id="'packageName'"
                              :schema-model-fields="schema"
                              :server-filtering="true"
                              :server-paging="true"
                              :batch='true'
                              :page-size='10'>
            </kendo-datasource>

            <kendo-grid ref="kendoRequestGrid"
                        :data-source-ref="'datasource1'"
                        :class="'notes'"
                        :sortable="true"
                        :resizable="true"
                        :navigatable="true"
                        :pageable="true"
                        :pdf-all-pages="true"
                        :pdf-avoid-links="true"
                        :pdf-paper-size="'A4'"
                        :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                        :pdf-landscape="true"
                        :pdf-repeat-headers="true"
                        :pdf-scale="0.8"
                        :columns="columns"
                        :editable="packagePopUpTemplate"
                        :edit="onEdit"
                        @databound="documentDataBound"
                        @save="onPopUpModalSaved">

                <toolbar>
                    <button title="Export All to Excel" class="k-button k-primary" @click="exportExcel">
                        Export All to Excel
                    </button>&nbsp;
                    <button class="k-button k-primary" @click="exportPDF">
                        Export All to PDF
                    </button>
                </toolbar>
            </kendo-grid>
        </div>
    </div>
</template>

<style>
    .kg-approve-btn {
        background-color: #37b400;
        color: #FFFFFF !important;
    }

    .kg-reject-btn {
        background-color: #f94e32;
        color: #FFFFFF !important;
    }
</style>

<script>
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid } from '@progress/kendo-grid-vue-wrapper';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { GridPdfExport } from '@progress/kendo-vue-pdf';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import ApiService from "../../core/services/api-service";
    import { process } from '@progress/kendo-data-query';
    import { Button } from '@progress/kendo-vue-buttons';
    import Sweetalert from "sweetalert2/dist/sweetalert2.all.min.js";
    import KendoPopupGenerator from '@/core/services/kendo-grid-popup-form-generator.js';

    export default {
        name: 'NotaryRequests',
        components: {
            'kendo-grid': Grid,
            'kendo-datasource': KendoDataSource,
            'pdfexport': GridPdfExport,
            'toolbar': GridToolbar,
            'k-button': Button,
            'native-grid': NativeGrid,
        },
        data() {
            return {
                schema: {
                    packageName: { type: 'string', editable: true },
                    accessLevel: { type: 'string', editable: true },
                    reason: { type: 'string', editable: true }
                },
                hideCol: true,
                shouldFilter: false,
                columns: [
                    { field: "packageName", title: 'Package  Name' },
                    { field: "accessLevel", title: 'Status' },
                    { field: "reason", title: 'Comments' },
                    {
                        title: '', command: [
                            { className: 'participant-cmd kg-approve-btn', text: 'Approve', click: (e) => { this.approveRequest(e) } },
                            { className: 'participant-cmd kg-reject-btn', text: 'Reject', click: (e) => { this.rejectRequest(e) } }
                        ]
                    },
                ],
            }
        },
        methods: {
            documentDataBound: function (e) {
                var self = this;
                var grid = e.sender

                var items = e.sender.items();

                items.each(function (index, item) {
                    var dataItem = grid.dataItem(item);

                    if (dataItem.accessLevel == "Approved" || dataItem.accessLevel == "Rejected") {

                        var row = $("tr[data-uid='" + dataItem.uid + "']");
                        row.find('.participant-cmd').remove();
                    }
                });
            },
            parameterMap: function (options, operation) {
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            readData: function (e) {
                var user = this.$store.state.Admin.loginInformation;
                if (e != null && e.data != null && e.data.filter != null) {
                    for (var i = 0; i < e.data.filter.filters.length; i++) {
                        let fld = e.data.filter.filters[i].field;

                        if (fld != null && fld != 'undefined')
                            e.data.filter.filters[i].field = fld.charAt(0).toUpperCase() + fld.slice(1);
                        else if (e.data.filter.filters[i].logic != null) {
                            for (var j = 0; j < e.data.filter.filters[i].filters.length; j++) {
                                fld = e.data.filter.filters[i].filters[j].field;
                                e.data.filter.filters[i].filters[j].field = fld.charAt(0).toUpperCase() + fld.slice(1);
                            }
                        }
                    }
                }

                return ApiService.GeneratePostRequest(`Admin/requests`, e.data).then(
                    response => {
                        e.success(response["content"]);
                    }
                );
            },
            exportExcel() {
                var self = this;
                this.readData({
                    success: function (data) {
                        saveExcel({
                            data: process(data.data, {
                                skip: 0
                            }).data,
                            fileName: "requests",
                            columns: self.columns
                        });
                    }
                });
            },
            exportPDF() {
                var self = this;
                this.readData({
                    success: function (data) {
                        var options = {
                            skip: 0
                        }
                        const itemsToExport = process(data.data, options).data;
                        (self.$refs.gridPdfExport).save(itemsToExport);

                        return data;
                    }
                });
            },
            approveRequest: function (e) {
                e.preventDefault();
                let gridRow = this.$refs.kendoRequestGrid.kendoWidget().dataItem($(e.target).closest("tr"));

                return ApiService.GeneratePostRequest(`Package/GrantVideoAccess?loan_package_id=` + gridRow.loan_package_id + '&lender_Id=' + gridRow.lender_id + '&accessLevel=2').then(
                    response => {
                        if (response.content) {
                            Sweetalert.fire("Request approved!", "", "success");
                        } else {
                            Sweetalert.fire("Failed", response.message, "error");
                        }
                        this.$refs.kendoRequestGrid.kendoWidget().dataSource.read();
                        $(e.target).closest("tr").find('.participant-cmd').hide();
                    }
                );
            },
            rejectRequest: function (e) {
                e.preventDefault();
                //open modal
                this.$refs.kendoRequestGrid.kendoWidget().editRow($(event.target).closest("tr"));
            },
            updateData: function (e) {
                let data = e.data.models[0];

                return ApiService.GeneratePostRequest(`Package/GrantVideoAccess?loan_package_id=` + data.loan_package_id + '&lender_Id=' + data.lender_id + '&accessLevel=3&comment=' + $("#comment").val()).then(
                    response => {
                        if (response.content) {
                            Sweetalert.fire("Request rejected!", "", "success");

                            //close modal
                            $(".k-window .k-window-content").each(function (index, element) {
                                $(element).data("kendoWindow").close();
                            });
                        } else {
                            Sweetalert.fire("Failed", response.message, "error");
                        }
                        this.$refs.kendoRequestGrid.kendoWidget().dataSource.read();
                    }
                );
            }
        },
        computed: {
            packagePopUpTemplate(e) {
                let html = "";
                html += "<div id='RequesPopup'>";
                html += "   <div class='col-md-12'>";
                html += "       <div class='row'>";
                html += KendoPopupGenerator.generatePopUpFormTextarea('comment', 'Comment', 'comment', 5, 30, true);
                html += "       </div>";
                html += "   </div>";
                html += "</div>";
                return { 'template': html, 'mode': 'popup', width: "600px", height: "300px", }
            }
        },
    }
</script>
