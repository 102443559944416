<template>
        <k-button :look="'flat'" @click="handler" class="customBtn">
            Manage Smart Tags
        </k-button>
  
</template>
<script>
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String,
        },
        components: {
            "k-button": Button,
        },
        methods: {
            handler: function () {
                    this.$emit("manageSmartTags", this.dataItem);
            },
        },
    };
</script>

<style scoped>
    .customBtn {
        font-size: small;
        padding: 4px 8px;
    }

    .deleteBtn {
        color: #fa4d52;
    }
</style>
