
<template>
    <section id="screen-page">
        <div class="bdr bdr-rad-15 container">
            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <div class="col-md-12 text-center">
                        <h2>Test Video, Audio and Location</h2>
                        <!--<img src="../../../../../public/images/icon-left-arrow.png" alt="" class="img-responsive arrow-icon position-left">-->
                    </div>
                </div>
            </div>
            <div class="row row-flex">
                <div class="innerbox col-md-6 column-flex">
                    <div class=" col-md-12 text-center">

                        <!-- test camera -->
                        <testcamera @camID="getCamera" />
                        <hr />

                        <!-- test audio -->
                        <testaudio @micID="getMicrophone" @speakerID="getSpeaker" />
                        <hr />
                         
                        <!-- test internet -->
                        <testinternetconnection />

                        <!-- test location -->
                        <testlocation @location="getLocation" />

                    </div>
                </div>
                <div class="innerbox dark-bg col-md-6 column-flex">
                    <div class=" col-md-12">
                        <br>
                        <br>
                        <br>
                        <ul class="signup-process">
                            <li> <span class="badge">1</span> Camera</li>
                            <li> <span class="badge">2</span> Audio</li>
                            <li> <span class="badge">3</span> Location</li>
                            <li>
                                <k-button :primary="true" :disabled="disabled" @click="isContinue">CONTINUE</k-button>
                            </li>
                        </ul>

                        <br>
                        <br>
                        <br>
                        <br>

                        <br>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<style>
    .pids-wrapper {
        width: 100%;
    }

    .pid {
        width: calc(10% - 10px);
        height: 10px;
        display: inline-block;
        margin: 5px;
    }
</style>

<script>

    import TestAudio from '@/components/forms/invitation-steps/test/TestAudio.vue';
    import TestAudioVideo from '@/components/forms/invitation-steps/test/TestAudioVideo.vue';
    import TestCamera from '@/components/forms/invitation-steps/test/TestCamera.vue';
    import TestHardwareInstructions from '@/components/forms/invitation-steps/test/TestHardwareInstructions.vue';
    import TestInternetConnection from '@/components/forms/invitation-steps/test/TestInternetConnection.vue';
    import TestLocation from '@/components/forms/invitation-steps/test/TestLocation.vue';
    import ApiService from "@/core/services/api-service";
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        name: 'Setup',
        components: {
            "k-button": Button,
            testaudio: TestAudio,
            testaudiovideo: TestAudioVideo,
            testcamera: TestCamera,
            testhardwareinstructions: TestHardwareInstructions,
            testinternetconnection: TestInternetConnection,
            testlocation: TestLocation,
        },
        data() {
            return {
                user: {},
                selectedCam: null,
                selectedMic: null,
                selectedSpeaker: null,
                location: "",
                isRon: this.$store.getters["Invitation/isRon"] || false,
                disabled: true,
            }
        },
        created() {
            this.$watch(() => ([this.selectedCam, this.selectedMic, this.selectedSpeaker]), () => {
                console.log(this.selectedCam, this.selectedMic, this.selectedSpeaker)
                this.disabled = (this.selectedCam == null || this.selectedMic == null || this.selectedSpeaker == null)
            });
        },
        async mounted() {
            console.log("ISRON: ", this.isRon);

            //FETCH CURRENT USER OBJ 
            //var user = await ApiService.GenerateGetRequest("admin/GetUser");
            //console.log("admin/GetUser: ", user);
           // this.user = user["content"];
        },
        methods: {
            getCamera(value) {
                console.log("getCamera: ", value);
                if (value != "") {
                    this.selectedCam = value;
                }
            },
            getMicrophone(value) {
                console.log("getMicrophone: ", value);
                if (value != "") {
                    this.selectedMic = value;
                }
            },
            getSpeaker(value) {
                console.log("getSpeaker: ", value);
                if (value != "") {
                    this.selectedSpeaker = value;
                }
            },
            getLocation(value) {
                console.log("getLocation: ", value);
                if (value != "") {
                    this.location = value;
                }
            },
            async isContinue() {
                if (this.disable)
                    return;
                var config = {
                  //  UserId: this.user.id,
                    MicID: this.selectedMic,
                    CamID: this.selectedCam,
                    SpeakerID: this.selectedSpeaker,
                    Location: this.location
                };
                console.log("config: ", config);
                //POST USERSYSTEMCONFIG
                return ApiService.GeneratePostRequest("user/addconfig", config).then(
                    response => {
                        console.log("user/addconfig:", response["content"]);
                        //HANDLE ROUTING
                        if (response["isSuccess"]) {
                            let settings = this.$store.getters["App/appSettings"];
                            if (this.isRon) {
                                this.$router.push('/signer/ssn');
                            } else {
                                if (settings["skipSignerDetails"] && settings["skipSSN"]) {
                                    this.$router.push({ path: `/signer/room` });
                                } else if (settings["skipSignerDetails"] && !settings["skipSSN"]) {
                                    this.$router.push('/signer/ssn');
                                } else if (!settings["skipSignerDetails"] && settings["skipSSN"]) {
                                    this.$router.push({ name: "SignerDetails" });
                                } else {
                                    this.$router.push({ name: "SignerDetails" });
                                }
                            }
                        }
                    }
                );
            }
        }
    }

</script>