<template>
    <kendo-datasource ref="documentsource"
                      :transport-read="readData"
                      :transport-update="updateData"
                      :transport-create="createData"
                      :transport-destroy="deleteData"
                      :schema-model-id="'userID'"
                      :schema-model-fields="schema"
                      :schema-data="'data'"
                      :schema-total="'total'"
                      :schema-groups="'groups'"
                      :schema-aggregates="'aggregates'"
                      :schema-errors="'errors'"
                      :transport-parameter-map="parameterMap"
                      :server-filtering="true"
                      :server-paging="true"
                      :batch='true'
                      :page-size='100'>
    </kendo-datasource>

    <kendo-grid :data-source-ref="'documentsource'"
                ref="kendoJqueryGrid" 
                :class="'documents'"
                :pageable="false"
                :edit="onEdit"
                :editable="packagePopUpTemplate"
                :sortable="true"
                :resizable="true"
                :pageable-refresh="true"
                :pageable-button-count="5"
                :pageable-page-sizes="[5, 10, 20, 100]"
                :pageable-always-visible="true"
                :navigatable="true"
                selected-field="selected"
                :filterable="shouldFilter"
                :pdf-all-pages="true"
                :pdf-avoid-links="true"
                :pdf-paper-size="'A4'"
                :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                :pdf-landscape="true"
                :pdf-repeat-headers="true"
                :pdf-scale="0.8"
                :columns="columns"
                @databound="documentDataBound"
                @filterchange="filterChange"
                @selectionchange="onSelectionChange"
                @headerselectionchange="onHeaderSelectionChange">
        <toolbar v-show="!hideCol"> 
            <div style="margin: 0 0px 0 auto;" v-show="show">
                <button title="Add New" class="k-button k-primary k-add-button" @click='documentInsert'>
                    Assign Notary
                </button>
            </div>
        </toolbar> 
    </kendo-grid>
    <pdfexport ref="gridPdfExport" v-show="false">
        <native-grid :columns="exportColumns" />
    </pdfexport>
</template>
<style scoped>
    * {
        font-weight: 400;
    }

    /deep/ .participant-cmd, /deep/ .participant-cmd:hover, /deep/ .participant-cmd::before {
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: unset;
        background: initial;
        border: none;
        border-radius: unset;
        transition: none;
        -webkit-transition: none;
        padding: 8px 5px;
        font: 900 .94em 'Lato', sans-serif;
        color: #4d97ac;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.ready_for_review /deep/ span {
            background: #387a38;
        }

        .status.ready_for_signing /deep/ span {
            background: #4cb64c;
        }

        .status.default /deep/ span {
            background: #f3a638;
        }

        .status.reviewed /deep/ span, .status.signed /deep/ span, .status.notarized /deep/ span {
            background: #54b7d3;
        }

        .status.archived /deep/ span {
            background: #E3D4D4;
        }

        .status.cancelled /deep/ span, .status.wet_sign /deep/ span {
            background: slategray;
        }
</style>
<script>
    import { useRoute } from 'vue-router';
    import ApiService from "../../core/services/api-service";
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { GridPdfExport } from '@progress/kendo-vue-pdf';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { process } from '@progress/kendo-data-query';
    import { loadFileUrl } from "@/core/services/api-service";
    import { downloadFileUrl } from "@/core/services/api-service";
    import KendoPopupGenerator from '@/core/services/kendo-grid-popup-form-generator.js';
    import GridDataHelper from "../../core/services/grid-data-helper";
    import Swal from 'sweetalert2';

    export default {
        name: 'PackageNotary',
        props: ['packageide', 'pnotarizeId', 'pnotaries', 'ntype'],
        components: {
            'kendo-grid': Grid,
            'kendo-grid-column': GridColumn,
            'kendo-datasource': KendoDataSource,
            'pdfexport': GridPdfExport,
            'toolbar': GridToolbar,
            'dropdownlist': DropDownList,
            'native-grid': NativeGrid,
        },
        data() {
            ///****************************************************************************** NOTE ******************************************************************************///
            ///This is the ONLY WAY right now to include a function to specify if a column can be visible. All other ways fail due to a bug in kendo that attempts to add a _ctx ///
            ///to variables in the function thereby breaking the generated template. The workaround is to have an inline function so that kendo cannot access the function scope ///
            ///******************************************************************************************************************************************************************///
             
            return {
                hideCol: false,
                shouldFilter: false,
                schema: {
                    //userID: { editable: false },
                    notary: { type: 'string', editable: true },
                },
                columns: [ 
                     //{ field: "userID", title: "ID#", width: "35px", visible: false },
                    { field: "notary", title: "Notary Name", width: "450px", visible: true },
                    {
                        title: '', width: "350px", command: [
                            { className: 'participant-cmd', text: 'Edit', click: this.documentEdit, visible: function (e) { return true; } }, 
                        ]
                    },

                ],

                exportColumns: [
                    //{ field: "userID", title: "ID#", width: "35px", visible: false },
                    { field: "notary", title: "Notary Name", width: "450px" },
                ],
                newid: "",
                newdoc: {},
                show: true,
                currentNotary: "",

                // variables to see which participants to remove or add to document
                existingParticipantsForDocument: [],
                participantsToAddForDocuments: [],
                participantsToRemoveForDOcuments: []
            }
        },
        async created() { 
            await this.getNotaryIntegration();
        },
        computed: {
            packagePopUpTemplate(e) {

                let html = "";
                html += "<div id='NotaryGrid'>";
                html += "   <div class='col-md-12'>";
                html += KendoPopupGenerator.generatePopUpFormDropdown(
                    "Notary",
                    "Notary Officer",
                    "UserID",
                    [
                        
                    ],
                    "Id",
                    "Text"
                ); 
                html += "</div>";
                return { 'template': html, 'mode': 'popup' }
            },
        },
        methods: {
            reloadParent: function () {
                this.$emit('reload-parent');
            }, 
            documentInsert(event) {
                event.preventDefault();
                this.$refs.kendoJqueryGrid.kendoWidget().addRow(); 
            },
            documentEdit(event) {
                event.preventDefault();
                this.$refs.kendoJqueryGrid.kendoWidget().editRow($(event.target).closest("tr"));

            }, 
            documentDataBound: function (e) {
                var self = this;

                var grid = e.sender;
                var items = e.sender.items();
                  
                this.reloadParent();
            }, 
            parameterMap: function (options, operation) {
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            readData: function (e) {

                if (!e.data) {
                    e.data = {};
                }
                e.data.LoanID = parseInt(this.$route.params.id);
                e.data.PackageId = parseInt(this.packageide); 

                return ApiService.GeneratePostRequest(`Package/notary`, e.data).then(
                    response => {
                        e.success(response.content);
                        if (response.content.data.length > 0)
                        {
                            this.currentNotary = response.content.data[0].notary;
                            console.log("this.currentNotary", response.content)
                            this.show = false; 
                        } 
                    }
                );
                    
            },
            createData: function (e) { 
                let formData = new FormData();
                var notary = this.pnotaries.find(x => x.userID == e.data.models[0]["UserID"]);
               
                if (notary != null) {
                    formData.append("LoanPackageId", this.packageide); // 1044);  
                    formData.append("AppUserID", notary.appUserId);
                    formData.append("UserID", e.data.models[0]["UserID"]);
                    formData.append("Name", notary.notary);
                    formData.append("AdminUserId", this.$store.state.Admin.loginInformation.userId);

                    return ApiService.GeneratePostRequest("package/notary/update", formData, { "Content-Type": "multipart/form-data" }).then(
                        response => {
                            e.success(response.content); //- look at line 409

                            Swal.fire(
                                'Notary assigned',
                                '',
                                'success'
                            );

                            if (response.content != null && response.content.length > 0) {
                                this.currentNotary = response.content[0].notary;
                                this.show = false;
                            }

                        }
                    );
                }

                
            },
            updateData: function (e) {
                let formData = new FormData();
                var notary = this.pnotaries.find(x => x.userID == e.data.models[0]["UserID"]);
              
                if (notary != null) {
                    formData.append("LoanPackageId", this.packageide); // 1044);  
                    formData.append("AppUserID", notary.appUserId);
                    formData.append("UserID", e.data.models[0]["UserID"]);
                    formData.append("Name", notary.notary);
                    formData.append("AdminUserId", this.$store.state.Admin.loginInformation.userId);


                    this.$refs.kendoJqueryGrid.kendoWidget().refresh();
                    return ApiService.GeneratePostRequest("package/notary/update", formData, { "Content-Type": "multipart/form-data" }).then(
                        response => {
                            e.success(response.content);
                            this.currentNotary = response.content[0].notary;
                            this.show = false;
                            Swal.fire(
                                'Notary updated',
                                '',
                                'success'
                            );

                        }
                    );
                }

            },
            deleteData: function (e) {
                let formData = new FormData();
                formData.append("LoanPackageId", this.packageide); // 1044);  
                formData.append("UserID", e.data.models[0]["notary"]);
                formData.append("AdminUserId", this.$store.state.Admin.loginInformation.userId); 
                return ApiService.GeneratePostRequest("package/notary/update", formData, { "Content-Type": "multipart/form-data" }).then(
                    response => {
                        e.success(response);
                        if (response["isSuccess"]) {
                            Swal.fire(
                                'Success',
                                'Notary deleted sucessfully',
                                'success'
                            );
                            this.$emit('readLog');
                        } else {
                            this.$refs.kendoJqueryGrid.kendoWidget().cancelChanges();
                            Swal.fire(
                                'Operation failed',
                                'Notary could not be deleted',
                                'error'
                            );
                        }
                    }
                );
            },
            async onEdit(e) {
                 
                let dropdownHtml = ""; 
                $(".k-window-title").text("Assign Notary");

                $(".k-edit-form-container").parent().width(1000).data("kendoWindow").title("Add").center();
                $(".k-edit-form-container").css('width', 'auto');

                /*console.log("pnotaries:", this.pnotaries);*/ 
                //Add dropdown options here 
                let n = this.currentNotary;
                console.log("current:", n);
                if (n === undefined || n=="") { dropdownHtml = "<option value=''></option>"; }
                else { dropdownHtml = ""; }
                this.pnotaries.forEach(function (item) {
                    let user_id = item.userID
                    let notary_name = item.notary;

                    if (notary_name == n) {
                        dropdownHtml += "<option selected value='" + user_id + "'>" + notary_name + "</option>";
                    }
                    else {
                        dropdownHtml += "<option value='" + user_id + "'>" + notary_name + "</option>";
                    }
                   
                })

                //dropdownHtml += "<option value='" + 30 + '>' + 'Nicole Cazeau' + '</option>"';
                 
                $("#NotaryGrid select").html(dropdownHtml)
                 
            },
            exportExcel() {
                var self = this;
                this.readData({
                    success: function (arr) {
                        var options = {
                            skip: 0,
                        };

                        saveExcel({
                            data: process(arr.data, options).data,
                            fileName: "packages",
                            columns: self.columns
                        });
                    }
                });
            },
            exportPDF() {
                var self = this;
                this.readData({
                    success: function (arr) {
                        var options = {
                            skip: 0,
                        };
                        const itemsToExport = process(arr, options).data;
                        (self.$refs.gridPdfExport).save(itemsToExport);
                        return arr;
                    }
                });
            },
            filterChange() {

            },
            onSelectionChange() {

            },
            onHeaderSelectionChange() {

            },
            reloadGrid() {
                this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read()
            },
            async getNotaryIntegration() {
                var self = this;
                await ApiService.GenerateGetRequest(
                    "Package/notaryIntegration"
                ).then((response) => {

                    if (response.content == true) {
                        self.show = false;
                    } 
                });
            },
              
        },
    }
</script>