import store from "../store/index";
class PermissionHelper {
    static GetPermissions(prefix) {
        console.log("perfix", prefix);
        var perms = store.getters["Admin/pagePermissions"](prefix);
         
        var permissions = [];
        perms.forEach((item) => {
            permissions[item.permissionName.replace(prefix, "")] = true
        });
         
        return permissions;
    }

    static GetPermission(name) {
        var permission = {};
        var result = store.getters["Admin/permission"](name);        
        if (result !== undefined) permission = { [result.permissionName]: true };
        return permission;
    }
}

export default PermissionHelper;