<template>
  <div style="text-align:center">
    <upload
      ref="uploader"
                :key="componentKey"
                :files="files"
                :auto-upload="false"               
                :batch="true"
                :multiple="true"     
                @add="onAdd"
                @remove="onRemove"
                @progress="onProgress"
                @statuschange="onStatusChange"
                :with-credentials="false"
      :save-url="onOpen"
                :restrictions="{
        allowedExtensions: ['.pdf'],
                }"
                />
       
    <external
      :upload-ref="'uploader'"
                      :custom-hint="hint"
                      :custom-note="note"
      :inner-style="{}"
    >
                <template v-slot:hintTemplate>
                    <span>Click select files or drag and drop files here to upload.</span>
                </template>
                <template v-slot:noteTemplate>
                    <span></span>
                </template>
            </external>       
    <k-dialog
      v-if="visibleDialog"
      :title="'Please select which document you are uploading'"
      @close="onClose"
      style="z-index:2"
    >
      <p :style="{ margin: '25px', textAlign: 'center', width: '80vw' }">
        <document-upload-selection
          :files="
            files.concat(
              uploadedDocs.map((x) => {
                return { uid: x.documentId, name: x.description };
              })
            )
          "
          :wetSignDocs="wetSignDocs"
          @mapFileToDoc="mapFileToDoc"
        />
      </p>
      <dialog-actions-bar style="text-align:center">
        <kbutton @click="onClose">Cancel</kbutton>
        <kbutton :primary="true" @click="completeMarking">Confirm</kbutton>
      </dialog-actions-bar>
    </k-dialog>
    </div>
</template>
<script>
import { Upload, ExternalDropZone } from "@progress/kendo-vue-upload";
    import ApiService from "@/core/services/api-service";
import Swal from "sweetalert2";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import DocumentIploadSelection from "./DocumentUploadSelection.vue";
import { v4 as uuidv4 } from 'uuid';

    export default {       
        components: {
            upload: Upload,
            external: ExternalDropZone,           
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "document-upload-selection": DocumentIploadSelection,
        },
  props: ["wetSignDocs", "uploadedDocs"],
        data: function () {            
            return {
                files: [],              
                componentKey: 0,
      hint: "hintTemplate",
      note: "noteTemplate",
      visibleDialog: false,
            };
        },        
        async mounted() {
            var $dropzone = $("div.k-external-dropzone");
            $dropzone.css("height", "125px");
        },
        methods: {
            onAdd(event) {
      event.affectedFiles.forEach(item => {
        this.files.push({...item, uid: uuidv4()})
      })
            },
            async onUpload(event) {
      if (this.files.length == 0) return;
      let documentsUploaded = 0;
      for (let i = 0; i < this.files.length; i++) {
                    let formData = new FormData();
                    formData.append("AdminUserId", this.$store.state.App.lenderId);
        formData.append(
          "NotarizeId",
          this.$store.state.App.signerVerificationData.NotarizeId
        );
        formData.append(
          "SignerId",
          this.$store.state.App.signerVerificationData.SignerId
        );
        formData.append("File", this.files[i].getRawFile());
        formData.append("FileName", this.files[i].name);
        formData.append("Extension", this.files[i].extension);
        let response = await ApiService.GeneratePostRequest(
          "document/signer/upload",
          formData,
          { "Content-Type": "multipart/form-data" }
        );
                    if (response && response["isSuccess"]) {
          console.log("uploaded doc", response.content);
          this.files[i].documentID = response.content.documentID;
          this.componentKey += 1;
          documentsUploaded++;
        } else {
          Swal.fire("Operation failed", response.message, "error");
        }
      }

      if (documentsUploaded > 0) {
        let wetSignDocs = this.wetSignDocs;
        let mapping = wetSignDocs
          .filter((x) => x.docFile != "")
          .map((x) => {
            let file = this.files.find((file) => file.uid == x.docFile.uid);
            if (file) {
              return {
                parentDocumentId: x.documentID,
                uploadedDocumentId: file.documentID,
              };
            } else {
              return {
                parentDocumentId: x.documentID,
                uploadedDocumentId: x.docFile.uid,
              };
            }
          });
        let response = await ApiService.GeneratePostRequest(
          "document/signer/updateWetSignUploadMapping",
          mapping
        );

        this.files = [];
        if (response.isSuccess) {
          this.$emit("uploadComplete");
                        Swal.fire(
            `${documentsUploaded} Document(s) have been uploaded`,
            "",
            "success"
                        );                        
                    } else {                       
                        Swal.fire(
            `Error uploading document. Please refresh and try again`,
            "",
            "error"
                        );
                    }
                }                        
            },
    onOpen() {
      this.visibleDialog = true;
    },
    onClose() {
      this.visibleDialog = false;
      this.files.forEach(file => {
        let idx = this.wetSignDocs.findIndex(x => x.docFile !== "" && x.docFile.uid == file.uid)
        if(idx >= 0){
          this.wetSignDocs[idx].docFile =  "";
        } 
      });  
      this.files = [];
    },
    async completeMarking() {
      this.visibleDialog = false;
      await this.onUpload(this.files);
    },
           
            onRemove(event) {
                this.files = event.newState;
            },
            onProgress(event) {               
                this.files = event.newState;
            },
            onStatusChange(event) {                
                this.files = event.newState;
            },
    mapFileToDoc(documentId, file) {
      this.wetSignDocs.forEach((doc) => {
        if (doc.documentId == documentId) {
          doc.docFile = { uid: file.uid, name: file.name };
        }
      });
        },        
  },
};
</script>
