<template>
    <div class="col-md-12" style="margin-top:20px">
        <div class="users-grid">
            <div class="title-block">
                <h3>
                    <strong>Settings</strong>
                </h3>
            </div>

            <div class="row k-pb-md">
                <div class="col-xs-12">
                    <div class="card" style="border:0;">
                        <ul class="Tabs">
                            <li class="Tabs__tab Tab active" v-for="tab in tabs" :key="tab" @click="selected= tab;">
                                {{tab}}
                            </li>
                            <li class="Tabs__presentation-slider" role="presentation">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div>
                    <component :is="selected" class="tab" @updateLogo="updateLogo"></component>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    * {
        font-weight: 400;
    }

    .card {
        border-radius: 16px;
        color: #5a6574;
    }

    .details, .users {
        padding: 10px 10px 20px 10px;
    }

    .card /deep/ .card-header {
        border-bottom: inherit;
        padding: 22px 0 22px 36px;
        font: 500 1.63em/1.84em 'Arial','Open Sans',sans-serif;
        background: #fafafa;
    }

    .card /deep/ .card-content {
        padding: 22px 36px;
        margin: 0 0 25px 0;
        font: 200 .90em/1.13em 'Arial','Open Sans',sans-serif;
    }

        .card /deep/ .card-content h1 {
            font: 500 1.84em 'Arial','Open Sans',sans-serif;
        }

        .card /deep/ .card-content h2 {
            font: 500 1.54em 'Arial','Open Sans',sans-serif;
        }

        .card /deep/ .card-content h3 {
            font: 500 1.34em 'Arial','Open Sans',sans-serif;
        }

    .Tabs {
        position: relative;
        background-color: #fff;
        margin: 10px 0;
        padding: 0;
        list-style: none;
        font-family: 'Roboto Slab';
    }

        .Tabs label a:hover, .Tabs label a {
            text-decoration: none;
            color: #666;
        }

        .Tabs:after {
            content: ' ';
            display: table;
            clear: both;
        }

    .Tabs__tab {
        float: left;
        padding: 15px 20px 10px 25px;
        text-align: left;
    }

    .Tabs__presentation-slider {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10px;
        height: 4px;
        background-color: #4d97ac;
        transform-origin: 0 0;
        transition: all 0.3s ease;
        margin: 0 0 0 25px;
    }

        .Tabs__presentation-slider button {
            float: right;
            margin: -33px 7px 0 0;
            width: 20px;
            border: none;
            background: none;
        }

    .Tab > a {
        display: block;
        padding: 10px 12px;
        text-decoration: none;
        color: #666;
        transition: color .15s;
    }

    .Tab .active > a {
        color: #222;
    }

    .Tab .active:hover > a {
        color: #222;
    }

    .users > section {
        position: absolute;
        top: -999em;
        left: -999em;
    }

        .users > section:first-child {
            position: static;
        }

        .users > section h2 {
            position: initial;
            display: block;
        }

            .users > section h2:after {
                content: "";
                display: block;
                height: 3px;
                background: transparent;
                background: -moz-linear-gradient(top, rgba(182,181,181,0.25) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(182,181,181,0.25) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(182,181,181,0.25) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000', GradientType=0 ); /* IE6-9 */
            }

    /deep/ .k-toolbar .k-add-button {
        float: right;
        margin-top: 5px;
    }

    /deep/ .k-toolbar .k-add-button {
        /*position:absolute;
        right: 0;*/
        margin: -1px 2px 0 8px;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.completed /deep/ span, .status.remote /deep/ span {
            background: #19a71c;
        }

        .status.pending /deep/ span, .status.pendingsigning /deep/ span {
            background: #f8ab10;
        }

        .status.canceled /deep/ span {
            background: #ff2b2b;
        }

        .status.reviewed /deep/ span {
            background: #e960ea;
        }

        .status.pendingreview /deep/ span {
            background: #c14e4e;
        }

        .status.paper /deep/ span {
            background: #c6c6c6;
        }

        .status.signing /deep/ span, .status.signed /deep/ span {
            background: #296298;
        }

    .users > div.k-grid-content.k-auto-scrollable {
        height: 288px !important;
    }
</style>
<script>
    import ApiService from "../../core/services/api-service";
    import RolesTemplateGrid from "../../components/grids/Roles";
    import UsersTemplate from "../../components/grids/Users";
    import GeneralSettings from "../../components/GeneralSettings";
    import EmailSettings from "../../components/EmailSettings";
    import PermissionHelper from "../../core/permission-helper";
    import ProfileTemplate from "../../components/forms/Profile"
    import CertificateTemplate from '../../components/forms/invitation-steps/verify/VerifyCertificate.vue'; 
    import DeviceSetup from "../../components/forms/invitation-steps/test/SettingsSetup";
    import NotarizeActTemplateGrid from "../../components/grids/NotarizeAct";

    const PERMISSION_PREFIX = "Settings_";


    const TAB_SETTINGS = {
        VIEW_SETTINGS: "CanViewSettings",
        VIEW_GENERAL: "CanViewGeneralSettings",
        VIEW_ROLES: "CanViewRoles",
        VIEW_USERS: "CanViewUsers",
        VIEW_EMAIL_TEMPLATES: "CanEditEmailTemplate",
        VIEW_PROFILE: "CanViewProfile",
        VIEW_CERTIFICATE: "CanViewCertificate",
        VIEW_DEVICES: "CanViewDevices",
        VIEW_NOTARIZE_ACTS: "CanViewNotarizeActs"
    }

    export default {
        name: 'AdminSettings',
        components: {
            'Roles': RolesTemplateGrid,
            'Users': UsersTemplate,
            'General': GeneralSettings,
            'Emails': EmailSettings,
            'Profile Settings': ProfileTemplate,
            'Verify Digital Certificate': CertificateTemplate,
            'Devices': DeviceSetup,
            'Notarial Certificate': NotarizeActTemplateGrid
        },
        computed: {},
        mounted() {
            var self = this;
            var tabs = jQuery('.Tab'/*[' + this.$options._scopeId + ']*/);

            Array.prototype.forEach.call(tabs, function (tab) {
                tab.addEventListener('click', self.setActiveClass);
            });
            var active = jQuery('.Tab.active'/*[' + this.$options._scopeId + ']*/);
            jQuery('.Tabs__presentation-slider').css({
                left: active.position().left,
                width: active.width() + 12
            });
            $(".users > div.k-grid-content.k-auto-scrollable").each(function (e, i) {
                $(this).css({ height: 288 });
            });
        },
        created() {
            this.permissions = PermissionHelper.GetPermissions(PERMISSION_PREFIX); 
            this.setTabsBasedOnAccess();
        },
        data() {
            return {
                tabs: [],
                selected: '',
                permissions: {}
            }
        },
        methods: {
            searchGrid: function () {
                console.log(this.search);
            },
            documentView: function () {
                return true;
            },
            setActiveClass: function (e) {
                e.preventDefault();
                var tabs = jQuery('.Tab'/*[' + this.$options._scopeId + ']*/);
                jQuery(tabs).removeClass('active');

                var active = jQuery(e.currentTarget);
                active.addClass('active');
                jQuery('.Tabs__presentation-slider').css({
                    left: active.position().left,
                    width: active.width() + 12
                });

                jQuery('.users > section').css({ position: 'absolute' });
                jQuery('.users > section#' + $('label', active).attr('for')).css({ position: 'static' });
            },
            updateLogo() {
                this.$emit('updateLogo');
            },
            setTabsBasedOnAccess: function () {
                var userTabs = [];
                var firstAvailableTab = '';
                 
                if (this.permissions[TAB_SETTINGS.VIEW_SETTINGS]) {
                    if (this.permissions[TAB_SETTINGS.VIEW_GENERAL]) userTabs.push('General');
                    if (this.permissions[TAB_SETTINGS.VIEW_ROLES]) userTabs.push('Roles');
                    if (this.permissions[TAB_SETTINGS.VIEW_USERS]) userTabs.push('Users');
                    if (this.permissions[TAB_SETTINGS.VIEW_EMAIL_TEMPLATES]) userTabs.push('Emails');
                    if (this.permissions[TAB_SETTINGS.VIEW_PROFILE]) userTabs.push('Profile Settings');
                    if (this.permissions[TAB_SETTINGS.VIEW_CERTIFICATE]) userTabs.push('Verify Digital Certificate');
                    if (this.permissions[TAB_SETTINGS.VIEW_DEVICES]) userTabs.push('Devices');
                    if (this.permissions[TAB_SETTINGS.VIEW_NOTARIZE_ACTS]) userTabs.push('Notarial Certificate');
                }
                else {
                    if (this.permissions['CanViewProfile']) userTabs.push('Profile Settings');
                    if (this.permissions['CanViewCertificate']) userTabs.push('Verify Digital Certificate');
                    if (this.permissions['CanViewDevices']) userTabs.push('Devices');
                    if (this.permissions['CanViewNotarizeActs']) userTabs.push('Notarial Certificate');
                }

                if (userTabs.length > 0) firstAvailableTab = userTabs[0];
               
                this.tabs = userTabs;
                this.selected = firstAvailableTab;
            },
        },
    }
</script>