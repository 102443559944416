<template>
    <div class=" col-md-12 text-center">
        <input type="hidden" id="SpeakerID" name="SpeakerID">
        <input type="hidden" id="MicID" name="MicID">

        <div class="row" style="margin:0em 7em 1em 0em;">
            <div class="col-xs-2">
                <img id="mic_icon" src="/images/icon-mic.png" alt="" class="img-resonsive testing-icon">
            </div>
            <div class="col-xs-10 text-left">
                <h3>Microphone</h3>
            </div>
        </div>

        <div class="row" style="margin:0em 7em 1em 0em;">
            <div class="col-xs-2">
            </div>
            <div class="col-xs-10 text-left">
                <select name="" id="audioInputSource" v-model="micID" class="form-control bdr-rad-0" @change="microphoneSelected" style="min-width:15rem">
                    <option v-for="(device, index) in inputDevices" :value="device.deviceId">
                        {{ device.label || 'Microphone ' + (index + 1) }}
                    </option>
                </select>
                <p class="text-left" style="margin-top: 2em">
                    Choose a Microphone.
                </p>
            </div>
        </div>

        <div class="row" style="margin:0em 7em 1em 0em;">
            <div class="col-xs-2">
            </div>
            <div class="col-xs-10 text-left">
                <p class="text-left">
                    Speak at normal volume. If the volume indicator does not move, try selecting a different microphone or restarting your browser.
                </p>
            </div>
        </div>

        <div class="row" style="margin:0em 7em 1em 0em;">
            <div class="col-xs-2">
            </div>
            <div class="col-xs-10 text-center no-padding">
                <div class="pids-wrapper">
                    <div class="pid" style="background-color: rgb(230, 231, 232);"></div>
                    <div class="pid" style="background-color: rgb(230, 231, 232);"></div>
                    <div class="pid" style="background-color: rgb(230, 231, 232);"></div>
                    <div class="pid" style="background-color: rgb(230, 231, 232);"></div>
                    <div class="pid" style="background-color: rgb(230, 231, 232);"></div>
                    <div class="pid" style="background-color: rgb(230, 231, 232);"></div>
                    <div class="pid" style="background-color: rgb(230, 231, 232);"></div>
                </div>
            </div>
        </div>

        <div class="row" style="margin:1em 7em 1em 0em;">
            <div class="col-xs-2">
                <img id="headphone_icon" src="/images/icon-headphone.png" alt="" class="img-resonsive testing-icon">
            </div>
            <div class="col-xs-10 text-left">
                <h3>Speaker</h3>
            </div>
        </div>

        <div class="row" style="margin:0em 7em 1em 0em;">
            <div class="col-xs-2">
            </div>
            <div class="col-xs-10">
                <audio style="display:none" id="audioPreview" title="audio stream from getUserMedia()" src="/testFile.mp3" controls=""></audio>
                <select name="" id="audioOutputSource" v-model="speakerID" class="form-control bdr-rad-0" @change="speakerSelected" style="min-width:15rem">
                    <option v-for="(device, index) in outputDevices" :value="device.deviceId">
                        {{ device.label || 'Speaker ' + (index + 1) }}
                    </option>
                </select>
            </div>
        </div>

        <div class="row" style="margin:0em 7em 1em 0em;">
            <div class="col-xs-2">
            </div>
            <div class="col-xs-10">
                <p class="text-left">
                    Choose a Speaker.
                </p>
            </div>
        </div>

        <div class="row" style="margin:0em 7em 1em 0em;">
            <div class="col-xs-2">
            </div>
            <div class="col-xs-2">
                <img src="/images/loud-speaker.png" style="cursor: pointer;" alt="" @click="previewAudio">
            </div>
            <div class="col-xs-8">
                <p class="text-left">
                    Play this Sound to check your speakers. If you did not hear it, select an alternative output or check your device volume.
                </p>
            </div>
        </div>
    </div>
</template>
<style>

    #mic_icon {
        margin: 0em 1.25em 0em 0em;
    }

    #headphone_icon {
        margin: 0em 1.25em 0em 0em;
    }
</style>
<script>
    const delay = 500;

    export default {
        name: 'TestAudio',
        emits: ["audioSelected", "speakerID", "micID"],
        data() {
            return {
                deviceInfos: [],
                outputCount: 0,
                inputCount: 0,
                showVideo: false,
                selectedMic: false,
                selectedSpeaker: false,
                micID: null,
                speakerID: null,
                stream: null,
                inputDevices: [],
                outputDevices: [],
            }
        },
        mounted() {
            // code copy pasted from old mvc project as itwould take WAYYY to long to convert
            this.recheckPermissions();
        },
        methods: {
            recheckPermissions() {
                const self = this;
                clearTimeout(self.timeout);
                self.timeout = setTimeout(async () => {
                    navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then(async function (stream) {
                        let devices = await navigator.mediaDevices.enumerateDevices();
                        self.deviceInfos = devices;
                        self.inputDevices = self.deviceInfos.filter(d => d.kind === 'audioinput');
                        self.outputDevices = self.deviceInfos.filter(d => d.kind === 'audiooutput');
                        if (self.outputDevices != null || self.outputDevices.length <= 0) {
                            self.outputDevices = [{
                                deviceId: 'default',
                                kind: 'audiooutput',
                                label: 'Speakers 1'
                            }];
                        }

                        self.micID = self.inputDevices[0].deviceId;
                        self.speakerID = self.outputDevices[0].deviceId;
                        self.$emit("micID", self.micID);
                        self.$emit("speakerID", self.speakerID);
                        self.selectedMic = true;
                        self.selectedSpeaker = true;
                        self.startAudioVisualizer(stream);
                    })
                        .catch(function (err) {
                            self.recheckPermissions();
                        });
                }, delay);
            },
            microphoneSelected() {
                this.$emit("micID", this.micID);
                this.selectedMic = true;
                if (this.selectedMic)
                    this.$emit("audioSelected");

                this.stopStream();

                const constraints = { audio: { deviceId: this.micID ? { exact: this.micID } : undefined }, };

                navigator.mediaDevices.getUserMedia(constraints).then(this.startAudioVisualizer).catch(function (err) { console.log(err); });
            },
            stopStream() {
                if (window.stream) {
                    window.stream.getTracks().forEach(function (track) {
                        track.stop();
                    });
                }
            },
            speakerSelected() {
                this.$emit("speakerID", this.speakerID);
                this.selectedSpeaker = true;
                if (this.selectedMic)
                    this.audioSelected();
            },
            audioSelected() {
                this.$emit("audioSelected");

                var output = $('#audioPreview')[0];
                output.setSinkId(this.speakerID);
            },
            startAudioVisualizer(stream) {
                const self = this;
                window.stream = stream;
                var audioContext = new AudioContext();
                var analyser = audioContext.createAnalyser();
                var microphone = audioContext.createMediaStreamSource(stream);
                var javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

                analyser.smoothingTimeConstant = 0.8;
                analyser.fftSize = 1024;

                microphone.connect(analyser);
                analyser.connect(javascriptNode);

                javascriptNode.connect(audioContext.destination);
                javascriptNode.onaudioprocess = function (e) {

                    var array = new Uint8Array(analyser.frequencyBinCount);
                    analyser.getByteFrequencyData(array);
                    var values = 0;

                    var length = array.length;
                    for (var i = 0; i < length; i++) {
                        values += (array[i]);
                    }

                    var average = values / length;

                    //console.log(e.inputBuffer);
                    self.colorPids(average);
                }
            },
            colorPids(vol) {
                var all_pids = $('.pid');
                var amout_of_pids = Math.round(vol / 7);
                var elem_range = all_pids.slice(0, amout_of_pids)
                for (var i = 0; i < all_pids.length; i++) {
                    all_pids[i].style.backgroundColor = "#e6e7e8";
                }
                for (var i = 0; i < elem_range.length; i++) {

                    // console.log(elem_range[i]);
                    elem_range[i].style.backgroundColor = "#69ce2b";
                }
            },
            previewAudio() {
                var audio = $('#audioPreview')[0];
                if (this.speakerID !== 'default')
                    audio.setSinkId(this.speakerID);
                audio.play();
            },
        },
    }
</script>
<style scoped>

    .pids-wrapper {
        width: 100%;
    }

    .pid {
        width: calc(10% - 10px);
        height: 10px;
        display: inline-block;
        margin: 5px;
    }
</style>