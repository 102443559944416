<template>
    <kendo-datasource ref="participantsource"
                      :transport-read="readData"
                      :transport-update="updateData"
                      :transport-create="createData"
                      :transport-destroy="deleteData"
                      :schema-model-id="'participantId'"
                      :schema-model-fields="schema"
                      :transport-parameter-map="parameterMap">
    </kendo-datasource>

    <kendo-grid :data-source-ref="'participantsource'"
                ref="kendoJqueryGrid"
                :class="'participants'"
                :sortable="true"
                :edit="onEdit"
                :resizable="true"
                :navigatable="true"
                :pdf-all-pages="true"
                :pdf-avoid-links="true"
                :editable="packagePopUpTemplate"
                :pdf-paper-size="'A4'"
                :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                :pdf-landscape="true"
                :pdf-repeat-headers="true"
                :pdf-scale="0.8"
                :columns="columns"
                @databound="documentDataBound"
                @save="onPopUpModalSaved">
        <toolbar>
            <button title="Add New"
                    class="k-button k-primary k-add-button particiant-add"
                    @click="participantAdd">
                Add
            </button>
            <div style="margin: 0 0px 0 auto;" v-if="canAdd">
                <button title="Export to Excel"
                        class="k-button k-primary mr-1"
                        @click="exportExcel">
                    Export to Excel
                </button>&nbsp;
                <button class="k-button k-primary" @click="exportPDF">
                    Export to PDF
                </button>
            </div>
        </toolbar>
        <!--<kendo-grid-column :field="'participantID'" :title="'ID#'" :width="100"></kendo-grid-column>-->
        <!--<kendo-grid-column :field="'participantName'" :title="'Participant Name'" :width="240"></kendo-grid-column>
            <kendo-grid-column :field="'email'" :title="'Email Address'" :width="200"></kendo-grid-column>
            <kendo-grid-column :field="'role'" :title="'Role'" :width="100"></kendo-grid-column>
            <kendo-grid-column :field="'notaryStatus'" :title="'Notary Status'" :width="160"></kendo-grid-column>
            <kendo-grid-column :field="'participantStatus'" :title="'Status'" :width="160" :template="participantTemplate"></kendo-grid-column>
            <kendo-grid-column :command="[
                    { className: 'participant-cmd', text: 'Send Invite', click: participantInvite, visible: function (e) {
                               console.log('partcommand', e);
                        return true;
                    } },
                    { className: 'participant-cmd', text: 'Edit', click: participantEdit, visible: function (e) { return true; } },
                    { className: 'participant-cmd', text: 'Delete', click: participantDelete, visible: function (e) { return true; } },
                    { className: 'participant-cmd', text: 'Assign Notary', click: participantNotary, visible: function (e) { return true; } }]" :title="'&nbsp;'" :width="280"></kendo-grid-column>-->
    </kendo-grid>
    <div v-show="false">
        <pdfexport ref="gridPdfExport">
            <native-grid :columns="exportColumns" />
        </pdfexport>
    </div>
</template>
<style scoped>
    * {
        font-weight: 400;
    }

    /deep/ .participant-cmd,
    /deep/ .participant-cmd:hover,
    /deep/ .participant-cmd::before {
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: unset;
        background: initial;
        border: none;
        border-radius: unset;
        transition: none;
        -webkit-transition: none;
        padding: 8px 5px;
        font: 900 0.94em "Lato", sans-serif;
        color: #4d97ac;
    }

    .mr-1 {
        margin-right: 10px;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.invitation_pending /deep/ span, .status.invitation_sent /deep/ span, .status.invitation_opened /deep/ span, .status.standby /deep/ span {
            background: #f3a638;
        }

        .status.package_reviewed /deep/ span, .status.package_reviewed_and_signed /deep/ span, .status.witnessed /deep/ span, .status.observed /deep/ span, .status.no_action_required /deep/ span {
            background: #54b7d3;
        }

        .status.invitation_expired /deep/ span {
            background: #E3D4D4;
        }

        .status.econsent_accepted /deep/ span, .status.docs_to_be_signed /deep/ span {
            background: #4cb64c;
        }

        .status.econsent_rejected /deep/ span, .status.opted_out /deep/ span {
            background: #e3503e;
        }

    .hideme {
        color: pink !important;
    }
</style>
<script>
    import { useRoute } from 'vue-router';
    import ApiService from "@/core/services/api-service";
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { GridPdfExport } from '@progress/kendo-vue-pdf';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { process } from '@progress/kendo-data-query';
    import KendoPopupGenerator from '@/core/services/kendo-grid-popup-form-generator.js';
    import Swal from 'sweetalert2';
    import GridDataHelper from '../../core/services/grid-data-helper';
    import PermissionHelper from "../../core/permission-helper";


    const PART_PERMISSIONS = {
        ADD: "Participant_CanAddParticipant",
        EDIT: "Participant_CanEditParticipant",
        EMAIL: "Participant_CanSendEmailInvite",
        DELETE: "Participant_CanDeleteParticipant",
    }

    const PREFIX = "Loan_";
    const LOANOFFICER = "LoanOfficer";
    const TITLEAGENT = "TitleAgent";
    const SETTLEMENTAGENT = "SettlementAgent";
    const NOTARY = "Notary";

    export default {
        name: "PackageParticipants",
        props: ["packageide", "pnotarizeId", "type", "setfilter", "clientRoles", "packageNotary"],
        components: {
            "kendo-grid": Grid,
            "kendo-grid-column": GridColumn,
            "kendo-datasource": KendoDataSource,
            pdfexport: GridPdfExport,
            toolbar: GridToolbar,
            dropdownlist: DropDownList,
            "native-grid": NativeGrid,
        },
        async created() {
            this.permissions = PermissionHelper.GetPermissions(PREFIX);
            let response = await ApiService.GenerateGetRequest(`admin/GetParticipantUsers`);
            if (response["isSuccess"])
                this.dropdownUsers = response["content"];

            response = await ApiService.GenerateGetRequest("Package/notaries");
            if (response["isSuccess"])
                response.content.forEach((notary) => {
                    this.dropdownUsers.push({
                        ...notary,
                        emailAddress: notary.email,
                        query: "Notary",
                        roleName: 'Notary',
                        applicationUserId: notary.appUserId,
                        name: notary.notary,
                    });
                    console.log("notary", response.content.length, notary.appUserId);
                });

            response = await ApiService.GenerateGetRequest(`Package/GetParticipantRoles`);
            if (response["isSuccess"])
                this.rolesForParticipant = response["content"];


        },
        data(component) {

            return {
                schema: {
                    participantId: { editable: false },
                    participantMappingId: { editable: false, hidden: true },
                    loanID: { type: "int", editable: false },
                    packageID: { type: "int", editable: false },
                    notarizeID: { type: "int", editable: false },
                    firstName: { type: "string", editable: true },
                    middleName: { type: "string", editable: true },
                    lastName: { type: "string", editable: true },
                    fullName: { type: "string" },
                    emailAddress: { type: "string", editable: true },
                    mobileNumber: { type: "number", editable: true },
                    participantType: { type: "string", editable: true },
                    notaryStatus: { type: "string", editable: false },
                    participantStatus: { type: "string", editable: false },
                    SSN: { type: "string", editable: true },

                    // invitationStatus: { type: "string", editable: false },
                },
                columns: [
                    {
                        template: this.fullNameTemplate,
                        width: "150px",
                        field: "fullName",
                        title: "Participant Name",
                    },
                    { field: "emailAddress", width: "300px", title: "Email" },
                    { field: "participantType", width: "130px", title: "Role", template: this.roleTemplate },
                    {
                        field: "participantStatus",
                        title: "Participant Status",
                        width: "150px",
                        template: this.participantTemplate,
                        headerAttributes: {
                            style: "text-align: center!important",
                        },
                    },
                    {
                        title: "",
                        width: "470px",
                        command: [
                            {
                                className: "participant-cmd",
                                text: "Edit",
                                click: this.participantEdit,
                                visible: function (e) {
                                    return true;
                                },
                            },
                            {
                                className: "participant-cmd",
                                text: "Delete",
                                click: this.participantDelete,
                                visible: function (dataItem) {
                                    return true;
                                },
                            },
                            {
                                className: "participant-cmd",
                                text: " Send Email Invite",
                                click: this.sendEmailInvite,
                                visible: function (e) {
                                    if (e.participantType == "LoanOfficer" || e.participantType == "TitleAgent" || e.participantType == "SettlementAgent") return false;
                                    return true;
                                },
                            },
                            {
                                className: "participant-cmd",
                                text: "Copy Invite Link",
                                click: this.copyInviteLink,
                                visible: function (e) {
                                    if (e.participantType == "LoanOfficer" || e.participantType == "TitleAgent" || e.participantType == "SettlementAgent") return false;
                                    return true;
                                },
                            },
                            {
                                className: "participant-cmd",
                                text: "Reset Attempts",
                                click: this.resetKbaAttempts,
                                visible: function (e) {
                                    if (e.participantType == "LoanOfficer" || e.participantType == "TitleAgent" || e.participantType == "SettlementAgent") return false;
                                    return true;
                                },
                            },
                            {
                                className: "participant-cmd",
                                text: "Revert Opt Out",
                                click: this.revertOptOut,
                                visible: function (dataItem) {
                                    return dataItem.participantStatus == "Opted_Out"
                                },
                            },
                        ],
                    },
                ],
                exportColumns: [
                    { field: "fullName", title: "Participant Name" },
                    { field: "emailAddress", width: "180px", title: "Email" },
                    { field: "participantType", width: "100px", title: "Role", template: this.roleTemplate },
                    {
                        field: "participantStatus",
                        title: "Participant Status",
                        width: "100px",
                        template: this.participantTemplate,
                        headerAttributes: {
                            style: "text-align: center!important",
                        },
                    },
                ],
                userId: 0,
                participant: {},
                currentRecordEditing: null,
                dropdownUsers: [],
                rolesForParticipant: [],

                // variables to see which documents to remove or add to participant
                existingDocumentsForParticipant: [],
                documentsToAddForParticipant: [],
                documentsToRemoveForParticipant: [],
                gridItems: [],
                permissions: {},

                selectedNotary: null
            };
        },
        computed: {
            packagePopUpTemplate(e) {
                let html = "";
                html += "<div id='ParticipantsGrid'>";
                html += "   <div class='col-md-6'>";
                var array = [
                    { Id: "Signer", Text: "Signer" },
                    { Id: "Observer", Text: "Observer" },
                   //  { Id: "LoanOfficer", Text: "Loan Officer" },
                    //{ Id: "TitleAgent", Text: "Title Agent" },
                    { Id: "Legal_Witness", Text: "Legal Witness" },
                    { Id: "Notary", Text: "Notary" },
                ]

                html += KendoPopupGenerator.generatePopUpFormDropdown(
                    "ParticipantType",
                    "Role",
                    "participantType",
                    array,
                    "Id",
                    "Text"
                );


                html += KendoPopupGenerator.generatePopUpFormDropdown(
                    "User",
                    "User",
                    "user",
                    [
                    ],
                    "Id",
                    "Text",
                    false
                );
                html += KendoPopupGenerator.generatePopUpFormFields(
                    "FirstName",
                    "First Name",
                    "firstName"
                );
                html += KendoPopupGenerator.generateMiddlePopUpFormFields(
                    "MiddleName",
                    "Middle Name",
                    "middleName"
                );
                html += KendoPopupGenerator.generatePopUpFormFields(
                    "LastName",
                    "Last Name",
                    "lastName"
                );
                html += KendoPopupGenerator.generateEmailPopUpFormFields(
                    "Email",
                    "Email",
                    "emailAddress"
                );
                html += KendoPopupGenerator.generateNumberPopUpFormFields(
                    "PhoneNumber",
                    "Phone Number",
                    "mobileNumber"
                );
                html += KendoPopupGenerator.generatePopUpFormFields(
                    "SSN",
                    "SSN",
                    "ssn",
                    false
                );
                html += "   </div>";
                html += `   <div class='right-grid col-md-6'>
                                <h3>Assign Documents</h3>
                                <ul class="list-group">`;

                html += `        </ul>
                            </div>`;
                html += "</div>";
                return { 'template': html, 'mode': 'popup', width: "600px", height: "300px", }
            },
            canAdd: function () { return this.permissions[PART_PERMISSIONS.ADD] },
            canEdit: function () { return this.permissions[PART_PERMISSIONS.EDIT] },
            canEmail: function () { return this.permissions[PART_PERMISSIONS.EMAIL] },
            canDelete: function () { return this.permissions[PART_PERMISSIONS.DELETE] },
        },
        methods: {
            reloadParent: function () {
                this.$emit('reload-parent');
            },
            participantTemplate(e) {
                var status = GridDataHelper.normalizeStatus(e.participantStatus);
                status = (e.participantType == "LoanOfficer" || e.participantType == "SettlementAgent" || e.participantType == "UnderWriter" || e.participantType == "Notary") ? status.replace("Invitation ", "") : status;
                var html = `<div class="status ` + e.participantStatus.toLowerCase() + `" ` + this.$options.__scopeId + `><span>` + status + `</span></div>`;
                return html;
            },
            fullNameTemplate(e) {
                return e.firstName + (e.middleName || " ") + e.lastName;
            },
            roleTemplate(e) {
                return GridDataHelper.normalizeStatus(e.participantType);
            },
            onPopUpModalSaved(e) {
                console.log("onPopUpModalSaved", e);
            },
            sendInvite: function (e) {
                console.log('sendInvite', e)
            },
            participantAdd() {
                this.$refs.kendoJqueryGrid.kendoWidget().addRow();
            },
            participantInvite() {
                console.log("clicked");
            },
            sendEmailInvite(event) {
                if (!this.canEmail) return;
                event.preventDefault();
                var rowData = this.$refs.kendoJqueryGrid.kendoWidget().dataItem($(event.target).closest("tr"));

                return ApiService.GeneratePostRequest(
                    `package/participants/send_invite`,
                    {
                        ParticipantId: rowData["participantId"],
                        AdminUserid: this.$store.state.Admin.loginInformation.userId, // todo get from api when integrated from miguel
                    }
                ).then((response) => {
                    if (response["isSuccess"]) {
                        rowData["participantStatus"] = response["content"];
                        this.$refs.kendoJqueryGrid.kendoWidget().refresh();
                        Swal.fire({
                            title: "Invite sent!",
                            text: "",
                            icon: "success",
                            confirmButtonText: "Ok",
                        });

                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "There was an error sending the invite",
                            icon: "error",
                            confirmButtonText: "Ok",
                        });
                    }
                });
            },
            copyInviteLink(event) {
                event.preventDefault();
                var rowData = this.$refs.kendoJqueryGrid
                    .kendoWidget()
                    .dataItem($(event.target).closest("tr"));
                navigator.clipboard.writeText(rowData["invitationLink"]);
                let el = $(event.target);
                el.text("Link Copied!");
                setTimeout(() => {
                    el.text("Copy Invite Link");
                }, 3000);
            },
            resetKbaAttempts(event) {
                event.preventDefault();
                var rowData = this.$refs.kendoJqueryGrid.kendoWidget().dataItem($(event.target).closest("tr"));
                var payload = {
                    "SignerId": rowData["participantMappingId"],
                    "NotarizeId": rowData["notarizeId"]
                };
                return ApiService.GeneratePostRequest(`verify/reset_attempts`, payload)
                    .then((response) => {
                        if (response["isSuccess"]) {
                            Swal.fire({
                                title: "Number of attempts reset!",
                                text: "",
                                icon: "success",
                                confirmButtonText: "Ok",
                            });
                        }
                        else {
                            Swal.fire({
                                title: "Error",
                                text: "There was an error resetting the number of attempts",
                                icon: "error",
                                confirmButtonText: "Ok",
                            });
                        }
                    });
            },
            participantEdit(event) {
                if (!this.canEdit) return;
                console.log("clicked");
                event.preventDefault();
                this.$refs.kendoJqueryGrid
                    .kendoWidget()
                    .editRow($(event.target).closest("tr"));
                this.currentRecordEditing = this.$refs.kendoJqueryGrid
                    .kendoWidget()
                    .dataItem($(event.target).closest("tr"));
            },
            participantDelete(event) {
                if (!this.canDelete) return;
                console.log("delete click");
                event.preventDefault();
                this.$refs.kendoJqueryGrid
                    .kendoWidget()
                    .removeRow($(event.target).closest("tr"));
            },
            participantNotary() {
                console.log("clicked");
            },
            allowInvitePermission: function (e) {
                console.log("canInviteParticipant", e);
                return true;
            },
            documentDataBound: function (e) {
                var self = this;
                var grid = e.sender;

                if (this.type == "notary") {
                    grid.element.find(".participant-cmd").hide();
                }

                var items = e.sender.items();
                /*var dataItem = grid.dataItem(this);
                this.reloadParent();*/

                console.log("SELF", this);
                console.log("Can Edit", this.canEdit);

                items.each(function (index, item) {
                    var dataItem = grid.dataItem(item);
                    console.log("DATA", dataItem);
                    console.log("SELF", self);
                    console.log("Can Edit", self.canEdit);

                    var row = $("tr[data-uid='" + dataItem.uid + "']");

                    if (!self.canEdit) {
                        row.find(".k-grid-Edit").remove();
                    }
                    if (!self.canDelete) {
                        row.find(".k-grid-Delete").remove();
                    }

                    if (dataItem.participantType == LOANOFFICER || dataItem.participantType == NOTARY || dataItem.participantType == TITLEAGENT || dataItem.participantType == SETTLEMENTAGENT || !self.canEmail) {
                        row.find(".k-grid-SendEmailInvite").remove();
                    }

                    if (dataItem.participantType == LOANOFFICER ||  dataItem.participantType == NOTARY || dataItem.participantType == TITLEAGENT || dataItem.participantType == SETTLEMENTAGENT) {
                        row.find(".k-grid-CopyInviteLink").remove();
                    }
                    if (dataItem.participantType == NOTARY) {
                        row.find(".k-grid-Edit").remove();
                        row.find(".k-grid-ResetAttempts").remove();
                    }
                });
            },
            parameterMap: function (options, operation) {
                if (operation !== "read" && options.models) {
                    return { models: kendo.stringify(options.models) };
                }
            },
            readData: function (e) {
                //console.log(e)
                console.log("Called Reading");
                const route = useRoute();
                return ApiService.GenerateGetRequest(
                    `package/${this.packageide}/participants`
                ).then((response) => {
                    console.log("Called Reading participant", response);

                    let gridItems = response["content"];
                    if (this.setfilter == "other") {
                        gridItems = gridItems.filter(
                            (x) => x.participantType != "Signer"
                        );
                    } else if (this.setfilter == "signer") {
                        gridItems = gridItems.filter(
                            (x) => x.participantType == "Signer"
                        );
                    }
                    e.success(gridItems);
                    console.log("gridItems", gridItems);
                });

            },
            createData: function (e) {
                if (!this.canAdd) return;
                // first check if creating notary - reuse existing endpoint. if no notary exists in current add, else show warning then call change api
                var rows = this.$refs.kendoJqueryGrid.kendoWidget().dataItems();
                //for (let index = 0; index < rows; index++) {
                //    let participantType = rows[index]["participantType"];
                //    if (participantType === "Notary")
                //}
                console.log("Called creating", e);
                let data = e.data;
                const self = this;

                if (e.data["participantType"] == "Notary") {
                    let formData = new FormData();
                    formData.append("LoanPackageId", this.packageide); 
                    formData.append("AppUserID", data.user);
                    formData.append("UserID", this.selectedNotary.userID);
                    formData.append("Name", data.firstName + " " + data.lastName);
                    formData.append("AdminUserId", this.$store.state.Admin.loginInformation.userId);
                    function handleNotaryUpdate() {
                        ApiService.GeneratePostRequest("package/notary/update", formData, { "Content-Type": "multipart/form-data" }).then(
                            response => {
                                if (!response.isSuccess) {
                                    Swal.fire(
                                        "Operation failed",
                                        response.message,
                                        "error"
                                    );
                                }
                                else {
                                    self.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
                                    Swal.fire(
                                        'Notary updated',
                                        '',
                                        'success'
                                    );
                                }
                            }
                        );
                    }
                    handleNotaryUpdate();
                    return;
                }





                //let gridItems = this.$refs.kendoJqueryGrid.kendoWidget().dataSource.data();
                //let gridItems = this.loadGrid();
                let gridItems = "";
                ApiService.GenerateGetRequest(
                    `package/${this.packageide}/participants`
                ).then((response) => {


                    gridItems = response["content"];

                    let noDuplicates = true;

                    for (var i = 0; i < gridItems.length; i++) {
                        if ((gridItems[i].firstName && gridItems[i].firstName.toLowerCase() == data.firstName.toLowerCase()) &&
                            (gridItems[i].middleName && gridItems[i].middleName.toLowerCase() == data.middleName.toLowerCase()) &&
                            (gridItems[i].lastName && gridItems[i].lastName.toLowerCase() == data.lastName.toLowerCase())) {
                            noDuplicates = false;

                        }
                    }

                    if (!noDuplicates) {
                        Swal.fire({
                            title: "Duplicate Record Detected",
                            icon: "warning",
                            html: "You've added a record which already exists, do you want to continue?",
                            showDenyButton: true,
                            confirmButtonText: "Continue",
                            confirmButtonColor: "Green",
                            denyButtonText: "Cancel"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.userId = this.$store.state.Admin.loginInformation.userId;
                                let postData = {
                                    firstName: data.firstName,
                                    middleName: data.middleName,
                                    lastName: data.lastName,
                                    emailAddress: data.emailAddress,
                                    mobileNumber: data.mobileNumber,
                                    participantType: data.participantType,
                                    adminUserId: this.$store.state.Admin.loginInformation.userId, // get this from api when created
                                    packageId: this.packageide,
                                    notarizeId: this.pnotarizeId,
                                    ssn: data.ssn,

                                };


                                ApiService.GeneratePostRequest(`package/participants/add`, postData).then(
                                    (response) => {
                                        console.log("creating successful participant", response);
                                        e.success(response["content"]);
                                        if (response["isSuccess"]) {
                                            let participantId = response["content"]["participantId"];

                                            let data = {
                                                documentId: 0,
                                                participantId: participantId,
                                                adminUserId: this.$store.state.Admin.loginInformation.userId,
                                            };
                                            if (this.documentsToAddForParticipant.length > 0) {
                                                this.documentsToAddForParticipant.forEach((id) => {
                                                    data.documentId = id;
                                                    ApiService.GeneratePostRequest(
                                                        `package/document/participants/add`,
                                                        data
                                                    ).then((response) => {
                                                        if (!response.isSuccess) {
                                                            Swal.fire({
                                                                title: 'Failed to assign participant to document',
                                                                text: response.message,
                                                                icon: 'error',
                                                                confirmButtonText: 'Ok'
                                                            });
                                                        }
                                                    });
                                                });
                                            }

                                            this.currentRecordEditing = null;
                                            this.documentsToAddForParticipant = [];
                                            Swal.fire("Success", "Duplicate participant added sucessfully", "success");
                                            this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
                                            this.$emit('readLog', this.packageide);
                                        } else {
                                            Swal.fire(
                                                "Participant could not be added",
                                                response["message"],
                                                "error"
                                            );
                                        }
                                    });
                            } else if (result.isDenied) {
                                Swal.fire('Duplicate participant not added', '', 'info')
                                this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
                            }
                        });
                    } else {
                        this.userId = this.$store.state.Admin.loginInformation.userId;
                        let postData = {
                            firstName: data.firstName,
                            middleName: data.middleName,
                            lastName: data.lastName,
                            emailAddress: data.emailAddress,
                            mobileNumber: data.mobileNumber,
                            //forcing to Observer or signer as other roles added which will break.

                            participantType: data.participantType,

                            adminUserId: this.$store.state.Admin.loginInformation.userId, // get this from api when created
                            packageId: this.packageide,
                            notarizeId: this.pnotarizeId,
                            ssn: data.ssn
                        };

                        if (data.participantType !== "Observer" && data.participantType !== "Signer" && data.participantType !== "Legal_Witness") {
                            postData["applicationUserId"] = parseInt($("#ParticipantsGrid #User").val());
                            switch (data.participantType) {
                                case "Observer":
                                    postData["roleId"] = self.rolesForParticipant.find((x) => x["roleName"] === "Observer")["roleId"];
                                    break;
                                case "Signer":
                                    postData["roleId"] = self.rolesForParticipant.find((x) => x["roleName"] === "Signer")["roleId"];
                                    break;
                                case "Legal_Witness":
                                    postData["roleId"] = self.rolesForParticipant.find((x) => x["roleName"] === "Signer")["roleId"];
                                    break;
                                case "LoanOfficer":
                                    postData["roleId"] = self.rolesForParticipant.find((x) => x["roleName"] === "Loan Officer")["roleId"];
                                    break;
                                case "TitleAgent":
                                    postData["roleId"] = self.rolesForParticipant.find((x) => x["roleName"] === "Title Agent")["roleId"];
                                    break;
                                case "SettlementAgent":
                                    postData["roleId"] = self.rolesForParticipant.find((x) => x["roleName"] === "Settlement Agent")["roleId"];
                                    break;
                            }

                        }
                        ApiService.GeneratePostRequest(`package/participants/add`, postData).then(
                            (response) => {
                                console.log("creating successful participant", response);
                                e.success(response["content"]);
                                if (response["isSuccess"]) {
                                    let participantId = response["content"]["participantId"];

                                    let data = {
                                        documentId: 0,
                                        participantId: participantId,
                                        adminUserId: this.$store.state.Admin.loginInformation.userId,
                                    };

                                    if (this.documentsToAddForParticipant.length > 0) {
                                        this.documentsToAddForParticipant.forEach((id) => {
                                            data.documentId = id;
                                            ApiService.GeneratePostRequest(
                                                `package/document/participants/add`,
                                                data
                                            ).then((response) => {
                                                if (!response.isSuccess) {
                                                    Swal.fire({
                                                        title: 'Failed to assign participant to document',
                                                        text: response.message,
                                                        icon: 'error',
                                                        confirmButtonText: 'Ok'
                                                    });
                                                }
                                            });
                                        });
                                    }

                                    this.currentRecordEditing = null;
                                    this.documentsToAddForParticipant = [];
                                    Swal.fire("Success", "Participant added sucessfully", "success");
                                    this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
                                    this.$emit('readLog', this.packageide);
                                } else {
                                    Swal.fire(
                                        "Participant could not be added",
                                        response["message"],
                                        "error"
                                    );
                                }
                            });
                    }
                });
            },
            updateData: function (e) {
                if (!this.canEdit) return;
                console.log("updating", e);
                if (this.currentRecordEditing) {
                    let data = e.data;
                    this.userId = this.$store.state.Admin.loginInformation.userId;
                    let postData = {
                        firstName: data.firstName,
                        middleName: data.middleName,
                        lastName: data.lastName,
                        emailAddress: data.emailAddress,
                        mobileNumber: data.mobileNumber,
                        participantId: this.currentRecordEditing.participantId,
                        adminUserId: this.$store.state.Admin.loginInformation.userId, // get this from api when created
                        ssn: data.ssn
                    };
                    return ApiService.GeneratePostRequest(
                        `package/participants/update`,
                        postData
                    ).then((response) => {
                        e.success(response["content"]);

                        // execute APIs to remove or add documents to particiapnt
                        console.log("update return data", response);
                        console.log(
                            "doing document deletes",
                            this.documentsToRemoveForParticipant
                        );
                        if (response["isSuccess"]) {
                            let participantId = this.currentRecordEditing.participantId;

                            let data = {
                                documentId: 0,
                                participantId: participantId,
                                adminUserId: this.$store.state.Admin.loginInformation.userId,
                            };
                            if (this.documentsToRemoveForParticipant.length > 0) {
                                this.documentsToRemoveForParticipant.forEach((id) => {
                                    data.documentId = id;
                                    ApiService.GeneratePostRequest(
                                        `package/document/participants/remove`,
                                        data
                                    ).then((response) => {
                                        if (!response.isSuccess) {
                                            Swal.fire({
                                                title: 'Failed to unassign participant to document',
                                                text: response.message,
                                                icon: 'error',
                                                confirmButtonText: 'Ok'
                                            });
                                        }
                                    });
                                });
                            }
                            if (this.documentsToAddForParticipant.length > 0) {
                                this.documentsToAddForParticipant.forEach((id) => {
                                    data.documentId = id;
                                    ApiService.GeneratePostRequest(
                                        `package/document/participants/add`,
                                        data
                                    ).then((response) => {
                                        if (!response.isSuccess) {
                                            Swal.fire({
                                                title: 'Failed to assign participant to document',
                                                text: response.message,
                                                icon: 'error',
                                                confirmButtonText: 'Ok'
                                            });
                                        }
                                    });;
                                });
                            }

                            this.currentRecordEditing = null;
                            this.documentsToRemoveForParticipant = [];
                            this.documentsToAddForParticipant = [];
                            Swal.fire("Success", "Participant updated sucessfully", "success");
                            this.$emit('readLog', this.packageide);
                        } else {
                            Swal.fire(
                                "Operation failed",
                                "Participant could not be updated",
                                "error"
                            );
                        }
                    });
                }
            },
            deleteData: function (e) {
                if (!this.canDelete) return;
                console.log("Deleting", e);
                let postData = {
                    participantId: e.data.participantId,
                    adminUserId: this.$store.state.Admin.loginInformation.userId, // get this from api when created
                };
                return ApiService.GeneratePostRequest(
                    `package/participants/remove`,
                    postData
                ).then((response) => {
                    console.log("delete response", response);
                    if (response["isSuccess"]) {
                        e.success(response);
                        Swal.fire("Success", "Participant deleted sucessfully", "success");
                        this.$emit('readLog', this.packageide);
                    } else {
                        this.$refs.kendoJqueryGrid.kendoWidget().cancelChanges();
                        Swal.fire(
                            "Operation failed",
                            response.message,
                            "error"
                        );
                    }
                });
            },
            revertOptOut: function (e) {
                var dataItem = this.$refs.kendoJqueryGrid.kendoWidget().dataItem($(e.target).closest("tr"));
                console.log("reversing opt out for", dataItem);
                Swal.fire({
                    title: "Revert Opt Out",
                    icon: "warning",
                    text: `Please confirm you want to revert opt out for ${dataItem.firstName} ${dataItem.lastName}`,
                    showCancelButton: true,
                    confirmButtonText: "Confirm",
                    confirmButtonColor: "Green",
                }).then(res => {
                    if (res.isConfirmed) {
                        ApiService.GeneratePostRequest(
                            `package/revert_opt_out?packageId=${this.packageide}&participantId=${dataItem.participantId}`
                        ).then((response) => {
                            console.log("reverse opt out response", response);
                            if (response["isSuccess"]) {
                                Swal.fire("Success", `Opt Out has been successfully reverted for ${dataItem.firstName} ${dataItem.lastName}`, "success").then(res => {
                                    this.$router.go();
                                });
                            } else {
                                this.$refs.kendoJqueryGrid.kendoWidget().cancelChanges();
                                Swal.fire(
                                    "Operation failed",
                                    response.message,
                                    "error"
                                );
                            }
                        });
                    }
                });

            },
            exportExcel() {
                var self = this;
                this.readData({
                    success: function (arr) {
                        var options = {
                            skip: 0,
                        };
                        saveExcel({
                            data: process(arr, options).data,
                            fileName: "loanpackages",
                            columns: self.columns,
                        });
                    },
                });
            },
            searchGrid: function () {
                console.log(this.search);
            },
            loadGrid() {
                const kdataSource = this.$refs.participantsource;
                console.table(dataSource);
                var grid = this.$refs.kendoJqueryGrid.kendoWidget();
                grid.dataSource(kdataSource);

            },
            exportPDF() {
                var self = this;
                this.readData({
                    success: function (arr) {
                        var options = {
                            skip: 0,
                        };
                        const itemsToExport = process(arr, options).data;
                        self.$refs.gridPdfExport.save(itemsToExport);
                        return arr;
                    },
                });
            },
            async onEdit(e) {
                if (!this.canEdit) return;
                const self = this;
                function disableFields(boolean, selectedOption, clearField = true) {
                    // disable fields make it read only
                    $("#ParticipantsGrid #FirstName").prop('disabled', boolean);
                    if (clearField) $("#ParticipantsGrid #FirstName").val('');

                    $("#ParticipantsGrid #MiddleName").prop('disabled', boolean);
                    if (clearField) $("#ParticipantsGrid #MiddleName").val('');

                    $("#ParticipantsGrid #LastName").prop('disabled', boolean);
                    if (clearField) $("#ParticipantsGrid #LastName").val('');

                    $("#ParticipantsGrid #Email").prop('disabled', boolean);
                    if (clearField) $("#ParticipantsGrid #Email").val('');

                    $("#ParticipantsGrid #PhoneNumber").prop('disabled', boolean);
                    if (selectedOption == "Signer") {
                        $("#ParticipantsGrid #PhoneNumber").prop('required', true);
                    } else {
                        $("#ParticipantsGrid #PhoneNumber").prop('required', false);
                    }

                    if (clearField) $("#ParticipantsGrid #PhoneNumber").val('');

                    $("#ParticipantsGrid #SSN").prop('disabled', boolean);
                    if (clearField) $("#ParticipantsGrid #SSN").val('');
                    boolean ? $("#ParticipantsGrid #User").hide() : $("#ParticipantsGrid #User").show();
                    boolean ? $("#ParticipantsGrid label[for='User']").parent().hide() : $("#ParticipantsGrid label[for='User']").parent().show();
                }

                if (this.clientRoles.length > 0) {
                    var loanOfficer = this.clientRoles.find(x => x.name == "Loan Officer");
                    var settlementAgent = this.clientRoles.find(x => x.name == "Settlement Agent");

                    $("#ParticipantsGrid #ParticipantType").append("<option value='" + loanOfficer.participantType + "'>" + loanOfficer.name + "</option>")
                    $("#ParticipantsGrid #ParticipantType").append("<option value='" + settlementAgent.participantType + "'>" + settlementAgent.name + "</option>")
                }
                if (e.model.isNew()) {
                    $(".k-window-title").text("Add");
                    disableFields(true);


                    // global variable for listeners to take into account
                    let searchVal = "";
                    $("#ParticipantsGrid #ParticipantType").on("change", function () {
                        let selectedOption = $(this).val();

                        //attach a validator to the container and get a reference
                        var validator = $("#ParticipantsGrid").kendoValidator({
                            validationSummary: false
                        }).data("kendoValidator");
                        //trigger validation
                        validator.validate();
                        //reset
                        validator.reset();
                        if (selectedOption !== "Signer" && selectedOption !== "Observer" && selectedOption !== "Legal_Witness") {
                            disableFields(true, selectedOption);

                            $("#ParticipantsGrid #User").show();
                            $("#ParticipantsGrid label[for='User']").parent().show();
                            switch (selectedOption) {
                                case "LoanOfficer":
                                    searchVal = "Loan Officer";
                                    break
                                case "TitleAgent":
                                    searchVal = "Title Agent";
                                    break

                                case "SettlementAgent":
                                    searchVal = "Settlement Agent";
                                    break
                                case "Notary":
                                    searchVal = "Notary";
                                    break
                            }
                            let html = "<option value=''>Select a user</option>";
                            for (let i = 0; i < self.dropdownUsers.length; i++) {
                                if (self.dropdownUsers[i]["roleName"] === searchVal) {
                                    if (searchVal == "Notary" && !self.dropdownUsers[i].userID)
                                        continue;
                                    let fullName = self.dropdownUsers[i]["notary"];
                                    if (self.dropdownUsers[i]["firstName"] && self.dropdownUsers[i]["lastName"])
                                        fullName = self.dropdownUsers[i]["firstName"] + " " + self.dropdownUsers[i]["lastName"];
                                    html += `<option value='${self.dropdownUsers[i]["applicationUserId"]}'>${fullName}</option>`;
                                }
                            }
                            $("#ParticipantsGrid #User").html(html);


                        } else {
                            disableFields(false, selectedOption);
                            $("#ParticipantsGrid #User").hide();
                            $("#ParticipantsGrid label[for='User']").parent().hide();
                        }

                    });

                    $("#ParticipantsGrid #User").on("change", function () {
                        let selectedOption = $(this).val();
                        let user = self.dropdownUsers.find((x) => x["applicationUserId"] == selectedOption);
                        if (searchVal === "Notary") {
                            // we use query here cause we append a new list of notaries to the original area.
                            user = self.dropdownUsers.find((x) => x["applicationUserId"] == selectedOption && x["userID"] && (x["query"] == searchVal || x["roleName"] == searchVal));
                            self.selectedNotary = user;
                        } else {
                            user = self.dropdownUsers.find((x) => x["applicationUserId"] == selectedOption && x["roleName"] == searchVal);
                        }
                        console.log("Found User ", user);
                        $("#ParticipantsGrid #FirstName").val(user["firstName"]).trigger("change");
                        $("#ParticipantsGrid #LastName").val(user["lastName"]).trigger("change");
                        $("#ParticipantsGrid #Email").val(user["emailAddress"]).trigger("change");
                        $("#ParticipantsGrid #PhoneNumber").val(user["phoneNumber"]).trigger("change");
                    });


                    $("#ParticipantsGrid #ParticipantType").trigger("change");

                } else {
                    $("#ParticipantsGrid #User").hide();
                    $("#ParticipantsGrid label[for='User']").parent().hide();
                    $("#ParticipantsGrid #ParticipantType").hide();
                    $("#ParticipantsGrid label[for='ParticipantType']").parent().hide();
                    let isInternal = e.model.participantType !== "Signer" && e.model.participantType !== "Observer" && e.model.participantType !== "Legal_Witness";
                    if (isInternal) {
                        disableFields(true, e.model.participantType, false)
                    }
                }
                this.documentsToRemoveForParticipant = [];
                this.documentsToAddForParticipant = [];

                // handles resizing the size of the edit form container
                $(".k-edit-form-container")
                    .parent()
                    .width(1000)
                    .data("kendoWindow")
                    .title("Add")
                    .center();
                $(".k-edit-form-container").css("width", "auto");

                if (!e.model.isNew()) {
                    $(".k-edit-form-container [name='ParticipantType']").hide();
                    $(".k-edit-form-container label[for='ParticipantType']")
                        .parent()
                        .hide();
                }

                let data = {
                    LoanID: parseInt(this.$route.params.id),
                    PackageId: this.packageide,
                };

                // every time we add or delete fetch the list of documents so we have the latest
                let response = await ApiService.GeneratePostRequest(
                    `Package/documents`,
                    data
                );

                console.log(response)
                if (response.total > 0) {
                    // generate html to insert
                    let html = "";
                    response["data"].forEach(function (item) {
                        html += "<li class='list-group-item'>";
                        html += KendoPopupGenerator.generatePopUpFormCheckboxFields(
                            item.documentID,
                            item.description,
                            item.documentID,
                            item.documentID
                        );
                        html += "</li>";
                    });
                    $("#ParticipantsGrid .right-grid ul").html(html);
                    if (e.model.isNew()) {
                        response.data.forEach((doc) => {
                            $(`#ParticipantsGrid .right-grid #${doc.documentID}`).prop("checked", true);
                            this.documentsToAddForParticipant.push(doc.documentID);
                        });
                    }
                }
                if (!e.model.isNew()) {
                    // fetch packages for participant and update checkboxes
                    console.log("currently editing", e.model);
                    data = {
                        loanPackageId: this.packageide,
                        participantId: e.model.participantId,
                    };
                    response = await ApiService.GeneratePostRequest(
                        `package/participant/documents`,
                        data
                    );
                    console.log("participant documents", response);
                    if (response["isSuccess"]) {
                        this.existingDocumentsForParticipant = response["content"];
                        response["content"].forEach((doc) => {
                            $(`#ParticipantsGrid .right-grid #${doc.documentId}`).prop(
                                "checked",
                                true
                            );
                        });
                    }
                }

                // attach listener to determine which documents to remove and add when admin submits
                $("#ParticipantsGrid")
                    .find(".right-grid .Toggle-Switch input")
                    .on("change", function () {
                        console.log("found");
                        e.model.dirty = true;
                        let docId = parseInt($(this).attr("data-id"));

                        // check if document actioned on is already in existing list
                        let doc = self.existingDocumentsForParticipant.find((document) => {
                            return document.documentId === docId;
                        });

                        if (doc) {
                            if (
                                $(this).prop("checked") &&
                                self.documentsToRemoveForParticipant.includes(docId)
                            ) {
                                self.documentsToRemoveForParticipant = self.documentsToRemoveForParticipant.filter(
                                    (item) => item !== docId
                                );
                            } else if (
                                !$(this).prop("checked") &&
                                !self.documentsToRemoveForParticipant.includes(docId)
                            ) {
                                self.documentsToRemoveForParticipant.push(docId);
                            }
                        } else {
                            if (
                                $(this).prop("checked") &&
                                !self.documentsToAddForParticipant.includes(docId)
                            ) {
                                self.documentsToAddForParticipant.push(docId);
                            } else if (
                                !$(this).prop("checked") &&
                                self.documentsToAddForParticipant.includes(docId)
                            ) {
                                self.documentsToAddForParticipant = self.documentsToAddForParticipant.filter(
                                    (item) => item !== docId
                                );
                            }
                        }

                        console.log(self.documentsToAddForParticipant);
                        console.log(self.documentsToRemoveForParticipant);
                    });
            },


        },
    };
</script>
