import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib'

const TAG_SETTINGS = {
    CheckBox: {
        minW: 20,
        minH: 20,
        controlWidth: "40px",
        controlHeight: "40px",
        bgColor: "rgba(255, 255, 0, 0.4)"
    },
    FreeForm: {
        maxH: 40,
        bgColor: "rgba(255, 255, 0, 0.4)"
    },
    Other: {
        minW: 300,
        minH: 40
    },
    Seal: {
        aspectRatio: true,
    }
}
const TAG_STYLES_SIGNING_ROOM = `font-family:  'Helvetica', 'Arial', sans-serif;font-size:0.7vw; overflow:hidden;outline: none;position:fixed;top: 50%; left: 50%;cursor: grab;z-index: 1;`
const PARAMS = {
    "Date": {
        params: JSON.stringify({ "format": "MM/DD/YYYY" })
    },
    "DateOfBirth": {
        params: JSON.stringify({ "format": "MM/DD/YYYY" })
    },
}

const MAX_LOAD_WIDTH = 280;


class SmartTagManager {

    static CreateTag(tagType, currentPage, pdfName, documentId, id, pageElement, onCreated) {
        let prefix = tagType === "OptionGroup" ? "Choose" : "Insert";

        let ctrlWidth = TAG_SETTINGS[tagType] ? TAG_SETTINGS[tagType].controlWidth : "300px";
        let ctrlHeight = TAG_SETTINGS[tagType] ? TAG_SETTINGS[tagType].controlHeight : "40px";
        let leftPos = (pageElement.width() / 2) - (ctrlWidth / 2);
        let topPos = (pageElement.height() / 3) - (ctrlHeight / 2);
        let tagOptions = {
            belongsToPage: currentPage,
            controlHeight: tagType == "CheckBox" ? "30px" : ctrlHeight,
            pdfName: pdfName,
            controlLeft: leftPos + "px",
            controlTop: topPos + "10px",
            controlWidth: tagType == "CheckBox" ? "30px" : ctrlWidth,
            displayText: prefix + " " + tagType,
            originalDisplayText: tagType,
            pageNo: currentPage,
            tagType: tagType,
            signerId: "",
            dynamicTagApplied: false,
            params: PARAMS[tagType] ? PARAMS[tagType].params : {},
            documentId: documentId,
            controlId: id + "dp_le_", // to differeniate client ids from server
            controlIdAlias: id + "dp_le_", // to differeniate client ids from server
            noSpaceIdAlias: id + "dp_le_", // to differeniate client ids from server
            id: id + "_", // to differeniate client ids from server
            value: "",
            guidedTagEnabled: false
        };

        var html = $(`<div>${prefix} ${tagType}</div>`).attr({
            id: tagOptions.id,
            'data-index': tagOptions.id,
            style: TAG_STYLES_SIGNING_ROOM + `width: ${tagOptions.controlWidth}; height:${tagOptions.controlHeight};background:${TAG_SETTINGS[tagType] ? TAG_SETTINGS[tagType].bgColor : "rgba(255, 255, 0, 0.4)"}`
        });


        if (onCreated)
            onCreated(tagOptions, html)
    }


    static CreateImageTag(tagType, base64Image, currentPage, pdfName, documentId, id, pageElement, onCreated) {
        let img = document.createElement("img")
        img.onload = () => {
            let ctrlHeight = img.naturalHeight;
            let ctrlWidth = img.naturalWidth;
            let leftPos = (pageElement.width() / 2) - (ctrlWidth / 2);
            let topPos = (pageElement.height() / 3) - (ctrlHeight / 2);
            let tagOptions = {
                belongsToPage: currentPage,
                pdfName: pdfName,
                documentId: documentId,
                controlHeight: (ctrlHeight) + "px",
                controlLeft: leftPos + "px",
                controlTop: topPos + "px",
                controlWidth: (ctrlWidth) + "px",
                originalWidth: ctrlWidth,
                originalHeight: ctrlHeight,
                displayText: tagType,
                originalDisplayText: tagType,
                pageNo: currentPage,
                tagType: tagType,
                signerId: "",
                dynamicTagApplied: false,
                controlId: id + "dp_le_", // to differeniate client ids from server
                controlIdAlias: id + "dp_le_", // to differeniate client ids from server
                id: id + "_", // to differeniate client ids from server
            };


            var html = `<div><img src="${base64Image}" style="max-width: 100%;max-height: 100%;"/></div>`

            if (ctrlWidth > MAX_LOAD_WIDTH) {
                let scale = ctrlWidth / MAX_LOAD_WIDTH;
                ctrlWidth /= scale;
                ctrlHeight /= scale;

                tagOptions.controlHeight = ctrlHeight+ "px";
                tagOptions.controlWidth = ctrlWidth+ "px";
            }


            html = $(html).attr({
                id: tagOptions.id,
                'data-index': tagOptions.id,
                style: TAG_STYLES_SIGNING_ROOM + `height:${ctrlHeight}px; width:${ctrlWidth}px;background: rgba(255, 255, 0, 0.4);`
            });

            if (onCreated)
                onCreated(tagOptions, html)
        };
        img.setAttribute("src", base64Image);
    }


    static InitializeSmartTag(tagJquerySelector, existingTag = false, tagType, options = {}) {
        let selector = tagJquerySelector;
        // similar to jquery document onready -
        if (options.onDragStop) {
            selector.draggable({
                containment: ".signer-room-form-containter",
                stop: function (event) {
                    var tagId = $(this).attr("data-index");
                    if (options.onDragStop)
                        options.onDragStop(tagId, $(this))
                },
            });
        }

        var dimensions = TAG_SETTINGS[tagType] || {}
        if (options.onResize) {
            selector.resizable({
                ...dimensions,
                resize: function (event, ui) {
                    var size = ui.size;
                    var tagId = $(this).attr("data-index");
                    if (options.onResize)
                        options.onResize(tagId, size, $(this))
                    //var index = self.tagsAddedDuringSigning.findIndex((x) => tagId == x.id);
                    //self.tagsAddedDuringSigning[index]["controlHeight"] = size.height + "px";
                    //self.tagsAddedDuringSigning[index]["controlWidth"] = size.width + "px";

                    //if (size.width > size.height) {
                    //    self.tagsAddedDuringSigning[index]["fontSize"] = size.width / 8 + "px";
                    //} else {
                    //    self.tagsAddedDuringSigning[index]["fontSize"] = size.height / 5 + "px";
                    //}
                    //console.log("new size ", tagId, self.tagsAddedDuringSigning[index]);

                    //$(this).trigger("mouseenter");
                },
            });
        }

        selector.on("mouseenter", function () {
            console.log("mouse entered");
            if (options.onMouseEnter)
                options.onMouseEnter($(this))
        });

        selector.trigger("mouseenter");
    }


    static async TextFixedToAbsolute(sealSelector, pageSelector, smartTag, pdfUrl) {
        let sealElement = $(sealSelector);
        let pageElement = $(pageSelector);

        sealElement.css("height", "auto");// want to use the height of the content not not user sets
        let sealRect = sealElement[0].getBoundingClientRect();
        let pageRect = pageElement[0].getBoundingClientRect();

        let width = sealRect.width;//parseFloat(smartTag["controlWidth"].replaceAll("px", ""));
        let height = sealRect.height;// parseFloat(smartTag["controlHeight"].replaceAll("px", ""));

        const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
        var pdfDoc = await PDFDocument.load(existingPdfBytes);
        const page = pdfDoc.getPage(0);

        let pageWidth = page.getWidth();
        let renderedWidth = pageElement.width();


        let top = Math.abs(sealRect.top - pageRect.top);
        let left = Math.abs(sealRect.left - pageRect.left);

        // calculate positions 
        let scale = renderedWidth / pageWidth;
        width = width / scale;
        height = height / scale;



        top = top / scale;
        left = left / scale;
        return {
            Top: top,
            Left: left,
            PageRatio: scale,
            ResizeRatio: 1,
            TagType: smartTag.tagType,
            Width: width,
            Height: height
        };
    }


    static async ImageFixedToAbsolute(sealSelector, pageSelector, smartTag, pdfUrl) {
        let sealElement = $(sealSelector);
        let pageElement = $(pageSelector);
        let width = parseFloat(smartTag["controlWidth"].replaceAll("px", ""));
        let height = parseFloat(smartTag["controlHeight"].replaceAll("px", ""));

        let sealRect = sealElement[0].getBoundingClientRect();
        let pageRect = pageElement[0].getBoundingClientRect();
        let top = Math.abs(sealRect.top - pageRect.top);
        let left = Math.abs(sealRect.left - pageRect.left);


        const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
        var pdfDoc = await PDFDocument.load(existingPdfBytes);
        const page = pdfDoc.getPage(0);

        let pageWidth = page.getWidth();
        let renderedWidth = pageElement.width();


        // calculate positions 
        let scale = renderedWidth / pageWidth;
        top = top / scale;
        width = width / scale;
        height = height / scale;
        left = left / scale;

        let imageScale = parseInt(smartTag["controlWidth"].replaceAll("px")) / smartTag.originalWidth;
        return {
            Top: top,
            Left: left,
            PageRatio: scale,
            ResizeRatio: imageScale || 1,
            TagType: smartTag.tagType,
            Width: width,
            Height: height
        };
    }

    static async GetScale(pdfPath, renderedWidth) {

        const existingPdfBytes = await fetch(pdfPath).then(res => res.arrayBuffer());
        var pdfDoc = await PDFDocument.load(existingPdfBytes);
        const page = pdfDoc.getPage(0);

        let pageWidth = page.getWidth();
        return renderedWidth / pageWidth;
    }

    static async GetFullScale(pdfPath, renderedWidth, renderedHeight) {

        const existingPdfBytes = await fetch(pdfPath).then(res => res.arrayBuffer());
        var pdfDoc = await PDFDocument.load(existingPdfBytes);
        const page = pdfDoc.getPage(0);

        let pageWidth = page.getWidth();
        let pageHeight = page.getHeight();
        return {
            width: renderedWidth / pageWidth,
            height: renderedHeight / pageHeight,
        }
    }
}

export default SmartTagManager;