<template>
  <!-- <Tooltip :anchor-element="'target'" :position="'top'"> -->
    <k-button
      title="Open"
      :icon="'eye'"
      @click="viewHandler"
      class="customBtn"
      id="open-btn"
    />
  <!-- </Tooltip> -->
</template>
<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Tooltip } from "@progress/kendo-vue-tooltip";

export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  components: {
    "k-button": Button,
    Tooltip,
  },
  methods: {
    viewHandler: function() {
        this.$emit("view", this.dataItem);          
    },
  },
};
</script>

<style scoped>
.customBtn {
  font-size: small;
  padding: 4px 8px;
  color: #4d97ac;
}
</style>
