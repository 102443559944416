window.url = require("./urls");
import axios from "axios";
import store from "../../store/index";
import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
import router from "@/router/index";

const URL = url.endPoint + "/api/";
export const loadFileUrl = url.endPoint + "/api/document/load?path=";
export const downloadFileUrl = url.endPoint + "/api/document/download?path=";
export const downloadRecordingUrl = url.endPoint + "/api/recording/download?filePath=";

class ApiService2 {
    static EnvironmentURL() {
        return URL;
    }


    static async GenerateRequest(endpoint, data = {}, config, headers = {}, loader = true) {
        //console.log("ENDPOINT HIT: ", endpoint);
        //console.log("Data: ", data);
        //console.log("config: ", config);
        //console.log("headers: ", headers);
        /*if (!endpoint.includes("transaction/sendscrolltowitness") && !endpoint.includes("document/update/status/reviewed") && !endpoint.includes("package/canSign") && !endpoint.includes("signer/signingParticipants") && !endpoint.includes("verify/signerverification")) {
            if (loader) {
                console.log("SHOW LOADER: ", true);
                store.commit("App/updateLoader", { showLoader: headers.loader != undefined ? headers.loader : true });
            }
        } else {
            console.log("SHOW LOADER: ", false);
        }
        activeRequestsCount++;
        let req = { isSuccess: false, message: "There system encountered an issue and was unable to process your request." };*/

        var req = "";
        try {
            req = await axios({
                url: URL + endpoint,
                headers: {
                    'Authorization': `Bearer ${store.getters["App/token"]}`,
                    ...headers
                },
                ...config
            });
        } catch (err) {
            console.log(err);
        }
        completedRequests++;
        if (activeRequestsCount === completedRequests) {
            console.log("completed");
            activeRequestsCount = 0;
            completedRequests = 0;
            store.commit("App/updateLoader", { showLoader: false });
        }

        if (req && req["data"])
            req = req["data"];

        return req;
    }

    static async GeneratePostRequest(endpoint, data = {}, headers) {
        return await this.GenerateRequest(endpoint, data, { method: 'post', data: data }, headers)
    }

    static async GenerateGetRequest(endpoint, data = {}, headers) {
        return await this.GenerateRequest(endpoint, data, { method: 'get', data: data }, headers)
    }
}

export default ApiService2;