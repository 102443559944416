<template>

    <div class="cssProgress">
        <div class="progress1">
            <div class="cssProgress-bar" data-percent="75"  :style="{ 'width': percentage + '%' }" >
                <span class="cssProgress-label" :style="{ 'color': (percentage >= 50 ? 'white' :'black') }">{{percentage}}%</span>
            </div>
        </div>
    </div>

</template>

<script>
    import moment from "moment";
    export default {
        name: "ProgressBar",
        props: ["percentage"],
        emits: [],
        components: {
        },
        data() {
            return {
            }
        },
        computed: { moment: () => moment },
        created() {
           
        },
        async mounted() {
          
        },
        methods: {
        }
    };
</script>

<style scoped>

    /* -----------------------------------------------------
	CSS Progress Bars
-------------------------------------------------------- */
    .cssProgress {
        width: 100%;
        margin-bottom: 20px;
    }

        .cssProgress .progress1{
            position: relative;
            overflow: hidden;
            width: 100%;
        }

        .cssProgress .cssProgress-bar {
            display: block;
            float: left;
            width: 0%;
            height: 100%;
            background: #3798d9;
            box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1);
            transition: width 0.8s ease-in-out;
        }

        .cssProgress .cssProgress-label {
            position: absolute;
            overflow: hidden;
            left: 0px;
            right: 0px;
            color: rgba(255, 255, 255, 1);
            font-size: 0.7em;
            text-align: center;
            text-shadow: 0px 1px rgba(0, 0, 0, 0.3);
        }

        .cssProgress .cssProgress-info {
            background-color: #9575cd !important;
        }

        .cssProgress .cssProgress-danger {
            background-color: #ef5350 !important;
        }

        .cssProgress .cssProgress-success {
            background-color: #66bb6a !important;
        }

        .cssProgress .cssProgress-warning {
            background-color: #ffb74d !important;
        }

        .cssProgress .cssProgress-right {
            float: right !important;
        }

        .cssProgress .cssProgress-label-left {
            margin-left: 10px;
            text-align: left !important;
        }

        .cssProgress .cssProgress-label-right {
            margin-right: 10px;
            text-align: right !important;
        }

        .cssProgress .cssProgress-label2 {
            display: block;
            margin: 2px 0;
            padding: 0 8px;
            font-size: 0.8em;
        }

            .cssProgress .cssProgress-label2.cssProgress-label2-right {
                text-align: right;
            }

            .cssProgress .cssProgress-label2.cssProgress-label2-center {
                text-align: center;
            }

        .cssProgress .cssProgress-stripes,
        .cssProgress .cssProgress-active,
        .cssProgress .cssProgress-active-right {
            background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
            background-size: 35px 35px;
        }

        .cssProgress .cssProgress-active {
            -webkit-animation: cssProgressActive 2s linear infinite;
            animation: cssProgressActive 2s linear infinite;
        }

        .cssProgress .cssProgress-active-right {
            -webkit-animation: cssProgressActiveRight 2s linear infinite;
            animation: cssProgressActiveRight 2s linear infinite;
        }

    @-webkit-keyframes cssProgressActive {
        0% {
            background-position: 0 0;
        }

        100% {
            background-position: 35px 35px;
        }
    }

    @keyframes cssProgressActive {
        0% {
            background-position: 0 0;
        }

        100% {
            background-position: 35px 35px;
        }
    }

    @-webkit-keyframes cssProgressActiveRight {
        0% {
            background-position: 0 0;
        }

        100% {
            background-position: -35px -35px;
        }
    }

    @keyframes cssProgressActiveRight {
        0% {
            background-position: 0 0;
        }

        100% {
            background-position: -35px -35px;
        }
    }

    /* -----------------------------------------------------
	Progress Bar 1
-------------------------------------------------------- */
    .progress1 {
        background-color: #EEE;
        box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);
    }

        .progress1 .cssProgress-bar {
            height: 18px;
        }

        .progress1 .cssProgress-label {
            line-height: 18px;
        }


    @-webkit-keyframes cssProgressGlowActive1 {
        0%, 100% {
            box-shadow: 5px 0px 15px 0px #3798D9;
        }

        45% {
            box-shadow: 1px 0px 4px 0px #3798D9;
        }
    }

    @keyframes cssProgressGlowActive1 {
        0%, 100% {
            box-shadow: 5px 0px 15px 0px #3798D9;
        }

        45% {
            box-shadow: 1px 0px 4px 0px #3798D9;
        }
    }

    @-webkit-keyframes cssProgressGlowActive2 {
        0%, 100% {
            box-shadow: 5px 0px 15px 0px #9575cd;
        }

        45% {
            box-shadow: 1px 0px 4px 0px #9575cd;
        }
    }

    @keyframes cssProgressGlowActive2 {
        0%, 100% {
            box-shadow: 5px 0px 15px 0px #9575cd;
        }

        45% {
            box-shadow: 1px 0px 4px 0px #9575cd;
        }
    }

    @-webkit-keyframes cssProgressGlowActive3 {
        0%, 100% {
            box-shadow: 5px 0px 15px 0px #ef5350;
        }

        45% {
            box-shadow: 1px 0px 4px 0px #ef5350;
        }
    }

    @keyframes cssProgressGlowActive3 {
        0%, 100% {
            box-shadow: 5px 0px 15px 0px #ef5350;
        }

        45% {
            box-shadow: 1px 0px 4px 0px #ef5350;
        }
    }

    @-webkit-keyframes cssProgressGlowActive4 {
        0%, 100% {
            box-shadow: 5px 0px 15px 0px #66bb6a;
        }

        45% {
            box-shadow: 1px 0px 4px 0px #66bb6a;
        }
    }

    @keyframes cssProgressGlowActive4 {
        0%, 100% {
            box-shadow: 5px 0px 15px 0px #66bb6a;
        }

        45% {
            box-shadow: 1px 0px 4px 0px #66bb6a;
        }
    }

    @-webkit-keyframes cssProgressGlowActive5 {
        0%, 100% {
            box-shadow: 5px 0px 15px 0px #ffb74d;
        }

        45% {
            box-shadow: 1px 0px 4px 0px #ffb74d;
        }
    }

    @keyframes cssProgressGlowActive5 {
        0%, 100% {
            box-shadow: 5px 0px 15px 0px #ffb74d;
        }

        45% {
            box-shadow: 1px 0px 4px 0px #ffb74d;
        }
    }
</style>
