<template>
    <k-dialog @close="cancel" 
              :width="500"
                :height="500"
              >
        <div :style="{ marginBottom: '1rem' }">
            <label>
                Customer<br />
                <k-input type="text"
                         :name="'Customer'"
                         v-model="itemInEdit.Customer"></k-input>
            </label>
        </div>
        <div :style="{ marginBottom: '1rem' }">
            <label>
                Stage<br />

                <k-input type="text"
                         :name="'Stage'"
                         v-model="itemInEdit.Stage"></k-input>
            </label>
        </div>
        <dialog-actions-bar>
            <button class="k-button"
                    @click="cancel">
                Cancel
            </button>
            <button class="k-button k-primary"
                    @click="save">
                Save
            </button>
        </dialog-actions-bar>
    </k-dialog>
</template>

<script>
    import ApiService from "@/core/services/api-service";

    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Input } from '@progress/kendo-vue-inputs';
    export default {
        name: 'AddEditLoanFormDialog',
        components: {
            'k-dialog': Dialog,
            DialogActionsBar,
            'k-input': Input
        },
        props: ["dataItem"],
        data() {
            return {
                itemInEdit: {
                    Customer: '',
                    ClosingType: '',
                    Status: '',
                    Stage: '',
                }
            }
        },
        created: function () {
            if (this.$props.dataItem) {
                this.itemInEdit = this.$props.dataItem;
            }
        },
        methods: {
            cancel() {
                this.$emit('cancel');
            },
            save() {
                this.$emit('save');
            }
        },

    }
</script>