<template>
  <!-- <Tooltip :anchor-element="'target'" :position="'top'"> -->
  <k-button
    v-if="!hideActions.includes('open')"
    title="Open"
    :icon="'eye'"
    @click="viewHandler"
    class="customBtn"
    id="open-btn"
  />

  <k-button
    v-if="!hideActions.includes('download')"
    title="Download"
    :icon="'download'"
    @click="downloadHandler"
    class="customBtn"
    id="dl-btn"
  />

  <k-button
    v-if="!hideActions.includes('print')"
    title="Print"
    :icon="'print'"
    @click="printHandler"
    class="customBtn"
    id="prnt-btn"
  />

  <k-button
    v-if="!hideActions.includes('delete')"
    title="Delete"
    :icon="'delete'"
    @click="deleteHandler"
    class="customBtn deleteBtn"
    id="prnt-btn"
  />
  <!-- </Tooltip> -->
</template>
<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import Swal from "sweetalert2";

export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
    hiddenActions: Array
  },
  components: {
    "k-button": Button,
    Tooltip,
  },
  methods: {
    downloadHandler: function() {
      this.$emit("download", this.dataItem);
    },
    viewHandler: function() {
      this.$emit("view", this.dataItem);
    },
    printHandler: function() {
      this.$emit("print", this.dataItem);
    },
    deleteHandler: function() {
      Swal.fire({
        title: "Please confirm you wish to delete the following document",
        icon: "warning",
        text: this.dataItem.description,
        confirmButtonText: "Confirm",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit("delete", this.dataItem.documentId);
        }
      });
    },
  },
   data: function() {
    return {
      hideActions: this.hiddenActions || []
    }
   }
};
</script>

<style scoped>
.customBtn {
  font-size: small;
  padding: 4px 8px;
  color: #4d97ac;
}

.deleteBtn {
  color: lightcoral;
}
</style>
