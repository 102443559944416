<template>
    <div class="card">
        <div class="card-body">
            <div style="clear:both;">
                <h3 class="card-title">
                    <strong>Loans</strong>
                    <button class="btn btn-blue curved" style="float:right" @click.prevent="this.itemInEdit = { };">Add New</button>
                    <button class="btn btn-blue curved" style="float: right; margin-right: 10px" @click.prevent="this.shouldFilter = !this.shouldFilter">Filter</button>
                </h3>
            </div>
            <div class="content">

                <div class="break-down-selection">
                    <ul style="list-style:none">
                        <li>Initial Disclosure</li>
                        <li>LE Rate Lock</li>
                        <li>Initial CD</li>
                        <li class="active">Closing Package</li>
                    </ul>
                </div>
                <div class="participants-grid">
                    <div class="title-block">
                        <h3>
                            <strong>Loans</strong>

                        </h3>
                    </div>
                    <grid ref="loans-grid"
                          :data-items="result"
                          :style="{height: '450px'}"
                          :pageable="true"
                          :total="total"
                          :skip="skip"
                          :take="take"
                          selected-field="selected"
                          :columns="columns"
                          :filterable="shouldFilter"
                          :filter="filter"
                          @filterchange="filterChange"
                          @pagechange="pageChangeHandler"
                          @selectionchange="onSelectionChange"
                          @headerselectionchange="onHeaderSelectionChange"
                          @rowclick="onRowClick" />

                    <add-edit-loan-form-dialog v-if="itemInEdit" :data-item="itemInEdit" @save="saveLoan" @cancel="cancelEditSave" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import products from '../../appdata/products.json';
    import ApiService from "@/core/services/api-service";
    import { Grid } from '@progress/kendo-vue-grid';
    import { filterBy } from '@progress/kendo-data-query';
    import AddEditLoanFormDialog from '@/components/forms/AddEditLoanFormDialog';
    export default {
        name: 'LoansGrid',
        components: {
            Grid,
            AddEditLoanFormDialog
        },
        props: [],
        data() {
            return {
                products: products,
                itemInEdit: null,
                skip: 0,
                take: 10,
                shouldFilter: false,
                filter: {
                    logic: "and",
                    filters: [
                        //{ field: "UnitPrice", operator: "neq", value: 18 },
                        //{ field: "FirstOrderedOn", operator: "gte", value: new Date("1996-10-10") }
                    ]
                },
                staticColumns: [
                    { field: 'Id', title: 'NO.' },
                    { field: 'Customer', title: 'Customer' },
                    { field: 'ClosingType', title: 'Closing Type' },
                    { field: 'ClosingDate', title: 'Closing Date' },
                    { field: 'Status', title: 'Status' },
                    { field: 'Stage', title: 'Stage' },
                ]
            }
        },
        computed: {
            areAllSelected() {
                return this.products.findIndex(item => item.selected === false) === -1;
            },
            columns() {
                return [
                    { field: 'selected', width: '80px', headerSelectionValue: this.areAllSelected },
                    ...this.staticColumns
                ]
            },
            result: {
                get: function () {
                    return filterBy(this.products.slice(this.skip, this.take + this.skip), this.filter);
                }
            },
            total() {
                return this.products ? this.products.length : 0;
            }
        },
        methods: {
            pageChangeHandler: function (event) {
                this.skip = event.page.skip;
                this.take = event.page.take;
            },
            onHeaderSelectionChange(event) {
                let checked = event.event.target.checked;
                this.products = this.products.map((item) => { return { ...item, selected: checked } });
            },
            onSelectionChange(event) {
                event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
            },
            onRowClick(event) {
                event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
            },
            filterChange: function (ev) {
                this.filter = ev.filter;
            },
            cancelEditSave() {
                this.itemInEdit = null;
            },
            saveLoan() {
                this.itemInEdit = null;
            },
        }
    }
</script>