import { createApp } from 'vue';
import App from './App.vue';
import AppMobile from './AppMobile.vue';
import router from './router';
import VueSignaturePad from 'vue-signature-pad';
import store from './store';
import swal from 'vue-sweetalert2';
// import '@sweetalert2/theme-bootstrap-4/bootstrap-4.css';
import 'sweetalert2/dist/sweetalert2.min.css'; // the default css from sweet alert
import ApiService from "@/core/services/api-service";
import { EditorInstaller } from '@progress/kendo-editor-vue-wrapper'
// import '@progress/kendo-theme-material/dist/all.css';
import './video';   

window.mobile = require("./core/services/mobiledetect");
function LoadApp() {
//if (isMobile()) {
//    console.log("MOBILE");

//    const app = createApp(AppMobile);
//    app.use(router).mount('#app');
//} else {
//    console.log("DESKTOP");

    const app = createApp(App);
    const options = {
        confirmButtonColor: '#FFC0CB',
        cancelButtonColor: '#FFC0CB'
  };

	app.use(store).use(router).use(VueSignaturePad).use(swal, options).use(EditorInstaller).mount('#app');

	store.commit("App/updateLoader", { showLoader: false });
	activeRequestsCount = 0;
    completedRequests = 0;
//}
}

if (window.location.pathname.toLowerCase().startsWith('/admin/logout') ||
    window.location.pathname == '/' ||
    window.location.pathname.toLowerCase().startsWith('/admin/login')) {
    LoadApp()
} else {
    ApiService.GeneratePostRequest('account/allowlogin').then(allowed => {
        if (allowed.data === false || allowed === false) {
            store.commit("Auth/signOut");
            store.commit("Admin/logout");
            store.reset();
            if (window.location.pathname.toLowerCase().startsWith('/admin/'))
                router.push('/Admin/Login');
            else
                router.push('/');
        }
        LoadApp();
    });
}