<template>
    <div class="switch-container">
        <span class="activeRole">{{ this.activeRole }}</span>
        <button ref="button"
                @click="onClick"
                class="k-button k-button-sm k-button-flat switchBtn">
            <i class="material-icons">sync</i>
            Switch Role
        </button>
        <Popup :anchor="'button'"
               :show="show"
               :anchor-align="anchorAlign"
               :popup-align="popupAlign"
               class="popup-content">
            <legend class="roles-legend">
                Roles<div class="k-button-flat k-button k-button-sm closeBtn" :onClick="onClose"><i class="material-icons">close</i></div>

            </legend>
            <div class="content">
                <div v-for="role in additionalRoles"
                     class="role-list"
                     :key="role.roleId">
                    <div style="flex:2;">
                        <span class="role">{{ role.roleName }}</span>
                    </div>
                    <div style="flex:1">
                        <button @click="switchRole(role.roleId)"
                                class="k-button k-button-sm k-button-outline switch-role">
                            Switch
                        </button>
                    </div>
                </div>
            </div>
        </Popup>
    </div>
</template>
<script>
    import { Popup } from "@progress/kendo-vue-popup";
    import { Button } from "@progress/kendo-vue-buttons";
    import ApiService from "@/core/services/api-service";

    export default {
        components: {
            Popup: Popup,
            kbutton: Button,
        },
        props: ["activeRole", "additionalRoles"],
        data: function () {
            return {
                show: false,
                anchorAlign: {
                    horizontal: "right",
                    vertical: "bottom",
                },
                popupAlign: {
                    horizontal: "right",
                    vertical: "top",
                },
            };
        },
        computed: {
            buttonText: function () {
                return this.show ? "Hide" : "Show";
            },
        },
        mounted() {
            this.show = false;
        },
        methods: {
            onClick() {
                this.show = !this.show;
            },

            onClose() {
                this.show = false;
            },

            async switchRole(roleId) {
                let response = await ApiService.GeneratePostRequest(
                    "account/switch_role?roleId=" + roleId
                );
                if (response.isSuccess) {
                    this.$store.commit("Admin/updateLoginInfo", response.content);
                    this.$router.go();
                }
            },
        },
    };
</script>

<style scoped>
    .switch-container {
        display: flex;
        align-items: center;
        justify-items: center;
    }

    .activeRole {
        flex: 1;
        font: 600 1.02em/1.05em "Arial", "Open Sans", sans-serif;
        color: #4d97ac;
    }

    .switchBtn {
        flex: 1;
        padding: 0px;
        font: 600 1.02em/1.05em "Arial", "Open Sans", sans-serif;
        color: #fb8386;
        margin-left: 10px;
    }

    .content {
        width: 30vh;
        height: auto;
        padding: 16px 10px;
    }

    .role-list {
        display: flex;
        align-items: center;
        justify-items: center;
    }

    .popup-content {
        width: auto !important;
    }

    .switch-role {
        padding: 4px 8px;
        font-size: 14px;
        text-transform: capitalize;
        width: 100%;
        color: #fb8386;
    }

    .role {
        font-weight: bold;
        font: 400 1.02em/1.05em "Arial", "Open Sans", sans-serif;
    }

    .roles-legend {
        margin-bottom: 0;
        font-size: 14px;
        padding-left: 10px;
    }

    .closeBtn {
        float: right;
    }
</style>
