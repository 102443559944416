<template>
    <a v-if="item.status == 'Request Access'" href="#" @click="this.onClick" class="link">{{item.status}}</a>
    <span v-else :class="'status-' + item.status">{{item.status}}</span>
</template>

<style scoped>
    .status-Approved {
        color: #37b400;
    }

    .status-Rejected {
        color: #f94e32;
    }
</style>
<script>
    import ApiService from "../../core/services/api-service";
    import Sweetalert from "sweetalert2/dist/sweetalert2.all.min.js";

    export default {
        name: "videoAccessTemplate",
        methods: {
            onClick: function (e) {
                e.preventDefault();

                return ApiService.GeneratePostRequest(`Package/AccessRequest?loan_package_id=` + this.templateArgs.loanPackageID).then(
                    response => {
                        if (response.content) {
                            Sweetalert.fire("Video access request sent!", "", "success");
                            this.templateArgs.status = "Pending";
                        } else {
                            Sweetalert.fire("Failed", response.message, "error");
                        }
                    }
                );
            }
        },
        computed: {
            item() {
                return this.templateArgs;
            }
        }
    };
</script>
