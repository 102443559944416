<template>
    <div>

        <table class="table table-responsive table-striped">
            <thead class="tr">
                <tr>
                    <th>
                        <h3>File</h3>
                    </th>
                    <th>
                        <h3>Type</h3>
                    </th>
                    <th v-if="ShowReviewStatus">
                        <h3>Review Status</h3>
                    </th>
                    <th v-if="ShowAgreementStatus">
                        <h3>Agreement Status</h3>
                    </th>
                    <th v-if="ShowSignedStatus">
                        <h3>Signed Status</h3>
                    </th>
                    <th>
                        <h3>Action</h3>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="d in session['Documents']">
                    <td>
                        <h3 class="light-text" v-if="d.NotarizeStatusID === 5">
                            <i class="fa fa-file-text-o"></i>
                            <a :href="d.renderType" download> {{d.DocumentTitle}}</a>
                        </h3>
                        <h3 class="light-text" v-else>
                            <i class="fa fa-file-text-o"></i>
                            {{d.DocumentTitle}}
                        </h3>
                    </td>
                    <td>
                        <h3 class="light-text"> {{notarizeTypeToString(d.NotarizeType)}}</h3>
                    </td>
                    <td v-if="ShowReviewStatus">
                        <h3 class="light-text" v-if="d.IsReview"> {{d.IsReview}}</h3>
                    </td>
                    <td v-if="ShowAgreementStatus">
                        <h3 class="light-text" v-if="d.IsAgreed"> {{d.IsAgreed}}</h3>
                    </td>
                    <td>
                        <h3 class="light-text ">
                            <a href="javascript:void(0)" class="link">Review Document</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style="text-align:center">
            <a href="#" class="btn btn-success" :disabled="IsSigningDisabled">Start Signing</a>
        </div>
    </div>
</template>


<script>
    import ApiService from "@/core/services/api-service";
    export default {
        name: 'DocumentList',
        props: ['session'],
        data() {
            return {
                ShowReviewStatus: false,
                ShowAgreementStatus: false,

                IsSigningDisabled:true
            }
        },
        created() {
            let session = this.session;
            // check if signing date is present
            if (session["ReviewNeeded"]) {
                this.ShowReviewStatus = true;
                for (let i = 0; i < session['Documents'].length; i++) {
                    let item = session['Documents'];
                    if (!item["IsReviewed"]) {
                        allDocumentsReviewed = false;
                        break;
                    }
                }
            }

            if (session["AgreementNeeded"]) {
                this.ShowAgreementStatus = true;
                for (let i = 0; i < session['Documents'].length; i++) {
                    let item = session['Documents'];
                    if (!item["IsAgreed"]) {
                        allDocumentsAgreed = false;
                        break;
                    }
                }
            }

            

            if (allDocumentsReviewed && allDocumentsAgreed) {
                IsSigningDisabled = false;
            }
            //console.log(this.list);
            //session['Documents']
        },
        methods: {

            notarizeStatusToString(id) {
                switch (id) {
                    case 1:
                        return "New";
                        break;
                    case 2:
                        return "Documents_Uploaded";
                        break;
                    case 3:
                        return "Signers_Added";
                        break;
                    case 4:
                        return "Notarized";
                        break;
                    case 5:
                        return "Payment_Done";
                        break;
                    case 6:
                        return "Aborted";
                        break;
                }
            },
            notarizeTypeToString(id) {
                switch (id) {
                    case 1:
                        return "eSign";
                        break;
                    case 2:
                        return "Notarize";
                        break;
                    case 3:
                        return "eSignLink";
                        break;
                    case 4:
                        return "NotarizeInvitation";
                        break;
                }
            },
        },
    }
</script>