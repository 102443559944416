
class GridDataHelper {
    // static normalizeStatus(string) {
    //     if (string) {

    //         console.log("Normalizing", string);
    //         let arr = string.split(/(?=[A-Z])/);// splits string by capital letter and also allows for the first letter to be lower case
    //         console.log("Normalizing Breakdown", arr);
    //         arr.map((word, i) => {
    //             word = word.replace(word[0], word[0].toLowerCase());

    //             if (i === 0)
    //                 word = word.replace(word[0], word[0].toUpperCase());
    //             return word.trim();
    //         });
    //         console.log("Completed ", arr.join(" "));

    //         return arr.join(" ");
    //     }
    //     return string;
    // }

    static normalizeStatus(string) {
        if (string) {
            return string.replaceAll("_"," ");
        }
        return string;
    }


}

export default GridDataHelper;