<template>
    <h4 :style="{ fontSize: '1em' }">
        <span class="k-icon k-i-warning" :style="{ fontSize: '2.5em' }" />
        <br /><br />
        no data here
    </h4>
</template>
<script>
    export default {
        name: "NoDataLoader",
    }
</script>