<template>
    <div class="row">
        <div class="innerbox col-md-7 ">
            <div class=" col-md-12">
                <br>
                <br>
                <div class="row">
                    <div class="col-md-6">
                        <p>
                            Please enter the Social Security Number
                            Signer Name: <span style="font-weight: bold">{{userFullName}}</span>
                        </p>
                        <h3><label for="SSNNo">Social Security Number</label></h3>
                        <input v-model="ssn" class="form-control bdr-rad-0" id="SSNNo" name="SSNNo" placeholder="**************" type="password" >
                        <span class="field-validation-valid text-danger" data-valmsg-for="SSNNo" data-valmsg-replace="true" v-if="msg != ''"> {{msg}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="innerbox col-md-5 ">
            <div class=" col-md-12">
                <div class="messagebox dark-bg">
                    <h3>Is my information secure? </h3>
                    <p>All user communications are encrypted and all data at rest (such as documents) are protected using industry-specified encryption protocols such as AES-256. All application accesses are also tracked using an audit trail.</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import ApiService from "@/core/services/api-service";
    export default {
        name: 'VerifySSN',
        emits: ["componentValid"],
        props: ["preloadedData"],
        data() {
            return {
                ssn: "",
                msg: ""
            }
        },
        computed: {
            userFullName() {
                return this.$store.getters["Auth/userFullName"];
            }
        },
        watch: {
            ssn(value) { this.emitValidState() },
        },
        methods: {
            async isValid() {
                if (this.ssn.trim() === "") {
                    this.msg = "Please enter your SSN";
                    return;
                }

                let response = await ApiService.GeneratePostRequest(`verify/ssn?ssn=${this.ssn}`);
                if (!response["isSuccess"])
                    this.msg = response["message"];

                return response["isSuccess"];
            },
            emitValidState() {
                let hasErrors = this.ssn === "";
                this.$emit("componentValid", !hasErrors);
            }
        },
    }
</script>
