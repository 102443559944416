<template>
    <div class="p-m">

        <div class="k-card k-card-vertical">
            <div class="k-card-header">
                <div class="col-lg-12">
                    <h4>Global Settings</h4>
                </div>
            </div>
            <div class="k-card-body">
                <div class="row k-pb-md">
                    <div class="col-lg-12">
                        <k-button class="k-button k-primary" @click="handleEditEmailFooter" ref="button">
                            Edit Email Footer
                        </k-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <k-button class="k-button k-primary" @click="handleEditEmailRecipients" ref="button">
                            Edit Email Recipients
                        </k-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row k-pb-md"></div>

        <div class="k-card k-card-vertical">
            <div class="k-card-header">
                <div class="col-lg-12">
                    <h4>Email Templates</h4>
                </div>
            </div>
            <EmailsTemplate></EmailsTemplate>

        </div>
        <div class="row k-pb-md"></div>
        <div class="row pb-m">
            <div class="col-lg-3 col-md-6">
                <div>
                    <button @click="save"
                            class="k-button k-primary">
                        Save
                    </button>
                </div>
            </div>
        </div>

        <div class="row k-pb-md"></div>

        <div class="k-card k-card-vertical">
            <div class="k-card-header">
                <div class="col-lg-12">
                    <h4>Signer Invitations</h4>
                </div>
            </div>
            <div class="k-card-body">
                <div class="row k-pb-md">
                    <div class="col-lg-3 col-md-6">
                        <div>
                            Automatically send invitations when package is created
                        </div>
                        <div style="vertical-align:bottom;">
                            <br />
                            <k-switch :checked="autoInvite" @change="autoInviteChange" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div>
                            Enable invitation Reminders
                        </div>
                        <div style="vertical-align:bottom;">
                            <br />
                            <k-switch :checked="enableInvite" @change="enableInviteChange" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div>
                            24 HR Reminder
                        </div>
                        <div style="vertical-align:bottom;">
                            <br />
                            <k-switch :checked="reminder24" @change="reminder24Change" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div>
                            48 HR Reminder
                        </div>
                        <div style="vertical-align:bottom;">
                            <br />
                            <k-switch :checked="reminder48" @change="reminder48Change" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div>
                            72 HR Reminder
                        </div>
                        <div style="vertical-align:bottom;">
                            <br />
                            <k-switch :checked="reminder72" @change="reminder72Change" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row k-pb-md"></div>
        <div class="k-card k-card-vertical">
            <div class="k-card-header">
                <div class="col-lg-12">
                    <h4>Loan Package Notifications</h4>
                </div>
            </div>
            <div class="k-card-body">
                <div class="row k-pb-md">
                    <div class="col-lg-3 col-md-6">
                        <div>
                            Enable Loan Package Activated Alert
                        </div>
                        <div style="vertical-align:bottom;">
                            <br />
                            <k-switch :checked="packageActivated" @change="packageActivatedChange" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div>
                            Enable Loan Package Expiring Alert
                        </div>
                        <div style="vertical-align:bottom;">
                            <br />
                            <k-switch :checked="packageExpiring" @change="packageExpiringChange" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div>
                            Hours before Expiry Date
                        </div>
                        <div style="vertical-align:bottom;">
                            <k-input :style="{ width: '100px' }" v-model="expiredHoursBefore"></k-input>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div>
                            Enable Loan Package Expired Alert
                        </div>
                        <div style="vertical-align:bottom;">
                            <br />
                            <k-switch :checked="packageExpired" @change="packageExpiredChange" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div>
                            Hours after Expiry Date
                        </div>
                        <div style="vertical-align:bottom;">
                            <k-input :style="{ width: '100px' }" v-model="expiredHoursAfter"></k-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row k-pb-md"></div>
        <div class="k-card k-card-vertical">
            <div class="k-card-header">
                <div class="col-lg-12">
                    <h4>Wetsign Notifications</h4>
                </div>
            </div>
            <div class="k-card-body">
                <div class="row k-pb-md">
                    <div class="col-lg-3 col-md-6">
                        <div>
                            Enable Wetsign Notifications
                        </div>
                        <div style="vertical-align:bottom;">
                            <br />
                            <k-switch :checked="enableWetsign" @change="enableWetsignChange" />
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div>
                            Select notification time
                        </div>
                        <div style="vertical-align:bottom;">
                            <dropdownlist :style="{ width: '230px' }"
                                          :data-items='hours'
                                          :text-field="'text'"
                                          :data-item-key="'id'"
                                          @change="wetsignAlertTimeChange"
                                          :value="wetsignAlertTime">
                            </dropdownlist>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div>
                            Hours elapsed start
                        </div>
                        <div style="vertical-align:bottom;">
                            <k-input :style="{ width: '100px' }" v-model="wetsignHoursBefore"></k-input>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div>
                            Hours elapsed end
                        </div>
                        <div style="vertical-align:bottom;">
                            <k-input :style="{ width: '100px' }" v-model="wetsignHoursAfter"></k-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Email Recipients-->
    <EmailRecipients :offset="offset" :show="showRecipientsModal" :systemRoles="systemRoles" :emailRecipients="emailRecipients" @handleSaveEmailRecipients="handleSaveEmailRecipients" @closeEmailRecipientsModal="closeEmailRecipientsModal" @addRecipient="addRecipient" @deleteRecipient="deleteRecipient"/>

    <!--Email Footer-->
    <popup :show="show" :popup-class="'popup-content'" :offset="offset" style="width:60vw;">
        <div id='eConsent' style="padding:1em;">
            <div class="row pb-m">
                <div class='col-lg-6'>
                    <h4>Edit Email Footer</h4>
                </div>
                <div class='col-lg-6' style="text-align:right;">
                    <button @click="handleSaveEmailFooter" class="k-button k-primary" style="margin-right:1em;">
                        Save
                    </button>
                    <button @click="close" class="k-button k-primary" style="margin-right:1em;">
                        Cancel
                    </button>
                </div>
            </div>
            <br />
            <div class='col-md-12'>
                <editor ref="editor"
                        :tools="tools"
                        :content-style="{
                            height: '490px'
                          }"
                        :default-content="emailFooter" />
            </div>
        </div>
    </popup>
</template>

<script>
    import moment from "moment";
    import ApiService from "@/core/services/api-service";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Button } from '@progress/kendo-vue-buttons';
    import { Input } from '@progress/kendo-vue-inputs';
    import Swal from "sweetalert2";
    import { Switch } from "@progress/kendo-inputs-vue-wrapper";
    import EmailsTemplate from "../components/grids/Emails";
    import { Popup } from '@progress/kendo-vue-popup';
    import { Editor } from "@progress/kendo-vue-editor";
    import EmailRecipients from "../components/EmailRecipients";

    export default {
        name: "EmailSettings",
        computed: { moment: () => moment },
        components: {
            'dropdownlist': DropDownList,
            "k-switch": Switch,
            'k-input': Input,
            'k-button': Button,
            'EmailsTemplate': EmailsTemplate,
            'popup': Popup,
            'editor': Editor,
            'EmailRecipients': EmailRecipients,
        },
        data: function () {
            return {
                autoInvite: false,
                enableInvite: false,
                reminder24: false,
                reminder48: false,
                reminder72: false,
                packageActivated: false,
                packageExpiring: false,
                packageExpired: false,
                expiredHoursBefore: '',
                expiredHoursAfter: '',
                enableWetsign: true,
                wetsignAlertTime: '',
                wetsignHoursBefore: '',
                wetsignHoursAfter: '',
                hours: [],
                show: false,
                emailFooter: '',
                offset: { left: 335, top: 108 },
                tools: [
                    ["Bold", "Italic", "Underline", "Strikethrough"],
                    ["Subscript", "Superscript"],
                    ["AlignLeft", "AlignCenter", "AlignRight", "AlignJustify"],
                    ["Indent", "Outdent"],
                    ["OrderedList", "UnorderedList"],
                    "FontSize",
                    "FontName",
                    "FormatBlock",
                    ["Undo", "Redo"],
                    ["Link", "Unlink", "InsertImage", "ViewHtml"],
                    ["InsertTable"],
                    ["AddRowBefore", "AddRowAfter", "AddColumnBefore", "AddColumnAfter"],
                    ["DeleteRow", "DeleteColumn", "DeleteTable"],
                    ["MergeCells", "SplitCell"],
                ],
                showRecipientsModal: false,
                recipients: '',
                systemRoles: [],
                emailRecipients: [],
            };
        },

        async mounted() {

            this.populateHours();
            //load email settings
            await ApiService.GenerateGetRequest(
                "Admin/LenderEmailConfig"
            ).then((response) => {
                console.log("get email settings ", response);
                this.autoInvite = response.content.enableAutomaticInvitations;
                this.enableInvite = response.content.enableInvitationReminders;
                this.reminder24 = response.content.reminder24;
                this.reminder48 = response.content.reminder48;
                this.reminder72 = response.content.reminder72;
                this.packageActivated = response.content.enablePackageActivatedAlert;
                this.packageExpiring = response.content.enableExpiringAlert;
                this.packageExpired = response.content.enableExpiredAlert;
                this.expiredHoursBefore = response.content.hoursBeforeExpiredAlert;
                this.expiredHoursAfter = response.content.hoursAferExpiryDate;
                this.enableWetsign = response.content.enableWetsignNotification;
                this.setWetsignAlertTimeChange(response.content.wetsignNotificationTime);
                this.wetsignHoursBefore = response.content.wetsignHoursElapsedStart;
                this.wetsignHoursAfter = response.content.wetsignHoursElapsedEnd;
                this.emailFooter = response.content.emailFooter == null ? "" : response.content.emailFooter;
                this.recipients = response.content.recipients == null ? "" : response.content.recipients;

                if (this.recipients != "") {
                    var arr = this.recipients.split(",");
                    for (var i = 0; i < arr.length; i++) {
                        this.emailRecipients.push({ "roleId": arr[i].trim() });
                    }
                }
            });

            var userId = this.$store.state.Admin.loginInformation.userId;
            let response = await ApiService.GenerateGetRequest("Role/GetRoles/" + userId);
            console.log("system roles", response);
            if (response["isSuccess"])
                this.systemRoles = response["content"];
        },
        methods: {
            close() {
                this.show = !this.show;
            },
            handleEditEmailFooter() {
                this.show = true;
            },
            handleSaveEmailFooter(e) {
                this.emailFooter = this.$refs.editor.getHTML();
                this.show = !this.show;
                this.save(e);
            },
            closeEmailRecipientsModal() {
                this.showRecipientsModal = !this.showRecipientsModal;
            },
            handleEditEmailRecipients() {
                this.showRecipientsModal = true;
            },
            handleSaveEmailRecipients(e) {
                console.log("this.emailRecipients", this.emailRecipients);
                for (var i = 0; i < this.emailRecipients.length; i++) {
                    var roleId = this.emailRecipients[i].roleId;
                    if (i != 0) {
                        this.recipients = this.recipients + "," + roleId;
                    } else {
                        this.recipients = roleId;
                    }
                }
                console.log("this.recipients", this.recipients);
                this.showRecipientsModal = !this.showRecipientsModal;
                this.save(e);
            },
            autoInviteChange(event) {
                this.autoInvite = !this.autoInvite;
            },
            enableInviteChange(event) {
                this.enableInvite = !this.enableInvite;
            },
            reminder24Change(event) {
                this.reminder24 = !this.reminder24;
            },
            reminder48Change(event) {
                this.reminder48 = !this.reminder48;
            },
            reminder72Change(event) {
                this.reminder72 = !this.reminder72;
            },
            packageActivatedChange(event) {
                this.packageActivated = !this.packageActivated;
            },
            packageExpiringChange(e) {
                this.packageExpiring = !this.packageExpiring;
            },
            packageExpiredChange(e) {
                this.packageExpired = !this.packageExpired;
            },
            expiredHoursBeforeChange(e) {
                this.expiredHoursBefore = event.value;
            },
            expiredHoursAfterChange(e) {
                this.expiredHoursAfter = event.value;
            },
            enableWetsignChange(event) {
                this.enableWetsign = !this.enableWetsign;
            },
            setWetsignAlertTimeChange(id) {
                var item = this.hours.find(i => i.id == id);
                if (item !== undefined) this.wetsignAlertTime = item;
            },
            wetsignAlertTimeChange(event) {
                this.wetsignAlertTime = event.value;
            },
            wetsignHoursBeforeChange(e) {
                this.wetsignHoursBefore = event.value;
            },
            wetsignHoursAfterChange(e) {
                this.wetsignHoursAfter = event.value;
            },
            populateHours() {
                this.hours = [];
                var meridiem = ['AM', 'PM'];
                for (var i = 0; i < 24; ++i) {
                    var a = (i % 12 == 0) ? 12 : 0;
                    var value = ((i % 12) + a) + ':00 ' + meridiem[i / 12 | 0];
                    this.hours.push({ "id": i, "text": value });
                }
            },
            addRecipient() {
                this.emailRecipients.push({"roleId": 0});
            },
            deleteRecipient(index) {
                this.emailRecipients.splice(index, 1);
            },
            save(e) {

                var payload = {
                    "EnableAutomaticInvitations": this.autoInvite,
                    "EnableInvitationReminders": this.enableInvite,
                    "Reminder24": this.reminder24,
                    "Reminder48": this.reminder48,
                    "Reminder72": this.reminder72,
                    "EnablePackageActivatedAlert": this.packageActivated,
                    "EnableExpiringAlert": this.packageExpiring,
                    "EnableExpiredAlert": this.packageExpired,
                    "HoursBeforeExpiredAlert": this.expiredHoursBefore,
                    "HoursAferExpiryDate": this.expiredHoursAfter,
                    "EnableWetsignNotification": this.enableWetsign,
                    "WetsignNotificationTime": this.wetsignAlertTime.id,
                    "WetsignHoursElapsedStart": this.wetsignHoursBefore,
                    "WetsignHoursElapsedEnd": this.wetsignHoursAfter,
                    "EmailFooter": this.emailFooter,
                    "Recipients": this.recipients,
                }
                return ApiService.GeneratePostRequest("Admin/UpdateLenderEmailConfig", payload)
                    .then(
                        response => {
                            if (response["isSuccess"]) {
                                Swal.fire("Success", "Email settings updated sucessfully", "success");
                            } else {
                                Swal.fire(
                                    "Operation failed",
                                    "Email settings could not be updated",
                                    "error"
                                );
                            }
                        }
                    );
            },
        },
    };
</script>
<style scoped></style>