<template>
        <stepper :page-headers="headers" :current-step="currentStep" @step-clicked-manually="stepClickedManually">
            <template #content>
                <component :is="currentComponent" ref="formRef" :preloadedData="preloadedData" @component-valid="componentValid"></component>
            </template>

            <template #navigation>
                <form role="form" v-show="showContinueButton">
                    <div class="panel panel-primary setup-content" id="step-1">
                        <div class="col-md-12 btn-screen-page text-right">
                            <input type="button" :value="continueButtonText" @click.prevent="next" class="btn-secondary" :disabled="isDisabled">
                        </div>
                    </div>
                </form>
            </template>
        </stepper>



        <!--TODO:  USE TELEPORT COMPONENT TO RENDER THIS ON BODY ROOT - not needed but good for dom layout -->
        <div class="modal fade" tabindex="-1" role="dialog" ref="invitationScreenErrorsRef">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title">An Error Occured</h4>
                    </div>
                    <div class="modal-body">
                        <p>{{modalMessage}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    
</template>

<script>
    import ApiService from "@/core/services/api-service";
    import Stepper from "@/components/Stepper.vue";
    import SignerDetails from "@/components/forms/invitation-steps/SignerDetails.vue";
    import ReviewDocuments from "@/components/forms/invitation-steps/ReviewDocuments.vue";
    import TestAudioVideo from "@/components/forms/invitation-steps/test/TestAudioVideo.vue";

    import VerifySSN from "@/components/forms/invitation-steps/verify/VerifySSN.vue";
    import VerifyQuestions from "@/components/forms/invitation-steps/verify/VerifyQuestions.vue";
    import PhotoUpload from "@/components/forms/invitation-steps/verify/PhotoUpload.vue";
    import SignDocuments from "@/components/forms/invitation-steps/SignDocuments.vue";
    import StepperFormCompleted from "@/components/StepperFormCompleted.vue";

    
    // the values are the ids assigned in the DB
    const SIGNER_DETAILS = 1;
    const SIGNER_DETAILS_eSIGN_LINK = 9;
    const VERIFY_SSN = 2;
    const VERIFY_QUESTIONS = 3;
    const VERIFY_PHOTO_ID = 4;
    const TEST_AUDIO_VIDEO = 5;
    const REVIEW_DOCUMENTS = 6;
    const REVIEW_DOCUMENTS_eSIGN_LINK = 10;
    const SIGN_DOCUMENTS = 7;
    const SIGN_DOCUMENTS_eSIGN_LINK = 11;
    const COMPLETE_DOCUMENTS = 8;
    const COMPLETE_DOCUMENTS_eSIGN_LINK = 12;

    const CONTINUE_BUTTON_TEXT = "CONTINUE";

    let generateComponentToLoad = (id, showComplete = false) => {
        //1 - Signer Details - <signer-details ref="formRef" v-if="selectedItem == 1" />
        //2 - Verify SSN - <verify-s-s-n ref="formRef" v-if="selectedItem == 10" />
        //3 - Verify Questions - <verify-questions ref="formRef" v-if="selectedItem == 3" />
        //4 - Verify I.D - <photo-upload ref="formRef" v-if="selectedItem == 4" />
        //5 - Verify Hardware - <test-audio-video ref="formRef" v-if="selectedItem == 5" />
        //6 - Review Documents -  <review-documents ref="formRef" v-if="selectedItem == 9" />
        //7 - Sign Documents - <review-documents ref="formRef" v-if="selectedItem == 11" />
        //8 - Complete - <review-documents ref="formRef" v-if="selectedItem == 12" />
        if (showComplete) {
            return "stepper-form-completed";
        }
        switch (id) {
            case SIGNER_DETAILS:
            case SIGNER_DETAILS_eSIGN_LINK:
                return "signer-details";
            case VERIFY_SSN:
                return "verify-s-s-n";
            case VERIFY_QUESTIONS:
                return "verify-questions";
            case VERIFY_PHOTO_ID:
                return "photo-upload";
            case TEST_AUDIO_VIDEO:
                return "test-audio-video";
            case REVIEW_DOCUMENTS:
            case REVIEW_DOCUMENTS_eSIGN_LINK:
                return "review-documents";
            case SIGN_DOCUMENTS:
            case SIGN_DOCUMENTS_eSIGN_LINK:
                return "sign-documents";
            case COMPLETE_DOCUMENTS:
            case COMPLETE_DOCUMENTS_eSIGN_LINK:
                return "stepper-form-completed";
        }
    }
    export default {
        name: 'Invitation',
        components: {
            Stepper,
            SignerDetails,
            TestAudioVideo,
            VerifySSN,
            VerifyQuestions,
            PhotoUpload,
            ReviewDocuments,
            SignDocuments,
            StepperFormCompleted
        },
        data() {
            return {
                headers: [],

                preloadedData: null,

                isDisabled: true,
                currentStep: 0,
                stepList: [],
                modalMessage: "",
                continueButtonText: CONTINUE_BUTTON_TEXT,
                showContinueButton: true

            }
        },

        created() {
            this.stepList = this.$store.getters["App/verificationSteps"];
            this.headers = this.stepList.map(function (item) {
                return item["name"]
            });


            // load current step from url hash or last known step
            if (location.hash.trim() !== "") {
                let val = parseInt(location.hash.replace("#", ""));
                if (!isNaN(val) && val < this.stepList.length && val <= this.$store.getters["App/currentIncompleteVerificationStep"])
                    this.currentStep = val;
                else
                    this.currentStep = this.$store.getters["App/currentIncompleteVerificationStep"];
            } else
                this.currentStep = this.$store.getters["App/currentIncompleteVerificationStep"];


            this.showOrHideContinueButton(this.currentStep);

        },
        computed: {
            currentComponent() {
                return generateComponentToLoad(this.selectedItem, this.showCompletedUI);
            },
            selectedItem() {
                //debugger
                return this.stepList[this.currentStep]["stepOrder"] || -1;
            },
            showCompletedUI() {
                let isOneTimeCompletion = this.stepList[this.currentStep]["isOneTimeCompletion"];
                let isCompleted = this.$store.getters["App/currentIncompleteVerificationStep"] > this.currentStep;
                return isOneTimeCompletion && isCompleted;
            },
        },
        methods: {
            async next() {
                // checks to handle preloading data if needed - research ways to write this in a more dynamic way
                // i.e each new step that requires data preloaded would need an if statement
                if (this.stepList[this.currentStep + 1]["id"] === VERIFY_QUESTIONS) {// remove + 10
                    this.handleContinueButtonChange();
                    this.modalMessage = "";

                    let questions = await this.fetchQuestions();
                    console.log("Fetching questions");
                    if (questions !== null) {
                        this.preloadedData = questions;
                        if (await this.$refs.formRef.isValid()) {
                            if (!this.showCompletedUI)
                                this.isDisabled = true;
                            this.currentStep++;
                            this.updateLastReachedStepOrderId();
                        }
                        return;
                    }
                    // show modal here
                    this.modalMessage = "We're having trouble fetching your verification questions. Please try again later or contact site administrator.";
                    $(this.$refs.invitationScreenErrorsRef).modal('show');

                } else if (await this.$refs.formRef.isValid()) {
                    this.handleContinueButtonChange();
                    this.currentStep++;
                    //TODO: refactor to work in all if else conditions - instead of having duplicate code
                    this.updateLastReachedStepOrderId();
                    // only disable if the current component isn't completed
                    if (!this.showCompletedUI)
                        this.isDisabled = true;

                }

            },
            // this event is when the component tell us the data is valid, enable the continue button
            componentValid(value, moveForwardNow = false) {
                this.isDisabled = !value;
                if (moveForwardNow) {
                    this.next();
                }
            },
            stepClickedManually(index) {
                this.handleContinueButtonChange(index);
                if (this.$store.getters["App/currentIncompleteVerificationStep"] >= index) {
                    this.currentStep = index;
                    // if show completed ui is true - disable button since nothing else to do
                    if (this.showCompletedUI)
                        this.isDisabled = false;
                }
            },
            handleContinueButtonChange(index) {
                let i = index || this.currentStep + 1;
                if (this.stepList[i]["id"] === REVIEW_DOCUMENTS || this.stepList[i]["id"] === REVIEW_DOCUMENTS_eSIGN_LINK) {
                    this.continueButtonText = "START SIGNING";
                } else if (this.continueButtonText != CONTINUE_BUTTON_TEXT)
                    this.continueButtonText = CONTINUE_BUTTON_TEXT;
               
                this.showOrHideContinueButton(i);
            },
            showOrHideContinueButton(i) {
                if (this.stepList[i]["id"] === SIGN_DOCUMENTS || this.stepList[i]["id"] === SIGN_DOCUMENTS_eSIGN_LINK && this.showContinueButton) {
                    this.showContinueButton = false;
                } else if (!this.showContinueButton) {
                    this.showContinueButton = true;
                }
            },
            updateLastReachedStepOrderId() {
                if (this.currentStep > this.$store.getters["App/currentIncompleteVerificationStep"]) {

                    this.$store.commit("App/updateLastReachedStepOrderId", { StepOrderId: this.currentStep });
                }
            },
            async fetchQuestions() {
                let response = await ApiService.GenerateGetRequest("verify/questions");
                if (response["isSuccess"]) {
                    return response["content"];
                } else {
                    return null;
                }
            },
        }
    }
</script>
