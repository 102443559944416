<template>
    <span :id="'ga-' + id" class="box"
          :style="{
              left: left + 'px',
              top: ((main && elementInView == true) || elementInView == undefined ? top : 0) + 'px',
              'z-index': increaseZIndex ? 100 : 2,
              'position': elementInView == false ? 'sticky' : 'absolute'
          }"
          :class="[ pending ? 'pending':'',
                   !pending && optional ? 'optional':'',
                   direction === 'top' ? 'arrow-top' : '',
                   text === '' ? 'mini-arrow' : '',
                   direction === 'bottom' ? 'arrow-bottom' : '',
                   direction === 'left' ? 'arrow-left' : '',
                   direction === 'right' ? 'arrow-right' : '',
                   !enabled ? 'disabled': '',
                   isnotarytag ? 'notary-tag' : '',
                   main ? 'main-width':''

          ]">
        {{text}}
    </span>
</template>

<script>
    export default {
        name: 'GuidedArrow',
        props: ["text", "direction", "left", "top", "id", "pending", "enabled", "optional", "increaseZIndex", "elementInView", "main", "isnotarytag"],
        data() {
            return {
                color: 'rgba(255, 255, 0, 1)',
                opacityLevel: 1
            }
        },
        async created() {
            let settings = await this.$store.getters["App/appSettings"];
            this.opacityLevel = settings.tagOpacity / 100;  // 80 / 100 = 0.80
            this.updateColor();
        },
        watch: {
            pending: function (newVal, oldVal) {
                if (newVal !== oldVal)
                    this.updateColor();
            },
            enabled: function (newVal, oldVal) {
                if (newVal !== oldVal)
                    this.updateColor();
            },
        },
        methods: {
            updateColor() {
                if (!this.enabled) {
                    this.color = `rgba(221, 221, 221, ${this.opacityLevel})`;
                    return;
                }

                if (this.pending)
                    this.color = `rgba(255, 255, 0, ${this.opacityLevel})`;
                else if (!this.pending && this.optional)
                    this.color = `rgba(0, 114, 225, ${this.opacityLevel})`;
                
            }
        }
    }
</script>

<style scoped>
    .box {
        background-color: green;
        color: #fff;
        padding: 5px;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        min-width: 80px !important;
        min-height: 30px !important;
        text-align: center;
        display: inline-block;
        box-shadow: 1px 1px 2px 0px white;
    }

    .box.main-width {
        min-width: 140px !important;
    }
    .box.arrow-top {
        margin-top: 40px;
    }

            .box.arrow-top:after {
                content: " ";
                position: absolute;
                right: 50%;
                transform: translateX(50%);
                bottom: 24px;
                border-top: none;
                border-right: 15px solid transparent;
                border-left: 15px solid transparent;
                border-bottom: 15px solid green;
            }

        .box.arrow-right:after {
            content: " ";
            position: absolute;
            right: -15px;
            top: 0;
            border-top: 15px solid transparent;
            border-right: none;
            border-left: 15px solid green;
            border-bottom: 15px solid transparent;
        }

        .box.arrow-bottom:after {
            content: " ";
            position: absolute;
            right: 30px;
            bottom: -15px;
            border-top: 15px solid green;
            border-right: 15px solid transparent;
            border-left: 15px solid transparent;
            border-bottom: none;
            right: 50%;
            transform: translateX(50%);
        }

        .box.arrow-left:after {
            content: " ";
            position: absolute;
            left: -15px;
            top: 0;
            border-top: 15px solid transparent;
            border-right: 15px solid green;
            border-left: none;
            border-bottom: 15px solid transparent;
        }

        .box.pending {
            background-color: v-bind(color);
            color: #000;
            /*box-shadow: 1px 1px 2px 0px #cdcdcd;*/
        }

            .box.pending.arrow-top:after {
                content: " ";
                position: absolute;
                right: 30px;
                top: 0;
                border-top: none;
                border-right: 15px solid transparent;
                border-left: 15px solid transparent;
                border-bottom: 15px solid v-bind(color);
            }

            .box.pending.arrow-right:after {
                content: " ";
                position: absolute;
                right: -15px;
                top: 0;
                border-top: 15px solid transparent;
                border-right: none;
                border-left: 15px solid v-bind(color);
                border-bottom: 15px solid transparent;
            }

            .box.pending.arrow-bottom:after {
                content: " ";
                position: absolute;
                right: 30px;
                bottom: -15px;
                border-top: 15px solid v-bind(color);
                border-right: 15px solid transparent;
                border-left: 15px solid transparent;
                border-bottom: none;
            }

            .box.pending.arrow-left:after {
                content: " ";
                position: absolute;
                left: -15px;
                top: 0;
                border-top: 15px solid transparent;
                border-right: 15px solid v-bind(color);
                border-left: none;
                border-bottom: 15px solid transparent;
            }

        .box.optional {
            background-color: v-bind(color);
            color: #FFF;
            box-shadow: 1px 1px 2px 0px #cdcdcd;
        }
            .box.optional.arrow-top:after {
                content: " ";
                position: absolute;
                right: 30px;
                top: 0;
                border-top: none;
                border-right: 15px solid transparent;
                border-left: 15px solid transparent;
                border-bottom: 15px solid v-bind(color);
            }

            .box.optional.arrow-right:after {
                content: " ";
                position: absolute;
                right: -15px;
                top: 0;
                border-top: 15px solid transparent;
                border-right: none;
                border-left: 15px solid v-bind(color);
                border-bottom: 15px solid transparent;
            }

            .box.optional.arrow-bottom:after {
                content: " ";
                position: absolute;
                right: 30px;
                bottom: -15px;
                border-top: 15px solid v-bind(color);
                border-right: 15px solid transparent;
                border-left: 15px solid transparent;
                border-bottom: none;
            }

            .box.optional.arrow-left:after {
                content: " ";
                position: absolute;
                left: -15px;
                top: 0;
                border-top: 15px solid transparent;
                border-right: 15px solid v-bind(color);
                border-left: none;
                border-bottom: 15px solid transparent;
            }

        .box.disabled {
            background-color: v-bind(color);
            color: #000;
        }
            .box.disabled.arrow-top:after {
                content: " ";
                position: absolute;
                right: 30px;
                top: 0;
                border-top: none;
                border-right: 15px solid transparent;
                border-left: 15px solid transparent;
                border-bottom: 15px solid v-bind(color);
            }

            .box.disabled.arrow-right:after {
                content: " ";
                position: absolute;
                right: -15px;
                top: 0;
                border-top: 15px solid transparent;
                border-right: none;
                border-left: 15px solid v-bind(color);
                border-bottom: 15px solid transparent;
            }

            .box.disabled.arrow-bottom:after {
                content: " ";
                position: absolute;
                right: 30px;
                bottom: -15px;
                border-top: 15px solid v-bind(color);
                border-right: 15px solid transparent;
                border-left: 15px solid transparent;
                border-bottom: none;
            }

            .box.disabled.arrow-left:after {
                content: " ";
                position: absolute;
                left: -15px;
                top: 0;
                border-top: 15px solid transparent;
                border-right: 15px solid v-bind(color);
                border-left: none;
                border-bottom: 15px solid transparent;
            }

		.box.mini-arrow {
			min-width: 20px !important;
			min-height: 30px !important;
		}

        .box.notary-tag {
            visibility: hidden;
        }

	    @media only screen and (max-width: 600px) {
			.box {
				padding: 2px 10px;
				min-height: 0 !important;
				min-width: 0 !important;
				font-size: 12px;
				margin-left: 20px;
			}
				.box.pending.arrow-left:after {
					content: " ";
					position: absolute;
					left: -12px;
					top: 0;
					border-top: 11px solid transparent;
					border-left: none;
					border-bottom: 10px solid transparent;
				}

				.box.disabled {
					display: none;
				}

				.box.main-width {
					margin-left: -24px;
					min-width: 0 !important;
					font-size: 8px;
					padding: 2px 4px;
					background-color: #008000ad;
					box-shadow: none;
				}
					.box.main-width.arrow-left:after {
						border-top: 12px solid transparent !important;
						border-right: 15px solid #008000ad;
						border-left: none;
						border-bottom: 14px solid transparent !important;
					}

				.box.arrow-bottom:after {
					border-top: 15px solid #008000ad;
				}
				.box.optional.arrow-left:after {
					border-top: 11px solid transparent;
					border-left: none;
					border-bottom: 11px solid transparent;
				}
	    }
</style>
