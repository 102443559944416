<template>
    <router-link-bread-crumbs :navItems="navItems" />
    <h3>{{ reportName }}</h3>
    <hr class="solid" />
    <k-button primary="true"
              :icon="'excel'"
              @click="exportExcel"
              style="margin-right:10px">Export Excel</k-button>
    <k-button primary="true" :icon="'pdf'" @click="exportPDFWithComp">Export PDF</k-button>
    <pdfexport ref="gridPdfExport" fileName="Report">
        <Grid ref="grid"
              :data-items="data"
              :columns="columns"
              :total="total"
              :take="take"
              :skip="skip"
              :pageable="pageable"
              @pagechange="pageChangeHandler"
              @rowclick="onRowClick"
              :resizable="true"
              :page-size="pageSize">
            <grid-norecords>
                There is no data available custom
            </grid-norecords>
        </Grid>
    </pdfexport>
</template>
<script>
    import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
    import ApiService from "@/core/services/api-service";
    import moment from "moment";
    import { loadFileUrl } from "@/core/services/api-service";
    import { Button } from "@progress/kendo-vue-buttons";
    import { useRoute } from "vue-router";
    import RouterLinkBreadCrumbsVue from "@/components/RouterLinkBreadCrumbs.vue";
    import { saveExcel } from "@progress/kendo-vue-excel-export";
    import { GridPdfExport } from "@progress/kendo-vue-pdf";

    export default {
        name: "AdminReportTable",
        props: [],
        components: {
            Grid: Grid,
            "grid-toolbar": GridToolbar,
            "grid-norecords": GridNoRecords,
            "k-button": Button,
            RouterLinkBreadCrumbs: RouterLinkBreadCrumbsVue,
            pdfexport: GridPdfExport,
        },
        computed: {
            data: {
                get: function () {
                    return this.reportData.slice(this.skip, this.take + this.skip);
                },
            },
            total() {
                return this.reportData ? this.reportData.length : 0;
            },
            moment: () => moment,
            areAllSelected() {
                return (
                    this.reportData.findIndex((item) => item.selected === false) === -1
                );
            },
            selectedTotal() {
                return this.reportData.filter((x) => x.selected == true).length;
            },
            columns() {
                if (this.reportId == 2) {
                    return [
                        {
                            field: "Id",
                            editable: false,
                            hidden: true,
                        },
                        {
                            field: "loanNumber",
                            title: "Loan Number",
                        },
                        {
                            field: "borrowerNames",
                            title: "Borrower Names",
                        },
                        {
                            field: "propertyAddress",
                            title: "Property Address",
                        },
                        {
                            field: "scanBackReceived",
                            title: "Scan Back Receieved",
                        },
                        {
                            field: "uploaderName",
                            title: "Uploader Name",
                        },
                        {
                            field: "documentName",
                            title: "Document Name",
                        },
                        {
                            field: "createdDate",
                            title: "Date Received",
                            cell: this.dateFormat,
                        },
                    ];
                } else {
                    return [
                        {
                            field: "Id",
                            editable: false,
                            hidden: true,
                        },
                        {
                            field: "createdDate",
                            title: "Creation Date",
                            cell: this.dateFormat,
                        },
                        {
                            field: "loanNumber",
                            title: "Loan Number",
                        },
                        {
                            field: "borrowerName",
                            title: "Borrower Name",
                        },
                        {
                            field: "loanOfficer",
                            title: "Loan Officer",
                        }
                    ];
                }
            },
        },
        data: function () {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                host: loadFileUrl,
                selectedField: "selected",
                reportId: 0,
                reportName: "",
                navItems: [
                    {
                        id: 1,
                        label: "Home",
                        url: "/admin",
                        icon: "home",
                    },
                    {
                        id: 2,
                        label: "Admin Reports",
                        icon: "graph",
                        url: "/admin/reports-listing/",
                    },
                ],
                reportData: [],
                pageable: {
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: true,
                    previousNext: true,
                },
            };
        },
        async created() {
            const route = useRoute();
            const reportId = route.params.reportId;
            this.reportId = reportId != null && reportId !== "" ? reportId : 0;
            if (this.reportId > 0) {
                let response = await ApiService.GenerateGetRequest(
                    "admin/reports/" + reportId
                );
                if (response && response.isSuccess) {
                    this.reportData = response.content.reportData;
                    const reportName = response.content.name;
                    console.log(this.reportData);

                    this.reportName =
                        reportName != null && reportName != "" ? reportName : "";
                    this.navItems.push({
                        id: this.navItems.length + 1,
                        label: this.reportName,
                        icon: "table",
                    });
                }

            }
        },
        methods: {
            pageChangeHandler: function (event) {
                this.skip = event.page.skip;
                this.take = event.page.take;
            },
            onHeaderSelectionChange(event) {
                let checked = event.event.target.checked;
                this.reportData = this.reportData.map((item) => {
                    return { ...item, selected: checked };
                });
            },
            onSelectionChange(event) {
                event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
            },
            onRowClick(event) {
                event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
            },
            dateFormat: function (h, tdElement, props, listeners) {
                return h(
                    "td",
                    {
                        on: {
                            click: function (e) {
                                listeners.custom(e);
                            },
                        },
                    },
                    [
                        props.dataItem.createdDate
                            ? moment(props.dataItem.createdDate).format("MM/DD/YYYY hh:mm A")
                            : " - ",
                    ]
                );
            },
            exportExcel() {
                saveExcel({
                    data: this.reportData,
                    fileName: this.reportName,
                    columns: this.columns,
                });
            },
            exportPDFWithComp: function () {
                this.$refs.gridPdfExport.save(this.reportData);
            },
        },
    };
</script>
<style>
    .select-counter {
        color: #3f51b5;
        font-weight: 900;
    }

    /* Solid border */
    hr.solid {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        margin-left: -16px;
        margin-left: -16px;
    }

    .report-clickable {
        cursor: pointer !important;
    }

    .report-name {
        font-weight: bold !important;
        color: #4d97ac;
    }

        .report-name:hover {
            text-decoration: underline;
        }
</style>
