<template>
    <kendo-datasource ref="notesource"
                      :transport-read="readData"
                      :transport-create="createData"
                      :transport-update="updateData"
                      :transport-destroy="deleteData"
                      :schema-model-id="'noteID'"
                      :schema-model-fields="schema"
                      :transport-parameter-map="parameterMap"
                      :page-size="8">

    </kendo-datasource>

    <kendo-grid ref="kendoRecordingGrid"
                :data-source-ref="'notesource'"
                :height="500"
                :class="'notes'"
                :sortable="true"
                :resizable="true"
                :navigatable="true"
                :pageable="true"
                :pdf-all-pages="true"
                :pdf-avoid-links="true"
                :pdf-paper-size="'A4'"
                :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                :pdf-landscape="true"
                :pdf-repeat-headers="true"
                :pdf-scale="0.8"
                :columns="columns"
                :edit="onEdit"
                @databound="noteDataBound"
                @save="onPopUpModalSaved">

    </kendo-grid>
    <k-popup :show="videoshow"
            :popup-class="'popup-content'" style="width:479px;" :anchor="'kendoRecordingGrid'" :anchor-align="anchorAlign" :popup-align="popupAlign">
        <div class="popup-inner-content">
            <k-button @click="closePopup" class="k-button k-primary" style="margin:0.5em; width:70px; float:right;">
                CLOSE
            </k-button>
            <video ref="videoRef" style="max-width: 479px; height:360px;" controls :key="videoLink">
                <source :src="videoLink"
                        type="video/mp4">
            </video>
            </div>
</k-popup>
</template>
<style scoped>
    * {
        font-weight: 400;
    }

    /deep/ .note-cmd, /deep/ .note-cmd:hover, /deep/ .note-cmd::before {
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: unset;
        background: initial;
        border: none;
        border-radius: unset;
        transition: none;
        -webkit-transition: none;
        padding: 8px 5px;
        font: 900 .94em 'Lato', sans-serif;
        color: #4d97ac;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.completed /deep/ span, .status.remote /deep/ span {
            background: #19a71c;
        }

        .status.pending /deep/ span, .status.pendingsigning /deep/ span {
            background: #f8ab10;
        }

        .status.canceled /deep/ span {
            background: #ff2b2b;
        }

        .status.reviewed /deep/ span {
            background: #e960ea;
        }

        .status.pendingreview /deep/ span {
            background: #c14e4e;
        }

        .status.paper /deep/ span {
            background: #c6c6c6;
        }

        .status.signing /deep/ span, .status.signed /deep/ span {
            background: #296298;
        }

    .popup-inner-content {
        width: 479px;
        height: 396px;
        margin: 0;
        padding: 0;
    }

</style>
<script>
    import ApiService from "@/core/services/api-service";
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { downloadRecordingUrl } from "@/core/services/api-service";
    import { Popup } from '@progress/kendo-vue-popup';
    import { Button } from '@progress/kendo-vue-buttons';

    export default {
        name: 'Recordings',
        props: ["notarizeID"],
        components: {
            'kendo-grid': Grid,
            'kendo-grid-column': GridColumn,
            'kendo-datasource': KendoDataSource,
            'dropdownlist': DropDownList,
            'native-grid': NativeGrid,
            toolbar: GridToolbar,
            'k-popup': Popup,
            'k-button' : Button,
        },
        computed: {
            anchorAlign: function () {
                return {
                    horizontal: "center",
                    vertical: "middle",
                };
            },
            popupAlign: function () {
                return {
                    horizontal: "center",
                    vertical: "middle",
                };
            }
        },
        data() {
            ///****************************************************************************** NOTE ******************************************************************************///
            ///This is the ONLY WAY right now to include a function to specify if a column can be visible. All other ways fail due to a bug in kendo that attempts to add a _ctx ///
            ///to variables in the function thereby breaking the generated template. The workaround is to have an inline function so that kendo cannot access the function scope ///
            ///******************************************************************************************************************************************************************///

            return {
                schema: {
                    id: { editable: false },
                    createdOn: { type: 'string', editable: true },
                    videoUrl: { type: 'string', editable: true },
                    filePath: { type: 'string', editable: true },
                    duration: { type: 'string', editable: true },
                },
                columns: [
                    { field: "id", title: 'ID', hidden: true },
                    { field: "filePath", title: 'Video', width: '40%' },
                    { field: "createdOn", title: 'Date', width: '20%', format: "{0:MM/dd/YYYY h:mm}" },
                    { field: "duration", title: 'Duration (s)', width: '10%'},
                    {
                        title: '', width: "350px", command: [
                            { className: 'participant-cmd', text: 'View', click: (e) => { this.viewRecording(e) }, visible: function (e) { return true; } },
                            { className: 'participant-cmd', text: 'Download', click: (e) => { this.downloadRecording(e) }, visible: function (e) { return true; } },
							{ className: 'participant-cmd', text: 'copy', click: (e) => { this.copyRecording(e) }, visible: function (e) { return true; } },
                        ]
                    },

                ],

                notesList: [],
                fileURL: "",
                videoshow: false,
                videoLink: "",
            }
        },
        async created() {

            let response = await ApiService.GenerateGetRequest("Recording/GetRecordings/" + this.notarizeID);
            console.log(response, "recordings");
            if (response["isSuccess"])
                this.notesList = response["content"];
        },
        methods: {
            onPopUpModalSaved(e) {
                console.log("onPopUpModalSaved", e);
            },
            noteDataBound: function (e) {
                var self = this;
                var grid = e.sender;
                var items = e.sender.items();
            },
            parameterMap: function (options, operation) {
                console.log("options", options);
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            readData: function (e) {
                //console.log(e)
                return ApiService.GenerateGetRequest("Recording/GetRecordings/" + this.notarizeID).then(
                    response => {
                        if (response["isSuccess"]) {
                            e.success(response["content"]);
                            console.log("notes2", response["content"]);
                        }
                    }
                );
            },
            createData: function (e) {
                console.log("Creating", e);

            },

            updateData: function (e) {
                console.log("updating", e);

            },
            deleteData: function (e) {
                console.log("deleting", e);

            },
            convertDuration: function (e) {
                e.preventDefault();
                var gridRow = this.$refs.kendoRecordingGrid.kendoWidget().dataItem($(e.target).closest("tr"));
                var seconds = gridRow.duration;
                return seconds / 60;
            },
            searchGrid: function () {
                console.log(this.search);
            },
            async downloadRecording(e) {
                e.preventDefault();
                var gridRow = this.$refs.kendoRecordingGrid.kendoWidget().dataItem($(e.target).closest("tr"));
                this.fileURL = gridRow.filePath;
                const doc = downloadRecordingUrl + this.fileURL;
                console.log("download recording", doc);
                const blob = new Blob([doc], { type: 'application/octet-stream' });
                const link = document.createElement('a');
                link.href = doc;
                link.download = "filedownload";
                document.body.appendChild(link);
                link.click()
                URL.revokeObjectURL(link.href)
            },
            async viewRecording(e) {
                e.preventDefault();
                var gridRow = this.$refs.kendoRecordingGrid.kendoWidget().dataItem($(e.target).closest("tr"));
                this.fileURL = gridRow.filePath;
                const doc = downloadRecordingUrl + this.fileURL;
                this.videoLink = doc;
                this.videoshow = true;
                
                console.log("view Recording ", this.videoshow);
            },
            copyRecording(e) {
                e.preventDefault();
				var gridRow = this.$refs.kendoRecordingGrid.kendoWidget().dataItem($(e.target).closest("tr"));
				navigator.clipboard.writeText(gridRow.filePath);
			},
            closePopup() {
                this.videoshow = false;
                console.log("trigger close");
            },

        },
    }
</script>