<template>
    <section id="screen-page">
        <div class="bdr bdr-rad-15 container">
            <h3>Are you a Title Agent looking to close online?</h3>
            <p><a href="/Home/ContactSales">Request a callback from our sales team</a></p>

            <div class="bdr-bottom row">
                <div class="title col-md-12">
                    <div class="col-md-12 text-center">
                        <h2>Contact</h2>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-md-offset-3 text-left profile">
                <div class="row">
                    <contact-form />
                </div>
            </div>
        </div>
    </section>


</template>

<script>
    import ContactForm from "@/components/forms/ContactForm.vue";
    export default {
        name: 'Contact',
        components: { ContactForm  }
    }
</script>