<template>
    <h1>Control Panel</h1>

    <div class="frame">
        <form>
            <div class="panel" data-panel="data-panel">
                <div class="panel__body" data-options="data-options">

                    <!--General-->
                    <div class="panel__line">
                        <div class="title-container">
                            <h2>General</h2>
                        </div>
                        <!--<label class="panel__item">
                            <kbutton @click.prevent="$emit('abort')">Abort</kbutton>
                        </label>
                        <label class="panel__item">
                            <kbutton @click.prevent="handleAddPage(obj)">Add Page</kbutton>
                        </label>-->
                    </div>

                    <!--Signer Management-->
                    <div class="panel__line participants">
                        <div class="title-container" style="clear:both">
                            <h2 style="padding: 8px;float:left">
                                Participants
                            </h2>
                            <kbutton @click.prevent="addNewParticipant"
                                     :look="'flat'"
                                     style="float:right"
                                     class="k-button-primary" :icon="'plus'"><span class="k-icon  k-i-user"></span></kbutton>
                        </div>
                        <label class="panel__item">
                            <div v-for="(obj, key, index) in connectedSigners" style="width: 100%; margin-bottom: 1em; box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 75%); -webkit-box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 75%); -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75); padding: 5px; border-radius: 4px;" class="row participant-card" :key="index">
                                <label class="col-md-5" style="position:relative;">
                                    {{obj.firstName}} {{obj.lastName}}
                                    <span style=" font-size: .8em; display: block;"> {{obj.participantType.replaceAll('_', ' ')}}</span>
                                    <span class="connected-status" :class="{'connected-status-away' : obj.status === 'Away', 'connected-status-waiting': obj.status === 'WaitingRoom', 'connected-status-present' : obj.status === 'InRoom'}"></span>
                        </label>
                                <label class="col-md-7">
                                    <div style="display: flex; justify-content: space-between;">
                                        <!-- If In Room -->
                                        <template v-if="obj.status === 'InRoom' && obj.participantType != 'Notary'">

                                            <kbutton @click.prevent="$emit('signerDetailsClick')"
                                                     :look="'flat'"
                                                     class="k-button-primary"
                                                     style="font-size: 0.8em; color: rgba(78,95,187, 0.87); padding: 0.2em 0.2em; ">Details</kbutton>

                                            <kbutton v-if="obj.participantType !== 'Observer'"
                                                     @click.prevent="handleEnableTags(obj, index)"
                                                     :look="'flat'"
                                                     class="k-button-primary"
                                                     :class="{'tags-enabled': obj.isEnabled }"
                                                     style="font-size: 0.8em; padding: 0.2em 0.2em; color: rgba(78,95,187, 0.87); ">{{obj.isEnabled  ? 'Tags Enabled' : 'Enable Tags' }}</kbutton>

                                            <kbutton v-if="obj.status === 'InRoom' && obj.participantType == 'Observer'"
                                                     @click.prevent="declineParticipant(obj.id)"
                                                     :look="'flat'"
                                                     class="k-button-primary"
                                                     style="font-size: 0.8em; color: rgba(78,95,187, 0.87); padding: 0.2em 0.2em; ">Disconnect</kbutton>
                                        </template>
                                        <!-- If Waiting -->
                                        <template v-if="obj.status === 'WaitingRoom' && obj.participantType != 'Notary'">
                                            <kbutton @click.prevent="admitParticipant(obj.id)"
                                                     :look="'flat'"
                                                     class="k-button-primary"
                                                     style="font-size: 0.8em; color: green; padding: 0.2em 0.2em; ">Admit</kbutton>

                                            <kbutton v-if="obj.participantType !== 'Observer'"
                                                     @click.prevent="handleManageTags(obj)"
                                                     :look="'flat'"
                                                     class="k-button-primary"
                                                     style="font-size: 0.8em; padding: 0.2em 0.2em; color: rgba(78,95,187, 0.87); ">Manage Tags</kbutton>
                                        </template>
                                        <!-- If Away -->
                                        <template v-if="obj.status === 'Away' && obj.participantType != 'Notary'">
                                            <kbutton @click.prevent="$emit('signerDetailsClick')"
                                                     :look="'flat'"
                                                     class="k-button-primary"
                                                     style="font-size: 0.8em; color: rgba(78,95,187, 0.87); padding: 0.2em 0.2em; ">Details</kbutton>

                                            <kbutton v-if="obj.participantType !== 'Observer'"
                                                     @click.prevent="handleManageTags(obj)"
                                                     :look="'flat'"
                                                     class="k-button-primary"
                                                     style="font-size: 0.8em; padding: 0.2em 0.2em; color: rgba(78,95,187, 0.87); ">Manage Tags</kbutton>
                                        </template>
                                        <!--Notary-->
                                        <template v-if="obj.participantType == 'Notary'">
                                            <kbutton @click.prevent="handleManageTags(obj)"
                                                     :look="'flat'"
                                                     class="k-button-primary"
                                                     style="font-size: 0.8em; padding: 0.2em 0.2em; color: rgba(78,95,187, 0.87); ">Manage Tags</kbutton>
                                        </template>
                                    </div>
                                </label>
                                <label class="col-md-12 extra-options">
                                    <div style="display: flex; justify-content: space-between;">
                                        <kbutton v-if="obj.status === 'WaitingRoom' && obj.participantType != 'Notary'"
                                                 @click.prevent="$emit('signerDetailsClick')"
                                                 :look="'flat'"
                                                 class="k-button-primary"
                                                 style="font-size: 0.8em; color: rgba(78,95,187, 0.87); padding: 0.2em 0.2em; ">Details</kbutton>

                                        <kbutton v-if="obj.status === 'InRoom' && obj.participantType !== 'Observer'"
                                                 @click.prevent="handleManageTags(obj)"
                                                 :look="'flat'"
                                                 class="k-button-secondary"
                                                 style="font-size: 0.8em; padding: 0.2em 0.2em; background: rgba(255, 104, 32, 0.87); color: rgba(78,95,187, 0.87);">Manage Tags</kbutton>


                                        <kbutton v-if="obj.participantType === 'Legal_Witness' && obj.status === 'InRoom'"
                                                 @click.prevent="showIAttest(obj)"
                                                 :look="'flat'"
                                                 class="k-button-secondary"
                                                 style="font-size: 0.8em; padding: 0.2em 0.2em; background: rgba(255, 104, 32, 0.87); color: rgba(78,95,187, 0.87);">Show I Attest</kbutton>


                                        <kbutton v-if="obj.status === 'InRoom' && obj.participantType !== 'Observer'"
                                                 @click.prevent="declineParticipant(obj.id)"
                                                 :look="'flat'"
                                                 class="k-button-primary"
                                                 style="font-size: 0.8em; color: rgba(78,95,187, 0.87); padding: 0.2em 0.2em; ">Disconnect</kbutton>
                                        <!--<kbutton v-if="obj.status === 'InRoom'"
    @click.prevent="admitParticipant(obj.participantId)"
    :look="'flat'"
    class="k-button-primary"
    style="font-size: 0.8em; padding: 0.2em 0.2em; color: rgba(78,95,187, 0.87); ">View Signer Screen</kbutton>-->
                                    </div>
                                </label>
                            </div>
                            <!--<table class="table table-bordered">
                                <tbody>

                                    <tr v-for="(obj, key, index) in connectedSigners" :key="index">
                                        <td class="col-md-12" style="text-align:center" v-if="obj.signer.notarizeID == NotarizeID">
                                            <h5></h5>
                                            <hr />
                                            <a href="#" @click="handleEnableTags(obj, index)" class="k-button" style="width: 15rem; margin-bottom: 1rem; margin-top: 1rem; margin-right: 1rem;" v-if="obj.participantType == 'Signer' || obj.participantType == 'Legal_Witness'">
                                                Enable Tags
                                            </a>
                                            <a href="#" @click="handleManageTags(obj)" class="k-button" style="width: 15rem; margin-bottom: 1rem; margin-top: 1rem; margin-right: 1rem;" v-if="obj.participantType == 'Signer' || obj.participantType == 'Legal_Witness' || obj.participantType == 'Notary'">
                                                Manage Tags
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>-->
                        </label>
                    </div>
                        </div>
            </div>
        </form>
    </div>

    <!--Manage Tags Modal-->
    <div ref="manageTagsModalRef" class="modal" style="display: none;">
        <div>
            <div class="modal-dialog">
                <div class="modal-content" style=" background: white;">
                    <div class="modal-header">
                        <h1>Manage Tags for {{selectedParticipant.firstName}} {{selectedParticipant.lastName}}</h1>
                        <div>
                            <button type="button" class="close" data-dismiss="modal">
                                x
                            </button>
                        </div>
                                    </div>

                    <div class="modal-body">
                                            <ul class="list" style="padding: 10px; display: flex; flex-wrap: wrap; text-align: center; margin-top: 0;">
                                                <li style=" flex: 0 0 33.333333%" v-for="tag in tagTypes">
                                <label @click="handleTagClicked(tag.type)">
                                                        {{tag.type}}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                    </div>
    </div>

    <!--Add Page Modal-->
    <div ref="addPageModalRef" class="modal" style="display: none;">
        <div>
            <div class="modal-dialog">
                <div class="modal-content" style=" background: white;">
                    <div class="modal-header">
                        <h1>Add Page</h1>
                        <div>
                            <button type="button" class="close" data-dismiss="modal">
                                x
                            </button>
                        </div>
                    </div>

                    <div class="modal-body">
                                            <span style="padding: 10px 0 0 10px; display: inline-block">Select a Document</span>
                                            <ul class="list" style="padding: 0 10px 10px 10px; margin-top: 0;">
                                                <li style="clear: both; " v-for="item in notarizeDocuments">
                                                    <label @click="showModalNotarialAct(item.notarialActName)">
                                                        {{item.notarialActName}}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
    </div>

</template>
<script>
    import { PanelBar } from '@progress/kendo-vue-layout';
    import { Button } from "@progress/kendo-vue-buttons";
    import ApiService from "@/core/services/api-service";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import Swal from "sweetalert2";

    export default {
        name: 'NotaryControlPanel',
        components: {
            PanelBar,
            "kbutton": Button,
            'dropdownlist': DropDownList,
        },
        props: ['NotarizeID', 'connectedSigners'],
        emits: ["signerDetailsClick", "showIAttest", "abort", "selectedSignerId", "addParticipant"],
        data() {
            return {
                notarizeDocuments: [],
                userProfile: [],
                tagTypes: [],
                selectedParticipant: {},
            };
        },
        async created() {
            var response = await ApiService.GenerateGetRequest("document/tag/types");
            if (response && response["isSuccess"]) {
                this.tagTypes = response["content"].map((item) => {
                    return { text: item.name, type: item.type, id: item.id };
                }).filter((item) => item.type !== "OptionGroupValue");
            }

        },
        async mounted() {
            //get user profile
            await ApiService.GenerateGetRequest(
                "Admin/GetUserProfile"
            ).then((response) => {
                console.log("Admin/GetUserProfile: ", response);
                this.userProfile = response.content;
            });
            console.log("notarizeid in notarycontrolpanel: ", this.NotarizeID);

            var userId = this.$store.state.Admin.loginInformation.userId;

            var response = await ApiService.GenerateGetRequest(`Notarize/activenotarizeact?userId=` + userId);
            console.log("activenotarizeact in notarycontrolpanel:", response);
            if (response.isSuccess) {
                this.notarizeDocuments = response.content
            }
        },
        methods: {
            log(val) {
                console.log('Booyah', val);
                console.log('Booyah', JSON.stringify(val));
            },
            imageUrl(imageName) {
                let baseImageUrl =
                    'https://demos.telerik.com/kendo-ui/content/web/panelbar/';
                return baseImageUrl + imageName + '.jpg';
            },
            showModalNotarialAct(type) {
                if (this.userProfile.seal == null || this.userProfile.seal == "") {
                    Swal.fire("Error", "No seal found!", "error");
                } else {
                    this.$emit("notarialActType", type);
                    this.$emit("showModalNotarialAct", true);
                }
                $(this.$refs.addPageModalRef).modal("hide");
            },
            handleEnableTags(obj, index) {
                this.$emit("selectedParticiapnt", obj);
                this.$emit('enableSigning');
            },
            showIAttest(obj) {
                this.$emit("selectedParticiapnt", obj);
                this.$emit("showIAttest");
            },
            handleManageTags(obj) {
                this.selectedParticipant = obj;
                $(this.$refs.manageTagsModalRef).modal("show");
                this.$emit("selectedParticiapnt", obj);
            },
            handleAddPage(obj) {
                $(this.$refs.addPageModalRef).modal("show");
                this.$emit("selectedParticiapnt", obj);
            },
            handleTagClicked(type) {
                $(this.$refs.manageTagsModalRef).modal("hide");
                this.$emit('tagClicked', type)
            },
            addNewParticipant() {
                this.$emit('addParticipant');
            },
            async admitParticipant(i) {
                //push to participant & update waiting list
                await ApiService.GeneratePostRequest(`transaction/notaryAdmitParticipant`, { "SignerID": i, "NotarizeID": this.NotarizeID, "NotaryAction": "accept" });
                this.$emit('updateList');
            },
            async declineParticipant(i) {
                //send decline to user
                await ApiService.GeneratePostRequest(`transaction/notaryAdmitParticipant/`, { "SignerID": i, "notarizeID": this.NotarizeID, "NotaryAction": "decline" });
                this.$emit('updateList');
            },
            // function only here to show statuses till its available - remove when proper statues are pulled
            randomInteger(min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }
        },
    }
</script>
<style scoped>
    .frame {
        width: 100%;
        border-radius: 4px;
        box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background: #fff;
        color: #333;
        font-family: "Open Sans", Helvetica, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    header {
        text-align: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;
    }

    h1 {
        font-size: 1.7rem;
        margin-bottom: 4px;
    }

    h2 {
        font-size: 1rem;
        font-weight: 400;
    }

    .list {
        list-style: none;
        margin-top: 24px;
        padding-left: 0;
    }

        .list li {
            margin-top: 10px !important;
            padding-bottom: 0px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        .list label {
            cursor: pointer;
            flex-grow: 1;
            transition: color 0.5s;
        }

        .list input {
            position: relative;
        }

            .list input::after {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                border: 1px solid #888888;
                border-radius: 50%;
                transform: translate(-50%, -50%) scale(1);
                top: 50%;
                left: 50%;
                background-color: white;
                transition: transform 0.6s, border 0.6s;
            }

            .list input::before {
                position: absolute;
                /*content: "\2714";*/
                height: 18px;
                width: 18px;
                transform: translate(-50%, -50%) scale(0.8);
                top: 60%;
                left: 80%;
                color: #888;
                z-index: 1;
                opacity: 0;
                transition: transform 0.6s, opacity 0.6s;
            }

            .list input:checked + label {
                color: rgba(51, 51, 51, 0.4);
            }

            .list input:checked::after {
                transform: translate(-50%, -50%) scale(1.2);
                border: 1px solid rgba(136, 136, 136, 0);
            }

            .list input:checked::before {
                opacity: 0.4;
                transform: translate(-50%, -50%) scale(1);
            }

    .title-container {
        width: 100%;
        padding-top: 10px;
        padding-left: 10px;
    }

        .title-container h2 {
            margin: 0;
            font-size: 1.6rem;
        }

    .panel {
        display: flex;
    }

    .panel__body {
        width: 100%;
        border: 1px solid #CCC;
    }

    .panel__side {
        width: 30%;
        padding: 20px;
    }

    .panel__line {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        border-bottom: 1px solid #CCC;
    }

    .panel__item {
        flex-direction: row;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        /*width: calc(100% / 4);*/
        padding: 10px 10px;
    }

        .panel__item > span {
            padding: 10px 0;
        }

    .cart {
        border: 2px solid transparent;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .cart--active {
        border-color: red;
    }

    .cart__body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .panel__img {
        cursor: pointer;
    }

    .accordion .card-header {
        border: 1px solid #CCC;
    }

    .accordion .card {
        margin: 0;
        border-radius: 0;
    }

    .accordion h2 {
        margin: 0;
        font-size: 1.7rem;
        padding: 10px;
    }



    .connected-status {
        width: 10px;
        height: 10px;
        display: inline-block;
        float: right;
        border-radius: 50%;
        position: absolute;
        left: 6px;
        top:0;
    }


    .connected-status-present {
        background: green;
    }


    .connected-status-waiting {
        background: #ccb601;
    }


    .connected-status-away {
        background: red;
    }


    .participants .extra-options {
        display:none;
    }

    .participants .participant-card:hover .extra-options {
        display: block;
    }


    .tags-enabled {
        color: green !important;
    }
</style>
