<template>
    <div class="col-md-12" style="margin-top:20px">
        <div class="participants-grid">
            <div class="title-block">
                <h3>
                    <strong>Pipeline</strong>
                </h3>
            </div>

            <kendo-datasource ref="datasource1"
                              :transport-read="readData"
                              :schema-model-id="'loanPackageID'"
                              :schema-model-fields="schemaModelFields"
                              :schema-data="'data'"
                              :schema-total="'total'"
                              :schema-groups="'groups'"
                              :schema-aggregates="'aggregates'"
                              :schema-errors="'errors'"
                              :transport-parameter-map="parameterMap"
                              :server-filtering="true"
                              :server-paging="true"
                              :batch='true'
                              :page-size='20'>
            </kendo-datasource>
            <kendo-grid ref="kendoGridRef"
                        :data-source-ref="'datasource1'"
                        :pageable="pageable"
                        :editable="false"
                        :sortable="true"
                        :resizable="true"
                        :navigatable="true"
                        :pageable-refresh="true"
                        :pageable-button-count="5"
                        :pageable-page-sizes="[5, 10, 20, 100]"
                        :pageable-always-visible="true"
                        :persistSelection="false"
                        :filterable="shouldFilter"
                        :pdf-all-pages="false"
                        :pdf-avoid-links="false"
                        :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                        :pdf-landscape="true"
                        :pdf-repeat-headers="true"
                        :reorderable="true"
                        :columnMenu="{componentType: 'modern'}"
                        :columnHide="columnVisibilityEvent"
                        :columnShow="columnVisibilityEvent"
                        @databound="signingRoomDataBound"
                        @filterchange="filterChange"
                        @selectionchange="onSelectionChange"
                        @headerselectionchange="onHeaderSelectionChange"
                        @rowclick="onRowClick"
                        @columnReorder="columnReorder">
                <toolbar>
                    <button title="Export to Excel" class="k-button k-primary" @click="exportSelectedExcel">
                        Export Selected to Excel
                    </button>&nbsp;
                    <button class="k-button k-primary" @click="exportSelectedPDF">
                        Export Selected to PDF
                    </button>
                    <button title="Export All to Excel" class="k-button k-primary" @click="exportExcel">
                        Export All to Excel
                    </button>&nbsp;
                    <button class="k-button k-primary" @click="exportPDF">
                        Export All to PDF
                    </button>
                </toolbar>
                <kendo-grid-column :selectable="true" width="50px" menu="false"></kendo-grid-column>
                <kendo-grid-column :field="'loanPackageID'" :title="'ID#'" :filterable="false" :menu="false" hidden="{true}"></kendo-grid-column>
                <kendo-grid-column :field="'packageName'" :title="'Loan # / Identifier'" width="200px" :template="cellTmp" :filterable="true" :menu="false"></kendo-grid-column>
                <kendo-grid-column :field="'name'" :title="'Signer'" :template="listBorrowers" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'propertyAddress'" :title="'Property Address'" width="250px" :filterable="false"></kendo-grid-column>
                <kendo-grid-column :field="'packageDescription'" :title="'Package Description'" width="250px" :filterable="true"></kendo-grid-column>
                <kendo-grid-column :field="'signingDescription'" :title="'Signing Type'" width="150px" :filterable="true" :filterable-multi="true" :filterable-data-source="signingTypeDataSource"></kendo-grid-column>
                <kendo-grid-column :field="'transDate'" :title="'Transaction Date'" width="200px" :format="'{0:MM/dd/yyyy h:mm tt}'"></kendo-grid-column>
                <kendo-grid-column :field="'signingDate'" :title="'Signing Date'" width="150px" :format="'{0:MM/dd/yyyy h:mm tt}'"></kendo-grid-column>
                <kendo-grid-column :field="'createdDate'" :title="'Created Date'" width="150px" :format="dateFormat" :filterable-ui="'datepicker'"></kendo-grid-column>
                <kendo-grid-column :field="'loanPackageID'" :title="'Notary Journal'" width="200px" :template="journalTemplate"></kendo-grid-column>
                <kendo-grid-column :field="'signingRoom'" :title="'Signing Room'" width="200px" :template="signingRoom"></kendo-grid-column>
                <kendo-grid-column :field="'isNotary'" :title="'Notary Assigned'" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'statusDescription'" :title="'Status'" width="200px" :filterable="true" :filterable-multi="true" :template="statusTemplate"></kendo-grid-column>
                <kendo-grid-column :field="'lenderName'" :title="'Lender'" width="200px" :filterable="true"></kendo-grid-column>
                <kendo-grid-column :field="'settlementAgentName'" :title="'Settlement Agent'" width="200px" :filterable="true"></kendo-grid-column>
                <kendo-grid-column :field="'appointmentTime'" :title="'Appointment Time'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'documentsUploadedDate'" :title="'Documents Uploaded'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'loanScheduledDate'" :title="'Loan Scheduled'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'notaryAcceptedDate'" :title="'Notary Accepted'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'notaryConfirmedWithBorrowerDate'" :title="'Notary Confirmed with Borrower'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'signingCancelledDate'" :title="'Signing Cancelled'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'signingRescheduledDate'" :title="'Signing Resecheduled'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'notaryPrintsDocumentsDate'" :title="'Notary Printed Documents'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'signingUnableToBeCompletedDate'" :title="'Signing Unable to be Completed'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'documentsReceivedDate'" :title="'Documents Receieved'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'signingCompleteDate'" :title="'Signing Complete'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'fedExStatus'" :title="'FedEx Status'" width="200px" :filterable-extra="false"></kendo-grid-column>
                <kendo-grid-column :field="'fedExTracking'" :title="'FedEx Tracking'" width="200px" :filterable-extra="false"></kendo-grid-column>
            </kendo-grid>

            <pdfexport ref="gridPdfExport" v-show="false">
                <native-grid :columns="columns" />
            </pdfexport>
        </div>
    </div>
</template>
<style scoped>
    .counter-bubble-container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.in_progress /deep/ span {
            background: #f3a638;
        }

        .status.closed /deep/ span, .status.completed /deep/ span {
            background: #54b7d3;
        }

        .status.archived /deep/ span {
            background: #E3D4D4;
        }

        .status.cancelled /deep/ span,
        .status.opted_out /deep/ span,
        .status.wet_sign /deep/ span,
        .status.printed /deep/ span {
            background: slategray;
        }

    .loanlink {
        color: #4d97ac;
        font-weight: 600;
    }

    .mb-3.search {
        float: right;
        background: #e8e8e8;
        margin: -65px 0 0 0;
        padding: 8px;
        width: 250px;
    }

        .mb-3.search /deep/ input {
            padding: 0 30px 0 0;
            border: none;
            background: none;
            box-shadow: none;
        }

            .mb-3.search /deep/ input:focus {
                border: none;
                background: none;
                box-shadow: none;
                transition: none;
            }

        .mb-3.search .material-icons {
            float: right;
            margin: -28px 0 0 35px;
            border: none;
            background: none;
        }
</style>
<script>
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { GridPdfExport } from '@progress/kendo-vue-pdf';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import ApiService from "../../core/services/api-service";
    import GridDataHelper from "../../core/services/grid-data-helper";
    import { process } from '@progress/kendo-data-query';
    import { Input } from '@progress/kendo-vue-inputs';
    import { Button } from '@progress/kendo-vue-buttons';
    import cellTemplate from "../templates/celltemplate";
    import dashboardCanViewLoanCellTemplate from "../templates/dashboardCanViewLoanCellTemplate";

    const SIGN_TYPE_MAP = [{ id: 1, signingDescription: 'eSign' }, { id: 2, signingDescription: 'RON' }, { id: 3, signingDescription: 'PRON' }, { id: 4, signingDescription: 'IPEN' }, { id: 5, signingDescription: 'HYBRID_WET_DOCS_AND_RECORDABLES' }, { id: 6, signingDescription: 'WET_SIGN' }, { id: 7, signingDescription: 'HYBRID_WET_RECORDABLES' }, { id: 8, signingDescription: 'HYBRID_WET' }, { id: 9, signingDescription: 'EMAIL' }, { id: 10, signingDescription: 'HYBRID' }];
    const PACKAGE_STATUS_MAP = [{ id: 1, desc: 'In_Progress' }, { id: 2, desc: 'Completed' }, { id: 2, desc: 'Cancelled' }, { id: 4, desc: 'Archived' }, { id: 5, desc: 'Opted_Out' }, { id: 6, desc: 'Wet_Sign' }, { id: 7, desc: 'Closed' }]

    export default {
        name: 'AdminOrders',
        components: {
            'kendo-grid': Grid,
            'kendo-grid-column': GridColumn,
            'kendo-datasource': KendoDataSource,
            'pdfexport': GridPdfExport,
            'toolbar': GridToolbar,
            'dropdownlist': DropDownList,
            'k-button': Button,
            'k-input': Input,
            'cellTemplate': cellTemplate,
            'native-grid': NativeGrid,
            'dashboardCanViewLoanCellTemplate': dashboardCanViewLoanCellTemplate,
        },
        data: function () {
            return {
                signingTypeDataSource: SIGN_TYPE_MAP,
                search: null,
                schemaModelFields: {
                    LoanPackageID: { editable: false },
                    LoanNumber: { type: 'string', editable: false },
                    name: { type: 'string', editable: false },
                    transDate: { type: 'date', editable: false },
                    //scheduleDate: { type: 'date', editable: false },
                    signingDate: { type: 'date', editable: false },
                    notes: { type: 'string', editable: false },
                    paymentAmount: { type: 'string', editable: false },
                    isNotary: { type: 'string', editable: false },
                    signingRoom: { type: 'string', editable: false },
                    statusDescription: { type: 'string', editable: false },
                },
                selected: [],
                shouldFilter: true,
                filter: {
                    logic: "and",
                    filters: [ 
                    ],
                    sort: {
                        field: "loanPackageID",
                        dir: "asc"
                    }
                },
                columns: [
                    { field: "loanPackageID", title: 'Package ID', menu: false, hidden: true },
                    { field: "packageName", title: 'Loan Number' },
                    { field: "name", title: 'Name', template: this.listBorrowers },
                    { field: "transDate", title: 'Trans Date' },
                    //{ field: "scheduleDate", title: 'Schedule Date' },
                    { field: "signingDate", title: 'Signing Date' },
                    { field: "notes", title: 'Notes' },
                    { field: "isNotary", title: 'Notary Assigned' },
                    { field: "signingRoom", title: 'Signing Room', hidden: true },
                    { field: "loanPackageID", title: 'Notary Journal', template: this.journalTemplate, hidden: true },
                    { field: "statusDescription", title: 'Status' },
                ],
                userRole: '',
                configLock: false
            }
        },
        created() {
        },
        mounted() {
            ApiService.GenerateNonLoadingPostRequest("Admin/GetPipelineConfig").then(
                response => {
                    if (response.isSuccess) {
                        var colOrder = JSON.parse(response.content);
                        var grid = this.$refs.kendoGridRef.kendoWidget();
                        var gridColumns = grid.columns;

                        for (var i = 0; i < colOrder.length; i++) {
                            var col = colOrder[i]
                            var index = gridColumns.findIndex(e => e.field == col.field);
                            grid.reorderColumn(i, gridColumns[index]);
                            if (col.hidden == 'true') {
                                grid.hideColumn(i);
                            }
                        }
                    } else {
                        ApiService.GenerateNonLoadingGetRequest("Admin/IsNotary").then(
                            response => {
                                this.userRole = response.content.participantType;
                                console.log("userRole: " + this.userRole);
                                if (response.content.isNotary == true) {
                                    this.$refs.kendoGridRef.kendoWidget().hideColumn(8);
                                    this.$refs.kendoGridRef.kendoWidget().hideColumn(7);
                                }
                                else {
                                    this.$refs.kendoGridRef.kendoWidget().hideColumn(6);
                                    if (this.userRole == "User") {
                                        this.$refs.kendoGridRef.kendoWidget().hideColumn(7);
                                    }
                                }
                            }
                        );
                    }
                }
            );
        },
        computed: {
        },
        methods: { 
            listBorrowers: function (e) {
                if (e.borrowers.length) {
                    let html = '<a data-toggle="tooltip" data-placement="top" title="' + e.borrowers.join(", ") + '">' +
                        e.borrowers[0] + ' <span class="borrower-list-icon glyphicon glyphicon-info-sign" aria-hidden="true"></span> ' +
                        '</a>';
                    return html;
                } else {
                    return '';
                }

            },
            journalTemplate: function (e) {
                let html = `<a href="#" data-id="${e.loanPackageID}" class="link package-journal">Notary Journal</a>`;
                return html;
            },
            //GO TO SIGNING ROOM
            connectToSigningRoom(userID, notarizeID) {
                console.log("userID: ", userID);
                console.log("notarizeID: ", notarizeID);
            },
            signingRoom: function (e) {
                let html = `<a href="#" data-id="${e.signerID},${e.notarizeID}" class="link signing-room">View</a>`;
                return html; 
            },
            signingRoomDataBound: function (e) {
                var self = this;
                $(".signing-room.link").on("click", function (e) {
                    e.preventDefault();
                    var data = $(this).attr("data-id");
                    console.log("signing room data id: ", data);
                    self.$store.commit("Auth/updateSigningRoomData",
                        {
                            userId: data.substr(0, data.indexOf(',')),
                            contextId: 0,
                            notarizeId: data.substring(data.indexOf(',') + 1),
                            hist: "Pipeline"
                        });
                    self.$router.push('/admin/signing');
                });

                var self = this;
                $(".package-journal.link").on("click", function (e) {
                    e.preventDefault();
                    console.log("pckg e", e.data);
                    self.$router.push('/admin/notary/' + $(this).attr("data-id"));
                });
            },
            cellTmp: function (e) { 
                return { 
                    template: dashboardCanViewLoanCellTemplate,
                    templateArgs: e
                }
            },
            statusTemplate(e) {
                var html = `<div class="status ` + e.statusDescription.toLowerCase() + `" ` + this.$options.__scopeId + `><span>` + GridDataHelper.normalizeStatus(e.statusDescription) + `</span></div>`;
                return html;
            },
            parameterMap: function (options, operation) {
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            readData: function (e) {
                var user = this.$store.state.Admin.loginInformation;
                if (e != null && e.data != null && e.data.filter != null) {
                    for (var i = 0; i < e.data.filter.filters.length; i++) {
                        let fld = e.data.filter.filters[i].field;
                        console.log(e.data.filter.filters[i]);
                        if (fld != null && fld != 'undefined')
                            e.data.filter.filters[i].field = fld.charAt(0).toUpperCase() + fld.slice(1);
                        else if (e.data.filter.filters[i].logic != null) {
                            for (var j = 0; j < e.data.filter.filters[i].filters.length; j++) {
                                fld = e.data.filter.filters[i].filters[j].field;
                                e.data.filter.filters[i].filters[j].field = fld.charAt(0).toUpperCase() + fld.slice(1);
                            }
                        }


                        if (e.data.filter.filters[i].field == 'StatusDescription') {
                            var replaceItem = PACKAGE_STATUS_MAP.find((x) => x.desc == e.data.filter.filters[i].value);
                            if (replaceItem !== undefined) {
                                e.data.filter.filters[i].field = 'PackageStatus';
                                e.data.filter.filters[i].value = replaceItem.id;
                            }
                        }
                    }
                }

                return ApiService.GeneratePostRequest(`Admin/orders`, e.data).then(
                    response => {
                        e.success(response["content"]);
                    }
                );
            },
            onHeaderSelectionChange(event) {
                let checked = event.event.target.checked;
            },
            onSelectionChange(event) {
                event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
            },
            onRowClick(event) {
                event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
            },
            filterChange: function (ev) {
                this.filter = ev.filter;
            },
            cancelEditSave() {
                this.itemInEdit = null;
            },
            saveLoan() {
                this.itemInEdit = null;
            },
            exportSelectedExcel() {
                var selected = [];
                var grid = this.$refs.kendoGridRef.kendoWidget();
                grid.select().each(function () {
                    selected.push(grid.dataItem(this));
                });
                console.log(selected);
                saveExcel({
                    data: selected,
                    fileName: "pipeline",
                    columns: this.columns
                });

            },
            exportSelectedPDF() {
                var selected = [];
                var grid = this.$refs.kendoGridRef.kendoWidget();
                grid.select().each(function () {
                    selected.push(grid.dataItem(this));
                });
                console.log("Selected", selected);
                (this.$refs.gridPdfExport).save(selected);
            },
            exportExcel() {
                var self = this;
                this.readData({
                    success: function (data) {
                        console.log("export data ", data.data);
                        saveExcel({
                            data: process(data.data, {
                                skip: 0
                            }).data,
                            fileName: "pipeline",
                            columns: self.columns
                        });
                    }
                });
            },
            searchGrid: function () {
                console.log(this.search);
            },
            exportPDF() {
                var self = this;
                this.readData({
                    success: function (data) {
                        var options = {
                            skip: 0
                        }
                        const itemsToExport = process(data.data, options).data;
                        (self.$refs.gridPdfExport).save(itemsToExport);

                        return data;
                    }
                });

            },
            packageDataBound: function(e) {
                var self = this;
                $(".package-journal.link").on("click", function (e) {
                    e.preventDefault();
                    console.log("pckg e", e.data);
                    self.$router.push('/admin/notary/' + $(this).attr("data-id"));
                });

            },
            columnReorder: function (e) {
                var self = this;
                var grid = e.sender;
                var col = e.column;

                if (e.oldIndex === 0) {
                    setTimeout(function (e) {
                        grid.reorderColumn(0, col);
                    }, 0);
                } else if (e.newIndex === 0) {
                    var oldIndex = e.oldIndex;
                    setTimeout(function (e) {
                        grid.reorderColumn(oldIndex, col);
                    }, 0);
                }

                setTimeout(function () {
                    self.saveColumnOrder();
                }, 1);
            },
            saveColumnOrder: function () {
                var self = this;
                if (this.configLock) {
                    return;
                }

                this.configLock = true;

                var grid = this.$refs.kendoGridRef.kendoWidget();

                var colOrder = [];

                for (var i = 0; i < grid.columns.length; i++) {
                    var col = grid.columns[i];
                    colOrder[i] = { field: col.field, hidden: col.hidden || col.hidden == 'true' ? 'true' : 'false' };
                }

                ApiService.GenerateNonLoadingPostRequest("Admin/SetPipelineConfig", colOrder).then(function () {
                    self.configLock = false;
                });
            },
            columnVisibilityEvent: function () {
                var self = this;
                setTimeout(function () {
                    self.saveColumnOrder();
                }, 1);
            }
        }
    }
</script>