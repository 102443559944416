<template>
    <pdfexport ref="PdfExport" forcePageBreak=".page-break" :page-template="pageTemplate" margin="0.25in" :paper-size="'Letter'">
        <div id="exportContent" ref="container" class="container">
            <div class="pdfexport">
                <!-- eConsent start-->
                <div style="text-align:center;">
                    <div class="header">
                        <div class="navbar-header">
                            <div class="logo"><img src="/images/smart-esign-logo.svg" alt=""></div>
                        </div>
                        <br />
                        <h1>e-Signature {{logType}} Audit Log</h1>
                        <hr class="k-hr" />
                    </div>
                    <div class="header-details">
                        <table style="margin:auto; width:50%; text-align:left;">
                            <tr>
                                <td>
                                    <strong>Loan Number:</strong>
                                </td>
                                <td>
                                    {{loanDetail.loanNumber}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Property Address:</strong>
                                </td>
                                <td>
                                    {{loanDetail.propertyAddress}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Lender:</strong>
                                </td>
                                <td>
                                    {{loanDetail.lenderName}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Borrower(s): </strong>
                                </td>
                                <td>
                                    {{loanDetail.borrowerName}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Report Generated Date:</strong>
                                </td>
                                <td>
                                    {{ moment().format("MMMM Do, YYYY &nbsp;&nbsp; h:mm:ss a") }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Generated IP Address:</strong>
                                </td>
                                <td>
                                    {{ip }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="text-align:left;">
                    <div v-for="item in consentList" :key="item.auditLogID" style="width:5.5in;">
                        <h3 class="pdfexport">e-Signature Consent</h3>
                        <h3 style="color:forestgreen;">
                            {{item.performedByName}} accepted eSign Consent on {{ moment(item.timestamp).format("MMMM Do, YYYY &nbsp;&nbsp; h:mm:ss a") }}
                            <br />
                        </h3>
                        <p v-html="item.eConsent" style="width: 5.5in; text-align:justify;" />
                    </div>
                </div>                
                <!-- eConsent end-->
                <div style="text-align: left;">
                    <div v-if="packageDocuments" >
                        <div v-for="i in packageDocuments" :key="i.documentID">
                            <document-log :docId="i.documentID" :packageId="pckgId" />                            
                        </div>
                    </div>
                    <div v-if="pckgLog.length" id="pkg-audit-logs" class="container">
                        <div>
                            <h3>Audit Log</h3>
                        </div>
                        <table style="width:5.6in; text-align:left;">
                            <thead>
                                <tr>
                                    <th style="width:.5in;">
                                        Timestamp
                                    </th>
                                    <th style="width:1.8in;">
                                        Action
                                    </th>
                                    <th style="width:.5in;">
                                        User
                                    </th>
                                    <th style="width:.5in;">
                                        IP Address
                                    </th>
                                </tr>
                            </thead>
                            <tr v-for="item in pckgLog" :key="item.auditLogId">
                                <td style="width:.5in;">
                                    {{ moment.parseZone(item.timestamp).format("MM-DD-YYYY hh:mm A") }}
                                </td>
                                <td style="width: 1.8in; word-wrap: anywhere; padding-right:.3in ">
                                    {{item.detailDescription}}
                                </td>
                                <td style="width: .5in; word-wrap: anywhere; padding-right: .1in ">
                                    {{item.performedByName}}
                                </td>
                                <td style="width:.5in;">
                                    {{item.ipAddress}}
                                </td>
                            </tr>
                        </table>                        
                    </div>
                </div>                
            </div>
        </div>
    </pdfexport>
</template>

<script>
    import moment from "moment";
    import { PDFExport, savePDF } from "@progress/kendo-vue-pdf";
    import ApiService from "@/core/services/api-service";
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { markRaw, h } from 'vue';
    import DocumentAuditLogPDF from "@/components/AuditLogs/DocumentAuditLog";

    export default {
        name: "AuditLogPDF",
        computed: {
            moment: () => moment
        },
        components: {
            grid: Grid,
            pdfexport: PDFExport,
            documentLog: DocumentAuditLogPDF
        },
        props: ["documentParticipants", "docId", "pckgId"],
        data: function () {
            return {
                consentList: {},
                documentDetails: [],
                loanPackage: "",
                loanDetail: {},
                packageId: "",
                packageDocuments: [],
                pckgLog: [],
                borrowerName: "",
                lenderName: "",
                logType: "",
                pageTemplate: markRaw({
                    props: {
                        pageNum: Number,
                        totalPages: Number
                    },
                    render: function () {
                        return h('div', {
                            style: { position: 'absolute', bottom: '10px', right: '10px' }
                        }, ['Page ' + this.$props.pageNum + ' of ' + this.$props.totalPages])
                    }
                }),
            }
        },
        async mounted() {
        },
        methods: {
            moment() {
                return moment();
            },
            exportPDFWithComponent: function () {
                console.log("called export from audit log pdf vue page. pkg id ", this.pckgId, this.docId);
                savePDF(this.$refs.container, {
                    paperSize: "Letter",
                    margin: 25,
                    fileName: `AuditLog`,
                    pageTemplate: "pageTemplate",
                    forcePageBreak: ".page-break",

                });
            },

            async populateLog(packageAuditLog = null, consentAuditLog = null) {
                console.log("populateLog called in AuditLogPDF");
                this.packageId = this.pckgId;
                let docRequest = {};
                docRequest.LoanID = parseInt(this.pckgId);
                docRequest.PackageId = parseInt(this.pckgId);
                if (this.docId && this.docId > 0) {
                    let docDetails = {};
                    docDetails.documentID = parseInt(this.docId);
                    this.packageDocuments.push(docDetails);
                    this.logType = "Document";
                    console.log("doc response", this.docId);

                } else {
                    if (this.pckgId && this.pckgId > 0) {
                        this.logType = "Package";
                        if (packageAuditLog == null) {
                            await ApiService.GenerateGetRequest(
                                "Package/GetPackageAuditLog/" + this.pckgId
                            ).then((response) => {
                                this.pckgLog = response;
                                console.log("package response", this.pckgId);
                            });
                        } else {
                            this.pckgLog = packageAuditLog;
                        }
                    }

                }

                if (this.pckgId) {

                    await ApiService.GeneratePostRequest(
                        "Package/GetLoanPackageById/" + this.pckgId
                    ).then((response) => {
                        this.loanDetail = response.content;
                        this.lenderName = this.loanDetail.lenderName;
                        this.borrowerName = this.loanDetail.borrowerName;
                        this.ip = this.loanDetail.ip;
                        console.log("LOAN DETAIL", this.loanDetail)
                    });

                }
                console.log(this.pckgLog, "package log");
                //get econsent
                if (consentAuditLog == null) {
                    await ApiService.GenerateGetRequest(
                        "Package/GetPackageConsentAuditLog/" + this.pckgId
                    ).then((response) => {
                        if (response && response.isSuccess) {
                            this.consentList = response.content;
                        }
                    });
                } else {
                    this.consentList = consentAuditLog;
                }
            },

        },

    };
</script>

<style scoped>
    .container {
        text-align: left;
    }

    table td, th {
        vertical-align: top;
    }

    .audit_list {
        margin: 0;
    }

    .audit_list ul {
        margin: 0;
        padding: 0;
        height: 400px;
        width: 100%;
        float: left;
        overflow-y: scroll;
    }

    .audit_list ul li {
        margin: 5px 0 15px 0;
        list-style: none;
        padding: 2px 0 8px 0;
    }

    .audit_list ul li:hover {
        background: rgba(78, 78, 78, 0.1);
    }

    .audit_list ul li .icon {
        float: left;
        margin: 6px 10px 0 0;
    }

    .audit_list ul li .icon .material-icons {
        font-size: 35px;
    }

    .audit_list ul li .title {
        font: 600 1.14em "Lato", sans-serif;
    }

    .audit_list ul li .time {
    }

    .pdfexport {
        width: 6.5in;
        font-size: 9px;
        overflow: hidden;
    }

    .pdfexport h1 {
        font-size: 14px;
    }

    .pdfexport h2, .pdfexport h3, h2 {
        font-size: 12px !important;
    }

    .pdfexport .logo img {
        width: 50% !important;
        margin-top: 10px;
    }

    .pdfexport td, th {
        padding: 0 2px 0 2px;
        word-wrap: anywhere;
    }

    .header {
        width: 5in;
        text-align: left;
    }
</style>
