<template>
    <div class="bdr bdr-rad-15 container">
        <div class="bdr-bottom row">
            <div class="title col-md-12">
                <div class="col-md-12 text-center">
                    <h2>Test Audio / Video</h2>
                </div>
            </div>
        </div>
        <div class="row row-flex">
            <div class="innerbox col-md-6">
                <div class="row" style="margin-bottom: 50px;">
                    <test-camera @camera-selected="cameraSelected" />
                </div>
                <div class="row" style="margin-bottom: 50px;">
                    <test-audio @audio-selected="audioSelected" />
                </div>
                <div class="row" style="margin-bottom: 50px;">
                    <test-internet-connection />
                </div>
            </div>
            <div class="innerbox dark-bg col-md-6 column-flex">

                <test-hardware-instructions />
            </div>
        </div>
    </div>
</template>

<script>

    import TestCamera from "@/components/forms/invitation-steps/test/TestCamera.vue";
    import TestAudio from "@/components/forms/invitation-steps/test/TestAudio.vue";
    import TestInternetConnection from "@/components/forms/invitation-steps/test/TestInternetConnection.vue";
    import TestHardwareInstructions from "@/components/forms/invitation-steps/test/TestHardwareInstructions.vue";

    
    export default {
        name: 'TestAudioVideo',
        props: ["preloadedData"],
        emits: ["componentValid"],
        components: {
            TestCamera,
            TestAudio,
            TestInternetConnection,
            TestHardwareInstructions
        },
        data() {
            return {
                itemToShow: "video",

                selectedCamera: false,
                selectedAudio: false,
            }
        },
        methods: {
            async isValid() {
                // check inputs ref for data
                console.log("hello");
                return true;
            },
            cameraSelected() {
                this.selectedCamera = true;
                if (this.selectedAudio)
                    this.$emit("componentValid", true);
                else
                    this.$emit("componentValid", false);
            },
            audioSelected() {
                this.selectedAudio = true;
                if (this.selectedCamera)
                    this.$emit("componentValid", true);
                else
                    this.$emit("componentValid", false);
            },
        },
    }
</script>
