<template>
    <div>  
        <h2>Signer's Session'</h2>
        <div id="screen-subscriber" v-show="sType == 'Notary'"></div>
        <div id="screen-publisher" v-show="sType == 'Signer'"></div>
        <kbutton @click="initializeSession" v-if="!callClicked && sType=='Signer'">Share Screen</kbutton>
        <kbutton @click="disconnectSession" v-if="callClicked" style="float:right">Stop Share Screen</kbutton>
    </div>
</template>

<script>
    import OT from '@opentok/client';
    import ApiService from "@/core/services/api-service";
    import { Button } from "@progress/kendo-vue-buttons";

    const errorHandler = err => {
        console.log("error message for screenshare", err);
    };

    var session = null;
    var spublisher = null;
    var subscriber = null;
    var sessionId = "";
    var token = "";
    var apiKey = "";
    var streamCreated = false;

    export default {
        name: 'ScreenShare',
        props: ["NotarizeID", "Type"],
        components: {
            "kbutton": Button,
        },
        async mounted() {
            console.log("notarize from session");
            var getOTSession = await ApiService.GenerateGetRequest("notarize/session/get/" + this.NotarizeID);

            // If a session has not been created for a notarize id, then create one; else, return the existing session data
            if (getOTSession["content"] != null) {
                console.log("getOTSessionfromDB", getOTSession);
                apiKey = getOTSession["content"].apiKey;
                sessionId = getOTSession["content"].sessionId;
                token = getOTSession["content"].token;
            } else {
                var response = await ApiService.GenerateGetRequest("notarize/session/create/" + this.NotarizeID);
                console.log("created new session", response);
                if (response["isSuccess"]) {
                    apiKey = response["content"][0];
                    sessionId = response["content"][1];
                    token = response["content"][2];
                } else {
                    alert('Failed to get opentok sessionId and token.');
                }
            }

            console.log("type", this.sType);
            session = OT.initSession(apiKey, sessionId);
            // Connect to the session
            session.connect(token, function (error) {
                if (error) {
                    this.handleError(error);
                }
            });

            // Subscribe to a newly created stream
            session.on('streamCreated', (event)=> {
               
                if (streamCreated == false) {
                    subscriber = event.stream;
                    session.subscribe(subscriber, "screen-subscriber", {
                        insertMode: 'append',
                    }, this.handleError);
                    streamCreated = true;
                }
                
            });

            // Executed when the session is unsubscribed
            session.on('streamDestroyed', event => {
                subscriber = null;
                streamCreated = false;
            });

        },
        data: () => ({
            callClicked: false,
        }),
        methods: {
            errorHandler,
            handleError: function (error) {
                if (error) {
                    alert(error.message);
                }
            },

            // Begin session
            initializeSession: function () {          
                this.callClicked = true;
                // Create a publisher
                spublisher = OT.initPublisher('screen-publisher', {
                    videoSource: 'screen',
                    insertMode: 'append',
                }, this.handleError);

                // If the connection is successful, initialize a publisher and publish to the session
                
                    session.publish(spublisher);
                
            },

            //End session
            disconnectSession: function () {

                this.callClicked = false;
                session.disconnect();                
                if (spublisher != null) {
                    session.unpublish(spublisher);
                }
                if (subscriber != null) {
                    session.unsubscribe(subscriber);
                }                
                spublisher = null;
                subscriber = null;
                streamCreated = false;

            }
        },
    }
</script>

<style>

    #screen-subscriber {
        position: absolute;
    }

    #screen-publisher {
        position: absolute;
        width: 20rem;
        height: 20rem;
        bottom: 10px;
        left: 0;
        z-index: 100;
    }

    /*.OT_publisher, .OT_subscriber {
        margin-top: 40rem;
    }*/

</style>
