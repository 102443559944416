<template>
  <k-dialog @close="cancel" :style="{ zIndex: 1 }">
    <div :style="{ marginBottom: '1rem' }">
      <label> Assign New Participant </label><br />
      <dropdownlist
        :data-items="
          packageParticipants.map(function(x) {
            return { ...x, name: x.firstName + ' ' + x.lastName };
          })
        "
        :value="selectedParticipant"
        :text-field="'name'"
        :data-item-key="'participantId'"
        @change="handleChange"
      >
      </dropdownlist>
    </div>
    <dialog-actions-bar :style="{ justifyContent: 'center' }">
      <button class="k-button" @click="cancel">
        Cancel
      </button>
      <button class="k-button k-primary" @click="save">
        Save
      </button>
    </dialog-actions-bar>
  </k-dialog>
</template>
<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { NumericTextBox, Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import ItemRenderer from "./ParticipantItemRenderer.vue";
import ValueRenderer from "./ParticipantValueRenderer.vue";

export default {
  components: {
    "k-input": Input,
    numerictextbox: NumericTextBox,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    dropdownlist: DropDownList,
    "custom-item-renderer": ItemRenderer,
    "custom-value-renderer": ValueRenderer,
  },
  props: ["packageParticipants"],
  data: function() {
    return {
      packageParticipants: [],
      selectedParticipant: "",
      participantInEdit: {
        Name: "",
        Role: "",
        EmailAddress: "",
      },
      itemRenderer: "myTemplate",
      valueRenderer: "valueTemplate",
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$emit("save", this.selectedParticipant);
    },
    handleChange(event) {
      this.selectedParticipant = event.value;
    },
  },
};
</script>
