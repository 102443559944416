<template>
    <div class="col-md-5 col-sm-12" style="padding-top: 20px;">
        <p class="text-black" style="font-size: 1.2em; ">
            <strong>Please review your documents prior to starting your notary session</strong>
        </p>
        <p class="text-black" style="font-size: 1.2em; ">
            <strong>Some documents may be eligible to sign prior to your notary session</strong>
        </p>
        <p class="text-black" style="font-size: 1.2em; ">
            <strong>Within 10 minutes of your scheduled appointment time, you will be able to start the notary session</strong>
        </p>
    </div>
    <div class="col-md-5 col-sm-12" style="padding-top: 10px;">
        <div>
            <a href="#" @click.prevent="readyToSignPressed" class="start-notary-session-button" :class="[ !SignReady ? 'disabled':'']">Start Notary Session</a>

            <p v-if="!SignReady && timeTillSigning > 0" class="notary-session-countdown"><span class="text-black"><strong>{{formatTime(timeTillSigning)}} Until Appointment</strong></span></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "IntroCountdown",
        props: ["SignReady", "secondsTillSigning"],
        emits: ["signingReady", "readyToSign"],
        components: {
        },
        data() {
            return {
                interval: null,
                timeTillSigning: 0,
            };
        },
        created() {
            if (this.SignReady) {
                this.timeTillSigning = 0;
                return;
            }

            if (typeof this.secondsTillSigning === 'number') {
                this.timeTillSigning = this.secondsTillSigning;
                this.startCountDown();
            }
        },
        watch: {
            secondsTillSigning: function (newVal, oldVal) {
                if (newVal !== oldVal && typeof newVal === 'number' && !this.SignReady)
                    this.timeTillSigning = newVal;
                    this.startCountDown();
            },
        },
        methods: {
            formatTime(timeInSeconds) {
                const h = Math.floor(timeInSeconds / 3600).toString().padStart(2, '0'),
                    m = Math.floor(timeInSeconds % 3600 / 60).toString().padStart(2, '0'),
                    s = Math.floor(timeInSeconds % 60).toString().padStart(2, '0');

                return (h == '00' ? "" : h + ':') + m + ':' + s;
            },
            readyToSignPressed() { 
                if (!this.SignReady) {
                    return;
                }
                if (this.secondsTillSigning <= 0 || this.SignReady)
                    this.$emit('readyToSign');
            },
            startCountDown() {
                if (this.SignReady) return;
                if (this.interval)
                    clearInterval(this.interval);
                this.interval = setInterval(() => {
                    this.timeTillSigning -= 1;
                    if (this.timeTillSigning <= 0) {
                        clearInterval(this.interval);
                        this.$emit('signingReady');
                        this.startCheckForReadyToSign();
                    }
                }, 1000);
            },
            startCheckForReadyToSign() {
                if (this.SignReady) return;
                // time can potentially be a few seconds off - so we just poll every 5 seconds until server returns we can sign
                this.interval = setInterval(() => {
                    if (this.SignReady) {
                        clearInterval(this.interval);
                    }
                    if (this.timeTillSigning <= 0 && !this.SignReady) {
                        this.$emit('signingReady');
                    }
                }, 5000);
            }
        },
    };
</script>

<style scoped>


    .start-notary-session-button {
        background: #3F51B5;
        padding: 10px;
        border-radius: 22px;
        color: #FFF;
        font-weight: bold;
        border: 2px solid #3F51B5;
        width: 250px;
        display: inline-block;
        text-align: center;
        font-size: 1.4em;
        cursor: pointer;
        text-decoration: none;
    }


        .start-notary-session-button.disabled {
            background: #a19d9d;
            border: 2px solid #888686;
            cursor: auto;
        }



        .start-notary-session-button:hover {
            text-decoration: none;
        }

    .notary-session-countdown {
        width: 250px;
        text-align: center;
        font-weight: bold;
        margin-top: 5px;
    }
</style>
