<template>
    <div>
        <div>
            <div>
                <div class="col">
                    <h5>
                        Calendar TimeZone
                        <dropdownlist id="dropdownlist"
                                      :style="{ width: '500px' }"
                                      :data-items='timeZones'
                                      :data-item-key="'id'"
                                      :text-field="'timeZoneName'"
                                      :value="selectedTZ"
                                      @change="changeTimeZones">
                        </dropdownlist>

                    </h5>
                </div>
            </div>
        </div>
        <br />
        <br />
        <div id="scheduler">
            <kendo-scheduler ref="kendoScheduler" :data-source="localDataSource"
                             :default-date="date"
                             :height="800"
                             :editable="false">
                <!--:timezone="selectedTZ">-->
                <kendo-scheduler-view :type="'day'"></kendo-scheduler-view>
                <kendo-scheduler-view :type="'workWeek'"></kendo-scheduler-view>
                <kendo-scheduler-view :type="'week'"></kendo-scheduler-view>
                <kendo-scheduler-view :type="'month'" :selected="true"></kendo-scheduler-view>
                <kendo-scheduler-view :type="'agenda'"></kendo-scheduler-view>
            </kendo-scheduler>
        </div>
    </div>
</template>
<script>
    import { Scheduler, SchedulerView } from '@progress/kendo-scheduler-vue-wrapper';
    import ApiService from "../../core/services/api-service";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import "@progress/kendo-date-math/tz/all";
    import '@progress/kendo-date-math/tz/regions/Europe';
    import '@progress/kendo-date-math/tz/Europe/Sofia'; 
    export default {
        name: 'AdminCalendar',
        components: {
            'kendo-scheduler': Scheduler,
            'kendo-scheduler-view': SchedulerView,
            'dropdownlist': DropDownList
        },
        data: function () {
            return {
                date: new Date(),
                localDataSource: [],
                timeZones: [],
                selectedTZ: 'Etc/UTC',
                //resources: [
                //    {
                //        field: "lenderId", // The field of the Scheduler event which contains the resource identifier.
                //        title: "Lender", // The label displayed in the Scheduler edit form for this resource.
                //        dataSource: [
                //            { text: "Lender 101", value: 1939, color: "#1c9ec4" },
                //            { text: "Lender 102", value: 1987, color: "#ff7663" },
                //            { text: "Lender 103", value: 2985, color: "#800000" },
                //            { text: "Lender 104", value: 1504, color: "#000080" },
                //            { text: "Lender 105", value: 1195, color: "#008080" },
                //            { text: "Lender 106", value: 1593, color: "#5f9ea0" },
                //            { text: "Lender 107", value: 1135, color: "#add8e6" },
                //            { text: "Lender 108", value: 1603, color: "#fff0f5" },
                //            { text: "Lender 109", value: 1602, color: "#7b68ee" }
                //        ]
                //    }]
            }
        },
        async mounted() {
            let self = this;
            //get timezone details
            ApiService.GenerateGetRequest("Package/currenttimezone").then(
                response =>
                {
                    this.selectedTZ = response.content != null ? response.content : this.timeZones.find(x => x.id == 7);
                }
            );

            $("#scheduler").kendoTooltip({
                filter: ".k-scheduler-content td, .k-scheduler-content .k-event-template",
                width: 300,
                position: "right",
                showOn:"mouseenter",
                content: function (e) {
                    var slot = self.$refs.kendoScheduler.kendoWidget().slotByElement(e.target);
                    if (!slot)
                        return "No Events";
                    var isTextOnly = $(e.target).hasClass("k-event-template");
                    
                    var events = self.$refs.kendoScheduler.kendoWidget().occurrencesInRange(slot.startDate, slot.endDate);
                    var content = "";
                    var html = [];
                    console.log(events[0]);
                    for (var i = 0; i < events.length; i++) {
                         
                        var signingdate = moment(String(events[i].transDate)).format('MM/DD/YYYY');

                        var cal_events = "<div><label>Loan#: &nbsp;</label>" + events[i].loanNumber + "";
                        cal_events += "<br /><label>Name: &nbsp;</label>" + events[i].borrowerName + "";
                        cal_events += "<br /><label>Notes: &nbsp;</label>" + events[i].notes + ""; 
                        cal_events += "<br /><label>Signing Date: &nbsp;</label>" + signingdate + "</div>";
                        //html.push("<div>" + events[i].title + "</div>");
                        html.push(cal_events);

                        if (isTextOnly) {
                            if (events[i].title === $(e.target).text()) {
                                content = html[i];
                                break;
                            }
                        } else {
                            content = html.join("");
                        }

                        if (!isTextOnly)
                            html.push("<hr/>");
                    }
                    return content == "" ? "No events" : content;
                }
            });
        },
        async created() {
            this.loadData();
            this.loadTimeZones();
        }, 
        methods: {
            parameterMap: function (options, operation) {
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            loadData: function () {
                return ApiService.GeneratePostRequest("Admin/schedules").then(
                    (response) => {
                        this.localDataSource = response.content; 
                    });
            },
            changeTimeZone(event) {
                this.selectedTZ = event.target.value;
            },
            loadTimeZones: function () {
                return ApiService.GeneratePostRequest("Package/timezones").then(
                    (response) => {
                        this.timeZones = response.content;
                    });
            },
            async changeTimeZones(event) {
                this.selectedTZ = event.target.value; 
                let response = await ApiService.GeneratePostRequest("admin/tschedules?name=" + event.target.value.description, {
                    "name": event.target.value.description
                });

                if (response["isSuccess"] && response["content"]) {   this.localDataSource= response["content"]; }
               
            }
        }
    }
</script>