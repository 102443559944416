<template>
  <!-- <Tooltip :anchor-element="'target'" :position="'top'"> -->
  <k-button
    title="Open"
    :icon="'eye'"
    @click="viewHandler"
    class="customBtn"
    id="open-btn"
  />

  <k-button
    title="Download"
    :icon="'download'"
    @click="downloadHandler"
    class="customBtn"
    id="dl-btn"
  />

  <k-button
    title="Print"
    :icon="'print'"
    @click="printHandler"
    class="customBtn"
    id="prnt-btn"
  />
  <!-- </Tooltip> -->
</template>
<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Tooltip } from "@progress/kendo-vue-tooltip";

export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  components: {
    "k-button": Button,
    Tooltip,
  },
  methods: {
    downloadHandler: function() {
      this.$emit("download", this.dataItem);
    },
    viewHandler: function() {
      this.$emit("view", this.dataItem);
    },
    printHandler: function() {
      this.$emit("print", this.dataItem);
    },
  },
};
</script>

<style scoped>
.customBtn {
  font-size: small;
  padding: 4px 8px;
  color: #4d97ac;
}
</style>
