<template>
    <div class="row" style="margin-top:30px;">
        <div style="margin-top:100px;"
             class="col-md-8 col-md-offset-2 text-center"
             v-if="files.length === 0">
            <h1>Hang On</h1>
            <h3>Fetching Documents</h3>
        </div>

        <div class="transition" :class="containerWidth">

            <h2 class="col-md-12">Documents For Review</h2>
            <!--<template v-for="(file, i) in files">
        <div class="transition "
             :class="cellWidth"
             v-if="file.isReview"
             @click.prevent="fileClicked(file, i)">
            <span class="pdf-icon"></span>
            <p>{{ file.documentTitle }}</p>
        </div>
    </template>-->
            <ul>
                <template v-for="(file, i) in files">
                    <li class="transition "
                        :class="cellWidth"
                        v-if="file.isReview"
                        @click.prevent="fileClicked(file, i)">
                        &bull;{{ file.documentName }}
                    </li>
                </template>
            </ul>
            <h2 style="margin-top:50px" class="col-md-12">Documents For Signing</h2>
            <!--<template v-for="(file, i) in files">
        <div class="transition "
                :class="cellWidth"
                v-if="!file.isReview"
                @click.prevent="fileClicked(file, i)">
            <span class="pdf-icon"></span>
            <p>{{ file.documentTitle }}</p>
        </div>
    </template>-->
            <ul>
                <template v-for="(file, i) in files">
                    <li class="transition "
                        :class="cellWidth"
                        v-if="!file.isReview"
                        @click.prevent="fileClicked(file, i)">
                        &bull;{{ file.documentName }}
                    </li>
                </template>
            </ul>
        </div>

    <div class="col-md-10"
         style="margin-bottom:10px;"
         v-if="fileView">
        <div>
            <h3 style="display: inline-block">{{ currentName }}</h3>
        </div>

        <iframe v-if="!isXML"
                style="width: 100%;height:1000px"
                :src="path"></iframe>
        <iframe v-if="isXML"
                style="width: 100%; height: 1000px"
                :srcdoc="path"></iframe>
        <button v-if="isReview && !isReviewed" style="height: auto;" class="btn-primary" @click="agreeToDocument">I Agree</button>
        <button class="btn-secondary pull-right" @click="close">Close</button>
    </div>
    </div>
</template>
<style scoped>
    .transition {
        /*transition: all 1.25s ease; lil buggy*/
        font-size: 16px;
        padding-left: 10px;
        cursor: pointer;
        word-wrap: normal;
    }

    .pdf-icon {
        font-size: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        position: relative;
        width: 6em;
        height: 8.5em;
        background-color: #eee;
        /*background-image: url("https://i.imgur.com/lZ5SgDE.png");*/
        background-repeat: no-repeat;
        -webkit-background-size: 85% auto;
        -moz-background-size: 85% auto;
        background-size: 85% auto;
        background-position: center 2em;
        border-radius: 1px 2em 1px 1px;
        border: 1px solid #ddd;
        margin-top: 10px;
        margin-right: 10px;
        cursor: pointer;
    }

        .pdf-icon:after {
            content: "PDF";
            font-family: Arial;
            font-weight: bold;
            font-size: 1.2em;
            text-align: center;
            padding: 0.2em 0 0.1em;
            color: #fff;
            display: block;
            position: absolute;
            top: 0.7em;
            left: -1.5em;
            width: 3.4em;
            height: auto;
            background: #da2525;
            border-radius: 2px;
        }
</style>
<script>
    import ApiService from "@/core/services/api-service";
    import { loadFileUrl } from "@/core/services/api-service"
    export default {
        name: "ReviewDocuments",
        props: ["preloadedData"],
        emits: ["componentValid"],
        async created() {
            let response = await ApiService.GenerateGetRequest("document/signer");
            if (response && response["isSuccess"]) {
                console.log("response docs", response["content"]);
                this.files = response["content"];

                // format date to date obj
                for (let i = 0; i < this.files.length; i++) {
                    // hardcoded to test reviewing documents
                    //this.files[i].isReview = true;
                    if (this.files[i]["signingDate"] !== null) {
                        this.files[i]["signingDate"] = new Date(this.files[i]["signingDate"]);
                    }
                }
            }


            console.log("in here", (await this.isValid()));
            if ((await this.isValid())) {
                this.$emit("componentValid", true);
            }
        },
        data() {
            return {
                files: [],
                path: "",
                currentName: "",
                index: 0,
                docId: 0,
                fileView: false,
                isXML: false,
                isReview: false,
                isReviewed: false,
            };
        },
        computed: {
            containerWidth() {
                return this.fileView ? "col-md-2" : "col-md-8 col-md-push-2";
            },
            cellWidth() {
                return this.fileView ? "col-md-12" : "col-md-12";
            },
        },
        methods: {
            async isValid() {
                let isValid = true;
                for (let i = 0; i < this.files.length; i++) {
                    if (this.files[i]["signingDate"] > new Date()) {
                        isValid = false;
                        break;
                    }
                }
                return isValid;
            },
            async fileClicked(file, index) {
                this.currentName = file.documentTitle;
                this.isReview = file.isReview || false;
                this.isReviewed = file.isReviewed || false;
                this.index = index|| 0;
                this.docId = file.id || 0;
                if (file["isEnote"]) {
                    this.path = `<div style="position: absolute;left: 50%;transform: translateX(-50%);">${file.html}</div>`;
                    this.isXML = true;
                } else {
                    this.isXML = false;
                    // debugger
                    // let results = await ApiService.GenerateGetBlobRequest("document/load?path=" + file.docURL);
                    // let blob = new Blob([results], { type: 'application/pdf' });
                    // let blobUrl = window.URL.createObjectURL(blob);
                    // this.path = blobUrl;
                    this.path = loadFileUrl + file.renderType;
                }

                this.fileView = true;
            },
            async agreeToDocument() {
                console.log(this.docId);
                console.log(this.index);
                // let response = await ApiService.GeneratePostRequest("document/update/reviewed", {
                //     isReviewed: true,
                //     documentId: this.docId
                // });
                // console.log(response);
                // if (response["isSuccess"]) {
                //     this.files[this.index]["isReviewed"] = true;
                //     alert("File Marked As Reviewed");
                // } else
                //     alert("There was an issue marking your document as reviewed!");

                this.files[this.index]["isReviewed"] = true;
                alert("File Marked As Reviewed");
            },

            close() {
                this.currentName = "";
                this.path = "";
                this.fileView = false;
            },
        },
    };
</script>
